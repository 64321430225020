import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import config from 'src/assets/config.json';
@Component({
  selector: 'app-column-filter-text-box',
  templateUrl: './column-filter-text-box.component.html',
  styleUrls: ['./column-filter-text-box.component.scss'],
})
export class ColumnFilterTextBoxComponent implements OnInit, OnChanges {
  searchField = new UntypedFormControl('');
  @Input() placeHolder: string;
  @Input() columnKey: string;
  @Input() columnFilterText: any = undefined;
  @Input() searchType: string;
  @Output() SearchValue = new EventEmitter<string>();
  private oldVal = '';

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if ('columnKey' in changes) {
      if (this.columnFilterText !== undefined && this.columnFilterText[this.columnKey] !== undefined) {
        if (this.columnFilterText[this.columnKey][this.searchType] !== null || undefined)
          this.searchField.setValue(this.columnFilterText[this.columnKey][this.searchType]);
      }
    }
  }

  ngOnInit(): void {
    this.searchField.valueChanges
      .pipe(debounceTime(config.DebounceTime), distinctUntilChanged())
      .subscribe((searchValue: string) => {
        if (searchValue !== this.oldVal) {
          this.oldVal = searchValue;
          this.SearchValue.emit(searchValue);
        }

      });
  }

  public clearInput() {
    this.oldVal = '';
    this.columnFilterText[this.columnKey] = undefined;
    this.searchField.setValue('');
  }
}
