<div class="leader-dashboard">

  <app-top-bar></app-top-bar>

  <div class="container-fluid">
    <div class="row">

      <div class="col-4">
        <app-function-diagram (doubleClickBarChart)="doubleClickBarEvent($event, realisedPotentialFunctionChart)"
          (selectedYearChange)="yearSelectionFunctionDiagramChange($event, realisedPotentialFunctionChart)"
          [functionsStatistics]="realisedPotentialFunctionData" [chartId]="realisedPotentialFunctionChart"
          [labels]="['Samlet potentiale', 'Realiseret resultat']"></app-function-diagram>
      </div>

      <div class="col-4">
        <app-function-diagram (doubleClickBarChart)="doubleClickBarEvent($event, remainingPotentialFunctionChart)"
          (selectedYearChange)="yearSelectionFunctionDiagramChange($event, remainingPotentialFunctionChart)"
          [functionsStatistics]="remainingPotentialFunctionData" [chartId]="remainingPotentialFunctionChart"
          [labels]="['Samlet potentiale', 'Rest potentiale']"></app-function-diagram>
      </div>

      <div class="col-4">
        <app-card-table (selectedYearChange)="tableCardAnalysisStatus($event)" [yearSelector]="true"
          [footerinfo]="'Potentiale og status på gennemgang pr. analyse'" [tableInformation]="potentialStatusTable">
        </app-card-table>
      </div>

      <div class="col-4">
        <app-card-diagram [chartId]="userManagementsPollsChart" (selectionChange)="selectionCardDiagramChange($event, userManagementsPollsChart)"
          [leftYUnit]="'kr'" [rightYUnit]="null" [cardDiagramDataSet]="userManagementsPollsData" [selectionList]="users"
          [labels]="['Realiseret potentiale', 'Antal behandlinger']"></app-card-diagram>
      </div>

      <div class="col-4">
        <app-card-diagram [chartId]="postingAndInvoicePollsChart" (selectionChange)="selectionCardDiagramChange($event, postingAndInvoicePollsChart)"
          [leftYUnit]="null" [rightYUnit]="null" [labels]="['Antal posteringer ', 'Antal fakturaer']"
          [cardDiagramDataSet]="postingAndInvoicePollsData" [hasTopInfo]="true"></app-card-diagram>
      </div>

      <div class="col-4">
        <app-card-table (selectedYearChange)="updateUserInformationTable($event)" [yearSelector]="true" [footerinfo]="'Brugeroplysninger'"
          [tableInformation]="userInformation">>
        </app-card-table>
      </div>

    </div>
  </div>
</div>
