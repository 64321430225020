import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { AnalysisFilter, PostingFilter } from 'src/app/api/vat';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['./keyword.component.scss'],
})
export class KeywordComponent implements OnInit, OnDestroy {
  faTrash = faTrash;
  searchWord: string;
  @Input() type: string;
  @Input() selectedFilters: Observable<AnalysisFilter | PostingFilter>;
  public selectedNodes: string[] = [];
  subscriptions: Subscription[] = [];
  constructor(private internalFilterService: InternalFilterService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.selectedFilters.subscribe((filters) => {
        if (this.isSelectedFilterEmpty(filters[this.type])) {
          filters[this.type] = [];
        }

        this.updateInternalFilterSelection(filters[this.type]);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  AddSearchWordToSelectedNodes(): void {
    const exists = this.selectedNodes.includes(this.searchWord);
    if (!exists && this.searchWord !== '') {
      this.selectedNodes.push(this.searchWord);
    }
    this.updateFilter();
    this.searchWord = '';
  }

  private updateInternalFilterSelection(selectedData: string[]) {
    this.selectedNodes = selectedData as string[];
  }

  public deleteSelectedNode(node: string): void {
    const index = this.selectedNodes.indexOf(node as string);

    if (index > -1) {
      this.selectedNodes.splice(index, 1);
    }

    this.updateFilter();
  }

  private updateFilter() {
    this.internalFilterService.updateSideFilter(this.type, this.selectedNodes);
  }

  private isSelectedFilterEmpty(selectedFilter): boolean {
    return selectedFilter === undefined || selectedFilter === null;
  }
}
