<aside [ngClass]="sidebarOpen ? '' : 'closed'">
  <ng-container *ngFor="let menuItem of menuItems">
    <ng-container *ngIf="!menuItem.children; else childrenMenu">
      <ul>
        <li>
          <a routerLink="/{{ menuItem.path }}" [attr.aria-label]="menuItem.title" [matTooltip]="menuItem.description"
            routerLinkActive="active">
            <mat-icon>{{ menuItem.icon }}</mat-icon>
            <span>{{ menuItem.title }}</span>
          </a>
        </li>
      </ul>
    </ng-container>
    <ng-template #childrenMenu>
      <mat-accordion class="accordion">
        <mat-expansion-panel class="accordion__panel" [expanded]="menuItem.open">
          <mat-expansion-panel-header class="accordion__header" [attr.aria-label]="menuItem.title"
            matTooltip="{{menuItem.description}}">
            <mat-icon>{{menuItem.icon}}</mat-icon> <span>{{ menuItem.title }}</span>
          </mat-expansion-panel-header>
          <ul *ngIf="menuItem.children.length > 0">
            <li *ngFor="let child of menuItem.children">
              <a [attr.aria-label]="child.title" [matTooltip]="child.description + child.path"
                [routerLink]="'/' + child.path" routerLinkActive="active">
                <mat-icon>{{child.icon}}</mat-icon>
                <span>{{ child.title }}</span>
              </a>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>
  </ng-container>
</aside>