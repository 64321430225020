import { Component, Inject, OnInit } from "@angular/core";
import { faTimes, faUser, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Message } from 'src/app/api/vat';

@Component({
  selector: "app-expanded-message",
  templateUrl: "./expanded-message.component.html",
  styleUrls: ["./expanded-message.component.scss"],
})
export class ExpandedMessageComponent implements OnInit {
  // Icons
  faTimes = faTimes;
  faUser = faUser;
  faCalendar = faCalendar;

  constructor(public dialogRef: MatDialogRef<ExpandedMessageComponent>, @Inject(MAT_DIALOG_DATA) public message: Message) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
