import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostingNoteChangeService {
  private notificationOnPostingNoteChanged: Subject<void> = new Subject<void>();
  public notificationOnPostingNoteChanged$: Observable<unknown> =
    this.notificationOnPostingNoteChanged.asObservable();
  constructor() {}

  notifyNoteOnPostings() {
    this.notificationOnPostingNoteChanged.next();
  }
}
