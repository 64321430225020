import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { InternalUserService } from '@modules/commons/services/internal-user.service';
import { User } from 'src/app/api/vat';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private internalUserService: InternalUserService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkUserLogin(next, url);

  }

  private checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    const auth = this.oidcSecurityService.isAuthenticated();
    if (!auth) {
      this.router.navigate(['login']);
      return false;
    }

    this.internalUserService.user$.subscribe((user: User) => {
      const currentUserRoles: string[] = user?.roles;
      let anyRolesFound: boolean = false;
      if (currentUserRoles && route.data.roles !== undefined) {
        for (const role of route.data.roles) {
          if (route.data.roles && currentUserRoles?.indexOf(role) === -1) {
            anyRolesFound = false;
          } else {
            anyRolesFound = true;
            break;
          }
        }

        if (anyRolesFound === false) {
          this.router.navigate(['unauthorized']);
          return false;
        } else {
          return true;
        }

      }
    });

    return true;
  }
}
