<div class="dialog">
  <div class="dialog__header">
    <span class="icon material-icons-outlined"></span> Kolonneindstillinger
  </div>
  <p> *Ændring i kolonneindstillinger er kun gældende for den valgte analyse</p>
  <button mat-icon-button class="dialog__close" (click)="close()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
  <div class="dialog__content">
    <div cdkDropList class="drag-drop" (cdkDropListDropped)="dragAndDrop($event)">
      <div class="drag-drop__box" *ngFor="let column of switchAbleColumnDefinitions; let i = index" cdkDrag
        [cdkDragDisabled]="isDisabled(column)" cdkDragLockAxis="y"
        [ngStyle]="{'background-color':column.switchAble === true ? '' : 'lightgrey' }">
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        <span>
          <span class="drag-drop__id">{{i}}</span><span class="drag-drop__name">{{column.name}}</span>
        </span>
        <mat-checkbox class="example-margin" [(ngModel)]="column.visible"></mat-checkbox>
      </div>
    </div>
  </div>
  <div class="dialog__buttons">
    <button mat-flat-button color="primary" *ngIf="isNotOnPostingNote()" (click)="save(false)">
      <span class="icon material-icons-outlined"></span>
      Gem
    </button>
    <button mat-stroked-button color="primary" (click)="save(true)">
      <span class="icon material-icons-outlined"></span>
      Gem midlertidigt
    </button>
    <button mat-flat-button color="warn" (click)="reset()">
      <span class="icon material-icons-outlined"></span>
      Standardindstillinger
    </button>
    <button mat-stroked-button color="warn" (click)="cancel()">
      <span class="icon material-icons-outlined"></span>
      Annuller
    </button>
  </div>
</div>