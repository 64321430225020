import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Material
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

// Components
import { FavoriteListComponent } from './components/favorite-list/favorite-list.component';

@NgModule({
  declarations: [FavoriteListComponent],
  imports: [CommonModule, FontAwesomeModule, MatListModule, MatButtonModule],
  exports: [FavoriteListComponent],
})
export class FavoritesModule {}
