import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@modules/store/services/local-storage.service';
import storeKeys from '@modules/store/localStorageKeys.json';
import {
  AnalysisFilter,
  Customer,
  PagingModel,
  Period,
  PostingFilter,
  PostingService,
} from 'src/app/api/vat';
//import { AnalysisFilter, Customer, PagingModel, Period, PostingFilter, PostingService } from 'src/app/api/vat';
import { BehaviorSubject, Observable } from 'rxjs';
import { LatestDetail } from 'src/app/global-models';
import configJson from 'src/assets/config.json';

@Injectable({
  providedIn: 'root',
})
export class InternalFilterService {
  postingFilterChanged: boolean = true;

  private analysisFilter$: BehaviorSubject<AnalysisFilter> =
    new BehaviorSubject<AnalysisFilter>(this.setDefaultAnalysisFilter());
  private sideFilter;
  private postingFilter$: BehaviorSubject<PostingFilter> =
    new BehaviorSubject<PostingFilter>(this.setDefaultPostingFilter());
  public sideFilterChanged$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public analysisFilter: Observable<AnalysisFilter> =
    this.analysisFilter$.asObservable();
  public postingFilter: Observable<PostingFilter> =
    this.postingFilter$.asObservable();

  constructor(
    private activatedRoute: ActivatedRoute,
    private postingService: PostingService,
    private localStorageService: LocalStorageService
  ) {
    const path = this.activatedRoute.children[0].routeConfig.path;
    if (path === 'posteringer/:analysisId') {
      this.getlatestBackendPostingFilter();
    }
  }

  public updateTypeFilter(type: string, selectedNodes) {
    this.postingFilterChanged = true;
    const filter = this.getCurrentFilter();
    if (selectedNodes === null || selectedNodes.length < 0) filter[type] = [];
    else filter[type] = selectedNodes;
    this.updateBehaviorSubjectFilter(filter);
  }

  public updateSideFilter(type: string, selectedNodes) {
    this.sideFilter = this.getCurrentFilter();
    this.postingFilterChanged = true;
    this.sideFilterChanged$.next(true);
    if (selectedNodes === null || selectedNodes.length < 0)
      this.sideFilter[type] = [];
    else this.sideFilter[type] = selectedNodes;
  }
  public searchOnSideFilter() {
    this.sideFilterChanged$.next(false);
    this.updateBehaviorSubjectFilter(this.sideFilter);
  }

  public updatePagingModelOnPostingFilter(
    pagingModel: PagingModel,
    analysisId: number
  ): void {
    const filter = this.postingFilter$.getValue();
    this.postingFilterChanged = this.pageModelHasChanged(pagingModel);
    filter.pageModel = pagingModel;
    filter.analysisId = analysisId;
    this.requestPostingFilterUpdate(filter);
  }

  public updatePostingFilters(postingFilter: PostingFilter): void {
    this.requestPostingFilterUpdate(postingFilter);
  }

  updatePostingFilterFromAnalysisFilter(analysisId: number): void {
    const analysisFilter = this.analysisFilter$.getValue();
    const postingFilter: PostingFilter = {
      analysisId: analysisId,
      periods: analysisFilter.periods,
    };
    postingFilter.sortBy = {
      propertyName: null,
      ascending: analysisFilter.sortBy.ascending,
    };
    postingFilter.pageModel = null;

    Object.getOwnPropertyNames(analysisFilter).forEach((property) => {
      if (property !== 'sortBy' && property !== 'period') {
        postingFilter[property] = analysisFilter[property];
      }
    });
    this.requestPostingFilterUpdate(postingFilter);
  }

  updateAnalysisFilterFromPostingFilter(postingFilter: PostingFilter): void {
    const analysisFilter: AnalysisFilter = {
      periods: postingFilter.periods,
    };

    analysisFilter.sortBy = {
      propertyName: null,
      ascending: postingFilter.sortBy.ascending,
    };

    Object.getOwnPropertyNames(postingFilter).forEach((property) => {
      if (
        property !== 'sortBy' &&
        property !== 'period' &&
        property &&
        property !== 'analysisIds' &&
        property !== 'analysisId'
      ) {
        analysisFilter[property] = postingFilter[property];
      }
    });
    this.analysisFilter$.next(analysisFilter);
  }

  private getlatestBackendPostingFilter(): void {
    const customerId = this.getCurrentCustomerId();
    const analysisId = this.localStorageService
      .getObject<LatestDetail[]>(storeKeys.LatestAnalysisStorageKey)
      .filter((l) => l.customerId === customerId)[0].analysisId;
    if (analysisId > -1) {
      this.postingService
        .getPostingFilterByAnalysisId(analysisId)
        .subscribe((postingFilter: PostingFilter) => {
          this.requestPostingFilterUpdate(postingFilter);
        });
    }
  }

  private getCurrentCustomerId(): string {
    return (
      (
        this.localStorageService.getObject<Customer>(
          storeKeys.CurrentSelectedCustomerStorageKey
        ) as Customer
      )?.customerId?.toString() ?? ''
    );
  }

  public resetAllFiltersToDefault(): void {
    let filter = this.getCurrentFilter();
    filter = this.setDefaultAnalysisFilter();
    this.analysisFilter$.next(filter);
    filter = this.setDefaultPostingFilter((filter as PostingFilter).analysisId);
    this.requestPostingFilterUpdate(filter);
  }

  public removeAllFilters() {
    let filter = this.getCurrentFilter();
    if (this.activatedRoute.children[0].routeConfig.path === 'analyser') {
      filter = this.setDefaultAnalysisFilter();
      filter.periods = this.getRangedPeriod();
      this.analysisFilter$.next(filter);
    } else {
      filter = this.setDefaultPostingFilter(
        (filter as PostingFilter).analysisId
      );
      filter.periods = this.getRangedPeriod();
      this.requestPostingFilterUpdate(filter);
    }
  }

  public updateAnalysisFilters(analysisFilter: AnalysisFilter): void {
    this.analysisFilter$.next(analysisFilter);
  }

  public updatePeriodFilter(periods: Period[]): void {
    this.postingFilterChanged = true;
    const filter = this.getCurrentFilter();
    filter.periods = periods;
    this.updateBehaviorSubjectFilter(filter);
  }

  private updateBehaviorSubjectFilter(filter): void {
    switch (this.activatedRoute.children[0].routeConfig.path) {
      case 'analyser':
        this.analysisFilter$.next(filter as AnalysisFilter);
        break;

      case 'posteringer/:analysisId':
        this.requestPostingFilterUpdate(filter as PostingFilter);
        break;

      default:
        break;
    }
  }

  public eraseTimeZoneOffSet(periodDateFrom: Date): string {
    const UTCDate =
      Date.UTC(
        periodDateFrom.getFullYear(),
        periodDateFrom.getMonth(),
        periodDateFrom.getDate()
      ) - periodDateFrom.getTimezoneOffset();

    return new Date(UTCDate).toISOString();
  }

  private getCurrentFilter(): any {
    if (this.activatedRoute.children[0].routeConfig.path === 'analyser') {
      return this.analysisFilter$.getValue();
    } else {
      return this.postingFilter$.getValue();
    }
  }

  public isPeriodsRangeSelected(currentPeriods: Period[]): boolean {
    if (currentPeriods.length === 0) {
      return false;
    }

    const rangePeriod: Period[] = this.getRangedPeriod();
    return (
      new Date(currentPeriods[0].fromDate).getFullYear() ===
        new Date(rangePeriod[0].fromDate).getFullYear() &&
      new Date(currentPeriods[0].toDate).getFullYear() ===
        new Date(rangePeriod[0].toDate).getFullYear()
    );
  }

  public getSelectedPeriodYears(): string {
    const fromYears: number[] = [];
    const toYears: number[] = [];
    this.postingFilter$.getValue().periods.forEach((period) => {
      fromYears.push(parseInt(period.fromDate.substring(0, 4), 0));
      toYears.push(parseInt(period.toDate.substring(0, 4), 0));
    });
    const union: number[] = [...new Set([...fromYears, ...toYears])];
    if (this.isPeriodsRangeSelected(this.postingFilter$.getValue().periods)) {
      return `${union[0]}-${union[1]}`;
    } else {
      let selectedYears = '';
      union.sort().forEach((year, index) => {
        selectedYears += year;

        if (index + 1 !== union.length) {
          selectedYears += ', ';
        }
      });
      return selectedYears;
    }
  }

  public getRangedPeriod(financialYearsLength = 6): Period[] {
    const currentYear = new Date().getFullYear();

    return [
      {
        fromDate: this.eraseTimeZoneOffSet(
          new Date(currentYear - (financialYearsLength - 1), 0, 1)
        ),
        toDate: this.eraseTimeZoneOffSet(new Date(currentYear, 11, 31)),
      } as Period,
    ];
  }

  private setDefaultPostingFilter(analysisId = null): PostingFilter {
    this.postingFilterChanged = true;
    return {
      periods: null,
      analysisId: analysisId,
      sortBy: {
        propertyName: null,
        ascending: false,
      },
      pageModel: {
        skip: 0,
        take: configJson.PageSizeBlock,
      },
    };
  }

  private setDefaultAnalysisFilter(): AnalysisFilter {
    return {
      periods: null,
      sortBy: {
        propertyName: 'title',
        ascending: true,
      },
    };
  }
  oldFilter: PostingFilter = undefined;

  private pageModelHasChanged(pageModel: PagingModel): boolean {
    if (this.oldFilter === undefined) return true;
    if (pageModel === undefined || pageModel === null) return true;
    if (this.oldFilter.pageModel === undefined) return true;
    if (this.oldFilter.pageModel?.skip ?? -1 !== pageModel.skip ?? -1)
      return true;
    if (this.oldFilter.pageModel?.take ?? -1 !== pageModel.take ?? -1)
      return true;
    if (this.oldFilter.pageModel?.sortBy !== pageModel.sortBy ?? '')
      return true;
    if (
      JSON.stringify(this.oldFilter.pageModel.columnFilters) !==
      JSON.stringify(pageModel.columnFilters)
    )
      return true;
    return false;
  }

  private filterHasChanged(filter: PostingFilter): boolean {
    if (this.oldFilter === undefined) return true;
    if (this.pageModelHasChanged(filter.pageModel)) return true;
    if (this.oldFilter.analysisId !== filter.analysisId) return true;
    if (this.oldFilter.texts !== filter.texts) return true;
    if (this.oldFilter.annexNos.sort() !== filter.annexNos.sort()) return true;
    if (this.oldFilter.costTypes.sort() !== filter.costTypes.sort())
      return true;
    if (this.oldFilter.dransts.sort() !== filter.dransts.sort()) return true;
    if (this.oldFilter.functions.sort() !== filter.functions.sort())
      return true;
    if (
      this.oldFilter.invoiceAttachments.sort() !==
      filter.invoiceAttachments.sort()
    )
      return true;
    if (this.oldFilter.invoiceTypes.sort() !== filter.invoiceTypes.sort())
      return true;
    if (this.oldFilter.ownerships.sort() !== filter.ownerships.sort())
      return true;
    if (this.oldFilter.periods.sort() !== filter.periods.sort()) return true;
    if (this.oldFilter.reposted.sort() !== filter.reposted.sort()) return true;
    if (this.oldFilter.admorgs.sort() !== filter.admorgs.sort()) return true;
    if (this.oldFilter.companyCodes.sort() !== filter.companyCodes.sort())
      return true;
    return false;
  }

  private requestPostingFilterUpdate(filter: PostingFilter): void {
    //has filter changed!
    if (this.postingFilterChanged || this.filterHasChanged(filter)) {
      this.postingFilterChanged = false;
      this.oldFilter = filter;
      this.updateAnalysisFilterFromPostingFilter(filter);
      this.postingFilter$.next(filter);
    }
  }
}
