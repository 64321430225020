import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-laws',
  templateUrl: './laws.component.html',
  styleUrls: ['./laws.component.scss'],
})
export class LawsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  scrollTop() {
    window.scrollTo(0, 0);
  }
}
