import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { faTimes, faList, faUser, faExpand, faCompressAlt } from '@fortawesome/free-solid-svg-icons';
import { YearSelectService } from '@modules/leader-dashboard/services/year-select.service';
import { FunctionsStatistic } from 'src/app/api/vat';
import { Chart, registerables } from 'chart.js';
import { SelectedFunction } from '../leader-dashboard/leader-dashboard.component';

@Component({
  selector: 'app-function-diagram',
  templateUrl: './function-diagram.component.html',
  styleUrls: ['./function-diagram.component.scss'],
})
export class FunctionDiagramComponent
  implements OnInit, AfterViewInit, OnChanges {
  // Icons
  faTimes = faTimes;
  faList = faList;
  faUser = faUser;
  faExpand = faExpand;
  faCompressAlt = faCompressAlt;

  myChart: Chart;

  cardExpanded: boolean = false;

  private touchtime = 0;

  @Input() labels: string[] = [];
  datasetOneTotal: number = 0;
  datasetTwoTotal: number = 0;

  @Input() chartId: string = '';
  @Input() functionsStatistics: SelectedFunction[] = [];
  @Output() doubleClickBarChart = new EventEmitter<any>();
  functionTitle: string = 'Alle';
  @Output() selectedYearChange = new EventEmitter<number>();
  public selectedYear: number;
  public previousFunctionNo: string = ' ';

  constructor(
    private yearSelectService: YearSelectService,
  ) {
    Chart.register(...registerables);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('functionsStatistics' in changes) {
      if (this.myChart !== undefined) {
        this.updateChartDiagramData();
      }
    }
  }

  ngAfterViewInit(): void {
    this.initiateChart();
    this.myChart.update();
  }

  ngOnInit(): void { }

  expandCard() {
    this.cardExpanded = !this.cardExpanded;
  }

  private updateChartDiagramData(): void {
    const selectedFunctions = this.functionsStatistics.filter(
      (f) => f.checkboxToggle === true
    );

    if (selectedFunctions.length !== 0) {
      this.myChart.data.datasets[0].data = selectedFunctions.map(
        (f) => f.dataSetOne
      );
      this.myChart.data.datasets[1].data = selectedFunctions.map(
        (f) => f.dataSetTwo
      );
      this.myChart.data.labels = selectedFunctions.map((f) => f.functionNo);
    } else {
      this.myChart.data.datasets[0].data = this.functionsStatistics.map(
        (f) => f.dataSetOne
      );
      this.myChart.data.datasets[1].data = this.functionsStatistics.map(
        (f) => f.dataSetTwo
      );
      this.myChart.data.labels = this.functionsStatistics.map(
        (f) => f.functionNo
      );
    }

    this.calculateSumOfDataSet();

    this.myChart.update();
  }

  private calculateSumOfDataSet(): void {
    this.datasetOneTotal =
      this.myChart.data.datasets[0].data.length > 0
        ? (this.myChart.data.datasets[0].data.reduce(
          (sum: number, current: number) => sum + current
        ) as number)
        : 0;
    this.datasetTwoTotal =
      this.myChart.data.datasets[1].data.length > 0
        ? (this.myChart.data.datasets[1].data.reduce(
          (sum: number, current: number) => sum + current
        ) as number)
        : 0;
  }

  public yearSelectChange(year: number): void {
    this.selectedYear = year;
    this.previousFunctionNo = ' ';
    this.selectedYearChange.emit(year);

  }

  public checkboxToggle(
    event: MatCheckboxChange,
    functionsStatistic: FunctionsStatistic
  ): void {
    const index: number = this.functionsStatistics.findIndex(
      (sf: SelectedFunction) => sf.functionNo === functionsStatistic.functionNo
    );
    this.functionsStatistics[index].checkboxToggle = event.checked;

    this.updateChartDiagramData();
    this.yearSelectService.setResetButton(true);
  }

  public doubleClickChartElement(evt, item: any[]): void {
    if (this.touchtime === 0) {
      this.touchtime = new Date().getTime();
    } else {
      if (new Date().getTime() - this.touchtime < 400) {
        const selectedItemBarValue = evt.chart.tooltip.title[0];
        this.previousFunctionNo = selectedItemBarValue;
        this.doubleClickBarChart.emit({
          functionNo: selectedItemBarValue,
          year: this.selectedYear,
        });
      } else {
        this.touchtime = new Date().getTime();
      }
      this.yearSelectService.setResetButton(true);

    }
  }

  goBackToPreviousChartView(): void {
    if (this.previousFunctionNo.length === 2) {
      this.previousFunctionNo = ' ';
    } else {
      this.previousFunctionNo = this.previousFunctionNo.substring(0, 2);
    }
    this.doubleClickBarChart.emit({
      functionNo: this.previousFunctionNo,
      year: this.selectedYear,
    });
  }

  private initiateChart(): void {
    const canvas = document.getElementById(this.chartId) as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    this.myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [
          {
            label: this.labels[0],
            data: [],
            order: 1,
            backgroundColor: ['#69b2f7'],
          },
          {
            label: this.labels[1],
            data: [],
            type: 'bar',
            order: 2,
            backgroundColor: ['#164b6d'],
          },
        ],
        labels: [],
      },
      options: {
        onClick: (event, item) => {
          this.doubleClickChartElement(event, item);
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            ticks: {
              major: {
                enabled: false,
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            grid: {
              display: true,
            },
            ticks: {
              callback(label: number, index, labels) {
                return `${label.toLocaleString('da-DK')} kr`;
              },
            },
          },
        },
      },
    });
  }
}
