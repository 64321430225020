import { Component, OnDestroy, OnInit } from '@angular/core';
import { FavoriteService } from '@modules/favorites/services/favorite.service';
import { Analysis } from 'src/app/api/vat';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit, OnDestroy {
  favoriteAnalysis: Analysis[];
  subscriptions: Subscription[] = [];

  constructor(private favoriteService: FavoriteService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.favoriteService.favoriteAnalysis.subscribe((res: Analysis[]) => {
        this.favoriteAnalysis = res;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
