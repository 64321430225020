import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

// Materials
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';

// Components
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { VatCurrencyPipe } from './pipes/vat-currency.pipe';
import { PostingActionSelectorComponent } from './components/posting-action-selector/posting-action-selector.component';
import { UppercaseSpacePipe } from './pipes/uppercase-space.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { FilterKeyRemovePipe } from './pipes/filter-key-remove.pipe';
import { CorrectedPotentialeInputBoxComponent } from '../commons/components/corrected-potentiale-input-box/corrected-potentiale-input-box.component';
import { AppHasRoleDirective } from '@modules/commons/directives/app-has-role.directive';
import { MessageTimeAuthorComponent } from './components/message-time-author/message-time-author.component';

@NgModule({
  declarations: [
    YearPickerComponent,
    VatCurrencyPipe,
    PostingActionSelectorComponent,
    UppercaseSpacePipe,
    StatusPipe,
    FilterKeyRemovePipe,
    CorrectedPotentialeInputBoxComponent,
    AppHasRoleDirective,
    MessageTimeAuthorComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatListModule,
    BrowserModule,
    MatTooltipModule,
    ReactiveFormsModule
  ],
  exports: [
    YearPickerComponent,
    VatCurrencyPipe,
    PostingActionSelectorComponent,
    UppercaseSpacePipe,
    StatusPipe,
    FilterKeyRemovePipe,
    CorrectedPotentialeInputBoxComponent,
    AppHasRoleDirective,
    MessageTimeAuthorComponent
  ],
})
export class CommonsModule { }
