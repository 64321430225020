import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  NgZone,
  ElementRef,
  Directive,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  faArrowDown,
  faTimes,
  faFileExcel,
  faComment,
  faCaretDown,
  faLessThan,
  faGreaterThan,
  faEquals,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayService } from '@modules/overlay/services/overlay.service';
import {
  Posting,
  UpdatePosting,
  PostingService,
  FileResponse,
} from 'src/app/api/vat';
import {
  ExcelColumnItem,
  ExcelExportBody,
  PostingAndFilter,
  PostingFilter,
} from 'src/app/api/vat';
import { Column } from 'src/app/global-models';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import * as FileSaver from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs/operators';
import { MessagesService } from '@modules/messages/services/messages.service';
import {
  FilterBarService,
  headerChip,
} from '@modules/filter/services/filter-bar.service';
import { PagingModel } from 'src/app/api/vat';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { HeaderService } from '@modules/shell/services/header.service';
import moment from 'moment';
import config from 'src/assets/config.json';
import { LoadingSpinnerService } from '@modules/shell/services/loading-spinner.service';
import { PostingStatusChangeService } from '@modules/commons/services/posting-status-change.service';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import { LoadingTemplateFactoryService } from '@modules/commons/services/loading-template-factory.service';
import { Subject, Subscription } from 'rxjs';
import { NotifyService } from '@modules/shell/services/notify.service';
import {
  CheckBoxService,
  PostingCheckbox,
} from '@modules/postings/services/check-box.service';
import { PostingNoteChangeService } from '@modules/commons/services/posting-note-change.service';

export const MY_FORMAT1 = {
  parse: {
    dateInput: 'MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MMMM YYYY',
  },
};
export const MY_FORMAT2 = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMAT1 }],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  // icons
  faArrowDown = faArrowDown;
  faTimes = faTimes;
  faFileExcel = faFileExcel;
  faComment = faComment;
  faCaretDown = faCaretDown;
  faLessThan = faLessThan;
  faGreaterThan = faGreaterThan;
  faEquals = faEquals;

  subscriptions: Subscription[] = [];

  // properties
  isTableLoading: boolean = true;
  columnKeys: string[] = [];
  displayedColunms: Column[] = [];
  dataSource: MatTableDataSource<Posting> = new MatTableDataSource<Posting>();
  postingFilter: PostingFilter;
  postingNoteId: number = 0;

  columnFilter: PagingModel = {
    skip: 0,
    take: config.PageSizeBlock,
    sortBy: { propertyName: null, ascending: true },
  };
  // misc
  private selectedRowPostingID: number;

  selectInTable: boolean = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('scroller') scroller: ElementRef;
  columns: Column[];
  infinityScroll = new Subject<PagingModel>();
  scrollOpen: boolean = true;
  isAllChecked: boolean = false;
  currentRoutePath: string;
  private isStatusActionBeenActivated: boolean = false;
  audits = [
    ' ',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
  ];
  currentYear = new Date().getFullYear();
  minDate = new Date(this.currentYear - 5, 0, 1);
  maxDate = new Date(this.currentYear, 11, 31);
  chosenYear: string;
  constructor(
    private overlayService: OverlayService,
    private internalFilterService: InternalFilterService,
    private postingService: PostingService,
    private labelGlossaryService: LabelGlossaryService,
    private messageServie: MessagesService,
    private filterBarService: FilterBarService,
    private ngZone: NgZone,
    private headerService: HeaderService,
    private spinnerService: LoadingSpinnerService,
    private postingStatusChangeService: PostingStatusChangeService,
    private postingNoteChangeService: PostingNoteChangeService,
    private route: ActivatedRoute,
    private loadingTemplateFactoryService: LoadingTemplateFactoryService,
    private notifyService: NotifyService,
    private checkBoxService: CheckBoxService
  ) {}

  ngOnDestroy(): void {
    this.resetColumnFilterObject();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private resetColumnFilterObject(): void {
    this.columnFilter = {
      skip: 0,
      take: config.PageSizeBlock,
      sortBy: { propertyName: null, ascending: true },
    };
    this.internalFilterService.updatePagingModelOnPostingFilter(
      this.columnFilter,
      this.postingFilter?.analysisId
    );
  }

  ngOnInit(): void {
    this.dataSource.data =
      this.loadingTemplateFactoryService.getPostingTemplate;
    this.currentRoutePath = this.route.snapshot.routeConfig.path;

    this.subscriptions.push(
      this.overlayService.currentSelectedPostingID$.subscribe((postingID) => {
        if (this.selectedRowPostingID !== postingID) {
          this.selectedRowPostingID = postingID;
        }
      })
    );

    this.subscriptions.push(
      this.labelGlossaryService.postingTableColumnsDefinition$.subscribe(
        (columns: Column[]) => {
          this.displayedColunms = columns.filter((o) => o.visible === true);
          this.columnKeys = this.displayedColunms.map((s) => s.key);
          this.ngZone.onMicrotaskEmpty.pipe(take(3)).subscribe(() => {
            this.table.updateStickyColumnStyles();
          });
        }
      )
    );

    this.subscriptions.push(
      this.overlayService.postingshasRelatedNote$.subscribe(
        (idList: number[]) => {
          this.dataSource.data.forEach((o: Posting) => {
            if (
              o.postingNoteIds.findIndex((p) => idList.includes(p) === true) !==
              -1
            ) {
              o.hasNote = true;
              o.hasRelatedNote = true;
            } else o.hasRelatedNote = false;
          });
        }
      )
    );

    this.subscriptions.push(
      this.infinityScroll
        .pipe(debounceTime(100), distinctUntilChanged())
        .subscribe(() => {
          this.resolveScroll();
        })
    );

    this.subscriptions.push(
      this.postingStatusChangeService.notificationOnPostingStatusChanged$.subscribe(
        () => {
          this.columnFilter.skip = 0;
          this.inifinyScrollResolve();
          this.internalFilterService.updatePagingModelOnPostingFilter(
            this.columnFilter,
            this.postingFilter.analysisId
          );
          this.toggleAll(false);
        }
      )
    );
    this.subscriptions.push(
      this.postingNoteChangeService.notificationOnPostingNoteChanged$.subscribe(
        () => {
          this.columnFilter.skip = 0;
          this.inifinyScrollResolve();
          this.internalFilterService.updatePagingModelOnPostingFilter(
            this.columnFilter,
            this.postingFilter.analysisId
          );
          this.toggleAll(false);
        }
      )
    );
    this.load();
  }

  checkStickyEnd() {
    return this.displayedColunms.filter((x) => x.isStickyEnd === true).length;
  }

  private roundNumberToFirstFifty(num: number): number {
    return Math.round(num / 50) * 50;
  }

  private inifinyScrollResolve(): void {
    let takeCalculation =
      (this.dataSource.data.length / config.PageSizeBlock) *
      config.PageSizeBlock;
    takeCalculation = this.roundNumberToFirstFifty(takeCalculation);
    this.columnFilter.take =
      this.roundNumberToFirstFifty(takeCalculation) <= config.PageSizeBlock
        ? config.PageSizeBlock
        : takeCalculation;
    this.isStatusActionBeenActivated = true;
  }

  isColumnCheckboxSelected(value: any, key: string): boolean {
    if (
      this.columnFilter !== undefined &&
      this.columnFilter?.columnFilters !== undefined
    ) {
      if (
        this.columnFilter?.columnFilters[key]?.inList.includes(
          value?.toString()
        )
      )
        return true;
    }
    return false;
  }
  getCurrency(row: Posting): string {
    return row.invoice?.invoiceCurrencyCode;
  }
  auditSelectionChanged(auditValue: string, row: Posting): void {
    if (this.isAllChecked === false) {
      const selectedPostingIds = this.getCheckboxSelectedPostings();
      if (
        this.getCheckboxSelectedPostings()
          .map((post) => post.postingId)
          .includes(row.postingId) === false
      )
        selectedPostingIds.push(row);

      this.postingService
        .updateBDOAuditByPostingIds(
          auditValue,
          selectedPostingIds.map((p) => p.postingId)
        )
        .subscribe((postingUpdated: number) => {
          this.columnFilter.skip = 0;
          this.updateAuditRows(selectedPostingIds, auditValue);
          this.toggleAll(false);
          this.notifyService.notifySnackBarInformation(
            `${postingUpdated.toLocaleString(
              'da-DK'
            )} Posteringer har ændret BDOAudit til værdien ${auditValue}`,
            'green'
          );
          this.isTableLoading = false;
        });
    } else {
      if (this.isPostingNoteRoute()) {
        this.postingFilter.pageModel.skip = 0;
        const deselectedPostingIDs = this.getCheckboxNotSelectedPostings().map(
          (p) => p.postingId
        );
        this.isTableLoading = true;
        this.route.url.subscribe((url) => {
          this.postingNoteId = Number(url[2].path);
        });
        const updatePosting: UpdatePosting = {
          editedNoteId: this.postingNoteId,
          deSelectedPostings: deselectedPostingIDs,
        };
        this.postingService
          .updateBDOAuditByPostingNoteId(auditValue, updatePosting)
          .subscribe((postingUpdated: number) => {
            this.updateAuditRows(this.dataSource.data, auditValue);
            this.toggleAll(false);
            this.notifyService.notifySnackBarInformation(
              `${postingUpdated.toLocaleString(
                'da-DK'
              )} Posteringer har ændret BDOAudit til værdien ${auditValue}`,
              'green'
            );
            this.isTableLoading = false;
          });
      } else {
        this.postingFilter.pageModel.skip = 0;
        const deselectedPostingIDs = this.getCheckboxNotSelectedPostings().map(
          (p) => p.postingId
        );
        this.isTableLoading = true;

        const updatePosting: UpdatePosting = {
          postingFilter: this.postingFilter,
          deSelectedPostings: deselectedPostingIDs,
          editedNoteId: 0,
        };
        this.postingService
          .updateBDOAuditByPostingFilter(auditValue, updatePosting)
          .subscribe((postingUpdated: number) => {
            this.updateAuditRows(this.dataSource.data, auditValue);
            this.toggleAll(false);
            this.notifyService.notifySnackBarInformation(
              `${postingUpdated.toLocaleString(
                'da-DK'
              )} Posteringer har ændret BDOAudit til værdien ${auditValue}`,
              'green'
            );
            this.isTableLoading = false;
          });
      }
    }
  }

  private updateAuditRows(rows: Posting[], auditValue: string): void {
    if (this.columnFilter.sortBy?.propertyName === 'bdoAudit') {
      this.inifinyScrollResolve();
      this.internalFilterService.updatePagingModelOnPostingFilter(
        this.columnFilter,
        this.postingFilter.analysisId
      );
    } else {
      rows.forEach((p) => {
        if (p.isSelected) p.bdoAudit = auditValue;
      });
    }
  }

  getDataValue(row: Posting, columnKeys: string): string {
    const keys = columnKeys?.split('.');
    if (keys.length === 1) return row[keys[0]];

    if (row[keys[0]] !== null) return row[keys[0]][keys[1]];
  }

  changeOfCorrectedPotential(postingRow: Posting): void {
    this.toggle(postingRow, true);
  }

  public get totalPostings(): number {
    return this.columnFilter?.totalRows ?? 0;
  }

  public get totalSelectPostings(): number {
    return this.dataSource.data.map((p) => p.isSelected === true)?.length ?? 0;
  }

  private load(): void {
    this.subscriptions.push(
      this.internalFilterService.postingFilter
        .pipe(
          filter((pf) => pf.analysisId !== null),
          debounceTime(2000)
        )
        .subscribe((postingFilter: PostingFilter) => {
          this.isTableLoading = true;
          if (this.isPostingsRoute()) {
            this.subscriptions.push(
              this.postingService
                .getPostingsByFilter(postingFilter)
                .subscribe((posting: PostingAndFilter) => {
                  if (postingFilter.analysisId === config.GlobalAnalysisId) {
                    this.headerService.updateTitleHeader(
                      'Alle fakturaer og posteringer'
                    );
                  } else {
                    this.headerService.updateTitleHeader(
                      posting.postings[0]?.primaryAnalysisTitle
                    );
                  }
                  this.dataSource.data = posting.postings;
                  this.postingFilter = posting.postingFilter;
                  this.overlayService.updateStatusOnSelectedPostings(
                    posting.postings
                  );
                  this.columnFilter = posting.postingFilter.pageModel;
                  if (
                    this.isAllChecked &&
                    this.totalSelectPostings !==
                      this.postingFilter.pageModel.totalRows
                  ) {
                    this.isAllChecked = false;
                    this.toggleAll(false);
                  }
                  this.resolveCheckBoxes();
                  this.resolveScrollPosition();

                  this.isTableLoading = false;
                })
            );
          } else if (this.isPostingNoteRoute()) {
            this.postingNoteLoad();
          }
        })
    );

    this.postingNoteLoad();
  }

  private isPostingNoteRoute(): boolean {
    return this.currentRoutePath === 'posteringer/postingnote/:postingNoteId';
  }

  private isPostingsRoute(): boolean {
    return this.currentRoutePath === 'posteringer/:analysisId';
  }

  private postingNoteLoad(): void {
    const noteId = 'postingNoteId';
    const postingNoteId = this.route.snapshot.params[noteId] ?? -1;

    if (postingNoteId > -1) {
      this.displaySelectedMessagePostings(postingNoteId);
    }
    this.subscriptions.push(
      this.messageServie.SelectedMessage$.subscribe((postingNoteId) => {
        this.displaySelectedMessagePostings(postingNoteId);
      })
    );
  }

  resolveCheckBoxes(): void {
    if (this.isAllChecked) {
      this.dataSource.data.forEach((p) => {
        p.isSelected = true;
      });
    }
  }

  clickCheckboxArea(row: Posting, event): void {
    if (event.shiftKey) {
      setTimeout(() => {
        row.isSelected = true;
      }, 50);
    } else {
      event.stopPropagation();
    }
  }

  private displaySelectedMessagePostings(postingNoteId: number): void {
    this.postingService
      .postingsByPostingNoteId(postingNoteId, this.columnFilter)
      .subscribe((result: PostingAndFilter) => {
        if (result.postings[0]?.primaryAnalysisTitle !== undefined) {
          this.headerService.updateTitleHeader(
            result.postings[0].primaryAnalysisTitle
          );
        }

        this.columnKeys = this.displayedColunms.map((s) => s.key);
        this.dataSource.data = result.postings;
        this.columnFilter = result.postingFilter.pageModel;
        this.columnFilter.totalRows = result.postingFilter.pageModel.totalRows;
        this.overlayService.setOverlayClosed();
        this.postingFilter = {
          analysisId: result.postings[0]?.primaryAnalysisId,
        };
        const chip: headerChip = {
          filter: {},
          type: 'ding',
          removable: false,
          headline: 'Posteringsnote',
        };
        this.filterBarService.setHeaderChip(chip);
        this.isTableLoading = false;
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  firstShiftSelectedRowIndex: number = null;
  public addRowAsSelected(row: Posting, event: MouseEvent): void {
    if (this.isTableLoading) {
      return;
    }

    if (event.shiftKey) {
      row.isSelected = true;

      if (this.firstShiftSelectedRowIndex === null) {
        this.toggle(row, true);
        this.firstShiftSelectedRowIndex =
          this.getIndexFromDataSourceByPosting(row);
      } else {
        const temp = this.firstShiftSelectedRowIndex;
        let shiftSecondSelectedRowIndex =
          this.getIndexFromDataSourceByPosting(row);

        if (temp > shiftSecondSelectedRowIndex) {
          this.firstShiftSelectedRowIndex = shiftSecondSelectedRowIndex;
          shiftSecondSelectedRowIndex = temp;
        }

        for (
          let index = this.firstShiftSelectedRowIndex;
          index <= shiftSecondSelectedRowIndex;
          index++
        ) {
          this.toggle(this.dataSource.data[index], true);
        }
      }

      window.getSelection().removeAllRanges();
    } else {
      this.firstShiftSelectedRowIndex =
        this.getIndexFromDataSourceByPosting(row);

      this.overlayService.addPostingToTab(row);
      this.overlayService.updateCurrentPostingID(row.postingId);
      this.overlayService.setOverlayMedium();
      this.toggle(row, true);
    }
  }

  private getIndexFromDataSourceByPosting(postingRow: Posting): number {
    return this.dataSource.data.findIndex(
      (x) => x.postingId === postingRow.postingId
    );
  }

  isCurrentChosenRow(posting: Posting): boolean {
    return posting.postingId === this.selectedRowPostingID;
  }

  public toggle(posting: Posting, isChecked: boolean): void {
    posting.isSelected = isChecked;
    this.handleSelectedIds();
    this.isIndeterminate();
  }

  public isIndeterminate(): boolean {
    if (this.countIsSelected() === 0) {
      this.isAllChecked = false;
      return false;
    }

    if (
      this.countIsSelected() === this.columnFilter.totalRows &&
      this.columnFilter.totalRows > 0
    ) {
      this.isAllChecked = true;
      return false;
    }

    if (
      this.countIsSelected() === this.dataSource.data.length &&
      this.dataSource.data.length > 0
    ) {
      this.isAllChecked = true;
      return false;
    }

    if (
      this.getCheckboxNotSelectedPostings().map((p) => p.postingId).length >
        0 &&
      this.getCheckboxSelectedPostings().map((p) => p.postingId).length > 0 &&
      this.isAllChecked === true
    ) {
      return true;
    }

    if (
      this.countIsSelected() < this.dataSource.data.length &&
      this.countIsSelected() !== 0
    ) {
      return this.isAllChecked;
    }

    return this.countIsSelected() > 0 && this.isAllChecked === false;
  }

  handleSelectedIds(): void {
    this.checkBoxService.updateSelectionOfCheckbox({
      selected: this.getCheckboxSelectedPostings().map((p) => p.postingId),
      deSelected: this.getCheckboxNotSelectedPostings().map((p) => p.postingId),
      isAllSelected: this.isAllChecked,
    } as PostingCheckbox);
  }

  private getCheckboxSelectedPostings = () =>
    this.dataSource.data.filter((o) => o.isSelected === true);

  private getCheckboxNotSelectedPostings = () =>
    this.dataSource.data.filter((o) => o.isSelected === false);

  public isChecked(posting: Posting): boolean {
    return (
      this.dataSource.data
        .filter((o) => o.isSelected === true)
        .indexOf(posting) > -1
    );
  }

  public CheckIfAllIsChecked(): boolean {
    return this.isAllChecked === true;
  }

  get getCheckboxSelectedCount(): number {
    if (this.isAllChecked === true && this.isIndeterminate())
      return (
        this.columnFilter.totalRows -
          this.dataSource.data.filter((o) => o.isSelected === false).length ?? 0
      );

    if (
      this.getCheckboxNotSelectedPostings().map((p) => p.postingId).length >
        0 &&
      this.getCheckboxSelectedPostings().map((p) => p.postingId).length > 0 &&
      this.isIndeterminate() === true
    )
      return (
        this.columnFilter.totalRows -
          this.dataSource.data.filter((o) => o.isSelected === false).length ?? 0
      );

    if (this.isAllChecked === true) return this.columnFilter.totalRows;

    return this.countIsSelected();
  }

  public countIsSelected(): number {
    return (
      this.dataSource.data.filter((o) => o.isSelected === true).length ?? 0
    );
  }

  public toggleAll(checked: boolean): void {
    if (checked) {
      this.isAllChecked = true;
      this.dataSource.data.forEach((row: Posting) => {
        row.isSelected = true;
        this.toggle(row, true);
      });
      this.postingFilter.pageModel = this.columnFilter;
    } else {
      this.isAllChecked = false;
      this.dataSource.data.forEach((p) => (p.isSelected = false));
    }

    this.handleSelectedIds();
  }

  public exportPostings(): void {
    if (this.columnFilter.totalRows <= config.MaxRowsForExcelExport) {
      const excelColumnItem: ExcelColumnItem[] =
        this.labelGlossaryService.getExcelColumns();
      this.postingFilter.pageModel = this.columnFilter;

      const body: ExcelExportBody = {
        postingFilter: this.postingFilter,
        columns: excelColumnItem,
      };

      if (this.isPostingNoteRoute()) {
        const noteId = 'postingNoteId';
        const postingNoteId = this.route.snapshot.params[noteId];
        this.postingFilter.periods = [];
        this.postingService
          .exportPostingsByPostingNote(postingNoteId, body)
          .subscribe((res: FileResponse) => {
            FileSaver.saveAs(
              res.data,
              `rapport_${body.postingFilter.analysisId}.xlsx`
            );
          });
      }

      if (this.isPostingsRoute()) {
        this.postingService
          .exportPostings(body)
          .subscribe((res: FileResponse) => {
            FileSaver.saveAs(
              res.data,
              `rapport_${body.postingFilter.analysisId}.xlsx`
            );
          });
      }
    }
  }

  columnFilterMultiTextBox(
    event: { value: string; type: string },
    key: string
  ): void {
    if (this.columnFilter.columnFilters[key] === undefined)
      this.columnFilter.columnFilters[key] = {
        less: null,
        equals: null,
        greater: null,
      };

    this.columnFilter.columnFilters[key][event.type] = event.value;

    if (
      (this.columnFilter.columnFilters[key].greater === null ||
        this.columnFilter.columnFilters[key].greater === '') &&
      (this.columnFilter.columnFilters[key].equals === null ||
        this.columnFilter.columnFilters[key].equals === '') &&
      (this.columnFilter.columnFilters[key].less === null ||
        this.columnFilter.columnFilters[key].less === '')
    ) {
      this.resetColumnFilter(key);
    } else {
      this.updateAfterColumnFilterSearch();
    }
  }

  columnFilterTextBox(value: string, key: string): void {
    if (this.columnFilter.columnFilters[key] === undefined)
      this.columnFilter.columnFilters[key] = {};

    this.columnFilter.columnFilters[key].like = value;
    this.updateAfterColumnFilterSearch();
  }

  ColumnFilterDate(value, key): void {
    if (this.columnFilter.columnFilters[key] === undefined)
      this.columnFilter.columnFilters[key] = {};

    this.columnFilter.columnFilters[key].date = moment
      .utc(value.value)
      .add(2, 'hours')
      .toISOString();
    this.updateAfterColumnFilterSearch();
  }
  ColumnFilterYear(value, key, yearpicker): void {
    if (this.columnFilter.columnFilters[key] === undefined)
      this.columnFilter.columnFilters[key] = {};
    this.chosenYear = moment
      .utc(value)
      .add(2, 'hours')
      .toISOString()
      .substring(0, 4);
    this.columnFilter.columnFilters[key].like = this.chosenYear;

    yearpicker.close();
    this.updateAfterColumnFilterSearch();
  }
  ColumnFilterCheckBox(value: string[], key: string): void {
    // As we have merged "Reguler Moms" & "Intet Potientiale" into "Behandlet" but still need the functionality
    // To set them as one of the above, this is a filter work around.
    if (key === 'status') {
      if (value.includes('1')) {
        value.push('2'); // Adding RegulerMoms as selected for the work around.
      }
    }

    if (key === 'vatType') {
      if (value.includes('0')) {
        const index = value.indexOf('0');
        value[index] = null; // If 0 is selected, we need to send null, because the value in the database is Null.
      }
    }

    this.columnFilter.columnFilters[key] =
      value.length !== 0 ? { inList: value } : undefined;
    this.updateAfterColumnFilterSearch();
  }

  hasColumnFilter(key: string): boolean {
    try {
      return this.columnFilter.columnFilters[key] !== undefined ? true : false;
    } catch (error) {
      return false;
    }
  }

  updateAfterColumnFilterSearch(): void {
    this.setTableScrollBarToTop();
    this.columnFilter.skip = 0;
    this.columnFilter.take = config.PageSizeBlock;
    this.internalFilterService.updatePagingModelOnPostingFilter(
      this.columnFilter,
      this.postingFilter.analysisId
    );
  }

  resetColumnFilter(key: string): void {
    this.toggleAll(false);
    this.isAllChecked = false;
    this.columnFilter.columnFilters[key] = undefined;
    this.updateAfterColumnFilterSearch();
  }

  resolveScrollPosition(): void {
    if (this.isStatusActionBeenActivated) {
      this.isStatusActionBeenActivated = false;
      this.columnFilter.skip = this.columnFilter.take - config.PageSizeBlock;
      this.columnFilter.take = config.PageSizeBlock;
    } else if (this.columnFilter.skip === 0) {
      this.scroller.nativeElement.scrollTop = 0;
      this.columnFilter.take = config.PageSizeBlock;
    }
  }

  resolveScroll(): void {
    this.spinnerService.pauseSpinner();
    if (this.currentRoutePath === 'posteringer/postingnote/:postingNoteId') {
      const noteId = 'postingNoteId';
      const postingNoteId = this.route.snapshot.params[noteId] ?? -1;
      this.postingService
        .postingsByPostingNoteId(postingNoteId, this.columnFilter)
        .subscribe(
          (result: PostingAndFilter) => {
            this.handleScrollingByDifferentRoute(result);
          },
          (e) => this.spinnerService.enableSpinner()
        );
    } else {
      this.postingService.getPostingsByFilter(this.postingFilter).subscribe(
        (posting: PostingAndFilter) => {
          this.handleScrollingByDifferentRoute(posting);
        },
        (e) => this.spinnerService.enableSpinner()
      );
    }
  }

  private handleScrollingByDifferentRoute(posting: PostingAndFilter): void {
    if (
      (this.getCheckboxNotSelectedPostings().map((p) => p.postingId).length >
        0 &&
        this.isIndeterminate()) ||
      this.isAllChecked === true
    ) {
      posting.postings.forEach((i) => {
        i.isSelected = true;
      });
    }
    this.dataSource.data = this.dataSource.data.concat(posting.postings);
    this.postingFilter = posting.postingFilter;
    this.columnFilter = this.postingFilter.pageModel;
    if (posting.postings.length > 0) this.scrollOpen = true;

    if (this.isIndeterminate() === false) this.resolveCheckBoxes();

    this.spinnerService.enableSpinner();
  }

  private setTableScrollBarToTop(): void {
    this.scroller.nativeElement.scrollTop = 0;
  }

  onTableScroll(e): void {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (
      scrollLocation > limit &&
      this.dataSource.data.length < this.columnFilter.totalRows &&
      this.scrollOpen
    ) {
      this.scrollOpen = false;
      this.postingFilter.pageModel = this.columnFilter;
      this.postingFilter.pageModel.skip += this.postingFilter.pageModel.take;
      this.infinityScroll.next(this.postingFilter.pageModel);
    }
  }

  sortData(sort: Sort): void {
    this.columnFilter.skip = 0;
    if (sort.direction !== '') {
      this.columnFilter.sortBy = {
        propertyName: sort.active,
        ascending: sort.direction === 'asc' ? true : false,
      };
    }

    this.postingFilter.pageModel = this.columnFilter;
    this.scrollOpen = false;
    this.dataSource.data = [];
    this.infinityScroll.next(this.postingFilter.pageModel);
  }
}
@Directive({
  selector: '[dateFormat2]',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMAT2 }],
})
export class CustomDateFormat {}
