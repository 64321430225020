<div *ngIf="totalPostings>0 || totalDisplayedPostings==0" class="total-rows">
  Posteringer i alt: {{totalDisplayedPostings | number:'':'da-DK'}} / {{totalPostings | number:'':'da-DK'}}
</div>
<div *ngIf="totalPostings==0 && totalDisplayedPostings!=0" class="total-rows">
  Indlæser...
</div>
<mat-form-field class="action-selector" floatLabel=never>

  <mat-label>Handling</mat-label>
  <mat-select [(ngModel)]="selectedAction" [disabled]="isDisabled">
    <mat-option *ngFor="let action of actionsData" [value]="action.value">
      {{action.viewValue}}
    </mat-option>
  </mat-select>
</mat-form-field>

<button mat-flat-button color="primary" (click)="doAction()" [disabled]="isDisabled">
  <fa-icon [icon]="faCheck"></fa-icon> Udfør
</button>

<button mat-flat-button class="column-picker-btn" color="primary" (click)="openPostingColumnListDialog()">
  <fa-icon></fa-icon> Kolonner
</button>

<span *ngIf="!isOverlay" matTooltip="{{excelExportToolTip()}}">
  <button [disabled]="excelExportDisabled()" mat-flat-button color="primary" (click)="exportPostings()">
    <fa-icon [icon]="faFileExcel"></fa-icon> Export
  </button>
</span>