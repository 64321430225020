<div class="dialog">
    <div class="dialog__header" *ngIf="currentDialog === 'createRuleDialog'">
        Opret regel
    </div>
    <div class="dialog__header" *ngIf="currentDialog === 'editRuleDialog'">
        Rediger regel
    </div>
    <div class="dialog__close" (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </div>
    <div class="dialog__content">
        <div class="dialog__form-item">
            <mat-form-field>
                <mat-label>Beskrivelse</mat-label>
                <input matInput placeholder="Beskrivelse">
            </mat-form-field>
        </div>
        <div class="dialog__form-item">
            <mat-form-field>
                <mat-label>Regel</mat-label>
                <textarea matInput placeholder="Regel"></textarea>
              </mat-form-field>
        </div>
    </div>
    <div class="dialog__buttons">
        <button mat-flat-button color="warn" (click)="close()">
            Annuller
        </button>
        <button mat-flat-button color="primary">
            Gem
        </button>
    </div>
</div>