import { AfterViewInit, Component, OnInit } from '@angular/core';
import { faCalendar, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { YearSelectService } from '@modules/leader-dashboard/services/year-select.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, AfterViewInit {
  // Icons
  faCalendar = faCalendar;
  faSyncAlt = faSyncAlt;

  selectedYear: Observable<number>;
  years: number[] = [];
  isResetButtonVisible: Observable<boolean> = this.yearSelectService.isResetButtonVisible.asObservable();


  constructor(private yearSelectService: YearSelectService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.years = this.yearSelectService.years;
    this.selectedYear = this.yearSelectService.globalYear$;
  }

  public resetFilters() {
    this.yearSelectService.resetAllYearSelection();
  }

  public selectYear(year: number): void {
    this.yearSelectService.setGlobalYear(year);
  }
}
