import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  faCaretRight,
  faCaretDown,
  faPlus,
  faMinus,
  faTimes,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { HierarchyViewNode } from 'src/app/api/vat';
import { AnalysisFilter, PostingFilter } from 'src/app/api/vat';
import { Observable, Subscription } from 'rxjs';
import filterTypes from 'src/assets/filterTypes.json';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss'],
})
export class HierarchyComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() data: Array<HierarchyViewNode> = [];
  @Input() selectedFilters: Observable<AnalysisFilter | PostingFilter>;
  @Output() selected: EventEmitter<any | HierarchyViewNode[]>;
  @Input() settingsMode: boolean = false;

  subscriptions: Subscription[] = [];
  visibleChildren: boolean = true;
  selectedList: Set<string> = new Set();
  treeControl = new NestedTreeControl<HierarchyViewNode>(
    (node) => node.children
  );
  expandToggle: boolean = true;
  dataSource = new MatTreeNestedDataSource<HierarchyViewNode>();
  bigDataNumber: number = 100;
  bigData: boolean = false;

  // icons
  faCaretRight = faCaretRight;
  faCaretDown = faCaretDown;
  faPlus = faPlus;
  faMinus = faMinus;
  faTimes = faTimes;
  faSearch = faSearch;

  internalPropertyName = () => {
    return this.type === filterTypes.functions.key ||
      this.type === filterTypes.admorgs.key
      ? 'key'
      : 'id';
  };

  constructor(private internalFilterService: InternalFilterService) {
    if (this.settingsMode) {
      this.selected = new EventEmitter<HierarchyViewNode[]>();
    } else {
      this.selected = new EventEmitter<any[]>();
    }
  }

  ngOnInit(): void {
    this.selectedList = new Set();
    this.dataSource.data = this.data;
    if (
      this.type === filterTypes.admorgs.key &&
      this.data[0].children[0].key !==
        this.data[0].children[0].children[0].key.trim()
    )
      this.data.forEach((node) => {
        node.children.forEach((child: HierarchyViewNode) => {
          this.addSelfToChildren(child);
        });
      });
    this.selectedFilters.subscribe((filters) => {
      if (this.iscurrentFilterTypeEmpty(filters[this.type])) {
        filters[this.type] = [];
        this.selectedList = new Set();
      } else {
        if (
          this.type == filterTypes.admorgs.key &&
          filters.admorgs !== undefined &&
          filters.admorgs.length > 0
        ) {
          filters.admorgs.forEach((id) => {
            this.data.forEach((parentNode) => {
              let selectedNode = this.findNodeByKey(id.toString(), parentNode);
              if (selectedNode !== undefined) {
                if (selectedNode.children) {
                  this.selectChildren(selectedNode.children[0]);
                } else this.selectChildren(selectedNode);
              }
            });
          });
        }
        if (
          this.type == 'functions' &&
          filters.functions !== undefined &&
          filters.functions.length > 0
        ) {
          filters.functions.forEach((id) => {
            this.data.forEach((parentNode) => {
              let selectedNode = this.findNodeByKey(id.toString(), parentNode);
              if (selectedNode !== undefined) {
                this.selectChildren(selectedNode);
              }
            });
          });
        }
        if (
          this.type == 'costTypes' &&
          filters.costTypes !== undefined &&
          filters.costTypes.length > 0
        ) {
          filters.costTypes.forEach((id) => {
            this.data.forEach((parentNode) => {
              let selectedNode = this.findNodeById(id, parentNode);
              if (selectedNode !== undefined && selectedNode.id === id) {
                this.selectChildren(selectedNode);
              }
            });
          });
        }
      }
    });
    this.dataSource.data.forEach((node) => {
      this.expandToSelectedNodes(node);
    });
  }
  private findNodeByKey(
    key: string,
    node: HierarchyViewNode
  ): HierarchyViewNode {
    if (node.key.trim() === key) {
      return node;
    } else if (node.children) {
      let oldNode: HierarchyViewNode = node;
      node = undefined;
      oldNode.children.forEach((child) => {
        if (node === undefined) {
          node = this.findNodeByKey(key, child);
        }
      });
      return node;
    }
    return undefined;
  }

  private findNodeById(id: number, node: HierarchyViewNode): HierarchyViewNode {
    if (node.id === id) {
      return node;
    } else if (node.children) {
      let oldNode: HierarchyViewNode = node;
      node = undefined;
      oldNode.children.forEach((child) => {
        if (node === undefined) {
          node = this.findNodeById(id, child);
        }
      });
      return node;
    }
    return undefined;
  }
  toggleNode(node: HierarchyViewNode) {
    if (this.isNodeSelected(node)) {
      if (node.children && node.children.length > 0) {
        node.children.map((c) => this.unSelectChildren(c));
      } else {
        this.selectedList.delete(node.key);
      }
    } else {
      if (node.children && node.children.length > 0) {
        this.expand(node);
        node.children.map((c) => this.selectChildren(c));
      } else {
        this.selectedList.add(node.key);
      }
    }
    this.update();
  }
  selectChildren(node: HierarchyViewNode) {
    if (node.children && node.children.length > 0) {
      node.children.map((c) => {
        this.selectChildren(c);
      });
    } else {
      if (!this.selectedList.has(node.key)) {
        this.selectedList.add(node.key);
      }
    }
  }

  unSelectChildren(node: HierarchyViewNode) {
    if (node.children && node.children.length > 0) {
      node.children.map((c) => this.unSelectChildren(c));
    } else {
      if (this.selectedList.has(node.key)) {
        this.selectedList.delete(node.key);
      }
    }
  }
  expand(node: HierarchyViewNode) {
    if (this.expandToggle) {
      this.treeControl.expand(node);
      if (node.children !== null)
        node.children.forEach((child) => this.expand(child));
    }
  }
  checkNumberofNodes(): boolean {
    let count: number = 0;
    this.data.forEach((node) => {
      count = count + 1;
      if (node.children !== null)
        node.children.forEach((child) => {
          count = count + 1;
          if (child.children !== null)
            child.children.forEach((grandchild) => {
              count = count + 1;
            });
        });
      console.log(count);
      if (count > this.bigDataNumber) return true;
    });

    return false;
  }

  isNodeSelected(node: HierarchyViewNode): boolean {
    return this.selectedList.has(node.key) || this.areAllChildrenSelected(node);
  }

  public areAllChildrenSelected(node: HierarchyViewNode, mode?: string) {
    return (
      this.isAnyChildChecked(false, node) &&
      !this.isAnyChildUnchecked(false, node)
    );
  }
  private isAnyChildChecked(
    anyCheckedSoFar: boolean,
    node: HierarchyViewNode
  ): boolean {
    if (node.children && node.children.length > 0 && !anyCheckedSoFar) {
      node.children.forEach((child) => {
        if (this.isNodeSelected(child)) anyCheckedSoFar = true;
        if (!anyCheckedSoFar)
          anyCheckedSoFar = this.isAnyChildChecked(anyCheckedSoFar, child);
      });
      return anyCheckedSoFar;
    }
  }
  private isAnyChildUnchecked(
    anyUncheckedSoFar: boolean,
    node: HierarchyViewNode
  ): boolean {
    if (node.children && node.children.length > 0 && !anyUncheckedSoFar) {
      node.children.forEach((child) => {
        if (!this.isNodeSelected(child)) anyUncheckedSoFar = true;
        if (!anyUncheckedSoFar)
          anyUncheckedSoFar = this.isAnyChildUnchecked(
            anyUncheckedSoFar,
            child
          );
      });
      return anyUncheckedSoFar;
    }
  }
  isNodeIndeterminate(node: HierarchyViewNode): boolean {
    return (
      this.isAnyChildChecked(false, node) &&
      this.isAnyChildUnchecked(false, node)
    );
  }
  private addSelfToChildren(node: HierarchyViewNode): void {
    if (node.children !== null && node.children.length > 0) {
      let newNode: HierarchyViewNode = {
        customerId: node.customerId,
        id: node.id,
        key: node.key + '   ',
        name: node.name,
        children: null,
      };
      node.children.unshift(newNode);
      node.children.forEach((child) => {
        if (child.children) {
          node.children[
            node.children.findIndex((x) => x.key === child.key)
          ].key = child.key + '   ';
        }
        this.addSelfToChildren(child);
      });
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private update(): void {
    const list = [];
    if (this.type == 'costTypes') {
      this.selectedList.forEach((key) =>
        this.data.forEach((node) => {
          let selectedNode = this.findNodeByKey(key, node);
          if (selectedNode !== undefined && !list.includes(selectedNode.id)) {
            list.push(selectedNode.id);
          }
        })
      );
    } else {
      this.selectedList.forEach((key) => list.push(key.trim()));
    }
    this.internalFilterService.updateSideFilter(this.type, list);
  }

  private iscurrentFilterTypeEmpty(selectedFilter): boolean {
    return (
      selectedFilter?.length === 0 ||
      selectedFilter === null ||
      selectedFilter === undefined
    );
  }

  private expandToSelectedNodes(node: HierarchyViewNode): void {
    if (node.children !== null && this.isAnyChildChecked(false, node)) {
      this.expand(node);
      node.children.forEach((child) => this.expandToSelectedNodes(child));
    }
  }

  public hasChild = (_: number, node: HierarchyViewNode) => {
    return !!node.children && node.children.length > 0;
  };
}
