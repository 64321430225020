import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';

// Custom modules
import { FilterModule } from '@modules/filter/filter.module';
import { FavoritesModule } from '@modules/favorites/favorites.module';
import { CommonsModule } from '@modules/commons/commons.module';

// Components
import { AnalyzesComponent } from './components/analyzes/analyzes.component';
import { AnalyzeComponent } from './components/analyze/analyze.component';
import { AnalyzeCardComponent } from './components/analyze-card/analyze-card.component';

@NgModule({
  declarations: [AnalyzesComponent, AnalyzeComponent, AnalyzeCardComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    FilterModule,
    FavoritesModule,
    RouterModule,
    MatButtonToggleModule,
    CommonsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserModule,
    MatSortModule,
    MatChipsModule,
  ],
  exports: [AnalyzesComponent, AnalyzeCardComponent],
})
export class AnalyzesModule {}
