import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { InternalCustomerService } from '@modules/shell/services/internal-customer.service';
import { Customer } from 'src/app/api/vat';

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss'],
})
export class CustomerSelectorComponent implements OnInit {
  selectedCustomer: Customer;

  customers: Customer[] = [];

  constructor(
    private internalCustomerService: InternalCustomerService,
    private internalCurrentCustomerService: InternalCurrentCustomerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private internalFilterService: InternalFilterService,
    private messagesService: MessagesService
  ) {
    this.customerSelection();
  }

  ngOnInit(): void { }

  private customerSelection(): void {
    this.internalCustomerService.customers.subscribe((customers) => {
      this.customers = customers;
    });
    this.internalCurrentCustomerService.customer.subscribe((customer) => {
      if (this.selectedCustomer !== customer) {
        this.selectedCustomer = customer;
      }
    });
  }

  public onCustomerSelection(customer: Customer): void {
    this.internalCurrentCustomerService.updateCustomer(customer);
    this.messagesService.checkForNewMessages();
    const dashboard: string = 'dashboard';
    if (this.activatedRoute.children[0].routeConfig.path === dashboard) {
      this.router.navigateByUrl('.', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(dashboard);
        this.internalFilterService.resetAllFiltersToDefault();
      });
    } else {
      this.router
        .navigate([dashboard])
        .then((isChanged: boolean) => {
          if (isChanged)
            location.reload();
        });
    }
  }

  compareObjects(o1: Customer, o2: Customer): boolean {
    return o1.customerId === o2.customerId;
  }
}
