import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Material
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  DateAdapter,
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
// Custom modules
import { FavoritesModule } from '@modules/favorites/favorites.module';
import { CommonsModule } from '@modules/commons/commons.module';

// Components
import { FilterComponent } from './components/filter/filter.component';
import { ChosenFiltersBarComponent } from './components/chosen-filters-bar/chosen-filters-bar.component';
import { HierarchyComponent } from './components/hierarchy/hierarchy.component';
import { ListComponent } from './components/list/list.component';
import { PeriodComponent } from './components/period/period.component';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { DatepickerCustomHeaderComponent } from './components/datepicker-custom-header/datepicker-custom-header.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { KeywordComponent } from './components/keyword/keyword.component';

@NgModule({
  declarations: [
    FilterComponent,
    ChosenFiltersBarComponent,
    HierarchyComponent,
    ListComponent,
    PeriodComponent,
    CheckboxListComponent,
    MonthPickerComponent,
    DatepickerCustomHeaderComponent,
    KeywordComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    FontAwesomeModule,
    MatInputModule,
    MatChipsModule,
    FavoritesModule,
    MatTreeModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatListModule,
    MatTooltipModule,
    MatSelectModule,
    CommonsModule,
    MatSlideToggleModule,
  ],
  exports: [FilterComponent, ChosenFiltersBarComponent, HierarchyComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class FilterModule {}
