<div *ngIf="postingInformation" class="info-table__wrapper">

  <table class="info-table list" *ngFor="let row of rowDefinitions">

    <ng-container [ngSwitch]="row.type">

      <ng-container *ngSwitchCase="'inputCurrency'">
        <tr>
          <td>{{getViewValue(row)}}:</td>
          <td>
            <app-corrected-potentiale-input-box [columnKey]="row.key" [row]="postingInformation">
            </app-corrected-potentiale-input-box>
            <span class="currency"> DKK</span>
          </td>
        </tr>
      </ng-container>

      <ng-container *ngSwitchCase="'currency'">
        <tr>
          <td>{{getViewValue(row)}}:</td>
          <td>{{postingInformation[row.key] | vatCurrency}}</td>
        </tr>
      </ng-container>

      <ng-container *ngSwitchCase="'date'">
        <tr>
          <td>{{getViewValue(row)}}:</td>
          <td>{{postingInformation[row.key] | date:'dd-MM-yyyy' }}</td>
        </tr>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <tr>
          <td>{{getViewValue(row)}}:</td>
          <td>{{getDataValue(row)}}</td>
        </tr>
      </ng-container>

    </ng-container>
  </table>
