<div class="admin">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <mat-tab-group mat-stretch-tabs>
                    <mat-tab label="Nyheder">
                        <div class="admin__tab">
                            <app-news></app-news>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>