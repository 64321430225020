import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'IntetPotentiale':
      case 'RegulerMoms':
        return 'Behandlet';

      case 'Ubehandlet':
      default:
        return value;
    }
  }

}
