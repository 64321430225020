import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent } from '../components/information-dialog/information-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class InformationDialogService {
  constructor(public dialog: MatDialog) {}

  showErrorMessage(data: InformationDialogDto) {
    this.dialog.open(InformationDialogComponent, {
      data: data,
    });
  }
}

export interface InformationDialogDto {
  title?: string;
  httpStatusCode?: number;
  correlationId?: string;
  responseTimeInMS?: string;
  url?: string;
  messages?: string[];
}
