import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faCalendar, faExpand, faCompressAlt } from '@fortawesome/free-solid-svg-icons';
import { TableDesign, TableInformation } from '@modules/leader-dashboard/Helpers/LeaderDashBoardHelper';
@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss'],
})
export class CardTableComponent implements OnInit, AfterViewInit, OnChanges {
  // Icons
  faCalendar = faCalendar;
  faExpand = faExpand;
  faCompressAlt = faCompressAlt;

  cardExpanded: boolean = false;

  displayedColumnsNames: string[] = [];
  @Input() footerinfo: string;
  @Input() tableInformation: TableInformation;
  @Input() yearSelector: boolean = false;
  @Output() selectedYearChange = new EventEmitter<number>();
  tableDesigns: TableDesign[] = [];
  periods: number[] = [];

  @Input() matSortActive: MatSort;

  @ViewChild('sort') sort: MatSort;

  dataSource = new MatTableDataSource();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('tableInformation' in changes && this.tableInformation !== undefined) {
      this.tableDesigns = this.tableInformation.tableDesigns;
      this.displayedColumnsNames = this.tableDesigns.map(t => t.propertyName);
      this.dataSource.data = this.tableInformation.data;
    }
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.tableInformation?.data);
  }

  isDateNull = (data: Date) => new Date(data).getFullYear() !== 1970;

  expandCard() {
    this.cardExpanded = !this.cardExpanded;
  }

  public yearSelectChange(year: number): void {
    this.selectedYearChange.emit(year);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
