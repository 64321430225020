import { Injectable } from '@angular/core';
import { LocalStorageService } from '@modules/store/services/local-storage.service';
import storeKeys from '@modules/store/localStorageKeys.json';
import { Customer } from 'src/app/api/vat';
import { BehaviorSubject, Observable } from 'rxjs';
import { InternalCustomerService } from './internal-customer.service';

@Injectable({
  providedIn: 'root',
})
export class InternalCurrentCustomerService {
  private customerSource$: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(
    null
  );
  public customer: Observable<Customer> = this.customerSource$.asObservable();

  constructor(
    private localStorageService: LocalStorageService,
    internalCustomerService: InternalCustomerService,
  ) {
    internalCustomerService.customers.subscribe((customers) => {
      if (customers !== undefined && customers !== null) {
        let currentCustomer =
          this.localStorageService.getObject<Customer>(
            storeKeys.CurrentSelectedCustomerStorageKey
          ) ?? customers[0];

        if (customers?.map(c => c.customerId)?.indexOf(currentCustomer.customerId) === -1)
          currentCustomer = customers[0];

        this.updateCustomer(currentCustomer);
      }
    });
  }

  updateCustomer(customer: Customer) {
    if (this.customerSource$.getValue()?.customerId !== customer.customerId) {
      this.customerSource$.next(customer);
      this.localStorageService.addOrUpdateObject(
        storeKeys.CurrentSelectedCustomerStorageKey,
        customer
      );
    }
  }

  getCustomer(): Customer {
    if (this.customerSource$?.value) {
      return this.customerSource$.value;
    }
    return null;
  }

  getCustomerId(): string {
    if (this.customerSource$?.value) {
      return this.customerSource$?.getValue().customerId.toString();
    }

    const customer: Customer = this.localStorageService.getObject<Customer>(
      storeKeys.CurrentSelectedCustomerStorageKey
    );

    if (customer !== null && customer !== undefined) {
      this.customerSource$.next(customer);
      return customer.customerId.toString();
    }

    return '';
  }
}
