import { Component, Input, OnInit } from '@angular/core';
import { faCalendar, faUser } from '@fortawesome/free-solid-svg-icons';
import { MessageHelper } from '@modules/messages/helpers/message-helper';

import { Message } from 'src/app/api/vat';

@Component({
  selector: 'app-message-time-author',
  templateUrl: './message-time-author.component.html',
  styleUrls: ['./message-time-author.component.scss'],
})
export class MessageTimeAuthorComponent implements OnInit {
  faCalendar = faCalendar;
  faUser = faUser;

  @Input() message: Message;
  publishedBy: string;

  constructor() {}

  ngOnInit(): void {
    this.publishedBy = MessageHelper.isPublishedBy(
      this.message.publishedBy,
      this.message.createdBy.userName
    );
  }
}
