import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscribable } from 'rxjs';

export interface headerChip {
  filter: any;
  type: string;
  headline: string;
  removable: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FilterBarService {
  public filterBarState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  private _headerChip: Subject<headerChip> = new Subject<headerChip>();
  public headerChip: Observable<headerChip> = this._headerChip.asObservable();

  constructor() {}

  setHeaderChip(chip: headerChip) {
    this._headerChip.next(chip);
  }

  toggleFilterBar(show: boolean = null) {
    if (show === null) {
      this.filterBarState.next(!this.filterBarState.value);
    } else {
      this.filterBarState.next(show);
    }
  }
}
