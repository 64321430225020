<div class="top-bar">
  <button mat-button [matMenuTriggerFor]="periodMenu">
    <fa-icon [icon]="faCalendar"></fa-icon>
    Global periode: {{selectedYear | async}}
  </button>
  <mat-menu #periodMenu="matMenu">
    <button (click)="selectYear(year)" mat-menu-item *ngFor="let year of years"> {{year}}</button>
  </mat-menu>

  <button (click)="resetFilters()" *ngIf="isResetButtonVisible | async" mat-button>
    <fa-icon [icon]="faSyncAlt"></fa-icon>Reset
  </button>
</div>
