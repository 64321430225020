<mat-list class="list">
  <mat-list-item *ngFor="let node of data">
    <div class="list__header">
      <mat-checkbox [checked]="isNodeSelected(node)" (change)="toggleNode(node)"></mat-checkbox>
      <span matTooltip="{{ node.key| filterKeyRemove:type }} {{ node.value }}">
        {{ node.key | filterKeyRemove:node.key | filterKeyRemove:type}} {{ node.value }}
      </span>
    </div>
  </mat-list-item>
</mat-list>
