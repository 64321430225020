import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserDto, UserService } from '@modules/shell/services/user.service';
import { SidebarService } from '@modules/shell/services/sidebar.service';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  title: string = '';
  user: UserDto;
  sidebarOpen: boolean = true;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private sidebarService: SidebarService,
    private systemService: SystemService
  ) {
    this.systemService.systemInfo$.subscribe((systemInfo) => {
      this.title = systemInfo.title;
      this.titleService.setTitle(this.title);
    });

    this.userService.user$.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {}

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
    this.sidebarService.setSidebarOpen(this.sidebarOpen);
  }
}
