import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faLessThan, faGreaterThan, faEquals } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-column-filter-multi-text-box',
  templateUrl: './column-filter-multi-text-box.component.html',
  styleUrls: ['./column-filter-multi-text-box.component.scss']
})
export class ColumnFilterMultiTextBoxComponent implements OnInit {

  faLessThan = faLessThan;
  faGreaterThan = faGreaterThan;
  faEquals = faEquals;

  searchFieldEquals = new UntypedFormControl('');
  searchFieldLess = new UntypedFormControl('');
  searchFieldGreaterThan = new UntypedFormControl('');
  @Input() placeHolder: string;
  @Input() columnKey: string;
  @Output() SearchValue = new EventEmitter<any>();

  debouncetime: number = 600;
  constructor() { }

  ngOnInit(): void {
    this.searchFieldEquals.valueChanges
      .pipe(debounceTime(this.debouncetime), distinctUntilChanged())
      .subscribe((searchValue: string) => {
        this.disablingForm();
        this.validateInput(this.searchFieldEquals, searchValue);
        this.updateEvent(this.searchFieldEquals, 'equals');

      });

    this.searchFieldLess.valueChanges
      .pipe(debounceTime(this.debouncetime), distinctUntilChanged())
      .subscribe((searchValue: string) => {
        this.disablingForm();
        this.validateInput(this.searchFieldLess, searchValue);
        this.updateEvent(this.searchFieldLess, 'less');

      });

    this.searchFieldGreaterThan.valueChanges
      .pipe(debounceTime(this.debouncetime), distinctUntilChanged())
      .subscribe((searchValue: string) => {
        this.disablingForm();
        this.validateInput(this.searchFieldGreaterThan, searchValue);
        this.updateEvent(this.searchFieldGreaterThan, 'greater');
      });
  }

  private disablingForm(): void {
    if (this.searchFieldGreaterThan.value === '' && this.searchFieldLess.value === '' && this.searchFieldEquals.value === '') {
      this.searchFieldGreaterThan.reset('');
      this.searchFieldLess.reset('');
      this.searchFieldEquals.reset('');
      this.searchFieldEquals.enable();
      this.searchFieldGreaterThan.enable();
      this.searchFieldLess.enable();
    }
    else if (this.searchFieldGreaterThan.value !== '' || this.searchFieldLess.value !== '') {
      this.searchFieldEquals.reset('');
      this.searchFieldEquals.disable();
    }
    else if (this.searchFieldEquals.value !== '') {
      this.searchFieldGreaterThan.reset('');
      this.searchFieldLess.reset('');
      this.searchFieldGreaterThan.disable();
      this.searchFieldLess.disable();
    }
  }

  private updateEvent(form: UntypedFormControl, type: 'less' | 'greater' | 'equals'): void {
    this.SearchValue.emit({
      value: form.value,
      type: type,
    });
  }

  private validateInput(form: UntypedFormControl, searchValue: string): void {
    const regex: RegExp = new RegExp(/[a-zA-Z?!"#¤%&/()=?+$£:;><*]+/g);
    form.setValue(searchValue.replace(regex, ''));
  }

  public clearAllInputs(): void {
    this.searchFieldEquals.reset('');
    this.searchFieldGreaterThan.reset('');
    this.searchFieldLess.reset('');
    this.searchFieldEquals.enable({ emitEvent: false });

    this.searchFieldGreaterThan.enable({ emitEvent: false });

    this.searchFieldLess.enable({ emitEvent: false });
  }

}
