<section [ngStyle]="{'height': isMonthJanuary ? '135px' : '104px'}">
  <div class="years" *ngFor="let financialYear of financialYears">
    <mat-checkbox class="checkbox" [disabled]="!financialYear.yearExists" [checked]="isYearSelected(financialYear)"
      (change)="selectionYearChange($event, financialYear)">
      {{ financialYear.year }}
    </mat-checkbox>
  </div>
</section>
<div class="month-picker" *ngIf="to && from">
  <app-month-picker [label]="'Fra'" [max]="to" [selectedDate]="from" (selection)="getFromDate($event)" class="first"></app-month-picker>
  <app-month-picker [label]="'Til'" [min]="from" [selectedDate]="to" (selection)="getToDate($event)"></app-month-picker>
  <button mat-flat-button [disabled]="isUpdateButtonDisable" color="primary" (click)="updateFilter()">Filtrer periode</button>
</div>
