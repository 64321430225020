import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MessageHelper } from '@modules/messages/helpers/message-helper';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { Message, Customer, MessageService } from 'src/app/api/vat';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  global: boolean = false;
  publishedBy: number = 1;
  messageId: number = 0;
  customer: Customer;
  KMD: string = MessageHelper.KMD;
  BDO: string = MessageHelper.BDO;

  newsFormControl = new UntypedFormGroup({
    subject: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    text: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  constructor(
    private internalCurrentCustomerService: InternalCurrentCustomerService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.customer = this.internalCurrentCustomerService.getCustomer();
  }

  public getSubjectControl = () => this.newsFormControl.get('subject');
  public getTextControl = () => this.newsFormControl.get('text');

  cancel() {
    this.messageId = 0;
    this.getSubjectControl().reset('');
    this.getTextControl().reset('');
  }

  sendClick() {
    const message: Message = {
      subject: this.getSubjectControl().value,
      text: this.getTextControl().value,
      isGlobal: this.global,
      postingNoteId: this.messageId,
      owner: true,
      isNews: true,
      publishedBy: this.publishedBy,
      postingReferencesCount: 0,
    };

    this.messageService.addMainMessage(message).subscribe(() => {
      this.getSubjectControl().reset('');
      this.getTextControl().reset('');
      this.global = false;
      this.publishedBy = 1;
    });
  }

  editMessage(message: Message) {
    this.getSubjectControl().setValue(message.subject);
    this.getTextControl().setValue(message.text);
    this.global = message.isGlobal;
    this.messageId = message.postingNoteId;
    this.publishedBy = message.publishedBy;
  }

  delete() {
    // slet nyhed
  }
}
