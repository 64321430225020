<div class="dialog">
    <div class="dialog__header">
        Eksporter med mail
    </div>
    <div class="dialog__close" (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </div>
    <div class="dialog__content">

        <div class="dialog__date-author">
            {{message.createDate | date: 'dd/MM yyyy'}} - {{message.createdBy.userName}}
        </div>

        <div class="dialog__recipients">
            <mat-form-field class="create-note__input">
                <mat-chip-list #chipList aria-label="Person selection">
                    <mat-chip *ngFor="let person of persons" [selectable]="selectable" [removable]="removable"
                        (removed)="removeUser(person)">
                        {{person.userName}}
                        <fa-icon [icon]="faTimes" matChipRemove *ngIf="removable"></fa-icon>
                    </mat-chip>
                    <mat-chip *ngFor="let additionalEmail of additionalEmails" [selectable]="selectable"
                        [removable]="removable" (removed)="removeEmail(additionalEmail)">
                        {{additionalEmail}}
                        <fa-icon [icon]="faTimes" matChipRemove *ngIf="removable"></fa-icon>
                    </mat-chip>
                    <input matInput placeholder="Tilføj personer der skal informeres om denne note" #personInput
                        [formControl]="personCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let person of filteredPersons" [value]="person.userId">
                        {{person.userName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="dialog__subject">
            <mat-form-field class="example-full-width">
                <mat-label>Mail emne</mat-label>
                <input matInput [(ngModel)]="subject">
            </mat-form-field>
        </div>

        <div class="dialog__text">
            <mat-form-field class="example-full-width">
                <mat-label>Mail tekst</mat-label>
                <textarea matInput [(ngModel)]="body"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="dialog__check">
        <div class="dialog__check-text">
            <p>OBS: Du er ved at sende en mail der kan indeholde følsomme oplysninger.</p>
            <br />
            <p>Ved brug af denne funktionalitet, kan det potentielt betyde at du deler CPR-oplysninger med den kollega,
                som du sender dette eksport til.</p>
            <p> I skal derfor være opmærksomme på, om I overholder jeres interne regler ved opbevaring herom.</p>
            <p>I har yderligere selv ansvar for at modtager, gerne må modtage de data I er ved at eksportere.
                Det understreges at brug af denne service strengt er for eget ansvar.</p>
            <br>
        </div>
        <mat-checkbox [(ngModel)]="checked" class="example-margin" labelPosition="after">
            <div class="accept">Jeg har læst ovenstående og accepterer</div>
        </mat-checkbox>
    </div>
    <div class="dialog__buttons">
        <span [matTooltipDisabled]="checked && recipientsAreChosen()"
            matTooltip="{{checked? 'Du kan ikke afsende e-mails uden en specificeret modtager' :'Godkend GDPR betingelser for at eksportere med mail'}}"
            aria-label="">
            <button mat-stroked-button color="primary" [disabled]="!checked || !recipientsAreChosen()"
                [ngClass]="!checked || !recipientsAreChosen()? 'disabled' : ''" (click)="sendMessage()">
                Send
            </button>
        </span>
        <button mat-stroked-button color="primary" (click)="close()">
            Annuller
        </button>
    </div>
</div>