import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService } from '@modules/shell/services/loading-spinner.service';

@Component({
  selector: 'app-throbber',
  templateUrl: './throbber.component.html',
  styleUrls: ['./throbber.component.scss'],
})
export class ThrobberComponent implements OnInit {
  public loading: boolean;
  constructor(private loadingSpinnerService: LoadingSpinnerService) { }

  ngOnInit() {
    this.loadingSpinnerService.loading.subscribe((loading) => {
      this.loading = loading;
    });
  }
}
