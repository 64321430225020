import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Posting } from 'src/app/api/vat';

@Injectable({
  providedIn: 'root',
})
export class CorrectedPotentialService {
  private correctedPotentialPostingRow: BehaviorSubject<Posting> = new BehaviorSubject<Posting>(
    null
  );
  public correctedPotentialPostingRow$: Observable<Posting> = this.correctedPotentialPostingRow.asObservable();

  constructor() {}

  updateCorrectedPotential(row: Posting) {
    this.correctedPotentialPostingRow.next(row);
  }
}
