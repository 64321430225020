<mat-form-field>
  <mat-label>Søg her...</mat-label>
  <input matInput [matAutocomplete]="auto" [formControl]="searchControl" [matAutocompletePosition]="'above'">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (closed)="handleCheckboxSelected()" >
    <div *ngIf=" searchValues.length !==0; else isEmpty">
      <mat-option *ngFor="let value of searchValues" [value]="value" matTooltip="{{value.dataValue}}">
        <mat-checkbox [checked]="value.checkboxSelected" (change)="toggleCheckboxSelection(value)" (click)="$event.stopPropagation()">
          {{ value.dataValue }}
        </mat-checkbox>
      </mat-option>
    </div>
    <ng-template #isEmpty>
      <mat-option disabled>Ingen Resultat</mat-option>
    </ng-template>
  </mat-autocomplete>
</mat-form-field>

<div class="button-area" *ngIf="isAnyCheckBoxSelected() || searchValues.length >= 3">
  <button mat-flat-button >OK</button>
  <button mat-flat-button (click)="clearCheckBoxSelection(); $event.stopPropagation()">Ryd</button>
</div>

<mat-list role="list" class="list">
  <mat-list-item role="listitem" *ngFor="let selectedNode of selectedNodes">
    <div *ngIf="isKeyValuePairType(); else notKeyValuePair" class="list__header" matTooltip="{{ selectedNode.value }}">
      {{ selectedNode.value }}
    </div>
    <ng-template #notKeyValuePair>
      <div class="list__header" matTooltip="{{ selectedNode }}">
        {{ selectedNode }}
      </div>
    </ng-template>
    <div class="list__action">
      <button mat-icon-button>
        <fa-icon (click)="deleteSelectedNode(selectedNode)" [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </mat-list-item>
</mat-list>
