import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private loadingSource = new BehaviorSubject<boolean>(false);
  loading = this.loadingSource.asObservable();
  private count = 0;

  constructor() {}

  addSpinner() {
    this.count++;
    this.loadingSource.next(true);
  }

  removeSpinner() {
    this.count--;
    if (this.count === 0) {
      this.loadingSource.next(false);
    }
  }
}
