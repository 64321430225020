<div *ngIf="favoriteAnalysis" class="favorites">
  <mat-list role="list" matRiple [ngClass]="isExpanded ? 'is-expanded' : ''">
      <div *ngFor="let favorite of favoriteAnalysis" class="favorites__link">
          <mat-list-item role="listitem">
              <a class="favorites__text" (click)="navigateToPostings(favorite.analysisId)" [title]="favorite.title">
                  {{favorite.title}}
              </a>
              <span class="favorites__star">
                  <button mat-icon-button aria-label="Fjern farvorit" (click)="toggleFavorit(favorite.analysisId)">
                      <fa-icon [icon]="['fas', 'star']"></fa-icon>
                  </button>
              </span>
          </mat-list-item>
      </div>
  </mat-list>
  <div *ngIf="amountOfFavorites > 3" class="favorites__expand" (click)="expandFavoriteList()" [ngClass]="isExpanded ? 'is-expanded' : ''">
      <fa-icon [icon]="faChevronDown"></fa-icon>
      <span *ngIf="!isExpanded">Udvid</span>
      <span *ngIf="isExpanded">Luk</span>
      <fa-icon [icon]="faChevronDown"></fa-icon>
  </div>
</div>

<div *ngIf="!favoriteAnalysis" class="no-favorites">
  Ingen favoritter
</div>
