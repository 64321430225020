<div class="top-info__wrapper" [ngClass]="statistics.financialYear === null ? 'animated-background': ''">
  <div class="row">
    <div class="top-info__year">
      <app-year-picker [selectedYear]="financialYear" [options]="yearPickerOptions" (onSelect)="loadData($event)"></app-year-picker>
    </div>
    <ng-container>
      <div class="col">
        <div class="top-info">
          <div class="top-info__number">
            {{statistics.realisedPotential | vatCurrency}}
          </div>
          <div class="top-info__text">
            Realiseret resultat
          </div>
        </div>
      </div>
      <div class="col">
        <div class="top-info">
          <div class="top-info__number">
            {{statistics.remainingPotential | vatCurrency}}
          </div>
          <div class="top-info__text">
            Rest potentiale
          </div>
        </div>
      </div>
      <div class="col">
        <div class="top-info">
          <div class="top-info__number">
            {{statistics.originalPotential | vatCurrency}}
          </div>
          <div class="top-info__text">
            Originalt potentiale
          </div>
        </div>
      </div>
      <div class="col">
        <div class="top-info">
          <div class="top-info__number">
            {{statistics.totalPotential | vatCurrency}}
          </div>
          <div class="top-info__text">
            Samlet potentiale
          </div>
        </div>
      </div>
      <div class="col">
        <div class="top-info">
          <div class="top-info__number">
            {{statistics.reviewStatus }} %
          </div>
          <div class="top-info__text">
            Status på gennemgang
          </div>
        </div>
      </div>
      <div class="col">
        <div class="top-info top-info__dialog-link" (click)="openCompletedAnalyzesDialog()">
          <div class="top-info__number">
            {{statistics.processedAnalysis?.length }} <fa-icon *ngIf="statistics.processedAnalysis?.length > 0" [icon]="faExternalLinkAlt"></fa-icon>
          </div>
          <div class="top-info__text">
            Status på analyser
          </div>
        </div>
      </div>
      <div class="col">
        <div class="top-info">
          <div class="top-info__number">
            {{statistics.latestPostingDate | date:'dd-MM-YYYY'}}
          </div>
          <div class="top-info__text">
            Data til dato
          </div>
        </div>
      </div>
      <div class="col">
        <div class="top-info">
          <div class="top-info__number">
            {{statistics.lastDataRead | date:'dd-MM-YYYY'}}
          </div>
          <div class="top-info__text">
            Data indlæst
          </div>
        </div>
      </div>


    </ng-container>
  </div>
</div>
