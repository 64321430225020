<div class="analyze" [ngClass]="id === globalAnalysisId ? 'total-rows' : ''">
  <div class="analyze__color">
    <div class="analyze__year">
      {{financialYears}}
    </div>
  </div>
  <div class="analyze__favorite">
    <button mat-icon-button aria-label="Tilføj farvorit" (click)="toggleFavorit(analyze.analysisId)">
      <fa-icon *ngIf="checkIfFavorite(analyze.analysisId) === true; else isFalse" [icon]="['fas', 'star']">
      </fa-icon>
      <ng-template #isFalse>
        <fa-icon [icon]="['far', 'star']">
        </fa-icon>
      </ng-template>
    </button>
  </div>
  <div class="analyze__content" *ngIf="contentLoaded(analyze.title); else loading;"
    (click)="navigateToPosting(analyze)">
    <div class=" analyze__header">
      {{analyze.title}}
    </div>
    <div class="analyze__info">
      <table>
        <tr>
          <td>Behandlet</td>
          <td>{{analyze.processed| number:'':'da-DK'}}</td>
        </tr>
        <tr>
          <td>Igangværende</td>
          <td>{{analyze.ongoing | number:'':'da-DK'}}</td>
        </tr>
        <tr>
          <td>Antal</td>
          <td>{{analyze.quantity | number:'':'da-DK'}}</td>
        </tr>
        <tr>
          <td>Realiseret resultat</td>
          <td *ngIf="analyze.realisedPotential != 0">
            {{analyze.realisedPotential | vatCurrency}}
          </td>
          <td *ngIf="analyze.realisedPotential === 0">-</td>
        </tr>
        <tr>
          <td>Rest potentiale</td>
          <td *ngIf="analyze.remainingPotential != 0">
            {{analyze.remainingPotential | vatCurrency}}</td>
          <td *ngIf="analyze.remainingPotential === 0">-</td>
        </tr>
        <tr>
          <td>Samlet potentiale</td>
          <td *ngIf="analyze.totalPotential != 0">{{analyze.totalPotential | vatCurrency}}</td>
          <td *ngIf="analyze.totalPotential === 0">-</td>
        </tr>
      </table>
    </div>
  </div>
  <ng-template #loading>
    <div class="analyze__content">
      <div class="animated-background">
        <div class="content-top"></div>
        <div class=" analyze__info">
          <div class="animated-background">
            <div class="content"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>