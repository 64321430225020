<div class="table__filter-menu__wrapper--input">
  <span>
    <fa-icon [icon]="faEquals"></fa-icon>
  </span>
  <input [formControl]="searchFieldEquals" placeHolder="Lig med">
</div>
<div class="table__filter-menu__wrapper--input">
  <span>
    <fa-icon [icon]="faGreaterThan"></fa-icon>
  </span>
  <input [formControl]="searchFieldGreaterThan" placeHolder="Større end">
</div>
<div class="table__filter-menu__wrapper--input">
  <span>
    <fa-icon [icon]="faLessThan"></fa-icon>
  </span>
  <input [formControl]="searchFieldLess" placeHolder="Mindre end">
</div>
