import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vatCurrency',
  pure: true,
})
export class VatCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currencyCode?: string,
    noText?: boolean,
    input?: boolean
  ): string {
    if (value === undefined || value === null) {
      return input ? '' : '-';
    } else if (noText) {
      return value.toLocaleString('da-DK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else if (!noText) {
      var valueWithCurrency = value.toLocaleString('da-DK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      if (currencyCode?.length > 0)
        valueWithCurrency = valueWithCurrency + ' ' + currencyCode;
      else valueWithCurrency = valueWithCurrency + ' DKK';
      return valueWithCurrency;
    }
  }
}
