import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  faArrowLeft,
  faArrowRight,
  faFileExcel,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
  faTh,
  faThList,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from 'file-saver';
import { FilterBarService } from '@modules/filter/services/filter-bar.service';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import {
  AnalysisFilter,
  AnalysisService,
  ExcelColumnItem,
  ExcelExportBody,
  FileResponse,
} from 'src/app/api/vat';
import { Subscription } from 'rxjs';
import { Column } from 'src/app/global-models';
import { LatestAnalyseView } from 'src/app/global-models/latest-analyse-view';
import { LocalStorageService } from '@modules/store/services/local-storage.service';
import storeKeys from '@modules/store/localStorageKeys.json';

export interface SortOption {
  label: string;
  value: string;
  ascending: boolean;
  faIcon: IconDefinition;
}

@Component({
  selector: 'app-analyzes',
  templateUrl: './analyzes.component.html',
  styleUrls: ['./analyzes.component.scss'],
})
export class AnalyzesComponent implements OnInit, OnDestroy {
  // Icons
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faTh = faTh;
  faThList = faThList;
  faSortAlphaDownAlt = faSortAlphaDownAlt;
  faSortAlphaDown = faSortAlphaDown;
  faSortAmountDownAlt = faSortAmountDownAlt;
  faSortAmountDown = faSortAmountDown;
  faFileExcel = faFileExcel;

  filterBarState: boolean = false;
  subscriptions: Subscription[] = [];
  toggleViewValue: string = 'table';
  sortOptions: SortOption[] = [];
  analysisFilter: AnalysisFilter;

  columns: Column[] = [];
  excelColumns: ExcelColumnItem[] = [];

  constructor(
    private filterBarService: FilterBarService,
    private internalFilterService: InternalFilterService,
    private labelGlossaryService: LabelGlossaryService,
    private analysisService: AnalysisService,
    private localStorageService: LocalStorageService
  ) {
    this.toggleViewValue = this.getLastestView();
    this.subscriptions.push(
      this.labelGlossaryService.analyzeColumnItemDefinition$.subscribe(
        (analyzeColumnItemDefinition: Column[]) => {
          this.columns = analyzeColumnItemDefinition;
          this.generateSortOptions();

          this.subscriptions.push(
            this.internalFilterService.analysisFilter.subscribe(
              (filter: AnalysisFilter) => {
                this.analysisFilter = filter;
              }
            )
          );
        }
      )
    );
    this.subscriptions.push(
      this.labelGlossaryService.excelAnalyseColumnItemDefinition$.subscribe(
        (excelAnalyseColumnItemDefinition: ExcelColumnItem[]) => {
          this.excelColumns = excelAnalyseColumnItemDefinition;
        }
      )
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterBarService.filterBarState.subscribe((value) => {
        this.filterBarState = value;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  selectSorting(option: SortOption): void {
    this.analysisFilter.sortBy = {
      propertyName: option.value,
      ascending: option.ascending,
    };
    this.internalFilterService.updateAnalysisFilters(this.analysisFilter);
  }

  saveLatestView(value: string): void {
    let newViewValue: LatestAnalyseView = {
      toggleViewValue: value,
    };

    this.toggleViewValue = value;
    this.localStorageService.addOrUpdateObject(
      storeKeys.LatestAnalyseViewStorageKey,
      newViewValue
    );
  }

  exportAnalysis(): void {
    const body: ExcelExportBody = {
      analysisFilter: this.analysisFilter,
      columns: this.excelColumns,
    };
    this.analysisService.exportPostings(body).subscribe((res: FileResponse) => {
      FileSaver.saveAs(res.data, `rapport_analyser.xlsx`);
    });
  }

  private getLastestView(): string {
    const latestToggle = this.localStorageService.getObject<LatestAnalyseView>(
      storeKeys.LatestAnalyseViewStorageKey
    );
    if (latestToggle !== null && latestToggle !== undefined) {
      return latestToggle.toggleViewValue;
    }
    return 'card';
  }

  private generateSortOptions(): void {
    this.columns.forEach((col: Column) => {
      if (col.key === 'financialYear') {
        return;
      }

      this.sortOptions.unshift(
        {
          label: col.name === 'Analyse' ? 'Titel' : col.name,
          value: col.key,
          ascending: true,
          faIcon:
            col.type === 'number' || col.type === 'currency'
              ? this.faSortAmountDownAlt
              : this.faSortAlphaDown,
        },
        {
          label: col.name === 'Analyse' ? 'Titel' : col.name,
          value: col.key,
          ascending: false,
          faIcon:
            col.type === 'number' || col.type === 'currency'
              ? this.faSortAmountDown
              : this.faSortAlphaDownAlt,
        }
      );
    });
  }
}
