<div class="chosen-filters" *ngIf="filterChips.length != 0">
    <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip *ngFor="let filterChip of filterChips" color="primary" selected [removable]="filterChip.removable"
            (removed)="removeFromFilter(filterChip)">
              {{filterChip.headline}}

              <ng-container *ngIf="filterChip.selectedData">
                {{filterChip.selectedData.split('!')[0]}}
                <fa-icon class="calender-icon" [icon]="faCalendar"></fa-icon>
                {{filterChip.selectedData.split('!')[1]}}
              </ng-container>

            <fa-icon [icon]="faTimes" matChipRemove *ngIf="filterChip.removable"></fa-icon>
        </mat-chip>
    </mat-chip-list>
    <mat-chip class="remover-chip" *ngIf="filterChips.length > 1" (click)="removeAllFilters()"><fa-icon [icon]="faTrash"></fa-icon> Fjern alle</mat-chip>
</div>
