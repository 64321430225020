import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Analysis, AnalysisByPostings, PostingFilter, PostingService, UpdatePosting } from 'src/app/api/vat';
import { Subscription } from 'rxjs';
import { HeaderService } from '@modules/shell/services/header.service';
import config from 'src/assets/config.json';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';

export interface AnalysisData {
  analysisId: number;
  name: string;
  year: string;
  processed: number;
  amount: number;
  realisedPotential: number;
  remainingPotential: number;
  totalPotential: number;
  isRuleMatched: boolean;
}
@Component({
  selector: 'app-select-analyze',
  templateUrl: './select-analyze.component.html',
  styleUrls: ['./select-analyze.component.scss'],
})
export class SelectAnalyzeComponent implements OnInit, AfterViewInit {
  // Icons
  faTimes = faTimes;

  private isAllPostingsSelected: boolean = false;
  private selectedPostingIds: number[] = [];
  private deSelectedPostingIds: number[] = [];
  private totalPostings: number = 0;
  private postingFilter: PostingFilter;

  displayedColumns: string[] = [
    'name',
    'year',
    'processed',
    'amount',
    'realisedPotential',
    'remainingPotential',
    'totalPotential',
  ];
  dataSource: MatTableDataSource<AnalysisData>;
  selectedAnalysisData: AnalysisData;

  @Input() view;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<SelectAnalyzeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      totalPostings: number;
      analysisByPostings: AnalysisByPostings;
      selectedPostingIds: number[];
      isAllPostingsSelected: boolean;
      deSelectedPostingIds: number[];
      postingFilter: PostingFilter;
    },
    private postingService: PostingService,
    private headerService: HeaderService,
    private internalFilterService: InternalFilterService
  ) {
    this.totalPostings = data.totalPostings;
    this.selectedPostingIds = data.selectedPostingIds;
    this.isAllPostingsSelected = data.isAllPostingsSelected;
    this.deSelectedPostingIds = data.deSelectedPostingIds;
    this.postingFilter = this.postingFilter;
    const mappedData: AnalysisData[] = [];

    this.headerService.headerTitleState.subscribe((title) => {
      const year: string = this.internalFilterService.getSelectedPeriodYears();

      if (data.analysisByPostings.ruleMatchedAnalysis) {
        const ruleMatchedAnalysis = this.filterAnalysis(
          data.analysisByPostings.ruleMatchedAnalysis,
          title
        ).map((analysis: Analysis) =>
          this.mapToAnalysisData(analysis, year, true)
        );

        mappedData.push(...ruleMatchedAnalysis);
      }

      if (data.analysisByPostings.nonRuleMatchedAnalysis) {
        const nonRuleMatchedAnalysis = this.filterAnalysis(
          data.analysisByPostings.nonRuleMatchedAnalysis,
          title
        ).map((analysis: Analysis) =>
          this.mapToAnalysisData(analysis, year, false)
        );

        mappedData.push(...nonRuleMatchedAnalysis);
      }
      this.dataSource = new MatTableDataSource(mappedData);

      this.internalFilterService.postingFilter.subscribe(
        (postingFilter: PostingFilter) => (this.postingFilter = postingFilter)
      );
    });
  }

  private filterAnalysis = (analysis: Analysis[], title: string) =>
    analysis.filter(
      (f) => f.analysisId !== config.GlobalAnalysisId && f.title !== title
    );

  private mapToAnalysisData(
    analysis: Analysis,
    year: string,
    isRuleMatched: boolean = false
  ): AnalysisData {
    return {
      analysisId: analysis.analysisId,
      name: analysis.title,
      year,
      processed: analysis.processed,
      amount: analysis.quantity,
      realisedPotential: analysis.realisedPotential,
      remainingPotential: analysis.remainingPotential,
      totalPotential: analysis.totalPotential,
      isRuleMatched,
    } as AnalysisData;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  close() {
    this.dialogRef.close();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setPrimaryAnalysis() {
    if (this.isAllPostingsSelected === false) {
      this.subscriptions.push(
        this.postingService
          .setPrimaryAnalysisByPostingIds(
            this.selectedAnalysisData.analysisId,
            this.selectedPostingIds
          )
          .subscribe(() => {
            this.close();
          })
      );
    } else {
      this.postingFilter.pageModel.skip = 0;
      this.postingFilter.pageModel.take = this.totalPostings;
      const updatePosting: UpdatePosting = {
        postingFilter: this.postingFilter,
        editedNoteId: 0,
        deSelectedPostings: this.deSelectedPostingIds,
      };
      this.postingService
        .setPrimaryAnalysisByPostingFilter(
          this.selectedAnalysisData.analysisId,
          updatePosting
        )
        .subscribe(() => {
          this.close();
        });
    }
  }
}
