import { Injectable } from '@angular/core';
import { LocalStorageService } from '@modules/store/services/local-storage.service';

import { Customer } from 'src/app/api/vat';

import { LatestDetail } from 'src/app/global-models';
import storeKeys from '@modules/store/localStorageKeys.json';

@Injectable({
  providedIn: 'root'
})
export class AnalyzeHelperService {

  constructor(
    private localStorageService: LocalStorageService,) { }

  public addAnalyzeToLatestLooked(analysisId: number): void {
    const customerId = this.getCurrentCustomerId();
    if (analysisId !== null) {
      let latestAnalysis =
        (this.localStorageService.getObject<LatestDetail[]>(
          storeKeys.LatestAnalysisStorageKey
        ) as LatestDetail[]) ?? [];

      const latestCustomerAnalysisCard = latestAnalysis.filter(l => l.customerId === customerId);
      const allOtherlatestAnalysisCard = latestAnalysis.filter(l => l.customerId !== customerId);

      this.removeAnalyzeCard(latestCustomerAnalysisCard, analysisId);
      latestCustomerAnalysisCard.unshift({
        analysisId: analysisId,
        customerId: customerId,
      } as LatestDetail);
      latestCustomerAnalysisCard.splice(storeKeys.DashboardItems);

      latestAnalysis = allOtherlatestAnalysisCard.concat(latestCustomerAnalysisCard);
      this.localStorageService.addOrUpdateObject(
        storeKeys.LatestAnalysisStorageKey,
        latestAnalysis
      );
    }
  }

  private getCurrentCustomerId(): string {
    return (
      (this.localStorageService.getObject<Customer>(
        storeKeys.CurrentSelectedCustomerStorageKey
      ) as Customer)?.customerId?.toString() ?? ''
    );
  }

  private removeAnalyzeCard(latestDetail: LatestDetail[], analysisId: number): void {
    const index = latestDetail.map(l => l.analysisId).indexOf(analysisId);
    if (index !== -1)
      latestDetail.splice(index, 1);
  }
}
