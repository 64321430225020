<ng-template [ngIf]="invoice" [ngIfElse]="emptyState">
  <div class="invoice">
    <div class="row">
      <div class="col-md-8">
        <div class="invoice__info invoice__info--left">
          <p><span>Leverandørnummer: </span>{{invoice.supplierNo}}</p>
          <p><span>Kreditor: </span>{{invoice.supplierName}}</p>
          <p><span>Debitor: </span>{{invoice.buyer}}</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="invoice__info">
          <p><span>Fakturanummer:</span> {{invoice.invoiceNo}}</p>
          <p><span>Fakturadato:</span> {{invoice.date | date:'dd-MM-YYYY'}}</p>
          <p><span>EAN: </span>{{invoice.ean}}</p>
        </div>
      </div>
    </div>

    <hr>

    <table class="invoice__lines">
      <tr>
        <td>Linje</td>
        <td>Varenr.</td>
        <td colspan="2">Beskrivelse</td>
        <td>Antal</td>
        <td>Enhed</td>
        <td class="right-align">Enhedspris</td>
        <td class="right-align">Moms</td>
        <td class="right-align">Total</td>
      </tr>
      <ng-container *ngFor="let invoiceline of invoice.invoiceLines">
        <tr>
          <td>{{invoiceline.lineNo}}</td>
          <td>{{invoiceline.itemNo}}</td>
          <td colspan="2">{{invoiceline.description}}</td>
          <td>{{invoiceline.quantity}}</td>
          <td>{{invoiceline.unitType}}</td>
          <td class="right-align">{{invoiceline.unitPrice | vatCurrency: invoice.invoiceCurrencyCode}}</td>
          <td class="right-align">{{invoiceline.vatPercentage}}%</td>
          <td class="right-align">{{invoiceline.total | vatCurrency: invoice.invoiceCurrencyCode}}</td>
        </tr>
      </ng-container>
    </table>

    <hr>

    <div class="row">
      <div class="col-6 offset-6">
        <div class="invoice__info">
          <p><span>Liniesum i alt excl. moms: </span>{{invoice.amountExVat | vatCurrency : invoice.invoiceCurrencyCode}}
          </p>
          <p><span>Momsgrundlag: </span>{{invoice.standardRatedAmount | vatCurrency : invoice.invoiceCurrencyCode}}</p>
          <p><span>Total momsbeløb (25%): </span>{{invoice.invoiceVatAmount | vatCurrency :
            invoice.invoiceCurrencyCode}}</p>
          <p><span>Total incl. moms: </span>{{invoice.amountTotal | vatCurrency : invoice.invoiceCurrencyCode}}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="invoice__info invoice__info--left top-20">
          <p><span>Fakturabeskrivelse: </span></p>
          <p>
            {{invoice.description}}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #emptyState>
  <div>
    Ingen faktura at vise
  </div>
</ng-template>