import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private headerTitleState$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  public headerTitleState = this.headerTitleState$.asObservable();

  constructor() {}

  updateTitleHeader(title: string) {
    this.headerTitleState$.next(title);
  }
}
