import { Component, OnInit } from '@angular/core';
import {
  faTachometerAlt,
  faChartPie,
  faComment,
  faUsersCog,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import { MenuService } from '@modules/shell/services/menu.service';
import { HeaderService } from '@modules/shell/services/header.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { RolesConstants } from '@modules/commons/classes/roles-constants';
import { MatDialog } from '@angular/material/dialog';
import { ManualsDialogComponent } from '@modules/dialog/components/manuals-dialog/manuals-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // Icons
  faTachometerAlt = faTachometerAlt;
  faChartPie = faChartPie;
  faComment = faComment;
  faUsersCog = faUsersCog;
  faChartBar = faChartBar;

  analysisTitle: string = '';
  messagesCount: number = 0;

  public readonly VAT_BDOAdministrator: string = RolesConstants.VAT_BDOAdministrator;
  public readonly VAT_StandardUser: string = RolesConstants.VAT_StandardUser;
  public readonly VAT_SuperUser: string = RolesConstants.VAT_SuperUser;
  public readonly VAT_Manager: string = RolesConstants.VAT_Manager;
  public readonly VAT_Reader: string = RolesConstants.VAT_Reader;

  constructor(
    private menuService: MenuService,
    private headerService: HeaderService,
    private messagesService: MessagesService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.messagesService.NewMessagesCount$.subscribe(
      (messageCount) => (this.messagesCount = messageCount)
    );
    this.headerService.headerTitleState.subscribe((value) => {
      this.analysisTitle = value;
    });
  }

  sidebarToggle() {
    this.menuService.toggleSidebarState();
  }

  manualsDialog() {
    const dialogRef = this.dialog.open(ManualsDialogComponent, {
      data: {
        title: this.analysisTitle
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
