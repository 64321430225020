<div class="dialog">
    <div class="dialog__header">
        Guide for {{data.title}}
    </div>
    <div class="dialog__close" (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </div>
    <div class="dialog__content">

        <span *ngIf="data.title === 'Interne arter – art 9'">
            Man kan med fordel sortere på kolonnen ”Leverandør”. Betalinger til eksterne leverandører bør ikke konteres på art 9, men henholdsvis art 4.0 eller 4.9.
        </span>

        <span *ngIf="data.title === 'Øreafrunding'">
            Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
        </span>

        <span *ngIf="data.title === 'Udlægsapp KMD'">
            Under udarbejdelse
        </span>

        <span *ngIf="data.title === 'Intern fakturering - art 9'">
            Man kan med fordel sortere på kolonnen ”Moms”. <br>
            Interne afregninger bør som udgangspunkt ikke ske på momsbærende arter.<br>
            Man bør derfor undersøg modposter i relation hertil.
        </span>

        <span *ngIf="data.title === 'Ældreboliger'">
            Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
        </span>

        <span *ngIf="data.title === 'Betalingskort'">
            Kontoudtoget kan gennemgås for at efterse om den enkelte udgift er med eller uden moms.
        </span>

        <span *ngIf="data.title === 'Gavekort'">
            Er der tale om et gavekort, bør det kontrolleres, om fakturaen indeholder moms, og herefter om udgiften er korrekt artskonteret.
        </span>

        <span *ngIf="data.title === 'Moms i fakturavarelinje'">
            Det skal her undersøges, om fakturaen (andet bilag) indeholder moms.
        </span>

        <span *ngIf="data.title === 'Rykker i fakturavarelinje'">
            Er der tale om en ”rykker”, skal det undersøges, om den oprindelige faktura indeholder moms, og herefter om udgiften er artskonteret korrekt.
        </span>

        <span *ngIf="data.title === 'Udgifter til eksterne på art 1 løn'">
            Det skal her undersøges, om udgifterne indeholder moms.
            <br>
            Afhængig af momsgrundlaget bør bogføringen ske på henholdsvis art 4.0 eller 4.9.
        </span>

        <span *ngIf="data.title === 'Interne lønudgifter - art 1'">
            Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
        </span>

        <span *ngIf="data.title === 'Udgifter til apoteker'">
            Man kan med fordel sorterer efter funktionen, for at efterse at udgifter bogføres ensartet på de enkelte funktioner - dette med afsæt i relevant lovgrundlag.
        </span>

        <span *ngIf="data.title === 'Udgifter på indtægtsarter'">
            Det bør undersøges, om udgifterne bør bogføres på en udgiftsart - eksempelvis art 2.9, 4.0 eller 4.9.
        </span>

        <span *ngIf="data.title === 'Forskel på bogført beløb og faktura beløb'">
            Det bør undersøges, om hele fakturabeløbet er bogført, og om der er foretaget korrekt artskontering.
        </span>

        <span *ngIf="data.title === 'Faktura viser kun et total beløb'">
            Posteringer bør undersøges for andet bilagsmateriale, for vurdering af korrekt moms- og artskontering.
        </span>

        <span *ngIf="data.title === 'Husleje – egne huslejeindtægter'">
            Det bør derfor undersøges, om der findes indtægter, der ikke har karakter af at være kontraktmæssig husleje, eksempelvis fordeling af udgifter i organisationen - udleje fra en afdeling til en anden.
        </span>

        <span *ngIf="data.title === 'Husleje – takstbetalinger'">
            Der bør undersøges, om der er tale om beboerbetalinger, opholdsbetalinger, takstbetalinger eller lign. som ikke er omfattet af art 7.1.
        </span>

        <span *ngIf="data.title === 'Husleje – opsamling'">
            Posteringer bør vurderes for, om der er indtægter som ikke er omfattet af bogføring på art 7.1, eksempelvis indtægter fra videreudlejning af eksterne lejemål, indtægter for leje af jord, engangslejeindtægter uden en egentlig lejekontrakt
            m.v.
        </span>

        <span *ngIf="data.title === 'Busdrift'">
            Man kan med fordel sortere kolonne ”IM Grp.”<br>
            Denne funktion indgår ikke i momsrefusionsordningen (dog undtaget gruppering 002).<br>
            Der bør undersøges om anvendelse af grupperinger i relation til udgiftens karakter er korrekt.
        </span>

        <span *ngIf="data.title === 'Færgedrift'">
            Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
        </span>

        <span *ngIf="data.title === 'Jernbanedrift'">
            Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
        </span>

        <span *ngIf="data.title === 'Tilskud til trafikselskaber'">
            Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
        </span>

        <span *ngIf="data.title === 'Faktura med moms svarer til bogført moms'">
            Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
        </span>

        <span *ngIf="data.title === 'Faktura med moms og bogført med moms'">
            Man kan med fordel sortere faldende på kolonne ”Potentiale” og tilvælge kolonne ”Moms bilag total”. Som et resultat heraf kan man se på de posteringer, hvor der er bogført mindre moms, end hvad fakturaen foreskriver.
        </span>

        <span *ngIf="data.title === 'Faktura med moms og bogført uden moms'">
            Man kan med fordel sortere faldende på kolonnen ”Potentiale”.
            <br>
            Undersøges om der er sket korrekt artskontering, ex. anvendelse af momsbærende arter.
        </span>

        <span *ngIf="data.title === 'Konteret i henhold til positivlisten'">
            Udsøg om poster overholder positivlistens bestemmelser, eksempelvis ingen driftsoverenskomst eller at udgifter til kommuner/regioner ikke er bogført med ejerforhold 4.
        </span>

        <span *ngIf="data.title === 'Tema positivlisten – HK 0'">
            Undersøg om der er tale om tilskud til eksterne, herunder om der er tale om drifts– eller anlægstilskud.
            <br>
            Vær opmærksom på at der gælder særlige regler for tilskud til anlæg og i forbindelse med en frivillig momsregistrering.
        </span>

        <span *ngIf="data.title === 'Tema positivlisten – HK 3'">
            Det anbefales at poster i relation til de enkelte funktioner vurderes i forhold til positivlistens bestemmelser.
        </span>

        <span *ngIf="data.title === 'Tema positivlisten – HK 4'">
            Det anbefales at poster i relation til de enkelte funktioner vurderes i forhold til positivlistens bestemmelser.
        </span>

        <span *ngIf="data.title === 'Tema positivlisten – HK 5'">
            Det anbefales at poster i relation til de enkelte funktioner vurderes i forhold til positivlistens bestemmelser.
        </span>

        <span *ngIf="data.title === 'Tema positivlisten – HK 6'">
            Det anbefales at vurdere om der er udgifter iht. Erhvervsudvikling, som er omfattes af positivlistens bestemmelser.
        </span>

        <span *ngIf="data.title === 'Overførsler til personer på art 5.2'">
            Analysen giver som udgangspunkt ikke mulighed for momsrefusion, men kan anvendes til kontrol af korrekt bogføring.
            <br>
            Indsigt i underliggende bevillinger/lovgrundlag kan med fordel buges til vurdering af om bogføringen er sket korrekt.
        </span>

        <span *ngIf="data.title === 'Tema positivlisten – tilskud og støtte'">
            Det bør undersøges, om posteringer opfylder positivlistens bestemmelser, og herunder om posteringer er korrekt artskonteret.
        </span>

        <span *ngIf="data.title === 'Øvrige tilskud'">
            Det bør undersøges, om der findes tilskud, som burde være konteret på andre funktioner, herunder funktioner omfattet af positivlisten, typiske eksempler findes på HK 6.
        </span>

        <span *ngIf="data.title === 'Erstatninger'">
            Posteringer bør gennemgås for at vurdere, om der reelt er tale om en erstatning, som i stedet bør bogføres på en indtægtsart - art 7.
        </span>

        <span *ngIf="data.title === 'Faktura uden moms og bogført uden moms'">
            Det bør kontrolleres om analysen indeholder leverandører som umiddelbart leverer momspligtige varer og ydelser. Hvis dette er tilfældet, bør det underliggende bilagsmateriale undersøges nærmere.
        </span>

        <span *ngIf="data.title === 'Faktura uden moms og bogført med moms'">
            Bilagsmateriale til posteringer i denne analyse bør gennemgås for at afklare, om der findes dokumentation for moms. Alternativ bør der reguleres for, for meget hjemtaget momsrefusion.
        </span>

        <span *ngIf="data.title === 'Beregnede omkostninger – art 0'">
            Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
        </span>

        <span *ngIf="data.title === 'Opsamling - omfattet af positivlisten'">
            Kontroller at der ikke er posteringer som giver adgang til momsrefusion i relation til positivlisten.
        </span>

        <span *ngIf="data.title === 'Opsamling - poster med leverandørnavn bogført uden moms'">
            Under udarbejdelse
        </span>

        <span *ngIf="data.title === 'Opsamling - poster med leverandørnavn bogført med moms'">
            Under udarbejdelse
        </span>

        <span *ngIf="data.title === 'Opsamling - poster uden leverandørnavn bogført med moms'">
            Under udarbejdelse
        </span>

        <span *ngIf="data.title === 'Udgifter til eksterne på art 9'">
            Man kan med fordel sortere på kolonnen ”Leverandør”. Betalinger til eksterne leverandører bør ikke konteres på art 9, men henholdsvis art 4.0 eller 4.9.
        </span>

        <span *ngIf="data.title === 'Intern fakturering - øvrige arter'">
            Man kan med fordel sortere på kolonnen ”Moms”. <br>
            Interne afregninger bør som udgangspunkt ikke ske på momsbærende arter.<br>
            Man bør derfor undersøg modposter i relation hertil.
        </span>

    </div>

    <div class="dialog__buttons">
        <button mat-flat-button color="primary" (click)="goToManual()">
            Gå til manual
        </button>
        <button mat-stroked-button (click)="close()">
            Luk
        </button>
    </div>
</div>