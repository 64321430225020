import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  OverlayRelatedFilter,
  OverlayService,
} from '@modules/overlay/services/overlay.service';
import { PagingModel, Posting, PostingService } from 'src/app/api/vat';
import { Subscription } from 'rxjs';
import {
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
  IconDefinition,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { Row } from 'src/app/global-models';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';

interface Sort {
  value: string;
  viewValue: string;
  faIcon: IconDefinition;
}

@Component({
  selector: 'app-account-no-related-postings',
  templateUrl: './account-no-related-postings.component.html',
  styleUrls: ['../overlay/overlay.component.scss'],
})
export class AccountNoRelatedPostingsComponent implements OnInit, OnDestroy {
  // Icons
  faSortAlphaDownAlt = faSortAlphaDownAlt;
  faSortAlphaDown = faSortAlphaDown;
  faSortAmountDownAlt = faSortAmountDownAlt;
  faSortAmountDown = faSortAmountDown;
  faSearch = faSearch;
  @Input() postingId: number;
  labelRows: Row[] = [];

  relatedPostings: Posting[] = [];
  isOrderByChanged: boolean = false;
  subscriptions: Subscription[] = [];
  popupVisible: boolean = false;
  hoveredPosting: Posting = undefined;
  loadedAll: boolean = false;
  skip: number = 0;
  take: number = 30;
  scrollLoad = false;
  pageModel: PagingModel;
  sorts: Sort[] = [
    {
      value: 'sort-1',
      viewValue: 'Leverandør',
      faIcon: this.faSortAlphaDown,
    },
    {
      value: 'sort-2',
      viewValue: 'Leverandør',
      faIcon: this.faSortAlphaDownAlt,
    },
    {
      value: 'sort-3',
      viewValue: 'Brutto beløb',
      faIcon: this.faSortAmountDownAlt,
    },
    {
      value: 'sort-4',
      viewValue: 'Brutto beløb',
      faIcon: this.faSortAmountDown,
    },
  ];

  sortby = this.sorts[2].value;

  constructor(
    private postingsService: PostingService,
    private overlayService: OverlayService,
    private labelGlossaryService: LabelGlossaryService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.labelGlossaryService.releatedPostingDefinitions$.subscribe(
        (labels) => {
          this.labelRows = labels;
          this.pageModel = this.resetPageModel();
          this.loadAccountNoRelatedPostings();
          this.overlayService.filterRelated$.subscribe(
            (filter: OverlayRelatedFilter) => {
              if (filter.postingId === this.postingId) {
                this.pageModel = filter.pageModel;
                this.relatedPostings = [];
                this.loadAccountNoRelatedPostings();
              }
            },
            (error) => console.error(error)
          );
        }
      )
    );
  }

  sortOrderChange(event: { value }) {
    this.isOrderByChanged = true;
    this.pageModel = this.resetPageModel(this.pageModel.filter);
    this.pageModel.sortBy.propertyName = event.value;
    this.overlayService.announceRelatedFilterValue({
      postingId: this.postingId,
      pageModel: this.pageModel,
    });
  }

  resetPageModel(filter: string = ''): PagingModel {
    return {
      skip: this.skip,
      take: this.take,
      filter: filter ?? '',
      sortBy: { propertyName: this.sorts[2].value, ascending: true },
    };
  }

  applyFilter(event: Event) {
    const filterText = (event.target as HTMLInputElement).value;
    this.pageModel = this.resetPageModel(filterText);
    this.overlayService.announceRelatedFilterValue({
      postingId: this.postingId,
      pageModel: this.pageModel,
    });
  }

  loadAccountNoRelatedPostings(): void {
    this.subscriptions.push(
      this.postingsService
        .getAccountNoRelatedPostings(this.postingId, this.pageModel)
        .subscribe((postings: Posting[]) => {
          if (this.isOrderByChanged) {
            this.isOrderByChanged = false;
            this.loadedAll = false;
            this.relatedPostings = postings;
          } else if (postings.length) {
            this.relatedPostings.push(...postings);
            this.scrollLoad = false;
            if (postings.length >= this.take) this.loadedAll = false;
            else {
              this.loadedAll = true;
            }
          } else {
            this.loadedAll = true;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  addPostingToTab(posting: Posting): void {
    this.overlayService.addPostingToTab(posting);
  }

  onScroll(event: Event): void {
    if (!this.scrollLoad && !this.loadedAll) {
      const tracker: any = event.target;
      if (
        tracker.scrollHeight - tracker.scrollTop <=
          tracker.clientHeight + 100 ||
        (tracker.clientHeight > 128 &&
          tracker.scrollHeight - tracker.scrollTop <=
            tracker.clientHeight + 200)
      ) {
        this.pageModel.skip += this.pageModel.take;
        this.loadAccountNoRelatedPostings();
        this.scrollLoad = true;
      }
    }
  }
}
