import { Component, OnInit } from '@angular/core';
import {
  faArrowRight,
  faTimes,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { MenuService } from '@modules/shell/services/menu.service';
import { AllMessagesComponent } from '@modules/dialog/components/all-messages/all-messages.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/api/vat';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { InternalUserService } from '@modules/commons/services/internal-user.service';
import { configureAuth } from '@modules/login/login.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  // Icons
  faTimes = faTimes;
  faArrowRight = faArrowRight;
  faSignOutAlt = faSignOutAlt;

  sidebarOpen: boolean = false;
  currentUser: User;

  constructor(
    private menuService: MenuService,
    private internalUserService: InternalUserService,
    public dialog: MatDialog,
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {}

  ngOnInit() {
    this.menuService.sidebarState.subscribe((value) => {
      this.sidebarOpen = value;
    });

    this.internalUserService.user$.subscribe((user: User) => {
      this.currentUser = user;
    });
  }

  logout() {
    const logoutAuthOptions: AuthOptions = {
      customParams: {
        client_id: environment.openIdConnectConfig.clientId,
        /* other params */
      },
    };
    this.oidcSecurityService.logoff(undefined, logoutAuthOptions);
  }

  sidebarToggle() {
    this.menuService.toggleSidebarState();
  }

  openAllNotesDialog(): void {
    const dialogRef = this.dialog.open(AllMessagesComponent, {
      width: '900px',
      data: {
        dataKey: 'notesDialog',
      },
    });
  }

  openAllNewsDialog(): void {
    const dialogRef = this.dialog.open(AllMessagesComponent, {
      width: '900px',
      data: {
        dataKey: 'newsDialog',
      },
    });
  }

  goToLaw() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/lovgivning`])
    );

    window.open(url, '_blank');
  }

  gotoManuals() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/manualer`])
    );

    window.open(url, '_blank');
  }
}
