import { Injectable } from '@angular/core';
import { FilterDefinition } from '@modules/filter/models/filterDefinition';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { ColumnDefinition, Customer, ExcelColumnItem } from 'src/app/api/vat';
import { BehaviorSubject, Observable } from 'rxjs';
import { Column, Row } from 'src/app/global-models';
import {
  OpusDefinition,
  PrismeDefinition,
  PrismeNYDefinition,
  ØSDefinition,
} from '../Customer-glossary-definitions';
import { GlossaryDefinitionHelper } from '../label-helper/glossary-definition-helper';

@Injectable({
  providedIn: 'root',
})
export class LabelGlossaryService {
  private filterDefinitions: FilterDefinition[] = [];
  private internalCustomerFinancialTypeId: number;

  private postingTableColumnsDefinition: BehaviorSubject<Column[]> =
    new BehaviorSubject<Column[]>([]);
  public postingTableColumnsDefinition$: Observable<Column[]> =
    this.postingTableColumnsDefinition.asObservable();

  private overlayDefinition: BehaviorSubject<Row[]> = new BehaviorSubject<
    Row[]
  >([]);
  public overlayDefinition$: Observable<Row[]> =
    this.overlayDefinition.asObservable();

  private releatedPostingDefinitions: BehaviorSubject<Row[]> =
    new BehaviorSubject<Row[]>([]);
  public releatedPostingDefinitions$: Observable<Row[]> =
    this.releatedPostingDefinitions.asObservable();

  private excelPostingColumnItemDefinition: BehaviorSubject<ExcelColumnItem[]> =
    new BehaviorSubject<ExcelColumnItem[]>([]);
  public excelPostingColumnItemDefinition$: Observable<ExcelColumnItem[]> =
    this.excelPostingColumnItemDefinition.asObservable();

  private excelAnalyseColumnItemDefinition: BehaviorSubject<ExcelColumnItem[]> =
    new BehaviorSubject<ExcelColumnItem[]>([]);
  public excelAnalyseColumnItemDefinition$: Observable<ExcelColumnItem[]> =
    this.excelAnalyseColumnItemDefinition.asObservable();

  private analyzeColumnItemDefinition: BehaviorSubject<Column[]> =
    new BehaviorSubject<Column[]>([]);
  public analyzeColumnItemDefinition$: Observable<Column[]> =
    this.analyzeColumnItemDefinition.asObservable();

  constructor(
    private internalCurrentCustomerService: InternalCurrentCustomerService
  ) {
    this.internalCurrentCustomerService.customer.subscribe(
      (customer: Customer) => {
        if (
          this.internalCustomerFinancialTypeId !==
          customer.customerFinancialTypeId
        ) {
          this.internalCustomerFinancialTypeId =
            customer.customerFinancialTypeId;
          this.setGlossaryDefinitionByCustomerFinancialType(
            customer.customerFinancialTypeId
          );
        }
      }
    );
  }

  private setGlossaryDefinitionByCustomerFinancialType(
    customerFinancialType: number
  ): void {
    let labelDefinitions: GlossaryDefinitionHelper;
    switch (customerFinancialType) {
      case 1:
        labelDefinitions = new OpusDefinition(
          this.internalCurrentCustomerService
        );
        break;
      case 2:
        labelDefinitions = new PrismeDefinition();
        break;
      case 3:
        labelDefinitions = new PrismeNYDefinition();
        break;
      case 4:
        labelDefinitions = new ØSDefinition();
        break;
      default:
        throw new Error('Need Definition for current Customer Financial Type.');
    }

    if (labelDefinitions === undefined)
      throw new Error('labelDefinition is undefined, control label service');

    this.filterDefinitions = labelDefinitions.filterDefinitions;

    this.updatePostingColumns(labelDefinitions.postingColumnDefinitions);
    this.updateOverlayRows(labelDefinitions.postingOverlayDefinitions);
    this.updateReleatedPostingDefinitions(
      labelDefinitions.releatedPostingDefinitions
    );
    this.updatePostingExcelColumns(
      labelDefinitions.postingExcelColumnItemDefinitions
    );
    this.updateAnalyzeColumnItemDefinition(
      labelDefinitions.analyzeItemDefinitions
    );
    this.updateAnalyseExcelColumns(
      labelDefinitions.analyzeExcelColumnItemDefinitions
    );
  }

  public getAnalysisFilterDefinitions(): FilterDefinition[] {
    return this.filterDefinitions;
  }

  public getPostingFilterDefinitions(): FilterDefinition[] {
    return this.filterDefinitions.filter((f) => f.filterType !== 'analysisIds');
  }

  public getExcelColumns(): ExcelColumnItem[] {
    return this.excelPostingColumnItemDefinition.getValue();
  }

  public updatePostingColumns(columns: Column[]): void {
    if (columns === null) {
      this.resetPostingColumnsToDefault().then(() => {});
    } else {
      this.postingTableColumnsDefinition.next(columns);
    }
  }

  public resetPostingColumnsToDefault(): Promise<void> {
    return new Promise<void>((resolve, rejcet) => {
      this.setGlossaryDefinitionByCustomerFinancialType(
        this.internalCustomerFinancialTypeId
      );
      resolve();
    });
  }

  private updateReleatedPostingDefinitions(rows: Row[]): void {
    this.releatedPostingDefinitions.next(rows);
  }

  private updateAnalyzeColumnItemDefinition(columns: Column[]): void {
    this.analyzeColumnItemDefinition.next(columns);
  }

  private updateOverlayRows(rows: Row[]): void {
    this.overlayDefinition.next(rows);
  }

  private updatePostingExcelColumns(excelColumnItem: ExcelColumnItem[]): void {
    this.excelPostingColumnItemDefinition.next(excelColumnItem);
  }

  private updateAnalyseExcelColumns(excelColumnItem: ExcelColumnItem[]): void {
    this.excelAnalyseColumnItemDefinition.next(excelColumnItem);
  }

  public mapColumnDefinitionsToColumns(columns: Column[]): ColumnDefinition[] {
    return columns.map((column: Column) => {
      return {
        columnKey: column.key,
        visible: column.visible,
        orderNumber: columns.indexOf(column),
      } as ColumnDefinition;
    });
  }

  public updatesPostingColumnVisibility(
    columnDefinition: ColumnDefinition[]
  ): Column[] {
    return columnDefinition.map((column: ColumnDefinition) => {
      const c = this.postingTableColumnsDefinition.value.find(
        (p) => p.key === column.columnKey
      );
      c.visible = column.visible;
      return c;
    });
  }
}
