import {
  faCalendar,
  faFileInvoice,
  faList,
  faUserCog,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { ComponentFilterType } from '@modules/filter/classes/component-filter-types';
import { Column } from 'src/app/global-models';
import filterTypes from 'src/assets/filterTypes.json';
import labelVariables from 'src/assets/label-variables.json';
import { GlossaryDefinitionHelper } from '../label-helper/glossary-definition-helper';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
export class OpusDefinition extends GlossaryDefinitionHelper {
  private label;
  constructor(
    private internalCurrentCustomerService: InternalCurrentCustomerService
  ) {
    super();
    this.label = JSON.parse(JSON.stringify(labelVariables));
    this.generateFilterDefinitions();
    this.generateAnalyzeItemDefinitions();

    // We are updating the reference of the JSON object, so the method call order is important!
    this.generatePostingTableDefinitions();
    this.generateOverlayDefinitions();
    this.generateReleatedPostingDefinitions();
    this.generatePostingExcelDefinitions();
    this.generateAnalyseExcelDefinitions();
  }

  public generateFilterDefinitions(): void {
    this.addFilterDefinition(
      true,
      filterTypes.periods,
      null,
      ComponentFilterType.period,
      faCalendar
    );
    this.addFilterDefinition(
      false,
      filterTypes.invoiceTypes,
      null,
      ComponentFilterType.checkboxlist,
      faFileInvoice
    );
    this.addFilterDefinition(
      false,
      filterTypes.invoiceAttachments,
      null,
      ComponentFilterType.checkboxlist,
      faFileInvoice
    );
    this.addFilterDefinition(
      false,
      filterTypes.costTypes,
      null,
      ComponentFilterType.hierarchy,
      faList
    );
    this.addFilterDefinition(
      false,
      filterTypes.dransts,
      null,
      ComponentFilterType.checkboxlist,
      faList
    );
    this.addFilterDefinition(
      false,
      filterTypes.functions,
      null,
      ComponentFilterType.hierarchy,
      faList
    );
    this.addFilterDefinition(
      false,
      filterTypes.ownerships,
      null,
      ComponentFilterType.checkboxlist,
      faList
    );
    this.addFilterDefinition(
      false,
      filterTypes.vatTypes,
      null,
      ComponentFilterType.checkboxlist,
      faList
    );
    let customer = this.internalCurrentCustomerService.getCustomer().customerId;
    if (customer == 107)
      this.addFilterDefinition(
        false,
        filterTypes.companyCodes,
        null,
        ComponentFilterType.checkboxlist,
        faList
      );
    this.addFilterDefinition(
      false,
      filterTypes.reposted,
      null,
      ComponentFilterType.checkboxlist,
      faUserCog
    );
    this.addFilterDefinition(
      false,
      filterTypes.texts,
      null,
      ComponentFilterType.keyword,
      faSearch
    );
    this.addFilterDefinition(
      false,
      filterTypes.annexNos,
      null,
      ComponentFilterType.list,
      faSearch
    );
    this.addFilterDefinition(
      false,
      filterTypes.accountNos,
      null,
      ComponentFilterType.list,
      faSearch
    );
    this.addFilterDefinition(
      false,
      filterTypes.suppliers,
      null,
      ComponentFilterType.list,
      faSearch
    );
    this.addFilterDefinition(
      false,
      filterTypes.profitCenter,
      null,
      ComponentFilterType.list,
      faSearch
    );
  }

  public generateAnalyzeItemDefinitions() {
    this.addAnalyzeDefinition(this.label.analyzeTitle);
    this.addAnalyzeDefinition(this.label.analyzeFinancialYear);
    this.addAnalyzeDefinition(this.label.analyzeProcessed);
    this.addAnalyzeDefinition(this.label.analyzeOngoing);
    this.addAnalyzeDefinition(this.label.analyzeQuantity);
    this.addAnalyzeDefinition(this.label.analyzeRealisedPotential);
    this.addAnalyzeDefinition(this.label.analyzeRemainingPotential);
    this.addAnalyzeDefinition(this.label.analyzeTotalPotential);
  }

  public generatePostingTableDefinitions(): void {
    this.addPostingColumnDefinition(
      this.label.hasNote,
      undefined,
      true,
      false,
      false,
      true
    );
    this.addPostingColumnDefinition(
      this.label.select,
      undefined,
      true,
      false,
      false,
      true
    );
    this.addPostingColumnDefinition(
      this.label.bdoAudit,
      undefined,
      false,
      false,
      true,
      false,
      [
        { value: null, viewValue: 'Tom' },
        { value: 1, viewValue: '1' },
        { value: 2, viewValue: '2' },
        { value: 3, viewValue: '3' },
        { value: 4, viewValue: '4' },
        { value: 5, viewValue: '5' },
        { value: 6, viewValue: '6' },
        { value: 7, viewValue: '7' },
        { value: 8, viewValue: '8' },
        { value: 9, viewValue: '9' },
        { value: 10, viewValue: '10' },
        { value: 11, viewValue: '11' },
        { value: 12, viewValue: '12' },
        { value: 13, viewValue: '13' },
        { value: 14, viewValue: '14' },
        { value: 15, viewValue: '15' },
        { value: 16, viewValue: '16' },
        { value: 17, viewValue: '17' },
        { value: 18, viewValue: '18' },
        { value: 19, viewValue: '19' },
        { value: 20, viewValue: '20' },
      ]
    );
    this.addPostingColumnDefinition(
      this.label.accountNo,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.supplier,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.supplierType,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.text,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.function,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.shortArt,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.dranstText,
      undefined,
      false,
      false,
      true,
      false,
      [
        { value: 1, viewValue: 'Drift' },
        { value: 2, viewValue: 'Statsrefusion' },
        { value: 3, viewValue: 'Anlæg' },
      ]
    );
    this.addPostingColumnDefinition(
      this.label.grouping,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.annexNo,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.invoice.invoiceNo,
      'Fakturanr.',
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.changeDate,
      undefined,
      false,
      false,
      false,
      false
    );
    this.addPostingColumnDefinition(
      this.label.changedByUserName,
      undefined,
      false,
      false,
      false,
      false
    );
    this.addPostingColumnDefinition(
      this.label.amountPosted,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.amountVatToll,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.vatAmount,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.amountVatTollTotal,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.amountTotal,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.agreementText,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.profitCenterNavn,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.amountPostedExVat,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.annexTotal,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.financialYear,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.date,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.publicDepartmentType,
      undefined,
      false,
      false,
      true,
      false,
      [
        { value: 245, viewValue: 'Region' },
        { value: 250, viewValue: 'Primærkommune' },
      ]
    );
    this.addPostingColumnDefinition(
      this.label.primaryAnalysisTitle,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.accountableCpr,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.allocaterInitials,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.annexType,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.costCenter,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.creatorInitials,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.expeditionNo,
      undefined,
      false,
      false,
      false,
      false
    ); // We dont get data this property for now, hidded for futher notice.
    this.addPostingColumnDefinition(
      this.label.ownership,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.pspValue,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.reposted,
      undefined,
      false,
      false,
      true,
      false,
      [
        { value: 0, viewValue: 'Nej' },
        { value: 1, viewValue: 'Ja' },
      ]
    );
    this.addPostingColumnDefinition(
      this.label.companyCode,
      undefined,
      false,
      false,
      true,
      false
    );
    this.addPostingColumnDefinition(
      this.label.sfaExemption,
      undefined,
      false,
      false,
      true,
      false,
      [
        { value: 0, viewValue: 'Nej' },
        { value: 1, viewValue: 'Ja' },
      ]
    );
    this.addPostingColumnDefinition(
      this.label.vatType,
      undefined,
      false,
      false,
      true,
      true
    );
    this.addPostingColumnDefinition(
      this.label.invoice.invoiceTypeText,
      undefined,
      false,
      false,
      true,
      false,
      [
        { value: 1, viewValue: 'Elektronisk Faktura (PIE)' },
        { value: 2, viewValue: 'Elektronisk Kreditnote (PCM)' },
        { value: 3, viewValue: 'Papirbaseret Faktura (PIP)' },
      ]
    );
    this.addPostingColumnDefinition(
      this.label.potential,
      undefined,
      false,
      true,
      false,
      true
    );
    this.addPostingColumnDefinition(
      this.label.correctedPotential,
      undefined,
      false,
      true,
      false,
      true
    );
    this.addPostingColumnDefinition(
      this.label.result,
      undefined,
      false,
      true,
      false,
      true
    );
    this.addPostingColumnDefinition(
      this.label.status,
      undefined,
      false,
      true,
      false,
      true,
      [
        { value: 0, viewValue: 'Ubehandlet' },
        { value: 1, viewValue: 'Behandlet' },
        { value: 3, viewValue: 'Igangværende' },
      ]
    );
  }

  public generateOverlayDefinitions(): void {
    this.addPostingOverlayDefinition(this.label.potential);
    this.addPostingOverlayDefinition(this.label.correctedPotential);
    this.addPostingOverlayDefinition(this.label.result);
    this.addPostingOverlayDefinition(this.label.status);
    this.addPostingOverlayDefinition(this.label.amountPosted);
    this.addPostingOverlayDefinition(this.label.amountPostedExVat);
    this.addPostingOverlayDefinition(this.label.amountVatToll);
    this.addPostingOverlayDefinition(this.label.reposted);
    this.addPostingOverlayDefinition(this.label.profitCenterNavn);
    this.addPostingOverlayDefinition(this.label.agreementText);
    this.addPostingOverlayDefinition(this.label.supplier);
    this.addPostingOverlayDefinition(this.label.supplierType);
    this.addPostingOverlayDefinition(this.label.publicDepartmentType);
    this.addPostingOverlayDefinition(this.label.text);
    this.addPostingOverlayDefinition(this.label.costCenter);
    this.addPostingOverlayDefinition(this.label.pspValue);
    this.addPostingOverlayDefinition(this.label.accountNo);
    this.addPostingOverlayDefinition(this.label.ownership);
    this.addPostingOverlayDefinition(this.label.dranstText);
    this.addPostingOverlayDefinition(this.label.shortArt);
    this.addPostingOverlayDefinition(this.label.grouping);
    this.addPostingOverlayDefinition(this.label.function);
    this.addPostingOverlayDefinition(this.label.date);
    this.addPostingOverlayDefinition(this.label.annexNo);
    this.addPostingOverlayDefinition(this.label.annexType);
    this.addPostingOverlayDefinition(this.label.invoice.invoiceTypeText);
    this.addPostingOverlayDefinition(this.label.vatType);
    this.addPostingOverlayDefinition(this.label.sfaExemption);
    this.addPostingOverlayDefinition(this.label.reportingDuty);
    this.addPostingOverlayDefinition(this.label.originalAnalysisTitle);
    this.addPostingOverlayDefinition(this.label.primaryAnalysisTitle);
    this.addPostingOverlayDefinition(this.label.creatorInitials);
    this.addPostingOverlayDefinition(this.label.allocaterInitials);
    this.addPostingOverlayDefinition(this.label.changedByUserName);
    this.addPostingOverlayDefinition(this.label.changeDate);
    this.addPostingOverlayDefinition(this.label.annexTotal);
    this.addPostingOverlayDefinition(this.label.amountVatTollTotal);
  }

  public generateReleatedPostingDefinitions(): void {
    this.addReleatedPostingDefinitions(this.label.accountNo);
    this.addReleatedPostingDefinitions(this.label.supplierName);
    this.addReleatedPostingDefinitions(this.label.text);
    this.addReleatedPostingDefinitions(this.label.amountPosted);
    this.addReleatedPostingDefinitions(this.label.amountVatToll);
    this.addReleatedPostingDefinitions(this.label.potential);
    this.addReleatedPostingDefinitions(this.label.status);
    this.addReleatedPostingDefinitions(this.label.profitCenterNavn);
  }

  public generateAnalyseExcelDefinitions(): void {
    this.addAnalysisExcelItemDefinition(this.label.analyzeTitle);
    this.addAnalysisExcelItemDefinition(this.label.analyzeFinancialYear);
    this.addAnalysisExcelItemDefinition(this.label.processedAnalyses);
    this.addAnalysisExcelItemDefinition(this.label.analyzeProcessed);
    this.addAnalysisExcelItemDefinition(this.label.analyzeOngoing);
    this.addAnalysisExcelItemDefinition(this.label.analyzeQuantity);
    this.addAnalysisExcelItemDefinition(this.label.analyzeRealisedPotential);
    this.addAnalysisExcelItemDefinition(this.label.analyzeRemainingPotential);
    this.addAnalysisExcelItemDefinition(this.label.analyzeTotalPotential);
  }

  public generatePostingExcelDefinitions(): void {
    this.postingColumnDefinitions.forEach((column: Column) => {
      if (this.isColumnNotExclude(column.key)) {
        if (column.key !== this.label.grouping['key']) {
          if (this.label[column.key] !== undefined)
            this.addPostingExcelItemDefinition(
              this.label[column.key],
              undefined
            );
        } else {
          this.addPostingExcelItemDefinition(
            this.label[column.key],
            undefined,
            3
          );
        }
      }
    });

    this.addPostingExcelItemDefinition(
      this.label.invoice.invoiceTypeText,
      undefined
    );
    this.addPostingExcelItemDefinition(
      this.label.invoice.supplierNo,
      undefined
    );
    this.addPostingExcelItemDefinition(
      this.label.invoice.supplierName,
      undefined
    );
    this.addPostingExcelItemDefinition(this.label.invoice.buyer, undefined);
    this.addPostingExcelItemDefinition(this.label.invoice.invoiceNo, undefined);
    this.addPostingExcelItemDefinition(this.label.invoice.date, undefined);
    this.addPostingExcelItemDefinition(this.label.invoice.ean, undefined);
    this.addPostingExcelItemDefinition(
      this.label.invoice.amountExVat,
      undefined
    );
    this.addPostingExcelItemDefinition(
      this.label.invoice.standardRatedAmount,
      undefined
    );
    this.addPostingExcelItemDefinition(
      this.label.invoice.invoiceVatAmount,
      undefined
    );
    this.addPostingExcelItemDefinition(
      this.label.invoice.amountTotal,
      undefined
    );
  }

  private isColumnNotExclude(columnName: string): boolean {
    switch (columnName) {
      case 'hasNote':
      case 'select':
      case 'invoice.invoiceNo':
        return false;

      default:
        return true;
    }
  }
}
