<div class="row">
  <div class="col-6">
    <div class="news">
      <div class="news__header">
        Nyhedsoverblik
      </div>
      <div class="news__all-admin-news">
        <app-messages [place]="'allmessageDialog'" (edit)="editMessage($event)"></app-messages>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="news">
      <form [formGroup]="newsFormControl">
        <div class="news__header">
          Opret nyhed
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Emne</mat-label>
          <input matInput formControlName="subject">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Besked</mat-label>
          <textarea matInput formControlName="text"></textarea>
        </mat-form-field>
        <div class="news__buttons">
          <div class="news__buttons--left">
            <!-- <button mat-flat-button color="warn" (click)="delete()">Slet</button> -->
          </div>
          <div class="news__buttons--right">
            <mat-checkbox [(ngModel)]="global" [ngModelOptions]="{standalone: true}">Global nyhed</mat-checkbox>
            <button mat-flat-button color="primary" (click)="sendClick()"
              [disabled]="(getSubjectControl().invalid || getTextControl().invalid)">Gem</button>
            <button mat-stroked-button (click)="cancel()">Annuller</button>
          </div>
        </div>
        <div class="news__from-radio">
          <mat-radio-group [(ngModel)]="publishedBy" [ngModelOptions]="{standalone: true}" aria-label="Vælg afsender">
            <mat-radio-button name="value1" [value]="1">Dit brugernavn</mat-radio-button>
            <mat-radio-button name="value2" [value]="2">{{BDO}}</mat-radio-button>
            <mat-radio-button name="value3" [value]="3">{{KMD}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
    </div>
  </div>

</div>
