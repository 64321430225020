import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostingStatusChangeService {
  private notificationOnPostingStatusChanged: Subject<void> =
    new Subject<void>();
  public notificationOnPostingStatusChanged$: Observable<unknown> =
    this.notificationOnPostingStatusChanged.asObservable();
  constructor() {}

  notifyStatusOnPostings() {
    this.notificationOnPostingStatusChanged.next();
  }
}
