import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@modules/shell/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(private snackBar: MatSnackBar) { }

  openSnackBar() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 60000,
      panelClass: ['snackbar', 'yellow'],
      data: {
        message: 'Der ingen tilknyttet kundegruppe til denne bruger',
        action: 'Ok',
      },
    });
  }

  notifySnackBarInformation(message: string, color: 'green' | 'yellow' | 'red') {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 10000,
      panelClass: ['snackbar', color],
      data: {
        message,
        action: 'Ok',
      },
    });
  }
}
