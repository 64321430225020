<div class="related__wrapper last">
    <div class="overlay__header">Posteringer til faktura el. bilag</div>
    <div class="related__container" (scroll)="onScroll($event)">
        <ng-template [ngIf]="relatedPostings && relatedPostings.length > 0" [ngIfElse]="emptyState">
            <div *ngFor="let posting of relatedPostings" class="related"
                (mouseover)="popupVisible=true; hoveredPosting = posting;"
                (mouseout)="popupVisible=false; hoveredPosting = undefined;" (click)="addPostingToTab(posting)">
                <span>
                    <span *ngIf="posting.supplierName === null">Ingen leverandør</span>
                    <span *ngIf="posting.supplierName !== null">{{posting.supplierName}}</span>
                </span>
                <span>{{posting.amountPosted | vatCurrency}}</span>
            </div>
        </ng-template>
        <ng-template #emptyState>
            <div>
                Ingen relaterede posteringer
            </div>
        </ng-template>
    </div>
    <div *ngIf="hoveredPosting" class="related__popup" [ngClass]="popupVisible ? 'is-visible' : ''">
      <div *ngFor="let label of  labelRows">

        <ng-container [ngSwitch]="label.type" >
          <div class="related__popup--item">
            <span>{{label.text}}:</span>

          <ng-container *ngSwitchCase="'currency'">
               <span>{{hoveredPosting[label.key] | vatCurrency}}</span>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div *ngIf="label.key === 'supplierName' else notSupplier">
                  <span>{{hoveredPosting.supplierName ?? "Ingen leverandør"}}</span>
              </div>

                <ng-template #notSupplier>
                    <span>{{hoveredPosting[label.key]}}</span>
                  </ng-template>
                </ng-container>
             </div>
          </ng-container >
      </div>
    </div>
</div>
