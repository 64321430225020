import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: BehaviorSubject<UserDto> = new BehaviorSubject<UserDto>(null);
  user$: Observable<UserDto> = this.user.asObservable();

  constructor(private httpClient: HttpClient) {}

  loadUser() {
    this.httpClient
      .get<UserDto>(environment.VatBackendUrl + '/api/userinfo')
      .subscribe((result) => {
        if (result) {
          this.user.next(result);
        }
      });
  }
}

export interface UserDto {
  username: string;
}
