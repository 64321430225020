<div class="latest">
    <div class="row">
        <div class="col-12">
            <div class="latest__header">Senest anvendte</div>
        </div>
        <ng-template [ngIf]="latestAnalysis.length > 0" [ngIfElse]="emptyState">
            <div *ngFor="let analysis of latestAnalysis" class="col-12 col-md-3">
                <app-analyze-card [analyze]="analysis"></app-analyze-card>
            </div>
        </ng-template>
        <ng-template #emptyState>
            <div class="col-3">
                <div class="no-latest">
                    <div class="no-latest__header">Du har ingen senest anvendte analyser</div>
                    <p>Gå til <a [routerLink]="'/analyser'">analyse overblikket</a> for at se dine tilgængelige analyser
                    </p>
                </div>
            </div>
        </ng-template>
    </div>
</div>
