import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

// Custom modules
import { CommonsModule } from '@modules/commons/commons.module';
import { AnalyzesModule } from '@modules/analyzes/analyzes.module';

// Components
import { HomeComponent } from './components/home/home.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { LatestComponent } from './components/latest/latest.component';
import { TopInfoComponent } from './components/top-info/top-info.component';

@NgModule({
  declarations: [
    HomeComponent,
    FavoritesComponent,
    LatestComponent,
    TopInfoComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    FontAwesomeModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    CommonsModule,
    AnalyzesModule,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
