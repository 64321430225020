import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

// Material
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

// Components
import { LoginComponent } from './components/login/login.component';

export const configureAuth = () => {
  return {
    authority: environment.openIdConnectConfig.stsServer,
    redirectUrl: environment.openIdConnectConfig.redirectUrl,
    postLogoutRedirectUri:
      environment.openIdConnectConfig.postLogoutRedirectUri,
    clientId: environment.openIdConnectConfig.clientId,
    scope: environment.openIdConnectConfig.scope,
    responseType: environment.openIdConnectConfig.responseType,
    triggerAuthorizationResultEvent:
      environment.openIdConnectConfig.triggerAuthorizationResultEvent,
    autoUserInfo: environment.openIdConnectConfig.autoUserinfo,
    silentRenew: environment.openIdConnectConfig.silentRenew,
    silentRenewUrl: environment.openIdConnectConfig.silentRenewUrl,
    renewTimeBeforeTokenExpiresInSeconds:
      environment.openIdConnectConfig.renewTimeBeforeTokenExpiresInSeconds,
    customParamsAuthRequest: {
      domain_hint: '',
    },

    logLevel: environment.production ? LogLevel.None : LogLevel.Warn,
  };
};

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    FontAwesomeModule,
    MatCheckboxModule,
    FormsModule,
    AuthModule.forRoot({
      config: configureAuth(),
    }),
  ],
  exports: [LoginComponent],
})
export class LoginModule {}
