import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { User } from 'src/app/api/vat';

import { InternalUserService } from '../services/internal-user.service';

@Directive({
  selector: '[appHasRole]'
})
export class AppHasRoleDirective implements OnInit {
  @Input() appHasRole: string[];
  @Input() force: boolean;
  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private internalUserService: InternalUserService
  ) {
  }

  ngOnInit() {
    if (this.force) {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
    else {
      this.internalUserService.user$.subscribe((user: User) => {

        const currentUserRoles: string[] = user?.roles;
        if (!currentUserRoles) {
          this.viewContainerRef.clear();
        }

        if (currentUserRoles?.some(r => this.appHasRole.includes(r))) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
    }
  }
}
