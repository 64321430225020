import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { CommonHelper } from '@modules/commons/helpers/common-helpers';
import {
  CompletedAnalyzesComponent,
  PeriodicElement,
} from '@modules/dialog/components/completed-analyzes/completed-analyzes.component';
import { SnackbarComponent } from '@modules/shell/components/snackbar/snackbar.component';
import { NotifyService } from '@modules/shell/services/notify.service';
import { AnalysisService, AnalysisStatistics, PostingService } from 'src/app/api/vat';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-top-info',
  templateUrl: './top-info.component.html',
  styleUrls: ['./top-info.component.scss'],
})
export class TopInfoComponent implements OnInit, OnDestroy {
  faExternalLinkAlt = faExternalLinkAlt;

  yearPickerOptions: number[] = [];
  statistics: AnalysisStatistics = {
    financialYear: null,
    realisedPotential: null,
    remainingPotential: null,
    totalPotential: null,
    latestPostingDate: null,
    reviewStatus: null,
    processedAnalysis: null,
    originalPotential: null,
    lastDataRead: null,
  };
  subscriptions: Subscription[] = [];
  dialogData: PeriodicElement[];
  financialYear: number;

  constructor(
    public dialog: MatDialog,
    private analysisService: AnalysisService,
    private postingService: PostingService,
    private notifyService: NotifyService
  ) {
    this.generateArrayOfYears()
      .then((years) => {
        this.yearPickerOptions = years;
        this.postingService.getLatestPostingFinancialYear()
          .subscribe((year: string) => {

            if (!this.yearPickerOptions.includes(parseInt(year, 0)))
              this.yearPickerOptions.unshift(parseInt(year, 0));

            if (year === null) {
              this.notifyService.notifySnackBarInformation('Der eksisterer ingen data, for den valgte kunde.', 'yellow');
            } else {
              this.financialYear = parseInt(year, 0);
              this.loadData(parseInt(year, 0));
            }
          });
      });
  }

  ngOnInit() { }

  openCompletedAnalyzesDialog(): void {
    this.dialog.open(CompletedAnalyzesComponent, {
      width: '1100px',
      data: this.dialogData,
    });
  }

  loadData(financialYear: number): void {
    this.subscriptions.push(
      this.analysisService
        .getStatistics(financialYear)
        .subscribe((res: AnalysisStatistics) => {
          this.statistics = res;

          this.dialogData = [];

          this.statistics.processedAnalysis?.forEach((item) => {
            this.dialogData.push({
              analysisId: item.analysis.analysisId,
              title: item.analysis.title,
              processedState: item.processedState,
              realisedPotential: item.analysis.realisedPotential,
              remainingPotential: item.analysis.remainingPotential,
              date: item.processedDate,
              year: item.analysis.financialYears[0],
            });
          });
          this.dialogData.push({
            analysisId: 0,
            title: 'Total',
            processedState: res.reviewStatus,
            realisedPotential: res.realisedPotential,
            remainingPotential: res.remainingPotential,
            date: res.latestPostingDate,
            year: res.financialYear,
          });
        })
    );
  }

  private generateArrayOfYears(): Promise<number[]> {
    return new Promise<number[]>((resolve, rejects) => {
      const years: number[] = CommonHelper.generateYears();
      resolve(years);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
