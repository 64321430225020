import { Component, Inject, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html',
  styleUrls: ['./create-rule.component.scss'],
})
export class CreateRuleComponent implements OnInit {
  // Icons
  faTimes = faTimes;

  currentDialog: string;

  constructor(
    public dialogRef: MatDialogRef<CreateRuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.currentDialog = this.data.dataKey;
  }

  close() {
    this.dialogRef.close();
  }
}
