import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import { PostingService } from 'src/app/api/vat';
import { Subscription } from 'rxjs';
import { Column } from 'src/app/global-models';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-posting-column-list',
  templateUrl: './posting-column-list.component.html',
  styleUrls: ['./posting-column-list.component.scss'],
})
export class PostingColumnListComponent implements OnInit {
  faTimes = faTimes;

  subscriptions: Subscription[] = [];
  allColumnDefinitions: Column[];
  switchAbleColumnDefinitions: Column[];
  private routePath: string;

  constructor(
    private labelGlossaryService: LabelGlossaryService,
    private postingService: PostingService,
    public dialogRef: MatDialogRef<PostingColumnListComponent>,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public analysisId: number
  ) {
    dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.labelGlossaryService.postingTableColumnsDefinition$.subscribe(
        (result: Column[]) => {
          this.allColumnDefinitions = result;
          this.switchAbleColumnDefinitions = this.allColumnDefinitions.filter(
            (c) =>
              c.switchAble === true ||
              c.key === 'correctedPotential' ||
              c.key === 'result'
          );
        }
      )
    );
    this.routePath = this.route.snapshot['_routerState'].url;
  }

  isDisabled(column: Column): boolean {
    if (column.key === 'correctedPotential' || column.key === 'result')
      return true;

    return false;
  }

  dragAndDrop(event: CdkDragDrop<any[]>) {
    const indexCount = this.switchAbleColumnDefinitions.length - 1;

    // Control that the user cant drop under the 2 lowest options in the list.
    if (
      event.currentIndex !== indexCount &&
      event.currentIndex !== indexCount - 1
    ) {
      moveItemInArray(
        this.switchAbleColumnDefinitions,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  isNotOnPostingNote(): boolean {
    return !this.routePath.includes('posteringer/postingnote/');
  }

  save(temperary: boolean): void {
    this.close();
    this.dialogRef.afterClosed().subscribe(() => {
      const firstLockedColumns = this.allColumnDefinitions.slice(0, 2);
      const lastLockedColumns = this.allColumnDefinitions.slice(-4);
      this.switchAbleColumnDefinitions.unshift(...firstLockedColumns);
      this.switchAbleColumnDefinitions.splice(
        this.switchAbleColumnDefinitions.length - 2,
        0,
        lastLockedColumns[0]
      );
      this.switchAbleColumnDefinitions.push(lastLockedColumns[3]);

      this.allColumnDefinitions = this.switchAbleColumnDefinitions;
      if (temperary === true) {
        this.labelGlossaryService.updatePostingColumns(
          this.allColumnDefinitions
        );
      } else {
        const columnDefinitions =
          this.labelGlossaryService.mapColumnDefinitionsToColumns(
            this.allColumnDefinitions
          );
        this.subscriptions.push(
          this.postingService
            .addOrUpdateUserColumnDefinitions(
              this.analysisId,
              columnDefinitions
            )
            .subscribe(() => {
              this.labelGlossaryService.updatePostingColumns(
                this.allColumnDefinitions
              );
            })
        );
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  cancel() {
    this.close();
  }

  reset() {
    this.labelGlossaryService.resetPostingColumnsToDefault().then(() => {
      const columnDefinitions =
        this.labelGlossaryService.mapColumnDefinitionsToColumns(
          this.allColumnDefinitions
        );
      this.subscriptions.push(
        this.postingService
          .addOrUpdateUserColumnDefinitions(
            this.analysisId,
            columnDefinitions
          )
          .subscribe(() => {
            this.labelGlossaryService.updatePostingColumns(
              this.allColumnDefinitions
            );
          })
      );
      this.close();
    });
  }
}
