<div class="favorites">
    <div class="row">
        <div class="col-12">
            <div class="favorites__header">Favoritter</div>
        </div>
        <ng-template [ngIf]="favoriteAnalysis.length !== 0" [ngIfElse]="emptyState">
            <div *ngFor="let analysis of favoriteAnalysis" class="col-12 col-md-3">
                <app-analyze-card [analyze]="analysis"></app-analyze-card>
            </div>
        </ng-template>
        <ng-template #emptyState>
            <div class="col-3">
                <div class="no-favorites">
                    <div class="no-favorites__header">Du har ingen favoritter</div>
                    <p>Klik på stjernen i hjørnet af en analyse for at tilføje den til dine favoritter.</p>
                </div>
            </div>
        </ng-template>
    </div>
</div>
