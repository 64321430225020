<div class="dialog">
  <div class="dialog__header">
    Status på analyser
  </div>
  <div class="dialog__close" (click)="close()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </div>
  <div class="dialog__content">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Titel </th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
      </ng-container>

      <ng-container matColumnDef="processedState">
        <th mat-header-cell *matHeaderCellDef> Process Status</th>
        <td mat-cell *matCellDef="let element"> {{element.processedState}} %</td>
        <td mat-footer-cell *matFooterCellDef> {{totalRow.processedState}} % </td>
      </ng-container>

      <ng-container matColumnDef="remainingPotential">
        <th mat-header-cell *matHeaderCellDef> Rest Potentiale </th>
        <td mat-cell *matCellDef="let element"> {{element.remainingPotential | vatCurrency}} </td>
        <td mat-footer-cell *matFooterCellDef> {{totalRow.remainingPotential | vatCurrency}} </td>
      </ng-container>

      <ng-container matColumnDef="realisedPotential">
        <th mat-header-cell *matHeaderCellDef> Realiseret resultat </th>
        <td mat-cell *matCellDef="let element"> {{element.realisedPotential | vatCurrency}} </td>
        <td mat-footer-cell *matFooterCellDef> {{totalRow.realisedPotential | vatCurrency}} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Dato </th>
        <td mat-cell *matCellDef="let element"> {{element.date | date:'dd-MM-YYYY'}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef> Regnskabsår </th>
        <td mat-cell *matCellDef="let element"> {{element.year}} </td>
        <td mat-footer-cell *matFooterCellDef> {{totalRow.year}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToAnalysis(row)"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>
  </div>
  <div class="dialog__buttons">
    <button mat-stroked-button color="primary" (click)="close()">
      Luk
    </button>
  </div>
</div>
