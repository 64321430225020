import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import {
  ResponseTimeInMs,
  CorrelationId,
} from '../constants/http-header.constants';
import { Router } from '@angular/router';
import { ROUTENAMES } from '../app-routing.names';
import { InformationDialogService } from '@modules/shell/services/information-dialog.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private informationService: InformationDialogService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let ok: any;
    return next.handle(request).pipe(
      tap(
        (event) => (ok = event instanceof HttpResponse ? 1 : 0),
        (error: HttpErrorResponse) => {
          switch (error.status) {
            case 401:
              this.router.navigate([ROUTENAMES.UNAUTHORIZED]);
              break;
            case 403:
              this.router.navigate([ROUTENAMES.FORBIDDEN]);
              break;
            default:
              // this.informationService.showErrorMessage({
              //   httpStatusCode: error.status,
              //   url: error.url,
              //   correlationId: error.headers.get(CorrelationId),
              //   responseTimeInMS: error.headers.get(ResponseTimeInMs),
              // });
              break;
          }
        }
      )
    );
  }
}
