<div [ngClass]="sidebarOpen === true ? 'sidebar' : 'sidebar closed'">
    <div class="sidebar__kmd-logo">
        <img src="../../../../../assets/images/kmd-logo.svg" />
    </div>
    <div class="sidebar__logout">
        <button mat-flat-button color="warn" (click)="logout()" [routerLink]="'/login'" >
            Log ud <fa-icon [icon]="faSignOutAlt"></fa-icon>
        </button>
    </div>
    <div class="top-info">
        <div class="row">
            <div class="col-12">
                <div class="profile">
                    <div class="profile__info">
                        <ul>
                            <li>{{currentUser?.userName}}</li>
                            <li>
                                <!-- <a href="#" class="change-password">Change password</a> · -->
                                <a target="_blank" (click)="goToLaw()">Lovgivning</a> ·
                                <a target="_blank" (click)="gotoManuals()">Manualer</a>
                                <span>
                                    <a (click)="openAllNewsDialog()">Alle nyheder</a> ·
                                    <a (click)="openAllNotesDialog()">Alle noter</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="sidebar__close">
                <button mat-icon-button color="primary" (click)="sidebarToggle()">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                </button>
            </div>

        </div>
    </div>
    <app-messages [place]="'sidebar'" ></app-messages>
</div>

<div class="sidebar__overlay" [ngClass]="sidebarOpen === false ? '' : 'visible'" (click)="sidebarToggle()">
</div>
