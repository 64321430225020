import { AnalysisStatus, UserInformation } from 'src/app/api/vat';

export interface TableDesign {
  propertyName: string;
  title: string;
  columnType?: string;
}

export interface TableInformation {
  tableDesigns: TableDesign[];
  data: any[];
}

export class LeaderDashBoardHelper {
  constructor() {
  }

  private static userInformationTableColumns: TableDesign[] = [
    { propertyName: 'userName', title: 'Navn' },
    { propertyName: 'lastLoginDate', title: 'Sidst anvendt', columnType: 'date' },
    {
      propertyName: 'postingsChangedByUser',
      title: 'Behandlet posteringer',
      columnType: 'number'
    },
    {
      propertyName: 'postingsOngoingByUser',
      title: 'Igangværende posteringer',
      columnType: 'number'
    },
    {
      propertyName: 'postingsRealisedByUser',
      title: 'Realiseret potentiale',
      columnType: 'currency'
    }
  ];

  private static potentialTableColumns: TableDesign[] = [
    { propertyName: 'analysisTitle', title: 'Navn' },
    { propertyName: 'financialYear', title: 'År' },
    {
      propertyName: 'realisedPotential',
      title: 'Realiseret',
      columnType: 'currency'
    },
    {
      propertyName: 'remainingPotential',
      title: 'Resterende',
      columnType: 'currency'
    },
    { propertyName: 'processedStatus', title: 'Status', columnType: 'procent' },
    { propertyName: 'lastProcessedPostingDate', title: 'Sidst anvendt', columnType: 'date' },
  ];

  public static mapToTableInformation(type: string, data: UserInformation[] | AnalysisStatus[]): TableInformation {
    switch (type.toLocaleLowerCase()) {
      case 'userinformation':
        return {
          tableDesigns: this.userInformationTableColumns,
          data
        };
      case 'analysisstatus':
        return {
          tableDesigns: this.potentialTableColumns,
          data
        };
      default:
        return;
    }

  }
}
