<div class="card" [ngClass]="cardExpanded ? 'card__expanded' : ''">
  <div class="card__top-bar">
    <div class="card__top-bar--left">
      <span *ngIf="yearSelector">
        <app-year-selector (selectedYearChange)="yearSelectChange($event)"></app-year-selector>
      </span>
      <button mat-button class="fixer">x</button>
    </div>
    <div class="card__top-bar--right">
      <button mat-icon-button class="expand-card" (click)="expandCard()">
        <fa-icon class="expand-icon" [icon]="faExpand" matTooltip="Udvid dette kort"></fa-icon>
        <fa-icon class="close-icon" [icon]="faCompressAlt" matTooltip="Minimer dette kort"></fa-icon>
      </button>
    </div>
  </div>
  <div class="card__table">
    <table mat-table matSort #sort="matSort" [dataSource]="dataSource" matSortActive="remainingPotential" matSortDirection="desc">

      <ng-container *ngFor="let tableDesign of tableDesigns">

        <ng-container [ngSwitch]="tableDesign.columnType">

          <ng-container *ngSwitchCase="'number'">
            <ng-container matColumnDef="{{tableDesign.propertyName}}">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableDesign.title}} </th>
              <td mat-cell *matCellDef="let row"> {{row[tableDesign.propertyName] | number:'':'da-DK'}} </td>
            </ng-container>

          </ng-container>

          <ng-container *ngSwitchCase="'currency'">
            <ng-container matColumnDef="{{tableDesign.propertyName}}">
              <th class="align-right" mat-header-cell *matHeaderCellDef mat-sort-header> {{tableDesign.title}} </th>
              <td class="align-right" mat-cell *matCellDef="let row"> {{row[tableDesign.propertyName] | vatCurrency }} </td>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'date'">
            <ng-container matColumnDef="{{tableDesign.propertyName}}">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableDesign.title}} </th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="isDateNull(row[tableDesign.propertyName])">
                  {{row[tableDesign.propertyName] | date:'dd-MM-YYYY'}}
                </span>
              </td>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'procent'">
            <ng-container matColumnDef="{{tableDesign.propertyName}}">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableDesign.title}} </th>
              <td mat-cell *matCellDef="let row"> {{row[tableDesign.propertyName]}}% </td>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container matColumnDef="{{tableDesign.propertyName}}">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableDesign.title}} </th>
              <td mat-cell *matCellDef="let row"> {{row[tableDesign.propertyName]}} </td>
            </ng-container>

          </ng-container>

        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsNames; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsNames;"></tr>
    </table>

  </div>
  <div class="card__info">
    <p>{{footerinfo}}</p>
  </div>
</div>
