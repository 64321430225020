import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarComponent } from '@modules/shell/components/snackbar/snackbar.component';
import { DateTime } from 'luxon';

import {
  AuthConfig,
  LoginOptions,
  OAuthErrorEvent,
  OAuthService,
  OAuthSuccessEvent,
} from 'angular-oauth2-oidc';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { ROUTENAMES } from 'src/app/app-routing.names';
import { environment } from 'src/environments/environment';
//import { SystemInfoDto, SystemInfoService } from './system-info.service';
import { SystemInfoDto, SystemService } from 'src/app/services/system.service';

@Injectable({
  providedIn: 'root',
})
export class OpenIDCService {
  private systemInfo: SystemInfoDto;
  private isAuthenticated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();
  checkTokenExpirationSubscription: Subscription;
  readonly EXPIRATIONCHECKINTERVALINSECONDS: number = 30;

  fiveMinSnackbarShown: boolean;
  twoMinSnackbarShown: boolean;

  constructor(
    private systemService: SystemService,
    private oAuthService: OAuthService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  init() {
    this.setupTokenExpirationWarning();
    return new Observable((subscriber) => {
      this.systemService.systemInfo$.subscribe(
        (info) => {
          this.systemInfo = info;
          this.oAuthService.configure(this.getConfig(info));

          // extra debug information, if turned on in getConfig
          if (this.oAuthService.showDebugInformation) {
            this.oAuthService.events.subscribe((event) => {});
          }
          this.oAuthService
            .loadDiscoveryDocumentAndTryLogin()
            .then((loaded) => {
              subscriber.complete();
            })
            .catch((error) => console.error(error));
          this.oAuthService.setupAutomaticSilentRefresh();
          this.oAuthService.events.subscribe((event) => {
            this.setIsAuthenticated(this.oAuthService.hasValidAccessToken());
            if (event instanceof OAuthErrorEvent) {
              // to debug error message add a letter to issuer in getConfig
              var currentEvent = event as OAuthErrorEvent;

              switch (event.type) {
                case 'token_refresh_error':
                  this.checkTokenExpirationSubscription.unsubscribe();
                  this.router.navigate([ROUTENAMES.LOGIN]);
                  this.logout();
                  break;
              }
            }
          });
        },
        (error) => {
          subscriber.error();
        }
      );
    });
  }

  private setupTokenExpirationWarning() {
    this.fiveMinSnackbarShown = false;
    this.twoMinSnackbarShown = false;

    this.checkTokenExpirationSubscription = interval(
      this.EXPIRATIONCHECKINTERVALINSECONDS * 1000
    ).subscribe(() => {
      let timeToTokenExpirationInMinutes;
      if (this.oAuthService.hasValidAccessToken()) {
        timeToTokenExpirationInMinutes = -Math.floor(
          DateTime.now()
            .diff(
              DateTime.fromJSDate(
                new Date(this.oAuthService.getAccessTokenExpiration())
              )
            )
            .valueOf() /
            1000 / // in seconds
            60 // in minutes
        );
      } else {
        timeToTokenExpirationInMinutes = undefined;
      }
    });
  }

  login() {
    this.oAuthService.initCodeFlow();
  }

  logout() {
    this.checkTokenExpirationSubscription.unsubscribe();
    this.oAuthService.logOut({
      client_id: this.systemInfo.clientId,
    });
  }

  // only set it if it has changed
  private setIsAuthenticated(value: boolean) {
    if (this.isAuthenticated.getValue() !== value) {
      this.isAuthenticated.next(value);
    }
  }

  private getConfig(info: SystemInfoDto): AuthConfig {
    return {
      // Url of the Identity Provider
      issuer: 'https://identity.kmd.dk/adfs',
      skipIssuerCheck: true,
      timeoutFactor: 0.9,
      redirectUri: window.location.origin,
      clientId: info.clientId,
      customQueryParams: {
        domain_hint: this.checkOverwriteDomainHint(info),
      },
      oidc: true,
      responseType: 'code',
      scope: info.scope,
      showDebugInformation: false,
    };
  }

  private checkOverwriteDomainHint(info: SystemInfoDto) {
    var subdomain = window.location.host.split('.')[1]
      ? window.location.host.split('.')[0].toLowerCase()
      : '';

    var domain_hint = info?.domainHints[subdomain]
      ? info?.domainHints[subdomain]
      : info?.domainHints['default'];

    return domain_hint;
  }

  openErrorSnackBar(errorMessage: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: undefined,
      panelClass: ['snackbar', 'red'],
      data: {
        message: errorMessage,
        action: 'Ok',
      },
    });
  }

  openInfoSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: undefined,
      panelClass: ['snackbar', 'yellow'],
      data: {
        message: message,
        action: 'Ok',
      },
    });
  }
}
