import { Injectable } from '@angular/core';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import {
  FilterService,
  PrimaryFilter,
  Period,
  Customer,
} from 'src/app/api/vat';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterDataService {
  private primaryFilter$: BehaviorSubject<PrimaryFilter> =
    new BehaviorSubject<PrimaryFilter>({ periods: null });
  public primaryFilter: Observable<PrimaryFilter> =
    this.primaryFilter$.asObservable();

  constructor(
    private filterService: FilterService,
    private internalCurrentCustomerService: InternalCurrentCustomerService
  ) {
    this.internalCurrentCustomerService.customer.subscribe(
      (customer: Customer) => {
        this.primaryFilter$.next({ periods: null });
        this.updatePrimaryFilterDate();
      }
    );
  }

  public updatePrimaryFilterDate(periods: Period[] = []) {
    if (this.isPeriodChanged(periods)) {
      this.resetForLoading(this.primaryFilter$.getValue());
      this.filterService
        .getPrimaryFilter(periods)
        .subscribe((primaryFilter: PrimaryFilter) => {
          this.primaryFilter$.next(primaryFilter);
        });
    }
  }

  private resetForLoading(primaryFilter: PrimaryFilter) {
    primaryFilter.functions = [];
    primaryFilter.costTypes = [];
    primaryFilter.admorgs = [];
    primaryFilter.analyzes = [];
  }

  private isPeriodChanged = (periods: Period[]) =>
    this.primaryFilter$.getValue().periods?.sort() !== periods?.sort();
}
