import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DatepickerCustomHeaderComponent } from '../datepicker-custom-header/datepicker-custom-header.component';
import { Moment } from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_FORMATS = {
  display: {
    dateInput: 'DD MMM',
  },
};

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class MonthPickerComponent implements OnInit, OnChanges {
  @Input() selectedDate: Moment;
  @Input() label: Date;
  @Input() min: Date;
  @Input() max: Date;
  @Output() selection = new EventEmitter<Moment>();
  customHeader = DatepickerCustomHeaderComponent;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedDate' in changes) {
      // For getting all dates in Year, we use 2020, because it has leap year.
      const staticYear: number = 2020;
      this.selectedDate = this.selectedDate.year(staticYear);
    }
  }
  chosenMonthHandler(normalizedDate: Moment): void {
    this.selection.emit(normalizedDate);
  }
}
