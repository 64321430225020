
<div class="dialog-wrapper">
  <fa-icon class="fa-3x" [icon]="faQuestionCircle"></fa-icon>
  <h1 mat-dialog-title>{{title}}</h1>

  <div mat-dialog-content>
         {{bodyInformation}}
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close color="primary" (click)="buttonEvent(true)">Ja</button>
      <button mat-flat-button mat-dialog-close color="warn" (click)="buttonEvent(false)" >Nej</button>
    </div>
</div>
