import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '@modules/admin/components/admin/admin.component';
import { AnalyzesComponent } from '@modules/analyzes/components/analyzes/analyzes.component';
import { BdoDashboardComponent } from '@modules/bdo-dashboard/components/bdo-dashboard/bdo-dashboard.component';
import { RolesConstants } from '@modules/commons/classes/roles-constants';
import { HomeComponent } from '@modules/home/components/home/home.component';
import { LeaderDashboardComponent } from '@modules/leader-dashboard/components/leader-dashboard/leader-dashboard.component';
import { LoginComponent } from '@modules/login/components/login/login.component';
import { AuthorizationGuard } from '@modules/login/guards/authorization.guard';
import { PostingsComponent } from '@modules/postings/components/postings/postings.component';
import { LawsComponent } from '@modules/shell/components/laws/laws.component';
import { ManualsComponent } from '@modules/shell/components/manuals/manuals.component';
import { NotFoundComponent } from '@modules/shell/components/not-found/not-found.component';
import { UnauthorizedSiteComponent } from '@modules/shell/components/unauthorized-site/unauthorized-site.component';



import { ForbiddenComponent } from '@modules/auth/components/forbidden/forbidden.component';
import { UnauthorizedComponent } from '@modules/auth/components/unauthorized/unauthorized.component';

import { ROUTENAMES } from './app-routing.names';

const routes: Routes = [
  { 
    path: '',
    redirectTo: '/dashboard', 
    pathMatch: 'full' 
  },
  { 
    path: 'login', 
    component: LoginComponent
  },
  {
    path: 'dashboard',
    component: HomeComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'analyser',
    component: AnalyzesComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'posteringer/:analysisId',
    component: PostingsComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'posteringer/postingnote/:postingNoteId',
    component: PostingsComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'leader-dashboard',
    component: LeaderDashboardComponent,
    canActivate: [AuthorizationGuard],
    data: {
      roles: [RolesConstants.VAT_Manager, RolesConstants.VAT_BDOAdministrator],
    },
  },
  {
    path: 'bdo-dashboard',
    component: BdoDashboardComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'lovgivning',
    component: LawsComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'manualer',
    component: ManualsComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthorizationGuard],
    data: {
      roles: [RolesConstants.VAT_BDOAdministrator],
    },
  },
    { 
    path: 'unauthorized', 
    component: UnauthorizedSiteComponent
  },
  {
    path: '**', 
    component: NotFoundComponent 
  },
  
  

  {
    path: ROUTENAMES.FORBIDDEN,
    component: ForbiddenComponent,
  },
  {
    path: ROUTENAMES.UNAUTHORIZED,
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
