import { Component, Input, OnInit } from '@angular/core';
import { Posting } from 'src/app/api/vat';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'src/app/global-models';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: [ './information.component.scss' ],
})
export class InformationComponent implements OnInit {
  @Input() postingInformation: Posting;
  rowDefinitions: Row[] = [];
  faCheck = faCheck;
  constructor(private labelGlossaryService: LabelGlossaryService) { }

  ngOnInit(): void {
    this.labelGlossaryService.overlayDefinition$.subscribe((rows: Row[]) => {
      this.rowDefinitions = rows;
    });
  }

  getViewValue(row: Row): string {
    return row?.overlayText !== undefined ? row.overlayText : row.text;
  }


  getDataValue(row: Row): string {
    const keys = row.key?.split('.');
    if (keys.length === 1)
      return this.postingInformation[ row.key ];

    if (this.postingInformation[ keys[ 0 ] ] === null)
      return '';

    return this.postingInformation[ keys[ 0 ] ][ keys[ 1 ] ];
  }
}
