import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

// Custom Modules
import { CommonsModule } from '../commons/commons.module';

// Components
import { MessagesComponent } from './components/messages/messages.component';

@NgModule({
  declarations: [MessagesComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    RouterModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatTooltipModule,
    CommonsModule
  ],
  exports: [MessagesComponent],
})
export class MessagesModule {}
