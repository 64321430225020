import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-are-you-sure-dialog',
  templateUrl: './are-you-sure-dialog.component.html',
  styleUrls: ['./are-you-sure-dialog.component.scss']
})
export class AreYouSureDialogComponent implements OnInit {

  faQuestionCircle = faQuestionCircle;
  private answer: boolean = false;
  title: string;
  bodyInformation: string = '';

  constructor(
    public matDialogRef: MatDialogRef<AreYouSureDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      bodyInformation: string
    }) {
    this.initDialog();
  }

  initDialog(): void {
    this.title = this.data.title;
    this.bodyInformation = this.data.bodyInformation;
    this.matDialogRef.disableClose = true;
    this.matDialogRef.beforeClosed().subscribe(result => {
      this.matDialogRef.close(this.answer);
    });
  }

  ngOnInit(): void {

  }

  buttonEvent(answer: boolean) {
    this.answer = answer;
  }

}
