import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  DateAdapter,
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

// Custom modules
import { FilterModule } from '@modules/filter/filter.module';
import { FavoritesModule } from '@modules/favorites/favorites.module';
import { MessagesModule } from '@modules/messages/messages.module';
import { CommonsModule } from '@modules/commons/commons.module';
import { OverlayModule } from '@modules/overlay/overlay.module';

// Components
import { PostingsComponent } from './components//postings/postings.component';
import {
  CustomDateFormat,
  TableComponent,
} from './components/table/table.component';
import { ColumnFilterTextBoxComponent } from './components/column-filter-text-box/column-filter-text-box.component';
import { ColumnFilterMultiTextBoxComponent } from './components/column-filter-multi-text-box/column-filter-multi-text-box.component';

@NgModule({
  declarations: [
    PostingsComponent,
    TableComponent,
    ColumnFilterTextBoxComponent,
    ColumnFilterMultiTextBoxComponent,
    CustomDateFormat,
  ],
  imports: [
    CommonModule,
    FilterModule,
    FavoritesModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatListModule,
    MatTabsModule,
    FontAwesomeModule,
    MessagesModule,
    CommonsModule,
    MatCheckboxModule,
    BrowserModule,
    MatSortModule,
    MatRadioModule,
    MatSelectModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    OverlayModule,
    MatTooltipModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatProgressSpinnerModule,
  ],
  exports: [PostingsComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class PostingsModule {}
