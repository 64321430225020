import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import {
  OverlayRelatedFilter,
  OverlayService,
} from '@modules/overlay/services/overlay.service';
import { PagingModel, Posting, PostingService } from 'src/app/api/vat';
import { Subscription } from 'rxjs';
import { Row } from 'src/app/global-models';

@Component({
  selector: 'app-related-postings',
  templateUrl: './related-postings.component.html',
  styleUrls: ['../overlay/overlay.component.scss'],
})
export class RelatedPostingsComponent implements OnInit, OnDestroy {
  @Input() postingId: number;
  pageModel: PagingModel;
  relatedPostings: Posting[] = [];
  subscriptions: Subscription[] = [];
  popupVisible: boolean = false;
  hoveredPosting: Posting = undefined;
  loadedAll: boolean = false;
  skip: number = 0;
  take: number = 30;
  scrollLoad = false;
  labelRows: Row[] = [];

  constructor(
    private postingsService: PostingService,
    private overlayService: OverlayService,
    private labelGlossaryService: LabelGlossaryService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.labelGlossaryService.releatedPostingDefinitions$.subscribe(
        (labels) => {
          this.labelRows = labels;
          this.pageModel = this.resetPageModel();
          this.loadRelatedPostings();
          this.subscriptions.push(
            this.overlayService.filterRelated$.subscribe(
              (filter: OverlayRelatedFilter) => {
                if (filter.postingId === this.postingId) {
                  this.pageModel = filter.pageModel;
                  this.relatedPostings = [];
                  this.loadRelatedPostings();
                }
              }
            )
          );
        }
      )
    );
  }

  resetPageModel(): PagingModel {
    return {
      skip: this.skip,
      take: this.take,
      filter: '',
      sortBy: this.pageModel?.sortBy ?? {
        ascending: true,
        propertyName: 'sort-3',
      },
    };
  }

  loadRelatedPostings(): void {
    this.subscriptions.push(
      this.postingsService
        .getInvoiceRelatedPostings(this.postingId, this.pageModel)
        .subscribe((postings: Posting[]) => {
          if (postings.length) {
            this.relatedPostings.push(...postings);
            this.scrollLoad = false;
            if (postings.length >= this.take) this.loadedAll = false;
            else {
              this.loadedAll = true;
            }
          } else {
            this.loadedAll = true;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  addPostingToTab(posting: Posting): void {
    this.overlayService.addPostingToTab(posting);
  }

  onScroll(event: Event): void {
    if (!this.scrollLoad && !this.loadedAll) {
      const tracker: any = event.target;
      if (
        tracker.scrollHeight - tracker.scrollTop <=
          tracker.clientHeight + 100 ||
        (tracker.clientHeight > 128 &&
          tracker.scrollHeight - tracker.scrollTop <=
            tracker.clientHeight + 200)
      ) {
        this.pageModel.skip += this.pageModel.take;
        this.loadRelatedPostings();
        this.scrollLoad = true;
      }
    }
  }
}
