import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '@modules/shell/services/header.service';
import {
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayService } from '@modules/overlay/services/overlay.service';
import { FilterBarService } from '@modules/filter/services/filter-bar.service';
import { Posting } from 'src/app/api/vat';
import { ActivatedRoute } from '@angular/router';
import { LoadingSpinnerService } from '@modules/shell/services/loading-spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-postings',
  templateUrl: './postings.component.html',
  styleUrls: ['./postings.component.scss'],
})
export class PostingsComponent implements OnInit, OnDestroy {
  // Icons
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  selectedPosting: Posting;
  postingStatus;

  subscriptions: Subscription[] = [];

  isNotRoutePostingNotePostings: boolean = true;

  filterBarState: boolean = false;
  overlayState: number;
  isLoading: boolean = false;
  constructor(
    private overlayService: OverlayService,
    private headerService: HeaderService,
    private filterBarService: FilterBarService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: LoadingSpinnerService
  ) {}

  ngOnDestroy(): void {
    this.headerService.updateTitleHeader('');
    this.subscriptions.map((s) => s.unsubscribe);
  }

  ngOnInit() {
    this.isNotRoutePostingNotePostings =
      this.route.snapshot.routeConfig.path !==
      'posteringer/postingnote/:postingNoteId';

    this.subscriptions.push(
      this.overlayService.overlayState$.subscribe((overlayState: number) => {
        this.overlayState = overlayState;
      })
    );
    this.subscriptions.push(
      this.filterBarService.filterBarState.subscribe((value: boolean) => {
        this.filterBarState = value;
      })
    );
    this.subscriptions.push(
      this.spinnerService.SmallSpinner$.subscribe((o) => (this.isLoading = !o))
    );

    this.changeDetectorRef.detectChanges();
  }
}
