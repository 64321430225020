import { Component, OnDestroy, OnInit } from '@angular/core';
import { Analysis, AnalysisService } from 'src/app/api/vat';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalStorageService } from '@modules/store/services/local-storage.service';
import storeKeys from '@modules/store/localStorageKeys.json';
import { LatestDetail } from 'src/app/global-models';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { LoadingTemplateFactoryService } from '@modules/commons/services/loading-template-factory.service';

@Component({
  selector: 'app-latest',
  templateUrl: './latest.component.html',
  styleUrls: ['./latest.component.scss'],
})
export class LatestComponent implements OnInit, OnDestroy {

  latestAnalysis: Analysis[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private analysisService: AnalysisService,
    private localStorageService: LocalStorageService,
    private internalCurrentCustomerService: InternalCurrentCustomerService,
    private loadingTemplateFactoryService: LoadingTemplateFactoryService
  ) { }

  ngOnInit() {
    this.loadLatest();
  }

  private loadLatest(): void {
    const latestAnalysis: LatestDetail[] = this.getLastestByCustomerId();
    if (latestAnalysis.length > 0) {
      const analysisIds = latestAnalysis.map((l) => l.analysisId);
      this.latestAnalysis = this.loadingTemplateFactoryService.createCardAnalyzesTemplate(analysisIds);
      this.subscriptions.push(
        this.analysisService
          .getByIds(analysisIds)
          .subscribe((result: Analysis[]) => {
            const ana: Analysis[] = [];
            const ids = result.map(p => p.analysisId);
            analysisIds.forEach(id => {
              const index = ids.indexOf(id);
              if (index > -1)
                ana.push(result[index]);
            });
            this.latestAnalysis = ana;
          })
      );
    }
  }

  private getLastestByCustomerId(): LatestDetail[] {
    const latestDetails =
      this.localStorageService.getObject<LatestDetail[]>(
        storeKeys.LatestAnalysisStorageKey
      ) ?? [];

    if (latestDetails.length > 0) {
      return latestDetails.filter((f) => f.customerId === this.internalCurrentCustomerService.getCustomerId());
    }
    return latestDetails;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
