export class CommonHelper {
  constructor() {
  }

  public static generateYears(): number[] {
    let numberOfFinancialYears: number = 6;
    if (this.isMonthJanuary())
      numberOfFinancialYears = 7;

    const years: number[] = [];
    const currentYear = new Date().getFullYear();
    for (let index = 0; index < numberOfFinancialYears; index++) {
      years.push(currentYear - index);
    }
    return years;
  }

  public static isMonthJanuary = () => new Date().getMonth() === 0;
}
