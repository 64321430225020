import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from './components/overlay/overlay.component';

// Materials
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FavoritesModule } from '@modules/favorites/favorites.module';

// costume Modules
import { MessagesModule } from '@modules/messages/messages.module';
import { CommonsModule } from '@modules/commons/commons.module';

// components
import { InformationComponent } from './components/information/information.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { RelatedPostingsComponent } from './components/related-postings/related-postings.component';
import { AccountNoRelatedPostingsComponent } from './components/account-no-related-postings/account-no-related-postings.component';

@NgModule({
  declarations: [OverlayComponent, InformationComponent, InvoiceComponent, RelatedPostingsComponent, AccountNoRelatedPostingsComponent],
  imports: [
    CommonModule,
    FavoritesModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatListModule,
    MatTabsModule,
    FontAwesomeModule,
    MatCheckboxModule,
    BrowserModule,
    MatSortModule,
    MatRadioModule,
    MatSelectModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MessagesModule,
    CommonsModule,
  ],
  exports: [OverlayComponent],
})
export class OverlayModule {}
