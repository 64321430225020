<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <app-top-info></app-top-info>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-latest></app-latest>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-favorites></app-favorites>
        </div>
    </div>
</div>