import { Component, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { SidebarService } from '@modules/shell/services/sidebar.service';
import { MenuItem } from './menu-item';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  sidebarOpen: boolean = true;
  menuItems: MenuItem[] = [];

  constructor(private sidebarService: SidebarService, private router: Router) {
    this.createMenu(this.menuItems, this.router.config);
  }

  ngOnInit(): void {
    this.sidebarService.sidebarOpen$.subscribe((sidebarState) => {
      this.sidebarOpen = sidebarState;
    });
  }

  // Create menu from routes, only add if route has data property
  private createMenu(MenuItems: MenuItem[], routes: Routes, path: string = '') {
    routes.forEach((route) => {
      if (route.data) {
        var menuItem = {
          title: route.data.title,
          icon: route.data.icon,
          description: route.data?.description,
          path: path + '/' + route.path,
        } as MenuItem;

        if (route.children?.length > 0) {
          menuItem.open = this.router.url.startsWith('/' + route.path);
          menuItem.children = [];
          this.createMenu(menuItem.children, route.children, menuItem.path);
        }

        MenuItems.push(menuItem);
      }
    });
  }
}
