import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faTimes, faTrash, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FilterChip } from '@modules/filter/models/filterChip';
import { FilterDefinition } from '@modules/filter/models/filterDefinition';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { FilterBarService, headerChip } from '@modules/filter/services/filter-bar.service';
import { AnalysisFilter, Period, PostingFilter } from 'src/app/api/vat';
import { filter } from 'rxjs/operators';
import { FilterDataService } from '@modules/filter/services/filter-data.service';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chosen-filters-bar',
  templateUrl: './chosen-filters-bar.component.html',
  styleUrls: ['./chosen-filters-bar.component.scss'],
})
export class ChosenFiltersBarComponent implements OnInit, OnDestroy {
  // icons
  faTimes = faTimes;
  faTrash = faTrash;
  faCalendar = faCalendar;

  removable = true;
  filterChips: FilterChip[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private internalFilterService: InternalFilterService,
    private activatedRoute: ActivatedRoute,
    private labelGlossaryService: LabelGlossaryService,
    private filterBarService: FilterBarService,
    private filterDataService: FilterDataService
  ) { }

  ngOnInit(): void {
    if (this.isPathAnalysisOverview()) {
      this.subscriptions.push(this.internalFilterService.analysisFilter
        .pipe(filter((a) => a.periods !== undefined && a.periods !== null))
        .subscribe((analysisFilter: AnalysisFilter) => {
          const filterDefinitions: FilterDefinition[] = this.labelGlossaryService.getAnalysisFilterDefinitions();
          this.addSelectedFiltersAsChips(filterDefinitions, analysisFilter);
        }));
    } else {
      this.subscriptions.push(this.internalFilterService.postingFilter
        .pipe(filter((a) => a.periods !== undefined && a.periods !== null))
        .subscribe((postingFilter: PostingFilter) => {
          const filterDefinitions: FilterDefinition[] = this.labelGlossaryService.getPostingFilterDefinitions();
          this.addSelectedFiltersAsChips(filterDefinitions, postingFilter);
        }));
    }
    this.subscriptions.push(this.filterBarService.headerChip.subscribe((chip: headerChip) => {
      this.filterChips = [];
      this.filterChips.push(chip);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private addSelectedFiltersAsChips(
    filterDefinitions: FilterDefinition[],
    filter: AnalysisFilter | PostingFilter
  ): void {
    this.filterChips = [];

    filterDefinitions.forEach((filterDefinition) => {
      if (filterDefinition.filterType === 'periods') {
        const periods = filter[filterDefinition.filterType] as Period[];
        if (this.internalFilterService.isPeriodsRangeSelected(periods) === false) {
          let selectedYears = '';
          this.getYearsFromPeriod(periods)
            .sort()
            .forEach((year, index) => {
              selectedYears += year;

              if (index + 1 !== periods.length) {
                selectedYears += ', ';
              }

              if (index + 1 === periods.length) {
                selectedYears += ' - ';
              }

            });
          let periodMonths: string = '';
          periodMonths += `  Fra: ${new Date(
            periods[0].fromDate
          ).toLocaleString('da-DK', { month: 'short', day: 'numeric' })}`;
          periodMonths += `  Til: ${new Date(
            periods[0].toDate
          ).toLocaleString('da-DK', { month: 'short', day: 'numeric' })}`;

          this.filterChips.push({
            type: filterDefinition.filterType,
            headline: filterDefinition.label + ':',
            removable: true,
            selectedData: selectedYears + '!' + periodMonths,
          });
        }
      } else {
        this.addChip(
          filter,
          filterDefinition.filterType,
          filterDefinition.label,
          true
        );
      }
    });
  }

  private getYearsFromPeriod(periods: Period[]): number[] {
    const fromYears = periods.map((s) => new Date(s.fromDate).getFullYear());
    const toYears = periods.map((s) => new Date(s.toDate).getFullYear());
    const concatArray = [...fromYears, ...toYears];
    return [...new Set(concatArray)];
  }

  private addChip(
    filter: any,
    type: string,
    headline: string,
    removable: boolean
  ): void {
    if (this.isNotEmpty(filter[type])) {
      this.filterChips.push({
        type,
        headline,
        removable,
      });
    }
  }

  private isNotEmpty(arrayFilter: any) {
    return (
      arrayFilter !== undefined &&
      arrayFilter?.length !== 0 &&
      arrayFilter !== null
    );
  }

  private isPathAnalysisOverview(): boolean {
    const routePath = this.activatedRoute.snapshot.routeConfig.path;
    return routePath === 'analyser';
  }

  public removeFromFilter(filterChip: FilterChip): void {
    if (filterChip.type === 'periods') {
      const rangedPerid = this.internalFilterService.getRangedPeriod();
      if (this.isPathAnalysisOverview()) {
        this.filterDataService.updatePrimaryFilterDate(rangedPerid);
      }
      this.internalFilterService.updatePeriodFilter(rangedPerid);
    } else {
      this.internalFilterService.updateTypeFilter(filterChip.type, null);
    }
  }

  public removeAllFilters(): void {
    this.internalFilterService.removeAllFilters();
  }
}
