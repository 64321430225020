import { Injectable } from '@angular/core';
import { PagingModel, Posting, PostingService } from 'src/app/api/vat';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import config from 'src/assets/config.json';

export interface OverlayRelatedFilter {
  pageModel: PagingModel;
  postingId: number;
}

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private overlayState: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  private postingTabs: BehaviorSubject<Posting[]> = new BehaviorSubject<Posting[]>([]);
  private currentSelectedPostingID: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private postingshasRelatedNote: Subject<number[]> = new Subject<number[]>();

  private filterRelatedValue: Subject<OverlayRelatedFilter> = new Subject<OverlayRelatedFilter>();
  private bounceFilterRelatedValue: Subject<OverlayRelatedFilter> = new Subject<OverlayRelatedFilter>();

  public overlayState$: Observable<number> = this.overlayState.asObservable();
  public postingTabs$: Observable<Posting[]> = this.postingTabs.asObservable();
  public currentSelectedPostingID$: Observable<number> = this.currentSelectedPostingID.asObservable();
  public postingshasRelatedNote$: Observable<
    number[]
  > = this.postingshasRelatedNote.asObservable();
  public filterRelated$: Observable<OverlayRelatedFilter> = this.filterRelatedValue.asObservable();


  constructor(private postingService: PostingService) {
    this.bounceFilterRelatedValue
      .pipe(debounceTime(config.DebounceTime), distinctUntilChanged())
      .subscribe(
        (filter) => {
          this.filterRelatedValue.next(filter);
        },
        (error) => console.log(error)
      );
  }

  maxOverlaySize() {
    if (this.overlayState.value === 0) {
      this.overlayState.next(1);
    } else {
      this.overlayState.next(2);
    }
  }

  updateStatusOnSelectedPostings(postings: Posting[]) {
    const postingTabs = this.postingTabs.value;
    postingTabs.forEach((tab) => {
      const postingIndex = postings.findIndex(
        (p) => p.postingId === tab.postingId
      );
      if (postingIndex > -1) {
        tab.result = postings[postingIndex].result;
        tab.status = postings[postingIndex].status;
        tab.correctedPotential = postings[postingIndex].correctedPotential;
      }
    });
    this.postingTabs.next(postingTabs);
  }

  minOverlaySize() {
    if (this.overlayState.value === 2) {
      this.overlayState.next(1);
    } else {
      this.overlayState.next(0);
    }
  }

  setOverlayClosed() {
    this.overlayState.next(0);
  }

  setOverlayMedium() {
    this.overlayState.next(1);
  }

  setOverlayFull() {
    this.overlayState.next(2);
  }

  updateCurrentPostingID(selectedPostingID: number) {
    if (this.currentSelectedPostingID.getValue() !== selectedPostingID) {
      this.currentSelectedPostingID.next(selectedPostingID);
    }
  }

  resetOverayTabs() {
    this.currentSelectedPostingID.next(null);
    this.removeAllPostingTabs();
    this.postingshasRelatedNote.next([]);
    this.setOverlayClosed();
  }

  removeAllPostingTabs() {
    this.postingTabs.next([]);
  }

  addPostingToTab(posting: Posting) {
    const postings = this.postingTabs.value;

    if (!(postings.findIndex((x) => x.postingId === posting.postingId) > -1)) {
      this.postingService
        .getPosting(posting.postingId)
        .subscribe((result) => {
          postings.push(result);
          this.postingTabs.next(postings);
        });
    }
  }

  annoucePostinghasRelatedNotes(ids: number[]) {
    this.postingshasRelatedNote.next(ids);
  }

  announceRelatedFilterValue(filter: OverlayRelatedFilter) {
    this.bounceFilterRelatedValue.next(filter);
  }
}
