<div class="filter-bar" [ngClass]="{
  'filter-bar__open' : filterBarState,
  'filter-bar__expanded' : filterBarExpanded}">
  <div class="filter-bar__content">
    <div class="filter-bar__default-btns">
      <button mat-flat-button color="primary" (click)="accordion.openAll()">Udvid alle</button>
      <button mat-button (click)="accordion.closeAll()">Luk alle</button>
    </div>

    <mat-accordion class="example-headers-align" multi>

      <ng-container *ngFor="let filterDefinition of filterDefinitions">

        <ng-container [ngSwitch]="filterDefinition.componentFilterType"
          *ngIf="filtersDataStream$ | async as filtersDataStream">

          <mat-expansion-panel [expanded]="filterDefinition.expanded">

            <mat-expansion-panel-header>
              <mat-panel-title>
                <fa-icon [icon]="filterDefinition.iconDefinition"></fa-icon> {{filterDefinition.label}}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="accordion-content">

              <ng-container *ngSwitchCase="componentFilterType.period">
                <app-period [selectedFilters]="currentSelectedFiltersStream"
                  [financialYears]="filtersDataStream.financialYear"></app-period>
              </ng-container>

              <ng-container *ngSwitchCase="componentFilterType.checkboxlist">
                <div *ngIf="filterDefinition.filterType !== 'analysisIds'; else show">
                  <app-checkbox-list [type]="filterDefinition.filterType"
                    [selectedFilters]="currentSelectedFiltersStream"
                    [data]="filtersDataStream[filterDefinition.filterType]"></app-checkbox-list>
                </div>

                <ng-template #show>
                  <app-checkbox-list [type]="filterDefinition.filterType"
                    [selectedFilters]="currentSelectedFiltersStream" [data]="filtersDataStream.analyzes">
                  </app-checkbox-list>
                </ng-template>
              </ng-container>

              <ng-container *ngSwitchCase="componentFilterType.list">
                <div>
                  <app-list [type]="filterDefinition.filterType" [selectedFilters]="currentSelectedFiltersStream">
                  </app-list>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="componentFilterType.keyword">
                <div>
                  <app-keyword [type]="filterDefinition.filterType" [selectedFilters]="currentSelectedFiltersStream">
                  </app-keyword>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="componentFilterType.hierarchy">
                <app-hierarchy [type]="filterDefinition.filterType" [selectedFilters]="currentSelectedFiltersStream"
                  [data]="filtersDataStream[filterDefinition.filterType]"></app-hierarchy>
              </ng-container>

            </div>

          </mat-expansion-panel>

        </ng-container>
      </ng-container>

    </mat-accordion>
  </div>

  <button class="filter-bar__search" [disabled]="noChanges" (click)="searchOnFilter()">
    Søg med Filter
  </button>
  <div class="filter-bar__expand" (click)="expandFilterbar()">
    <fa-icon *ngIf="!filterBarExpanded" [icon]="faChevronRight"></fa-icon>
    <fa-icon *ngIf="filterBarExpanded" [icon]="faChevronLeft"></fa-icon>
  </div>

  <div class="filter-bar__close" (click)="toggleFilterBar()">
    <fa-icon *ngIf="!filterBarState" [icon]="faChevronRight"></fa-icon>
    <fa-icon *ngIf="filterBarState" [icon]="faChevronLeft"></fa-icon>
    <span>Filtre</span>
    <fa-icon *ngIf="!filterBarState" [icon]="faChevronRight"></fa-icon>
    <fa-icon *ngIf="filterBarState" [icon]="faChevronLeft"></fa-icon>
  </div>
</div>