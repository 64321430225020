import { EventEmitter, Input, OnDestroy } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faFileExcel, faCheck } from '@fortawesome/free-solid-svg-icons';
import { SelectAnalyzeComponent } from '@modules/dialog/components/select-analyze/select-analyze.component';

import { AnalysisByPostings, AnalysisService, ColumnDefinition, Posting, PostingFilter, PostingService, PostingStatus, UpdatePosting } from 'src/app/api/vat';

import { Subscription } from 'rxjs';
import config from '../../../../../assets/config.json';
import { formatNumber } from '@angular/common';
import { PostingStatusChangeService } from '@modules/commons/services/posting-status-change.service';
import { Column, PostingAction } from 'src/app/global-models';
import { ActivatedRoute } from '@angular/router';
import { NotifyService } from '@modules/shell/services/notify.service';
import {
  CheckBoxService,
  PostingCheckbox,
} from '@modules/postings/services/check-box.service';
import { PostingColumnListComponent } from '@modules/dialog/components/posting-column-list/posting-column-list.component';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';

@Component({
  selector: 'app-posting-action-selector',
  templateUrl: './posting-action-selector.component.html',
  styleUrls: ['./posting-action-selector.component.scss'],
})
export class PostingActionSelectorComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    private postingService: PostingService,
    private analysisService: AnalysisService,
    private postingStatusChangeService: PostingStatusChangeService,
    private route: ActivatedRoute,
    private checkBoxService: CheckBoxService,
    private notifyService: NotifyService,
    private labelGlossaryService: LabelGlossaryService
  ) {}
  faFileExcel = faFileExcel;
  faCheck = faCheck;

  selectedAction: number;
  subscriptions: Subscription[] = [];
  private routePath: string;

  @Input() postingFilter: PostingFilter;
  @Input() isAllPostingsSelected: boolean = false;
  @Input() isOverlay: boolean = false;
  @Input() isDisabled: boolean = false;
  @Output() ExportPostingsEvent = new EventEmitter();
  @Input() totalPostings: number = 0;
  @Input() selectedPostingForStatusChange: Posting;
  @Input() totalSelectPostings: number = 0;
  @Input() totalDisplayedPostings: number = 0;
  private selectedPostingIdsForStatusChange: number[] = [];
  private deSelectedPostingIdsForStatusChange: number[] = [];
  private editedNoteId: number = 0;
  private postingColumnDefinitions: Column[];

  actionsData: PostingAction[] = [
    { value: 0, viewValue: 'Ubehandlet' },
    { value: 1, viewValue: 'Intet potentiale' },
    { value: 2, viewValue: 'Reguler moms' },
    { value: 3, viewValue: 'Igangværende' },
    { value: 4, viewValue: 'Flyt til anden analyse' },
  ];

  ngOnInit(): void {
    this.routePath = this.route.snapshot.routeConfig.path;
    this.checkBoxService.postingCheckboxs$.subscribe(
      (checkboxPosting: PostingCheckbox) => {
        this.selectedPostingIdsForStatusChange = checkboxPosting.selected;
        this.isAllPostingsSelected = checkboxPosting.isAllSelected;
        this.deSelectedPostingIdsForStatusChange = checkboxPosting.deSelected;
      }
    );
    if (this.isOverlay === false) this.setPostingGetUserColumnDefinitions();
  }

  private setPostingGetUserColumnDefinitions() {
    const analysisId = this.getAnalysisIdFromUrlRoute();
    if (analysisId !== undefined)
      this.postingService
        .getUserColumnDefinitions(analysisId)
        .subscribe((columnDefinitions: ColumnDefinition[]) => {
          if (columnDefinitions !== null) {
            this.postingColumnDefinitions =
              this.labelGlossaryService.updatesPostingColumnVisibility(
                columnDefinitions
              );
            this.labelGlossaryService.updatePostingColumns(
              this.postingColumnDefinitions
            );
          } else {
            this.labelGlossaryService.updatePostingColumns(null);
          }
        });
  }

  public openPostingColumnListDialog(): void {
    this.dialog.open(PostingColumnListComponent, {
      data: this.getAnalysisIdFromUrlRoute() as number,
      autoFocus: false,
    });
  }

  public doAction() {
    const action = this.selectedAction;
    if (this.isOverlay) {
      this.selectedPostingIdsForStatusChange = [
        this.selectedPostingForStatusChange.postingId,
      ];
    }

    if (
      action !== undefined &&
      this.selectedPostingIdsForStatusChange.length > 0
    ) {
      if (this.isActionMoveAnalysis(action)) {
        this.moveAnalysis(this.selectedPostingIdsForStatusChange).then(() => {
          this.postingStatusChangeService.notifyStatusOnPostings();
        });
      } else {
        this.updateStatus(
          this.selectedPostingIdsForStatusChange,
          this.deSelectedPostingIdsForStatusChange
        )
          .then((postingChanged: number) => {
            this.postingStatusChangeService.notifyStatusOnPostings();
            this.notifyService.notifySnackBarInformation(
              `${postingChanged.toLocaleString(
                'da-DK'
              )} Posteringer har ændret status til ${
                this.actionsData[action].viewValue
              }`,
              'green'
            );
          })
          .catch(() => {
            this.notifyService.notifySnackBarInformation(
              `Der er valgt for mange posteringer. Prøv et mindre antal valgte`,
              'red'
            );
          });
      }
    }
  }

  private moveAnalysis(selectedPostingIDs: number[]): Promise<void> {
    return new Promise<void>((resolve, rejects) => {
      this.subscriptions.push(
        this.analysisService
          .getByPostingIds(
            selectedPostingIDs,
            this.postingFilter.periods
          )
          .subscribe((result: AnalysisByPostings) => {
            const dialogRef = this.dialog.open(SelectAnalyzeComponent, {
              width: '1100px',
              data: {
                totalPostings: this.totalPostings,
                analysisByPostings: result,
                selectedPostingIds: this.selectedPostingIdsForStatusChange,
                isAllPostingsSelected: this.isAllPostingsSelected,
                deSelectedPostingIds: this.deSelectedPostingIdsForStatusChange,
                postingFilter: this.postingFilter,
              },
              panelClass: 'select-analysis-dialog',
            });

            this.dialog.afterAllClosed.subscribe(() => {
              resolve();
            });
          })
      );
    });
  }

  private isActionMoveAnalysis = (action: number) => action === 4;

  private updateStatus(
    selectedPostingIDs: number[],
    deSelectedPostingIDs: number[]
  ): Promise<number> {
    return new Promise<number>((resolve, rejects) => {
      if (this.isAllPostingsSelected === false || this.isOverlay === true) {
        this.postingService
          .setStatusOnPostingsByIds(
            this.selectedAction as PostingStatus,
            selectedPostingIDs
          )
          .subscribe(
            (postingChanged: number) => {
              resolve(postingChanged);
            },
            (error) => {
              rejects();
            }
          );
      } else {
        this.postingFilter.pageModel.skip = 0;
        this.postingFilter.pageModel.take = this.totalPostings;
        let updatePosting: UpdatePosting;
        this.routePath = this.route.snapshot.routeConfig.path;
        if (this.routePath === 'posteringer/postingnote/:postingNoteId')
          this.route.url.subscribe((url) => {
            this.editedNoteId = Number(url[2].path);
          });
        if (this.editedNoteId == 0) {
          updatePosting = {
            postingFilter: this.postingFilter,
            deSelectedPostings: deSelectedPostingIDs,
            editedNoteId: 0,
          };
          this.postingService
            .setStatusOnPostingsByPostingFilter(
              this.selectedAction as PostingStatus,
              updatePosting
            )
            .subscribe(
              (postingChanged: number) => {
                resolve(postingChanged);
              },
              (error) => {
                rejects();
              }
            );
        } else {
          updatePosting = {
            editedNoteId: this.editedNoteId,
            deSelectedPostings: deSelectedPostingIDs,
          };

          this.postingService
            .setStatusOnPostingsByPostingNoteId(
              this.selectedAction as PostingStatus,
              updatePosting
            )
            .subscribe(
              (postingChanged: number) => {
                resolve(postingChanged);
              },
              (error) => {
                rejects();
              }
            );
        }
      }
      this.selectedAction = undefined;
    });
  }

  public exportPostings(): void {
    this.ExportPostingsEvent.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe);
  }

  excelExportDisabled() {
    return this.totalPostings >= config.MaxRowsForExcelExport;
  }

  excelExportToolTip() {
    return this.excelExportDisabled()
      ? `Eksporteringsfunktionen understøtter maks ${formatNumber(
          config.MaxRowsForExcelExport,
          'da-DK'
        )} rækker.`
      : `Eksportér ${formatNumber(this.totalPostings, 'da-DK')} rækker`;
  }

  private getAnalysisIdFromUrlRoute = () =>
    this.route.snapshot.params['analysisId'] as number;
}
