import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sidebarOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public sidebarOpen$: Observable<boolean> = this.sidebarOpen.asObservable();

  constructor() {}

  toggleSidebar() {
    this.sidebarOpen.next(!this.sidebarOpen.value);
  }

  setSidebarOpen(value: boolean) {
    this.sidebarOpen.next(value);
  }
}
