import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

// Custom modules
import { CommonsModule } from '@modules/commons/commons.module';

// Components
import { LeaderDashboardComponent } from './components/leader-dashboard/leader-dashboard.component';
import { CardTableComponent } from './components/card-table/card-table.component';
import { FunctionDiagramComponent } from './components/function-diagram/function-diagram.component';
import { CardDiagramComponent } from './components/card-diagram/card-diagram.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { YearSelectorComponent } from './components/year-selector/year-selector.component';

@NgModule({
  declarations: [
    LeaderDashboardComponent,
    CardTableComponent,
    FunctionDiagramComponent,
    CardDiagramComponent,
    TopBarComponent,
    YearSelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    CommonsModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatCheckboxModule,
    MatTooltipModule,
  ],
  exports: [LeaderDashboardComponent],
})
export class LeaderDashboardModule {}
