import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

import { ApiModule, BASE_PATH } from '@vat/vat-webapi';

import { API_BASE_URL, AnalysisService, PostingService, IdentityService, MessageService, SignalService, FilterService, CustomerService, UserService, LeaderDashboardService } from './api/vat';

import { HttpInterceptor } from './http.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Custom modules
import { ShellModule } from '@modules/shell/shell.module';
import { HomeModule } from '@modules/home/home.module';
import { AnalyzesModule } from '@modules/analyzes/analyzes.module';
import { MessagesModule } from '@modules/messages/messages.module';
import { FilterModule } from '@modules/filter/filter.module';
import { FavoritesModule } from '@modules/favorites/favorites.module';
import { PostingsModule } from '@modules/postings/postings.module';
import { LoginModule } from '@modules/login/login.module';
import { DialogModule } from '@modules/dialog/dialog.module';
import { BdoDashboardModule } from '@modules/bdo-dashboard/bdo-dashboard.module';
import { LeaderDashboardModule } from '@modules/leader-dashboard/leader-dashboard.module';
import { OverlayModule } from '@modules/overlay/overlay.module';
import { AdminModule } from '@modules/admin/admin.module';



import { AuthModule } from './modules/auth/auth.module';
import { ReportModule } from '@modules/report/report.module';
import { SystemService } from './services/system.service';
import { HttpSpinnerInterceptor } from './interceptores/http-spinner.interceptor';
import { HttpRequestBearerInterceptor } from './interceptores/http-request-bearer.interceptor';
import { HttpResponseInterceptor } from './interceptores/http-response.interceptor';
import { TestingModule } from '@modules/testing/testing.module';

import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';

registerLocaleData(localeDa);

@NgModule({
  declarations: [AppComponent],
  imports: [
    LoginModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    ShellModule,
    HomeModule,
    AnalyzesModule,
    PostingsModule,
    FilterModule,
    FavoritesModule,
    MessagesModule,
    DialogModule,
    BdoDashboardModule,
    LeaderDashboardModule,
    HttpClientModule,
    ApiModule,
    AdminModule,
    OverlayModule,

    //AuthModule,
    ReportModule,

    TestingModule,
  ],
  exports: [RouterModule],
  providers: [
    {
		  provide: API_BASE_URL, useValue: environment.VatBackendUrl
	  },
    {
		  provide: BASE_PATH, useValue: environment.VatBackendUrl
	  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true
	  },
	
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSpinnerInterceptor,
      multi: true,
    },
    /*
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestBearerInterceptor,
      multi: true,
    },
    */
    {
      provide: APP_INITIALIZER,
      useFactory: (systemService: SystemService) => () => systemService.init(),
      deps: [SystemService],
      multi: true,
    },
    AnalysisService, PostingService, IdentityService, MessageService, SignalService, FilterService, CustomerService, UserService, LeaderDashboardService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
