import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bdo-dashboard',
  templateUrl: './bdo-dashboard.component.html',
  styleUrls: ['./bdo-dashboard.component.scss']
})
export class BdoDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
