import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
})
export class YearPickerComponent implements OnInit {
  @Input() selectedYear: number;
  @Input() options: number[] = [];
  @Output() onSelect: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void { }

  select(event): void {
    this.onSelect.emit(this.selectedYear);
  }
}
