import { Injectable } from '@angular/core';
import { Analysis, Posting } from 'src/app/api/vat';

@Injectable({
  providedIn: 'root',
})
export class LoadingTemplateFactoryService {
  private tableTemplatePostings: Posting[] = [];

  constructor() {
    this.createTablePostingTemplate();
  }
  //release check
  public createCardAnalyzesTemplate(analysisIds: number[]): Analysis[] {
    const cardAnalyzesTemplate: Analysis[] = [];
    for (let index = 0; index < analysisIds.length; index++)
      cardAnalyzesTemplate.push({
        analysisId: index,
        title: null,
        ongoing: 0,
        categoryNo: 0,
        processed: 0,
        quantity: 0,
        realisedPotential: 0,
        remainingPotential: 0,
        totalPotential: null,
        financialYears: [null],
        orderNumber: null,
      });

    return cardAnalyzesTemplate;
  }

  createTablePostingTemplate() {
    const tableTemplatePostings: Posting[] = [];
    for (let index = 0; index < 30; index++) {
      tableTemplatePostings.push({
        postingId: index,
        primaryAnalysisTitle: null,
        primaryAnalysisId: null,
        potential: null,
        correctedPotential: null,
        result: null,
        financialYear: null,
        amountPostedExVat: null,
        amountPosted: null,
        amountVatToll: null,
        vatAmountPosted: null,
        vatAmount: null,
        amountTotal: null,
        date: null,
        art: null,
        artCombined: null,
        dranst: null,
        dranstText: '',
        ownership: null,
        accountNo: null,
        function: null,
        grouping: null,
        agreementText: null,
        supplier: null,
        supplierNo: null,
        supplierName: null,
        supplierType: null,
        publicDepartmentType: null,
        internalSupplierNo: null,
        text: null,
        pspValue: null,
        vatType: null,
        profitCenterNavn: null,
        reposted: null,
        sfaExemption: null,
        annexNo: null,
        expeditionNo: null,
        reference: null,
        status: '',
        changedBy: null,
        changedByUser: null,
        changeDate: null,
        invoice: null,
        costCenter: null,
        department: null,
        annex: null,
        annexType: null,
        accountableCpr: null,
        creatorInitials: null,
        allocaterInitials: null,
        originalAnalysisTitle: null,
        hasNote: false,
        reportingDuty: null,
        hasRelatedNote: false,
        isSelected: false,
        annexTotal: null,
        amountVatTollTotal: null,
        changedByUserName: null,
      });
      this.tableTemplatePostings = tableTemplatePostings;
    }
  }
  get getPostingTemplate(): Posting[] {
    return this.tableTemplatePostings;
  }
}
