<div class="card" [ngClass]="cardExpanded ? 'card__expanded' : ''">
  <div class="card__top-bar">
    <div class="card__top-bar--left">
      <app-year-selector (selectedYearChange)="yearSelectChange($event)"></app-year-selector>
      <button mat-button [matMenuTriggerFor]="funktionMenu">
        <fa-icon [icon]="faList"></fa-icon>
        Funktion: {{functionTitle}}
      </button>
      <mat-menu #funktionMenu="matMenu">
        <mat-option class="checkbox" *ngFor="let functions of functionsStatistics">
          <mat-checkbox (change)="checkboxToggle($event, functions)" (click)="$event.stopPropagation()">
            {{ functions.functionNo}} - {{functions.functionName}}
          </mat-checkbox>
        </mat-option>
      </mat-menu>
    </div>
    <div class="card__top-bar--right">
      <button mat-button class="back-btn" *ngIf="previousFunctionNo !== ' '" (click)="goBackToPreviousChartView()">Tilbage</button>
      <button mat-icon-button class="expand-card" (click)="expandCard()">
        <fa-icon class="expand-icon" [icon]="faExpand" matTooltip="Udvid dette kort"></fa-icon>
        <fa-icon class="close-icon" [icon]="faCompressAlt" matTooltip="Minimer dette kort"></fa-icon>
      </button>
    </div>
  </div>
  <div class="card__graph">
    <canvas id="{{chartId}}" width="1000" height="410"></canvas>
  </div>
  <div class="card__info">
    <div class="card__info--left">
      {{labels[0]}}: {{datasetOneTotal | vatCurrency}}
    </div>
    <div class="card__info--right">
      {{labels[1]}}: {{datasetTwoTotal | vatCurrency}}
    </div>
  </div>
</div>
