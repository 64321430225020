import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterKeyRemove',
})
export class FilterKeyRemovePipe implements PipeTransform {
  transform(value: string, type: string | number | boolean): string {
    if (
      type === true ||
      type === false ||
      type === 'analysisIds' ||
      type === 'reposted' ||
      type === 'companyCodes'
    ) {
      return '';
    }

    return value;
  }
}
