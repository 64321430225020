import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

// Custom modules
import { MessagesModule } from '@modules/messages/messages.module';
import { StoreModule } from '@modules/store/store.module';
import { CommonsModule } from '@modules/commons/commons.module';

// Components
import { ShellComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ThrobberComponent } from './components/throbber/throbber.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LawsComponent } from './components/laws/laws.component';
import { ManualsComponent } from './components/manuals/manuals.component';
import { CustomerSelectorComponent } from './components/customer-selector/customer-selector.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UnauthorizedSiteComponent } from './components/unauthorized-site/unauthorized-site.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MenuComponent } from './components/menu/menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';

@NgModule({
  declarations: [
    ShellComponent,
    HeaderComponent,
    NotFoundComponent,
    ThrobberComponent,
    SidebarComponent,
    LawsComponent,
    ManualsComponent,
    CustomerSelectorComponent,
    SnackbarComponent,
    UnauthorizedSiteComponent,
    TopBarComponent,
    SpinnerComponent,
    MenuComponent,
    SettingsComponent,
    InformationDialogComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MessagesModule,
    StoreModule,
    FormsModule,
    CommonsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  exports: [ShellComponent],
})
export class ShellModule {}
