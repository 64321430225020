import { Injectable } from '@angular/core';
import { Customer, CustomerService } from 'src/app/api/vat';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternalCustomerService {
  private customersSource: BehaviorSubject<Customer[]> = new BehaviorSubject<
    Customer[]
  >(null);
  public customers: Observable<
    Customer[]
  > = this.customersSource.asObservable();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private customersService: CustomerService
  ) {
    this.oidcSecurityService.isAuthenticated$.subscribe((auth) => {
      if (auth.isAuthenticated === true) {
        this.customersService
          .getAllCustomers()
          .toPromise()
          .then((result) => {
            this.customersSource.next(result);
          });
      }
    });
  }
}
