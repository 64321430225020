export class MessageHelper {
  public static readonly KMD: string = 'På vegne af KMD';
  public static readonly BDO: string = 'På vegne af BDO';

  public static isPublishedBy(publishedBy: number, userName: string): string {
    switch (publishedBy) {
      case 1:
        return userName;
      case 2:
        return this.BDO;
      case 3:
        return this.KMD;
      default:
        return userName;
    }
  }
}
