<div class="analyze-wrapper" [ngClass]="!filterBarState ? 'full-size' : ''">
  <app-filter></app-filter>
  <div class="top-bar">
    <div class="top-bar__left">
      <app-chosen-filters-bar></app-chosen-filters-bar>
    </div>
    <div class="top-bar__right">
      <mat-form-field floatLabel=never>
        <mat-label>Sorter</mat-label>
        <mat-select>
          <mat-option *ngFor="let option of sortOptions" [value]="option.value" (click)="selectSorting(option)">
            {{option.label}} <fa-icon style="float: right;" [icon]="option.faIcon"></fa-icon>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group name="toggleView" #group="matButtonToggleGroup" aria-label="Toggle view"
        (change)="saveLatestView(group.value)" [(ngModel)]="toggleViewValue" [value]="toggleViewValue">
        <mat-button-toggle value="card">
          <fa-icon [icon]="faTh"></fa-icon>
        </mat-button-toggle>
        <mat-button-toggle value="table">
          <fa-icon [icon]="faThList"></fa-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button style="margin-left: 20px;" mat-flat-button color="primary" (click)="exportAnalysis()">
        <fa-icon [icon]="faFileExcel"></fa-icon> Export
      </button>
    </div>
  </div>
  <app-analyze [view]="toggleViewValue" [columns]="columns"></app-analyze>
</div>