<div class="content__wrapper">
    <div class="container">
        <div class="row">
            <div class="col-4">
                <div class="sidebar">
                    <ul>
                        <li>Lovgivning</li>
                        <li (click)="scroll(target1)">Budget- og regnskabssystem for kommuner og regioner</li>
                        <li (click)="scroll(target2)">Gældende momslov</li>
                        <li (click)="scroll(target3)">Gældende bekendtgørelse om momsrefusionsordning for kommuner og
                            regioner</li>
                        <li (click)="scroll(target4)">Offentlig-moms.dk</li>
                    </ul>
                </div>
            </div>
            <div class="col-8">
                <div class="content">

                    <div class="section" #target1>
                        <h2>Budget- og regnskabssystem for kommuner og regioner</h2>
                        <a target="_blank" href="https://budregn.sim.dk">https://budregn.sim.dk</a>
                        <p> Når du tilgår dette link, skal du vælge budget- og regnskabssystem for kommuner eller
                            regioner,
                            du vil herefter blive dirigeret videre til rette side. </p>
                        <p>På siden finder du information omkring korrekt
                            bogføring og kan blandt andet læse om, hvilke udgifter der skal bogføres på de forskellige
                            funktioner og grupperinger.
                            Du kan også finde orienteringsskrivelser fra Social- og indenrigsministeriet, hvori der
                            informeres om ændringer
                            for blandt andet funktioner, grupperinger og præcisering af bogføringspraksis.</p>
                    </div>

                    <div class="section" #target2>
                        <h2>Gældende momslov</h2>
                        <a target="_blank"
                            href="https://www.retsinformation.dk/eli/lta/2019/1021">https://www.retsinformation.dk/eli/lta/2019/1021</a>
                        <p>Via dette link kommer du videre til retsinformation, hvor du får vist den gældende momslov
                            som er gældende for
                            ikke myndighedsopgaver i kommuner og regioner. </p>
                        <p>I denne lov kan du blandt andet læse om, hvornår der skal betales moms af varer og ydelser,
                            fritagelser, omvendt betalingspligt og al anden generel information om moms.</p>
                    </div>

                    <div class="section" #target3>
                        <h2>Gældende bekendtgørelse om momsrefusionsordning for kommuner og regioner</h2>
                        <p> Gældende momsrefusionsbekendtgørelse:
                            <a target="_blank"
                                href="https://www.retsinformation.dk/eli/lta/2022/946">https://www.retsinformation.dk/eli/lta/2022/946</a>
                        </p>
                        <p> Gældende for år 2021:
                            <a target="_blank"
                                href="https://www.retsinformation.dk/eli/lta/2020/2091">https://www.retsinformation.dk/eli/lta/2020/2091</a>
                        </p>
                        <p> Gældende for år 2020:
                            <a target="_blank"
                                href="https://www.retsinformation.dk/eli/lta/2019/480">https://www.retsinformation.dk/eli/lta/2019/480</a>
                        </p>
                        <p> Gældende for år 2019:
                            <a target="_blank"
                                href="https://www.retsinformation.dk/eli/lta/2018/1744">https://www.retsinformation.dk/eli/lta/2018/1744</a>
                        </p>
                        <p> Gældende for år 2018:
                            <a target="_blank"
                                href="https://www.retsinformation.dk/eli/lta/2017/1593">https://www.retsinformation.dk/eli/lta/2017/1593</a>
                        </p>

                        <p>Via dette link kommer du videre til retsinformation, hvor du får vist den gældende
                            bekendtgørelse om momsrefusionsordning
                            for kommuner og regioner. </p>
                        <p>Det er blandt andet her, hvor du får oplyst, hvilke funktioner
                            evt. kombineret med art, grupperinger og noter,
                            der er optaget på positivlisten, satsen for momsandelsprocenten samt hvordan beløbet for
                            positivlistemoms udregnes. </p>

                    </div>

                    <div class="section" #target4>
                        <h2>Offentlig-moms.dk</h2>
                        <a target="_blank" href="https://offentlig-moms.dk/">https://offentlig-moms.dk/</a>
                        <p>Denne side er lavet af BDO. Du har mulighed for at oprette dig som bruger og herefter få
                            indblik i,
                            hvilke institutioner der har en driftsoverenskomst eller er omfattet af en statslig
                            momsrefusionsordning.</p>
                        <p>Begge dele er yderst relevant, når man arbejder med momsanalyser og skal vurdere
                            posteringerne.
                        </p>

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>