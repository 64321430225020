import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { VatCurrencyPipe } from '@modules/commons/pipes/vat-currency.pipe';
import { CorrectedPotentialService } from '@modules/commons/services/corrected-potential.service';

import { CorrectedPotential, Posting, PostingService } from 'src/app/api/vat';

import { distinctUntilChanged, filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-corrected-potentiale-input-box',
  templateUrl: './corrected-potentiale-input-box.component.html',
  styleUrls: ['./corrected-potentiale-input-box.component.scss'],
})
export class CorrectedPotentialeInputBoxComponent implements OnInit {
  private regex: RegExp = new RegExp(/^-?[-0-9.,]+$/);
  correctedPotentialForm: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(this.regex),
  ]);

  constructor(
    private correctedPotentialService: CorrectedPotentialService,
    private postingService: PostingService
  ) {}
  @Input() columnKey: string;
  @Input() row: Posting;
  @Output() inputChanged: EventEmitter<Posting> = new EventEmitter<Posting>();
  ngOnInit(): void {
    this.updateFormField(this.row.correctedPotential);
    this.disableInputIfNeeded(this.columnKey, this.row);

    this.correctedPotentialService.correctedPotentialPostingRow$
      .pipe(filter((c) => c !== null))
      .subscribe((selectedPostingToCorrect) => {
        if (this.row.postingId === selectedPostingToCorrect.postingId) {
          this.updateFormField(selectedPostingToCorrect.correctedPotential);
        }
      });

    this.correctedPotentialForm.valueChanges
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevValue, nextValue]: [string, string]) => {
        if (this.correctedPotentialForm.valid || nextValue === '') {
          this.correctedPotentialForm.setValue(nextValue);
        } else {
          this.correctedPotentialForm.setValue(prevValue);
        }
      });
  }

  private updateFormField(value: number): void {
    this.correctedPotentialForm.setValue(this.transformValue(value));
  }

  private transformValue(value: number) {
    return new VatCurrencyPipe().transform(value, '', true, true);
  }

  public onInputBlur(): void {
    switch (this.columnKey) {
      case 'correctedPotential': {
        let value = parseFloat(
          this.correctedPotentialForm?.value.replace('.', '').replace(',', '.')
        );
        if (isNaN(value)) {
          value = null;
        }

        this.correctedPotentialForm.setValue(value);
        this.row.correctedPotential = value;
        this.correctedPotentialService.updateCorrectedPotential(this.row);
        const corretedPotenail: CorrectedPotential = { potential: value };
        this.postingService
          .setCorrectedPotential(this.row.postingId, corretedPotenail)
          .subscribe();

        this.inputChanged.emit(this.row);

        break;
      }
    }
  }

  public disableInputIfNeeded(columnKey: string, row: Posting): void {
    switch (columnKey) {
      case 'correctedPotential': {
        switch (row.status.toLocaleLowerCase().trim()) {
          case 'intetpotentiale':
          case 'regulermoms':
            this.correctedPotentialForm.disable();

          default:
            this.correctedPotentialForm.enable();
        }
      }
    }
  }
}
