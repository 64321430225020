import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private systemInfo: AsyncSubject<SystemInfoDto> =
    new AsyncSubject<SystemInfoDto>();
  systemInfo$: Observable<SystemInfoDto> = this.systemInfo.asObservable();

  constructor(private httpClient: HttpClient) {}

  init() {
    return new Observable((subscriber) => {
      // HTTP Get call
      this.httpClient
        .get<SystemInfoDto>(environment.VatBackendUrl + '/api/systeminfo')
        .subscribe(
          (result) => {
            this.systemInfo.next(result);
            this.systemInfo.complete();
            subscriber.complete();
          },
          (error) => {
            subscriber.error();
          }
        );
    });
  }
}

export interface SystemInfoDto {
  title: string;
  issuer: string;
  clientId: string;
  scope: string;
  domainHints: [key: string];
}
