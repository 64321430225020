import { Component, Renderer2 } from '@angular/core';
import { MessagesService } from '@modules/messages/services/messages.service';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { InternalCustomerService } from '@modules/shell/services/internal-customer.service';

import {
  ClientActions,
  Customer,
  DoAction,
  IdentityService,
} from 'src/app/api/vat';

import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { InternalUserService } from '@modules/commons/services/internal-user.service';
import { InternalActionsService } from '@modules/shell/services/internal-actions.service';
import { NotifyService } from '@modules/shell/services/notify.service';

import { SidebarService } from '@modules/shell/services/sidebar.service';
import { UserService } from '@modules/shell/services/user.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { OpenIDCService } from '@modules/auth/services/open-idc.service';
import { IdleService } from '@modules/auth/services/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  authenticated: boolean = false;
  authenticationLoaded: boolean = false;
  customerLoaded: boolean = false;
  timer: any;
  currentUser: any;
  clientActions: ClientActions;

  constructor(
    private messagesService: MessagesService,
    private oidcSecurityService: OidcSecurityService,
    private internalCurrentCustomerService: InternalCurrentCustomerService,
    private internalCustomerService: InternalCustomerService,
    private identityService: IdentityService,
    private internalUserService: InternalUserService,
    private internalActionService: InternalActionsService,
    private notifyService: NotifyService,
    private renderer: Renderer2,

    private userService: UserService,
    private sidebarService: SidebarService
  ) /*
    private openIDCService: OpenIDCService,
    private oAuthService: OAuthService
    private IdleService: IdleService,
    */
  {
    // NEW
    /*
    this.openIDCService.isAuthenticated$.subscribe((result) => {
      console.log("IS AUTHENTICATED: " + result);
      
      if (result) {
        console.log("GETTING ACCESS TOKEN!!!");
        const token = this.oAuthService.getAccessToken();
        console.log(token);
        
        console.log("LOADING USER!!!");
        this.userService.loadUser();

        this.userService.user$.subscribe(u => {
          console.log(u);
        });

        this.removeInitLoadingScreen();
      }
      else {
        console.log("LOGGING IN!!!");
        
        this.openIDCService.login();
      }
    });
    */

    //OLD

    this.oidcSecurityService.checkAuth().subscribe((auth: LoginResponse) => {
      this.authenticated = auth.isAuthenticated;
      this.authenticationLoaded = true;
      if (auth.isAuthenticated === true) {
        this.identityService.getCurrentUser().subscribe((currentUser) => {
          this.currentUser = currentUser;
          this.internalUserService.updateUser(currentUser);
          const currentUserEmail = currentUser.email
            .split('@')[1]
            .split('.')[0];
          this.oidcSecurityService.getConfiguration().subscribe((c) => {
            c.customParamsAuthRequest['domain_hint'] = environment
              .openIdConnectConfig.customParams.domain_hint[
              currentUserEmail.toLocaleLowerCase()
            ]
              ? environment.openIdConnectConfig.customParams.domain_hint[
                  currentUserEmail.toLocaleLowerCase()
                ]
              : environment.openIdConnectConfig.customParams.domain_hint
                  .default;
          });

          this.removeInitLoadingScreen();
        });

        this.internalCustomerService.customers.subscribe((customers) => {
          if (customers?.length === 0) {
            this.notifyService.openSnackBar();
          } else if (customers) {
            const customer: Customer =
              this.internalCurrentCustomerService.getCustomer();
            if (customer === null) {
              this.internalCurrentCustomerService.updateCustomer(customers[0]);
            }

            // Because we have added a new column for customer, we need to update the localStorage on the browser.
            if (customer.customerFinancialTypeId == null) {
              this.internalCurrentCustomerService.updateCustomer(customers[0]);
            }

            this.customerLoaded = customers ? true : false;
            this.messagesService.checkForNewMessages();
          }
        });
        this.getSignals();
      }
    });
    /*
     */
  }

  private removeInitLoadingScreen(): void {
    const loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  getSignals() {
    let token = '';
    this.oidcSecurityService.getAccessToken().subscribe((t) => {
      token = '' + t;
    });

    const messagesService = this.messagesService;
    const timer = this.timer;
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.VatBackendUrl + '/api/signal', {
        accessTokenFactory: () => token,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Error)
      .build();
    connection
      .start()
      .then(() => {
        console.info('SignalHub Connected!');
      })
      .catch((err) => {
        setInterval(() => {
          messagesService.checkForNewMessages();
        }, 60000);
        return console.error(err.toString());
      });

    connection.on('DoWork', (action: DoAction) => {
      const customer: Customer =
        this.internalCurrentCustomerService.getCustomer();
      this.identityService.getCurrentUser().subscribe((currentUser) => {
        this.internalActionService.handleClientAction(
          customer.customerId,
          currentUser.userId,
          action
        );
      });
    });
  }
}
