export const environment = {
  production: false,

  VatBackendUrl: 'https://dev.momstjek.dk',

  openIdConnectConfig: {
    stsServer: 'https://identity.kmd.dk/adfs/.well-known/openid-configuration',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    clientId: 'e037a058-4952-4f86-89ee-bd4267cfa3d3',
    scope: 'openid urn:vat-analysis-api.dev/user_impersonation',
    responseType: 'code',
    triggerAuthorizationResultEvent: false,
    autoUserinfo: false,
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    customParams: {
      domain_hint: {
        default: 'kmd-ad-prod',
        kmd: 'kmd-ad-prod',
        bdo: 'bdo-aad-prod',
        dima: 'dima-idp-prod',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
