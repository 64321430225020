import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import {
  AnalysisFilter,
  KeyValuePairOfIntegerAndString,
  PostingFilter,
} from 'src/app/api/vat';
import { Observable, Subscription } from 'rxjs';
import filterTypes from 'src/assets/filterTypes.json';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent implements OnInit, OnDestroy {
  faTimes = faTimes;
  faTrash = faTrash;

  selectedNodes: KeyValuePairOfIntegerAndString[] = [];
  subscriptions: Subscription[] = [];

  constructor(private internalFilterService: InternalFilterService) {}

  @Input() type: string;
  @Input() data: KeyValuePairOfIntegerAndString[] = [];
  @Input() selectedFilters: Observable<AnalysisFilter | PostingFilter>;

  ngOnInit(): void {
    this.subscriptions.push(
      this.selectedFilters.subscribe((selected) => {
        this.selectedNodes = [];
        if (this.isSelectedFilterEmpty(selected[this.type])) {
          selected[this.type] = [];
        } else {
          this.getSelected(selected[this.type]);
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getSelected(selectedData: Array<number | string>) {
    selectedData.forEach((sd) => {
      if (this.type === 'vatTypes') {
        const selected = this.data.find((s) => s.key.toString() === sd);
        if (selected !== undefined) {
          this.selectedNodes.push(selected);
        }
      } else {
        const selected = this.data.find((s) => s.key === sd);
        if (selected !== undefined) {
          this.selectedNodes.push(selected);
        }
      }
    });
  }

  private isSelectedFilterEmpty(selectedFilter): boolean {
    return selectedFilter === undefined || selectedFilter === null;
  }

  public toggleNode(node: KeyValuePairOfIntegerAndString): void {
    const index = this.selectedNodes.map((n) => n.key).indexOf(node.key);
    if (index > -1) {
      this.selectedNodes.splice(index, 1);
    } else {
      this.selectedNodes.push(node);
    }

    if (this.type === filterTypes.vatTypes.key) {
      const selected = this.selectedNodes.map((sn) => sn.key.toString());
      this.internalFilterService.updateSideFilter(this.type, selected);
    } else {
      const selected = this.selectedNodes.map((sn) => sn.key);
      this.internalFilterService.updateSideFilter(this.type, selected);
    }
  }

  public isNodeSelected(node: KeyValuePairOfIntegerAndString): boolean {
    const index = this.selectedNodes?.findIndex((x) => x.key === node.key);
    return index === -1 ? false : true;
  }
}
