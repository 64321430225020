<div class="card" [ngClass]="cardExpanded ? 'card__expanded' : ''">
  <div class="card__top-bar">
    <div class="card__top-bar--left">
      <app-year-selector (selectedYearChange)="yearSelectChange($event)"></app-year-selector>
      <button *ngIf="selectionList.length > 0" mat-button [matMenuTriggerFor]="listMenu">
        <fa-icon [icon]="faList"></fa-icon>
        Bruger: {{selectedListItem.name}}
      </button>
      <mat-menu #listMenu="matMenu">
        <button mat-menu-item *ngFor="let selection of selectionList" (click)="listSelection(selection)"> {{selection.name}}</button>
      </mat-menu>
    </div>
    <div class="card__top-bar--right">
      <p *ngIf="hasTopInfo">Opdateret: <span>14/09 2021</span></p>
      <p *ngIf="hasTopInfo">Data indlæst: <span>{{loadedData}}</span></p>
      <button mat-icon-button class="expand-card" (click)="expandCard()">
        <fa-icon class="expand-icon" [icon]="faExpand" matTooltip="Udvid dette kort"></fa-icon>
        <fa-icon class="close-icon" [icon]="faCompressAlt" matTooltip="Minimer dette kort"></fa-icon>
      </button>
    </div>
  </div>

  <div class="card__graph">
    <div class="card__graph-color card__graph-color--left"></div>
    <div class="card__graph-color card__graph-color--right"></div>
    <canvas id="{{chartId}}" width="1000" height="410"></canvas>
  </div>

  <div class="card__info">
    <div class="card__info--left">
      {{labels[0]}}: <span *ngIf="leftYUnit === null; else notNull"> {{datasetOneTotal | number:'':'da-DK' }}</span>
      <ng-template #notNull>
        <span> {{datasetOneTotal | vatCurrency }}</span>
      </ng-template>
    </div>
    <div class="card__info--right">
      {{labels[1]}}: <span *ngIf="rightYUnit === null; else notNull"> {{datasetTwoTotal | number:'':'da-DK' }}</span>
      <ng-template #notNull>
        <span> {{datasetTwoTotal | vatCurrency }}</span>
      </ng-template>
    </div>
  </div>
</div>
