import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FilterDefinition } from '@modules/filter/models/filterDefinition';
import { ExcelColumnItem } from 'src/app/api/vat';
import { Column, Row, viewValue } from 'src/app/global-models';


export abstract class GlossaryDefinitionHelper {
  public filterDefinitions: FilterDefinition[] = [];
  public postingColumnDefinitions: Column[] = [];
  public postingOverlayDefinitions: Row[] = [];
  public releatedPostingDefinitions: Row[] = [];
  public postingExcelColumnItemDefinitions: ExcelColumnItem[] = [];
  public analyzeExcelColumnItemDefinitions: ExcelColumnItem[] = [];
  public analyzeItemDefinitions: Column[] = [];


  // Properties for variables in label file.
  private readonly key: string = 'key';
  private readonly text: string = 'text';
  private readonly type: string = 'type';
  private readonly overlayText: string = 'overlayText';

  constructor() {
  }

  abstract generateFilterDefinitions(): void;
  abstract generatePostingTableDefinitions(): void;
  abstract generateOverlayDefinitions(): void;
  abstract generateReleatedPostingDefinitions(): void;
  abstract generateAnalyzeItemDefinitions(): void;
  abstract generatePostingExcelDefinitions(): void;
  abstract generateAnalyseExcelDefinitions(): void;

  protected addFilterDefinition(
    expanded: boolean,
    definition: object,
    refinedLabel: string,
    componentFilterType,
    iconDefinition: IconDefinition
  ): void {

    if (this.isLabelRedefined(refinedLabel))
      definition[this.text] = refinedLabel;

    this.filterDefinitions.push({
      expanded,
      label: definition[this.text],
      filterType: definition[this.key],
      componentFilterType,
      iconDefinition,
    });
  }

  private isLabelRedefined(refinedLabel: string): boolean {
    return refinedLabel !== null && refinedLabel !== undefined;
  }

  protected addPostingColumnDefinition(
    definition: object,
    newLabelText: string = null,
    isStickyStart: boolean,
    isStickyEnd: boolean,
    switchAble: boolean,
    visible: boolean,
    filterSelect?: viewValue[],
    tooltip?: string
  ): void {

    if (this.isLabelRedefined(newLabelText))
      definition[this.text] = newLabelText;

    if (newLabelText === 'Momskode')
      definition[this.type] = 'string';

    this.postingColumnDefinitions.push({
      key: definition[this.key],
      name: definition[this.text],
      type: definition[this.type],
      isStickyStart,
      isStickyEnd,
      filterSelect,
      switchAble,
      visible,
      tooltip
    });
  }

  protected addAnalyzeDefinition(
    definition: object,
  ): void {
    this.analyzeItemDefinitions.push({
      key: definition[this.key],
      name: definition[this.text],
      type: definition[this.type],
      isStickyStart: false,
      isStickyEnd: false,
      switchAble: false,
      visible: true
    });
  }

  protected addReleatedPostingDefinitions(
    definition: object,
  ): void {
    this.releatedPostingDefinitions.push({
      key: definition[this.key],
      text: definition[this.text],
      type: definition[this.type],
      overlayText: definition[this.overlayText],
    });
  }


  protected addPostingOverlayDefinition(
    definition: object,
  ): void {
    this.postingOverlayDefinitions.push({
      key: definition[this.key],
      text: definition[this.text],
      type: definition[this.type],
      overlayText: definition[this.overlayText],
    });
  }


  protected addPostingExcelItemDefinition(
    definition: object,
    newLabelText: string = null,
    padZeros: number = null,
    isFunktion: boolean = false
  ): void {
    const isCurrency: boolean = definition[this.type] === 'currency' && 'inputCurrency' ? true : false;

    if (this.isLabelRedefined(newLabelText))
      definition[this.text] = newLabelText;

    this.postingExcelColumnItemDefinitions.push({
      valuePropertyName: definition[this.key],
      header: definition[this.text],
      isCurrency,
      padZeros,
      isFunktion
    });
  }

  protected addAnalysisExcelItemDefinition(
    definition: object,
    newLabelText: string = null,
    padZeros: number = null,
    isFunktion: boolean = false
  ): void {
    const isCurrency: boolean = definition[this.type] === 'currency' ? true : false;

    if (this.isLabelRedefined(newLabelText))
      definition[this.text] = newLabelText;

    this.analyzeExcelColumnItemDefinitions.push({
      valuePropertyName: definition[this.key],
      header: definition[this.text],
      isCurrency,
      padZeros,
      isFunktion
    });

  }
}
