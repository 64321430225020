<div class="postings-wrapper" [ngClass]="{
  'overlay-medium' : overlayState === 1 || overlayState === 2,
   'full-size' : !filterBarState || !isNotRoutePostingNotePostings }">
  <app-filter *ngIf="isNotRoutePostingNotePostings"></app-filter>
  <div class="top-bar">
    <div class="top-bar__left">
      <span class="fixer">fixer</span>
      <app-chosen-filters-bar></app-chosen-filters-bar>
    </div>
    <div class="top-bar__right">
      <app-posting-action-selector [postingFilter]="table.postingFilter" [isAllPostingsSelected]="table.CheckIfAllIsChecked()"
        [totalDisplayedPostings]="table.dataSource.data.length" (ExportPostingsEvent)="table.exportPostings()" [totalPostings]="table.totalPostings"
        [totalSelectPostings]="table.totalSelectPostings">
      </app-posting-action-selector>
    </div>
  </div>
  <app-table #table></app-table>
  <div *ngIf="isLoading" class="table-throbber">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="overlay-wrapper">
  <app-overlay [isAllPostingsSelected]="table.CheckIfAllIsChecked()" [totalPostings]="table.totalPostings"></app-overlay>
</div>
