<div class="login">
    <div class="login__bg-layer">
        <div class="login__content">
            <h2>
                <fa-icon [icon]="faUser"></fa-icon> &nbsp; Login
            </h2>
            <p>
                Klik på Login, for at komme til din respektive login side.
            </p>
            <!-- <mat-form-field>
                <mat-label>Brugernavn</mat-label>
                <input matInput type="text">
            </mat-form-field> -->

            <button mat-flat-button color="primary" (click)="login()">
                <fa-icon [icon]="faUnlock"></fa-icon> Login
            </button>
        </div>
        <div class="login__logo">
            <img src="../../../../../assets/images/bdo-logo.png" />
            <span>Moms Tjek 2.0</span>
        </div>

        <div class="login__kmd-logo">
            <img src="../../../../../assets/images/kmd-logo.svg" />
        </div>
    </div>
</div>