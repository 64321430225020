import { Injectable } from '@angular/core';
import { MessagesService } from '@modules/messages/services/messages.service';
import { ClientActions, DoAction, Message, SignalService } from 'src/app/api/vat';
import { BehaviorSubject } from 'rxjs';
import { NotifyService } from '@modules/shell/services/notify.service';
import { MessageHelper } from '@modules/messages/helpers/message-helper';

@Injectable({
  providedIn: 'root',
})
export class InternalActionsService {
  clientActions: ClientActions = null;
  public reloadFunctions: BehaviorSubject<DoAction> =
    new BehaviorSubject<DoAction>(null);
  public reloadAdmOrgs: BehaviorSubject<DoAction> =
    new BehaviorSubject<DoAction>(null);

  constructor(
    private signalService: SignalService,
    private messagesService: MessagesService,
    private notifyService: NotifyService
  ) {}

  handleClientAction(customerId: number, userId: number, action: DoAction) {
    if (this.clientActions === null) {
      this.signalService
        .getClientActionList()
        .subscribe((result: ClientActions) => {
          this.clientActions = result;
          this.setAction(customerId, userId, action);
        });
    } else this.setAction(customerId, userId, action);
  }

  private setAction(
    customerId: number,
    userId: number,
    action: DoAction
  ): void {
    switch (action.action) {
      case this.clientActions.reloadFunctionsHierarchy: {
        if (this.validate(action, customerId, userId)) {
          this.reloadFunctions.next(action);
        }
        break;
      }
      case this.clientActions.reloadAdmOrgsHierarchy: {
        if (this.validate(action, customerId, userId)) {
          this.reloadAdmOrgs.next(action);
        }
        break;
      }
      case this.clientActions.mainNewsAdded: {
        if (this.validateCustomerId(action, customerId)) {
          const note = action.parameters as Message;
          const publishedBy = MessageHelper.isPublishedBy(
            note.publishedBy,
            note.createdBy.userName
          );
          this.notifyService.notifySnackBarInformation(
            `Du har modtaget en nyhed ${publishedBy}`,
            'green'
          );
          this.messagesService.checkForNewMessages();
        }
        break;
      }
      case this.clientActions.sendMessage: {
        if (this.validateCustomerId(action, customerId)) {
          this.messagesService.checkForNewMessages();

          if (this.validateUserId(action, userId))
            this.notifyService.notifySnackBarInformation(
              'Du er blevet tilføjet til en posteringsnote',
              'green'
            );
        }
        break;
      }
      default: {
        console.error('unhandled signal from server');
        break;
      }
    }
  }

  validateCustomerId(action: DoAction, customerId: number): boolean {
    if (action.customerId?.indexOf(customerId) > -1) return true;
    return false;
  }

  validateUserId(action: DoAction, userId: number): boolean {
    if (action.userId.indexOf(userId) > -1) return true;
    return false;
  }

  validate(action: DoAction, customerId: number, userId: number): boolean {
    if (
      this.validateCustomerId(action, customerId) &&
      this.validateUserId(action, userId)
    )
      return true;
    return false;
  }
}
