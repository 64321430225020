import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterBarService } from '@modules/filter/services/filter-bar.service';
import { Router } from '@angular/router';

import { Analysis, AnalysisFilter, AnalysisService } from 'src/app/api/vat';

import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { Column } from 'src/app/global-models';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import config from 'src/assets/config.json';
import { LoadingTemplateFactoryService } from '@modules/commons/services/loading-template-factory.service';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import { AnalyzeHelperService } from '@modules/analyzes/services/analyze-helper.service';

@Component({
  selector: 'app-analyze',
  templateUrl: './analyze.component.html',
  styleUrls: ['./analyze.component.scss'],
})
export class AnalyzeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() columns: Column[];
  @Output() columnsNames = new EventEmitter<string[]>();

  filterBarState: boolean = false;
  columnKeys: string[] = [];
  analysisFilter: AnalysisFilter;
  globalAnalysisId: number = config.GlobalAnalysisId;

  subscriptions: Subscription[] = [];

  dataSource: MatTableDataSource<Analysis> = new MatTableDataSource<Analysis>();
  @Input() view;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private filterBarService: FilterBarService,
    private router: Router,
    private analysisService: AnalysisService,
    private internalFilterService: InternalFilterService,
    private loadingTemplateFactoryService: LoadingTemplateFactoryService,
    private labelGlossaryService: LabelGlossaryService,
    private analyzeHelperService: AnalyzeHelperService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.labelGlossaryService.analyzeColumnItemDefinition$.subscribe(
        (analyzeColumnItemDefinition: Column[]) => {
          this.columnKeys = analyzeColumnItemDefinition.map((s) => s.key);
        }
      )
    );
    this.load();
  }

  private load() {
    this.subscriptions.push(
      this.internalFilterService.analysisFilter
        .pipe(
          filter((af) => af.periods !== null),
          debounceTime(2000)
        )
        .subscribe(
          (analysisFilter: AnalysisFilter) => {
            this.dataSource.data =
              this.loadingTemplateFactoryService.createCardAnalyzesTemplate(
                new Array(30)
              );
            this.analysisFilter = analysisFilter;
            this.subscriptions.push(
              this.analysisService
                .getByFilter(analysisFilter)
                .subscribe((analysis: Analysis[]) => {
                  this.dataSource.data = analysis;
                })
            );
          },
          (error: Error) => {}
        )
    );

    this.subscriptions.push(
      this.filterBarService.filterBarState.subscribe((value) => {
        this.filterBarState = value;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.columnsNames.emit(this.columns.map((s) => s.name));
  }

  navigateToPosting(row: Analysis) {
    this.analyzeHelperService.addAnalyzeToLatestLooked(row.analysisId);
    this.internalFilterService.updatePostingFilterFromAnalysisFilter(
      row.analysisId
    );
    this.router.navigate(['posteringer/', row.analysisId]);
  }
}
