import { Component, OnInit } from '@angular/core';
import { faUnlock, faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // Icons
  faUnlock = faUnlock;
  faUser = faUser;
  faKey = faKey;

  constructor(private oidcSecurityService: OidcSecurityService) {}

  ngOnInit() {}

  login() {
    this.oidcSecurityService.authorize();
  }
}
