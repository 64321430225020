import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  public sidebarState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor() {}

  toggleSidebarState() {
    this.sidebarState.next(!this.sidebarState.value);
  }
  toggleSidebarStateTrue() {
    this.sidebarState.next(true);
  }
  toggleSidebarStateFalse() {
    this.sidebarState.next(false);
  }
}
