<div class="messages">

  <div *ngFor="let item of messageColletion" [ngClass]="{
        'message' : true,
        'news' : isPostingNote(item) === false,
        'note' : isPostingNote(item),
        'message__right' : item.owner
    }">
    <div *ngIf="isPostingNote(item)" class="message__icon">
      <fa-icon [icon]="faComment"></fa-icon>
    </div>
    <div *ngIf="isPostingNote(item) === false" class="message__icon">
      <fa-icon [icon]="faNewspaper"></fa-icon>
    </div>

    <div class="message__header">
      ID{{item.postingNoteId}} - {{item.subject}}
    </div>
    <div
      *ngIf="((item.noteForMe === true && item.readDate === null) || (this.isPostingNote(item) === false && item.readDate === null)); then close else reopen">
    </div>
    <ng-template #close>
      <div class="message__close">
        <button mat-icon-button aria-label="Luk besked" (click)="closeMessage(item)">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
    </ng-template>
    <ng-template #reopen>
      <div class="message__reopen" [ngStyle]="{'right': place === 'overlay' ? '5px' : '28px'}"
        style="position: absolute; top:0px;">
        <button mat-icon-button (click)="reopenMessage(item)" matTooltip="Tilføj til infocenter igen">
          <fa-icon [icon]="faArrowCircleRight"></fa-icon>
        </button>
      </div>
    </ng-template>

    <div class="message__time-author">
      <app-message-time-author [message]="item"></app-message-time-author>
    </div>

    <div class="message__text">
      {{item.text}}
    </div>

    <div class="message__buttons">
      <button mat-icon-button (click)="openExpandedMessageDialog(item)" matTooltip="Se mere" aria-label="">
        <fa-icon [icon]="faEye"></fa-icon>
      </button>
      <button mat-icon-button class="go-to-btn" *ngIf="isPostingNote(item)"
        [routerLink]="'/posteringer/postingnote/'+item.postingNoteId" (click)="goToPosting(item)"
        matTooltip="Gå til postering" aria-label="">
        <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
      </button>
      <button mat-icon-button *ngIf="permissionToEdit(item)" (click)="editNote(item)" class="message__edit"
        matTooltip="Rediger" aria-label="">
        <fa-icon [icon]="faPen"></fa-icon>
      </button>
      <button mat-icon-button *ngIf="permissionToEdit(item)" (click)="openDeleteConfirmation(item)"
        class="message__delete" matTooltip="Slet" aria-label="">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
      <button mat-icon-button (click)="eksportNote(item)" *ngIf="mailEnabled" class="message__export"
        matTooltip="Eksport med mail" aria-label="">
        <fa-icon [icon]="faEnvelope"></fa-icon>
      </button>
      <div class="related-info" *ngIf="item.postingReferencesCount > 1">{{item.postingReferencesCount}} relationer</div>
    </div>
    <div class="message__extra-hover-text" *ngIf="extraHoverText">
      <fa-icon [icon]="faExclamationCircle"></fa-icon> Der er flere noter til denne postering, se dem i overlayet.
    </div>
  </div>
</div>