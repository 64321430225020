import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';
import { PostingFilter } from 'src/app/api/vat';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { AnalyzeHelperService } from '@modules/analyzes/services/analyze-helper.service';
import configJson from 'src/assets/config.json';

export interface PeriodicElement {
  analysisId: number;
  title: string;
  processedState: number;
  realisedPotential: number;
  remainingPotential: number;
  date: string;
  year: number;
}

@Component({
  selector: 'app-completed-analyzes',
  templateUrl: './completed-analyzes.component.html',
  styleUrls: ['./completed-analyzes.component.scss'],
})
export class CompletedAnalyzesComponent implements OnInit {
  // Icons
  faTimes = faTimes;

  displayedColumns: string[] = [
    'title',
    'processedState',
    'realisedPotential',
    'remainingPotential',
    'date',
    'year',
  ];
  dataSource = this.data.slice(0, -1);
  totalRow: PeriodicElement = this.data[this.dataSource.length];



  constructor(
    public dialogRef: MatDialogRef<CompletedAnalyzesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PeriodicElement[],
    private router: Router,
    private internalFilterService: InternalFilterService,
    private analyzeHelperService: AnalyzeHelperService
  ) {
  }

  ngOnInit() {
  }

  navigateToAnalysis(row: any): void {
    const postingFilter: PostingFilter = {
      sortBy: {
        propertyName: null,
        ascending: false,
      },
      pageModel: {
        skip: 0,
        take: configJson.PageSizeBlock,
      },
      analysisId: row.analysisId,
      periods: [{
        financialYear: row.year.toString(),
        fromDate: this.internalFilterService.eraseTimeZoneOffSet(new Date(row.year, 0, 1)),
        toDate: this.internalFilterService.eraseTimeZoneOffSet(new Date(row.year, 11, 31)),
      }]
    };

    this.internalFilterService.updatePostingFilters(
      postingFilter
    );
    this.analyzeHelperService.addAnalyzeToLatestLooked(row.analysisId);
    this.router.navigate(['posteringer/', row.analysisId]);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
