import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Custom Modules
import { MessagesModule } from '@modules/messages/messages.module';
import { CommonsModule } from '../commons/commons.module';

// Components
import { ExpandedMessageComponent } from './components/expanded-message/expanded-message.component';
import { AllMessagesComponent } from './components/all-messages/all-messages.component';
import { CompletedAnalyzesComponent } from './components/completed-analyzes/completed-analyzes.component';
import { SelectAnalyzeComponent } from './components/select-analyze/select-analyze.component';
import { CreateRuleComponent } from './components/create-rule/create-rule.component';
import { ExportMessageComponent } from './components/export-message/export-message.component';
import { AreYouSureDialogComponent } from './components/are-you-sure-dialog/are-you-sure-dialog.component';
import { ManualsDialogComponent } from './components/manuals-dialog/manuals-dialog.component';
import { PostingColumnListComponent } from './components/posting-column-list/posting-column-list.component';

@NgModule({
  declarations: [
    ExpandedMessageComponent,
    AllMessagesComponent,
    CompletedAnalyzesComponent,
    SelectAnalyzeComponent,
    CreateRuleComponent,
    ExportMessageComponent,
    AreYouSureDialogComponent,
    ManualsDialogComponent,
    PostingColumnListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    RouterModule,
    MessagesModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatListModule,
    MatTabsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    CommonsModule,
    MatTooltipModule,
    DragDropModule
  ],
  exports: [CompletedAnalyzesComponent],
})
export class DialogModule { }
