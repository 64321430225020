import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseSpace'
})
export class UppercaseSpacePipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    if (args[ 0 ] === "status") {
      return value.replace(/([A-Z])/g, ' $1').trim()
        .toLocaleLowerCase()
        .replace(/^\w/, c => c.toUpperCase());
    } else {
      return value;
    }
  }
}
