import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faStar, fas } from '@fortawesome/free-solid-svg-icons';
import { FavoriteService } from '@modules/favorites/services/favorite.service';
import { Analysis } from 'src/app/api/vat';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FavoriteDetail } from 'src/app/global-models';

@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss'],
})
export class FavoriteListComponent implements OnInit, OnDestroy {
  // Icons
  faChevronDown = faChevronDown;
  faStar = faStar;

  isExpanded: boolean = false;

  favoriteAnalysis: Analysis[];
  favoriteDetails: FavoriteDetail[];
  subscriptions: Subscription[] = [];

  amountOfFavorites: number;

  constructor(
    private router: Router,
    private favoriteService: FavoriteService,
    library: FaIconLibrary
  ) {
    library.addIconPacks(fas, far);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.favoriteService.favoriteAnalysisDetail
        .pipe(filter((favorites) => favorites !== undefined))
        .subscribe((favorites: FavoriteDetail[]) => {
          this.favoriteDetails = favorites;
          this.amountOfFavorites = favorites.length;
        })
    );

    this.subscriptions.push(
      this.favoriteService.favoriteAnalysis.subscribe(
        (favoriteAnalysis: Analysis[]) => {
          this.favoriteAnalysis = favoriteAnalysis;
        }
      )
    );
  }

  expandFavoriteList(): void {
    this.isExpanded = !this.isExpanded;
  }

  navigateToPostings(analysisId: number): void {
    this.router.navigate(['posteringer/', analysisId]);
  }

  toggleFavorit(analysisId: number): void {
    this.favoriteService.toggleFavorite(analysisId);
  }

  checkIfFavorite(analysisId: number): boolean {
    return this.favoriteService.checkIfFavorite(
      this.favoriteDetails,
      analysisId
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
