import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private loadingSource = new BehaviorSubject<boolean>(false);
  loading = this.loadingSource.asObservable();
  private count = 0;
  private _isSpinnerEnabeled: boolean = true;

  private smallSpinner: Subject<boolean> = new Subject<boolean>();
  public SmallSpinner$: Observable<boolean> = this.smallSpinner.asObservable();

  constructor() {}

  get isSpinnerEnabeled(): boolean {
    return this._isSpinnerEnabeled;
  }

  public pauseSpinner() {
    this._isSpinnerEnabeled = false;
    this.smallSpinner.next(this._isSpinnerEnabeled);
  }

  public enableSpinner() {
    this._isSpinnerEnabeled = true;
    this.smallSpinner.next(this._isSpinnerEnabeled);
  }

  addSpinner() {
    this.count++;
    this.loadingSource.next(true);
  }

  removeSpinner() {
    this.count--;
    if (this.count <= 0) {
      this.loadingSource.next(false);
      this.count = 0;
    }
  }
}
