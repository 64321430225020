import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'app-datepicker-custom-header',
  templateUrl: './datepicker-custom-header.component.html',
  styleUrls: ['./datepicker-custom-header.component.scss'],
})
export class DatepickerCustomHeaderComponent<D> {
  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>
  ) {}

  get periodLabel() {
    return this.dateAdapter
      .format(this.calendar.activeDate, 'MMM')
      .toLocaleUpperCase();
  }
}
