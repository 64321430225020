import { Injectable } from '@angular/core';
import { CommonHelper } from '@modules/commons/helpers/common-helpers';
import { NotifyService } from '@modules/shell/services/notify.service';
import { PostingService } from 'src/app/api/vat';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class YearSelectService {
  private globalYear: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public globalYear$: Observable<number> = this.globalYear
    .asObservable()
    .pipe(filter(year => year !== null));

  public years: number[] = [];

  defaultYear: number;

  public isResetButtonVisible: Subject<boolean> = new Subject<boolean>();

  constructor(
    private postingService: PostingService,
    private notifyService: NotifyService) {
    this.initService();
  }

  private initService(): void {
    this.setResetButton(false);
    this.years = CommonHelper.generateYears();
    this.postingService.getLatestPostingFinancialYear()
      .subscribe((year: string) => {
        if (year === null) {
          this.notifyService.notifySnackBarInformation('Der eksisterer ingen data, for den valgte kunde.', 'yellow');
        } else {
          if (!this.years.includes(parseInt(year, 0)))
            this.years.unshift(parseInt(year, 0));

          this.defaultYear = parseInt(year, 0);
          this.globalYear.next(parseInt(year, 0));
        }
      });
  }

  public setGlobalYear(year: number): void {
    if (this.globalYear.value !== year) {
      this.setResetButton(true);
      this.globalYear.next(year);
    }
  }

  public resetAllYearSelection(): void {
    this.setResetButton(false);
    this.globalYear.next(this.defaultYear);
  }

  public setResetButton(visible: boolean): void {
    this.isResetButtonVisible.next(visible);
  }
}
