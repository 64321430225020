import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ExcelColumnItem,
  Message,
  MessageService,
  PostingService,
  User,
  VATMail,
} from 'src/app/api/vat';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@modules/shell/components/snackbar/snackbar.component';
import { InternalUserService } from '@modules/commons/services/internal-user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { readSync } from 'fs';

@Component({
  selector: 'app-export-message',
  templateUrl: './export-message.component.html',
  styleUrls: ['./export-message.component.scss'],
})
export class ExportMessageComponent implements OnInit {
  // Icons
  faTimes = faTimes;

  subject: string = 'Besked eksporteret fra BDO Moms Tjek';
  body: string;
  visible = true;
  selectable = true;
  removable = true;
  personCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredPersons: User[] = [];
  persons: User[] = [];
  allPersons: User[] = [];
  checked: boolean = false;
  currentUser: User;
  currentCustomer: string;
  additionalEmails: string[] = [];
  emailForm: FormGroup;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]/; //+\.[dD][kK]/;

  @ViewChild('personInput') personInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<ExportMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public message: Message,
    private internalCurrentCustomerService: InternalCurrentCustomerService,
    private postingService: PostingService,
    private messageService: MessageService,
    private labelGlossaryService: LabelGlossaryService,
    private internalUserService: InternalUserService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: [Validators.required, Validators.email],
    });

    this.loadUsers();
    this.internalCurrentCustomerService.customer.subscribe((customer) => {
      this.currentCustomer = customer.group.toLowerCase().split('-')[2] + '.dk';
    });
    this.internalUserService.user$.subscribe((user) => {
      this.currentUser = user;
      const lb = '\r\n';
      this.body = `ID${this.message.postingNoteId} - ${this.message.subject} ${lb}${lb}${this.message.text} ${lb}${lb}Denne e-mail er sendt med BDO Moms Tjek${lb}${lb}Venlig hilsen${lb}${user.userName}`;
    });
  }

  private loadUsers() {
    const customerid: number =
      +this.internalCurrentCustomerService.getCustomerId();
    this.postingService
      .getUsersForPostingNote(customerid)
      .subscribe((result) => {
        this.allPersons = result;
        this.filteredPersons = result;
        this.message.mentionUserIds.forEach((o) => {
          this.persons.push(
            this.allPersons.filter((u) => u.userId === o).pop()
          );
          this.allPersons = this.allPersons.filter((u) => u.userId !== o);
        });
      });
  }

  close() {
    this.dialogRef.close();
  }

  sendMessage() {
    const excelItemColumns: ExcelColumnItem[] =
      this.labelGlossaryService.getExcelColumns();
    const bodyExport: VATMail = {
      columns: excelItemColumns,
      subject: this.subject,
      body:
        `<div style="font-size: 16px; line-height: 16px;">` +
        this.body.replace(/\n/g, '<br/>') +
        `</div>`,
      userIds: this.persons.map((o) => o.userId),
      recipients: this.additionalEmails,
    };

    this.messageService
      .sendMail(this.message.postingNoteId, bodyExport)
      .subscribe((result) => {
        if (result) this.close();
        if (result == false) this.wrongDomainMessage();
      });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our person
    if ((value || '').trim() && this.emailPattern.test(value)) {
      this.additionalEmails.push(value);
    } else {
      this.invalidEmailMessage(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.personCtrl.setValue(null);
  }

  invalidEmailMessage(user) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 10000,
      panelClass: ['snackbar', 'red'],
      data: {
        message: `Ugyldig e-mail: ${user}`,
        action: 'Ok',
      },
    });
  }
  wrongDomainMessage() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 10000,
      panelClass: ['snackbar', 'red'],
      data: {
        message: `Moms Tjek kan ikke sende mails til andre domæner end eget domæne, f.eks. navn@kunde.dk. 
        Ved behov for yderligere e-mail domæner, bedes du kontakte os.`,
        action: 'Ok',
      },
    });
  }
  recipientsAreChosen(): boolean {
    return this.persons.length + this.additionalEmails.length > 0;
  }
  removeUser(person: User): void {
    const index = this.persons.indexOf(person);

    if (index >= 0) {
      this.persons.splice(index, 1);
    }
    this.filteredPersons = this.allPersons.filter(
      (o) => !this.persons.includes(o)
    );
  }
  removeEmail(additionalEmail: string): void {
    const index = this.additionalEmails.indexOf(additionalEmail);

    if (index >= 0) {
      this.additionalEmails.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const user: User = this.allPersons.find(
      (x) => x.userId === event.option.value
    );
    this.persons.push(user);
    this.personInput.nativeElement.value = '';
    this.filteredPersons = this.allPersons.filter(
      (o) => !this.persons.includes(o)
    );
    this.personCtrl.setValue(null);
  }
}
