<div class="snackbar-content">
    <button mat-icon-button class="snackbar-content__close" (click)="dismiss()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <div class="snackbar-content__text">
        <fa-icon class="icon high" [icon]="faExclamationTriangle"></fa-icon>
        <fa-icon class="icon medium" [icon]="faExclamationCircle"></fa-icon>
        <fa-icon class="icon low" [icon]="faCheck"></fa-icon>
        <div>
            <p>{{data.message}}</p>
            <br />
            <p>{{data.error}}</p>
        </div>
    </div>
    <button class="snackbar-content__button" (click)="action()" mat-stroked-button color="primary">
        {{data.action}}
    </button>
</div>