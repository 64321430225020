import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  faUser,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { FilterBarService } from '@modules/filter/services/filter-bar.service';
import {
  AnalysisFilter,
  PostingFilter,
  Period,
  PrimaryFilter,
} from 'src/app/api/vat';
import { FilterDefinition } from '@modules/filter/models/filterDefinition';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FilterDataService } from '@modules/filter/services/filter-data.service';
import { ComponentFilterType } from '@modules/filter/classes/component-filter-types';
import { LabelGlossaryService } from '@modules/label-glossary/services/label-glossary.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  private filtersData: BehaviorSubject<PrimaryFilter>;
  filterDefinitions: FilterDefinition[];
  subscriptions: Subscription[] = [];

  public componentFilterType = ComponentFilterType;
  public currentSelectedFiltersStream: Observable<
    AnalysisFilter | PostingFilter
  >;
  public filtersDataStream$: Observable<PrimaryFilter>;
  private analysis: string = 'analyser';
  // icons
  faUser = faUser;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  noChanges: boolean = true;
  filterBarState: boolean = true;
  filterBarExpanded: boolean = false;

  isAnalysisSite: boolean = true;
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;

  constructor(
    private filterBarService: FilterBarService,
    private internalFilterService: InternalFilterService,
    private route: ActivatedRoute,
    private labelGlossaryService: LabelGlossaryService,
    private filterDataService: FilterDataService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.filtersData.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterBarService.filterBarState.subscribe((state) => {
        this.filterBarState = state;
      })
    );
    this.internalFilterService.sideFilterChanged$.subscribe((changes) => {
      if (changes) this.noChanges = false;
      else this.noChanges = true;
    });

    const routePath = this.route.snapshot.routeConfig.path;
    this.loadFilters(routePath);
  }

  private loadFilters(routePath: string): void {
    this.filterDefinitions =
      routePath === this.analysis
        ? this.labelGlossaryService.getAnalysisFilterDefinitions()
        : this.labelGlossaryService.getPostingFilterDefinitions();

    this.subscriptions.push(
      this.filterDataService.primaryFilter
        .pipe(filter((p) => p.periods !== null))
        .subscribe((primaryFilter: PrimaryFilter) => {
          if (
            this.filtersData === undefined &&
            this.filtersDataStream$ === undefined
          ) {
            this.filtersData = new BehaviorSubject<PrimaryFilter>(
              primaryFilter
            );
            this.filtersDataStream$ = this.filtersData.asObservable();

            if (routePath === this.analysis) {
              this.subscriptions.push(
                this.internalFilterService.analysisFilter
                  .pipe(take(1))
                  .subscribe((analysisFilter: AnalysisFilter) => {
                    const periods =
                      analysisFilter.periods === null
                        ? primaryFilter.periods
                        : analysisFilter.periods;
                    if (
                      !this.isPeriodsEqual(
                        analysisFilter.periods,
                        primaryFilter.periods
                      )
                    ) {
                      this.internalFilterService.updatePeriodFilter(periods);
                    }
                  })
              );
            }
          } else {
            this.filtersData.next(primaryFilter);
          }
        })
    );

    if (routePath === this.analysis) {
      this.currentSelectedFiltersStream =
        this.internalFilterService.analysisFilter;

      this.subscriptions.push(
        this.internalFilterService.analysisFilter.subscribe(
          (analysisFilter: AnalysisFilter) => {
            this.expandFilterIfDataIsSelected(analysisFilter);
          }
        )
      );
    } else {
      this.currentSelectedFiltersStream =
        this.internalFilterService.postingFilter;

      this.subscriptions.push(
        this.internalFilterService.postingFilter.subscribe(
          (postingFilter: PostingFilter) => {
            this.expandFilterIfDataIsSelected(postingFilter);
          }
        )
      );
    }
  }
  searchOnFilter() {
    this.internalFilterService.searchOnSideFilter();
  }
  private isPeriodsEqual(
    firstPeriods: Period[],
    secondPeriods: Period[]
  ): boolean {
    if (
      firstPeriods?.map((s) => s.fromDate).sort() ===
      secondPeriods?.map((p) => p.fromDate).sort()
    )
      return true;
    if (
      firstPeriods?.map((s) => s.toDate).sort() ===
      secondPeriods?.map((p) => p.toDate).sort()
    )
      return true;
    if (
      firstPeriods?.map((s) => s.financialYear).sort() ===
      secondPeriods?.map((p) => p.financialYear).sort()
    )
      return true;

    return false;
  }

  private expandFilterIfDataIsSelected(filter): void {
    this.filterDefinitions.forEach((fd) => {
      if (filter[fd.filterType] !== undefined) {
        if (
          filter[fd.filterType] !== null &&
          filter[fd.filterType].length > 0
        ) {
          fd.expanded = true;
        }
      }
    });
  }

  public expandFilterbar() {
    this.filterBarExpanded = !this.filterBarExpanded;
  }

  public toggleFilterBar(): void {
    this.filterBarService.toggleFilterBar();
    this.filterBarExpanded = false;
  }
}
