import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FavoriteService } from '@modules/favorites/services/favorite.service';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';

import { Analysis, AnalysisFilter, PostingFilter, PostingService } from 'src/app/api/vat';

import { Subscription } from 'rxjs';
import { FavoriteDetail } from 'src/app/global-models';
import config from 'src/assets/config.json';
import { AnalyzeHelperService } from '@modules/analyzes/services/analyze-helper.service';

@Component({
  selector: 'app-analyze-card',
  templateUrl: './analyze-card.component.html',
  styleUrls: ['./analyze-card.component.scss'],
})
export class AnalyzeCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() analyze: Analysis;
  @Input() analysisFilter: AnalysisFilter;
  favoriteDetail: FavoriteDetail[];
  subscriptions: Subscription[] = [];
  financialYears: string | number[];
  routePath: string;
  id: number;
  globalAnalysisId: number = config.GlobalAnalysisId;
  private isCardLoading: boolean = true;

  public contentLoaded = (title: string) => {
    this.isCardLoading = title === null;
    return title !== null;
  };

  constructor(
    private favoriteService: FavoriteService,
    private router: Router,
    private internalFilterService: InternalFilterService,
    private route: ActivatedRoute,
    private postingService: PostingService,
    private analyzeHelperService: AnalyzeHelperService,
    library: FaIconLibrary
  ) {
    library.addIconPacks(fas, far);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('analysisFilter' in changes) {
      this.dashSeparator(this.analyze.financialYears);
    }
  }

  ngOnInit(): void {
    this.routePath = this.route.snapshot.routeConfig.path;
    this.subscriptions.push(
      this.favoriteService.favoriteAnalysisDetail.subscribe(
        (favorites: FavoriteDetail[]) => {
          this.favoriteDetail = favorites;
          this.id = this.analyze.analysisId;
          this.dashSeparator(this.analyze.financialYears);
        }
      )
    );
  }

  public dashSeparator(values: number[]): void {
    if (this.routePath === 'dashboard') {
      this.financialYears = this.addCommaAndSpace(values);
    } else {
      if (
        this.internalFilterService.isPeriodsRangeSelected(
          this.analysisFilter.periods
        )
      ) {
        let textResult: string = '';
        values?.forEach((value, index) => {
          textResult += value?.toString();

          if (index + 1 !== values.length) {
            textResult += ' - ';
          }
        });
        this.financialYears = textResult;
      } else {
        this.financialYears = this.addCommaAndSpace(values);
      }
    }
  }

  addCommaAndSpace(values: number[]): string {
    let textResult: string = '';
    values?.forEach((v, index) => {
      if (v !== null) {
        textResult += v.toString();
        if (index + 1 !== values.length) {
          textResult += ', ';
        }
      }
    });
    return textResult;
  }

  public navigateToPosting(row: Analysis): void {
    if (this.isCardLoading) return;

    this.analyzeHelperService.addAnalyzeToLatestLooked(row.analysisId);

    // We need this, to get the right postingFilter from Backend.
    // When we click on latest or Favorites.
    if (this.routePath === 'dashboard') {
      this.router.navigate(['posteringer/', row.analysisId]).then(() => {
        this.subscriptions.push(
          this.postingService
            .getPostingFilterByAnalysisId(row.analysisId)
            .subscribe((postingFilter: PostingFilter) => {
              postingFilter.pageModel.take = config.PageSizeBlock;
              this.internalFilterService.updatePostingFilters(postingFilter);
            })
        );
      });
    } else {
      this.router.navigate(['posteringer/', row.analysisId]).then(() => {
        this.internalFilterService.updatePostingFilterFromAnalysisFilter(
          row.analysisId
        );
      });
    }
  }

  toggleFavorit(analysisId: number): void {
    this.favoriteService.toggleFavorite(analysisId);
  }

  checkIfFavorite(analysisId: number): boolean {
    return this.favoriteService.checkIfFavorite(
      this.favoriteDetail,
      analysisId
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
