import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageItem } from '../models/LocalStorageItem';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorageSource: BehaviorSubject<Array<LocalStorageItem>> =
    new BehaviorSubject([]);
  public readonly localstorage: Observable<Array<LocalStorageItem>> =
    this.localStorageSource.asObservable();

  constructor() {
    const keys = Object.keys(localStorage);
    const items: Array<LocalStorageItem> = [];
    keys.forEach((key) => {
      const value = localStorage.getItem(key);
      const newLocalStorageItem = new LocalStorageItem();
      newLocalStorageItem.key = key;
      newLocalStorageItem.value = value;
      items.push(newLocalStorageItem);
    });
    this.localStorageSource.next(items);
  }

  addOrUpdateObject(key: string, value: object) {
    this.addOrUpdateItem(key, JSON.stringify(value));
  }

  addOrUpdateItem(key: string, value: string) {
    localStorage.setItem(key, value);
    const values = this.localStorageSource.value;
    const newLocalStorageItem = new LocalStorageItem();
    newLocalStorageItem.key = key;
    newLocalStorageItem.value = value;
    if (
      this.localStorageSource.value.find((x) => x.key === key) === undefined
    ) {
      values.push(newLocalStorageItem);
    } else {
      const index = this.localStorageSource.value.findIndex(
        (x) => x.key === key
      );
      values[index] = newLocalStorageItem;
    }
    this.localStorageSource.next(values);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
    const values = this.localStorageSource.value;
    const newValues = values.filter((x) => x.key !== key);
    this.localStorageSource.next(newValues);
  }

  getObject<T>(key: string): T {
    const str = this.getItem(key)?.value;
    return str ? JSON.parse(str) : undefined;
  }

  getItem(key: string): LocalStorageItem {
    return this.localStorageSource.value.find((x) => x.key === key);
  }
}
