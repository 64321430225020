import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-manuals',
  templateUrl: './manuals.component.html',
  styleUrls: ['./manuals.component.scss'],
})
export class ManualsComponent implements OnInit, AfterViewInit {
  chosenAnalysis: string;

  @ViewChildren('analyseTitle') content: QueryList<any>;
  kommunePanelState: boolean = false;
  regionPanelState: boolean = false;
  guidancePanelState: boolean = false;
  instructionsPanelState: boolean = false;

  constructor(public myElement: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.content
        .toArray()
        .map((f) => f.nativeElement)
        .forEach((f) => {
          if (f.innerHTML === this.chosenAnalysis) {
            var headerOffset = 80;
            var elementPosition = f.getBoundingClientRect().top;
            var offsetPosition =
              elementPosition + window.pageYOffset - headerOffset;
          }
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        });
      sessionStorage.setItem('analysisTitle', '');
    }, 200);
  }

  ngOnInit(): void {
    this.chosenAnalysis = sessionStorage.getItem('analysisTitle');
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
