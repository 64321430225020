<mat-form-field>
  <mat-label>Søg her...</mat-label>
  <input matInput [(ngModel)]="searchWord" (keydown.enter)="AddSearchWordToSelectedNodes()" type='text' >
</mat-form-field>
<mat-list role="list" class="list">

  <mat-list-item role="listitem" *ngFor="let selectedNode of selectedNodes">
      <div class="list__header" matTooltip="{{ selectedNode }}">
        {{ selectedNode }}
      </div>
    <div class="list__action">
      <button mat-icon-button>
        <fa-icon (click)="deleteSelectedNode(selectedNode)" [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </mat-list-item>
</mat-list>
