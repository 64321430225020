import { Injectable, OnDestroy } from '@angular/core';
import { LoadingTemplateFactoryService } from '@modules/commons/services/loading-template-factory.service';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { LocalStorageService } from '@modules/store/services/local-storage.service';
import storeKeys from '@modules/store/localStorageKeys.json';
import { Analysis, AnalysisFilter, AnalysisService } from 'src/app/api/vat';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FavoriteDetail } from 'src/app/global-models';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService implements OnDestroy {
  private favoriteAnalysisDetailSource: BehaviorSubject<FavoriteDetail[]> =
    new BehaviorSubject<FavoriteDetail[]>(null);
  private favoriteAnalysisSource: BehaviorSubject<Analysis[]> =
    new BehaviorSubject<Analysis[]>([]);

  public favoriteAnalysisDetail: Observable<FavoriteDetail[]> =
    this.favoriteAnalysisDetailSource.asObservable();
  public favoriteAnalysis: Observable<Analysis[]> =
    this.favoriteAnalysisSource.asObservable();

  private analysisFilter: AnalysisFilter;
  private subscriptions: Subscription[] = [];

  constructor(
    private analysisService: AnalysisService,
    private internalFilterService: InternalFilterService,
    private localStorageService: LocalStorageService,
    private internalCurrentCustomerService: InternalCurrentCustomerService,
    private loadingTemplateFactoryService: LoadingTemplateFactoryService
  ) {
    this.init();
  }

  private init() {
    this.subscriptions.push(
      this.internalFilterService.analysisFilter.subscribe(
        (analysisFilter) => (this.analysisFilter = analysisFilter)
      )
    );

    this.internalCurrentCustomerService.customer.subscribe((customer) => {
      let favoriteDetails =
        this.localStorageService.getObject<FavoriteDetail[]>(
          storeKeys.FavoriteAnalysisStorageKey
        ) ?? [];

      if (this.isFavoriteAnalysisDetailsNotEmpty(favoriteDetails)) {
        favoriteDetails = favoriteDetails.filter(
          (f) => f.customerId === customer.customerId.toString()
        );
        const tempAnalysis =
          this.loadingTemplateFactoryService.createCardAnalyzesTemplate(
            favoriteDetails.map((k) => k.analysisId)
          );
        this.favoriteAnalysisSource.next(tempAnalysis);
      }

      this.favoriteAnalysisDetailSource.next(favoriteDetails);
    });
    this.setAnalysisInformation();
  }

  private setAnalysisInformation() {
    this.subscriptions.push(
      this.favoriteAnalysisDetail
        .pipe(filter((s) => s !== null))
        .subscribe((favoriteAnalysisDetails: FavoriteDetail[]) => {
          if (this.isFavoriteAnalysisDetailsNotEmpty(favoriteAnalysisDetails)) {
            const favoritsAnalysisIds = favoriteAnalysisDetails.map(
              (f) => f.analysisId
            );
            this.subscriptions.push(
              this.analysisService
                .getByIds(favoritsAnalysisIds)
                .subscribe((result: Analysis[]) => {
                  let correctResult: Analysis[] = [];
                  result.forEach((analysis) => {
                    if (
                      favoritsAnalysisIds.includes(analysis.analysisId) &&
                      correctResult
                        .map((el) => el.analysisId)
                        .indexOf(analysis.analysisId) == -1
                    )
                      correctResult.push(analysis);
                  });
                  this.favoriteAnalysisSource.next(correctResult);
                })
            );
          } else {
            this.favoriteAnalysisSource.next([]);
          }
        })
    );
  }

  private isFavoriteAnalysisDetailsNotEmpty = (
    favoriteAnalysisDetails: FavoriteDetail[]
  ) =>
    favoriteAnalysisDetails !== undefined && favoriteAnalysisDetails.length > 0;

  toggleFavorite(analysisId: number): void {
    const favoriteDetails = this.favoriteAnalysisDetailSource.getValue();

    const existsIndex = favoriteDetails?.findIndex(
      (f) => f.analysisId === analysisId
    );
    if (existsIndex > -1) {
      favoriteDetails.splice(existsIndex, 1);
    } else {
      if (this.analysisFilter.periods === null) {
        this.analysisFilter.periods = [];
      }
      this.subscriptions.push(
        this.analysisService
          .saveFilter(analysisId, this.analysisFilter)
          .subscribe(() => {})
      );
      favoriteDetails.unshift({
        analysisId,
        customerId: this.internalCurrentCustomerService.getCustomerId(),
      } as FavoriteDetail);
    }

    this.favoriteAnalysisDetailSource.next(favoriteDetails);
    this.persistToLocalStorage();
  }

  private persistToLocalStorage(): void {
    this.localStorageService.addOrUpdateObject(
      storeKeys.FavoriteAnalysisStorageKey,
      this.favoriteAnalysisDetailSource.getValue()
    );
  }

  public checkIfFavorite(
    favoriteDetail: FavoriteDetail[],
    analysisId: number
  ): boolean {
    return (
      favoriteDetail !== undefined &&
      favoriteDetail.map((s) => s.analysisId).includes(analysisId)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
