import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faTimes, faList, faUser, faExpand, faCompressAlt } from '@fortawesome/free-solid-svg-icons';
import { YearSelectService } from '@modules/leader-dashboard/services/year-select.service';
import { Chart, registerables } from 'chart.js';
import { CardDiagramData } from '../leader-dashboard/leader-dashboard.component';

@Component({
  selector: 'app-card-diagram',
  templateUrl: './card-diagram.component.html',
  styleUrls: ['./card-diagram.component.scss'],
})
export class CardDiagramComponent implements OnInit, AfterViewInit, OnChanges {
  // Icons
  faTimes = faTimes;
  faList = faList;
  faUser = faUser;
  faExpand = faExpand;
  faCompressAlt = faCompressAlt;

  cardExpanded: boolean = false;

  myChart: Chart;
  datasetOneTotal: number = 0;
  datasetTwoTotal: number = 0;
  loadedData: string;

  @Input() chartId: string;
  @Input() leftYUnit: string = null;
  @Input() rightYUnit: string = null;
  @Input() labels: string[] = [];
  @Input() hasTopInfo: boolean = false;
  @Input() cardDiagramDataSet: CardDiagramData[] = [];
  @Input() selectionList: any[] = [];
  @Output() selectionChange = new EventEmitter<object>();

  selectedListItem: any;
  private selectedYear: number;

  constructor(private yearSelectService: YearSelectService) {
    Chart.register(...registerables);
  }

  ngAfterViewInit(): void {
    this.initiateChart(this.leftYUnit, this.rightYUnit);
  }

  ngOnInit(): void {
  }

  expandCard() {
    this.cardExpanded = !this.cardExpanded;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('cardDiagramDataSet' in changes) {
      if (this.myChart !== undefined) {
        this.updateChartDiagramData();
      }
    }

    if ('selectionList' in changes && this.selectionList.length > 0) {
      this.selectedListItem = this.selectionList[0];
    }
  }

  public yearSelectChange(year: number): void {
    this.selectedYear = year;
    this.selectionChange.emit({
      year,
      item: this.selectedListItem?.userId,
    });
  }

  public listSelection(selection: any): void {
    this.selectedListItem = selection;
    this.selectionChange.emit({
      year: this.selectedYear,
      item: this.selectedListItem.userId,
    });
    this.yearSelectService.setResetButton(true);
  }

  private updateChartDiagramData(): void {
    (this.myChart.data.datasets[0].data as number[]) = [];
    (this.myChart.data.datasets[1].data as number[]) = [];

    for (let index = 0; index <= 11; index++) {
      this.myChart.data.datasets[0].data.push(0);
      this.myChart.data.datasets[1].data.push(0);
    }
    this.cardDiagramDataSet.forEach((value) => {
      this.myChart.data.datasets[0].data[value.monthKey - 1] = value.dataSetOne;
      this.myChart.data.datasets[1].data[value.monthKey - 1] = value.dataSetTwo;
    });
    this.loadedData = this.cardDiagramDataSet[0]?.menuInfo;
    this.calculateSumOfDataSet();
    this.myChart.update();
  }

  private calculateSumOfDataSet(): void {
    this.datasetOneTotal =
      this.myChart.data.datasets[0].data.length > 0
        ? (this.myChart.data.datasets[0].data.reduce(
          (sum: number, current: number) => sum + current
        ) as number)
        : 0;
    this.datasetTwoTotal =
      this.myChart.data.datasets[1].data.length > 0
        ? (this.myChart.data.datasets[1].data.reduce(
          (sum: number, current: number) => sum + current
        ) as number)
        : 0;
  }

  private initiateChart(leftYUnit: string, rightYUnit: string): void {
    const canvas = document.getElementById(this.chartId) as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    this.myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [
          {
            label: this.labels[0],
            yAxisID: 'left',
            data: [],
            order: 1,
            backgroundColor: ['#69b2f7'],
          },
          {
            label: this.labels[1],
            yAxisID: 'right',
            data: [],
            type: 'bar',
            order: 2,
            backgroundColor: ['#164b6d'],
          },
        ],
        labels: [
          'Januar',
          'Februar',
          'Marts',
          'April',
          'Maj',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'December',
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            ticks: {
              major: {
                enabled: false,
              },
            },
            grid: {
              display: false,
            },
          },
          left: {
            position: 'left',
            grid: {
              display: true,
            },
            ticks: {
              callback(label: number, index, labels) {
                return `${label.toLocaleString('da-DK')} ${leftYUnit ?? ''}`;
              },
            },
          },
          right: {
            position: 'right',
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              callback(label: number, index, labels) {
                return `${label.toLocaleString('da-DK')} ${rightYUnit ?? ''}`;
              },
            },
          },
        },
      },
    });
  }
}
