<div class="dialog" [ngClass]="{
    'news-dialog' : currentDialog === 'newsDialog',
    'notes-dialog' : currentDialog === 'notesDialog'}">
    <div class="dialog__header" *ngIf="currentDialog === 'newsDialog'">
        Alle nyheder
    </div>
    <div class="dialog__header" *ngIf="currentDialog === 'notesDialog'">
        Alle noter
    </div>
    <div class="dialog__search" *ngIf="currentDialog === 'notesDialog'">
        <mat-form-field>
            <input matInput placeholder="Søg i noter" [formControl]="searchValue" placeholder="Søg i noter">
        </mat-form-field>
    </div>
    <div class="dialog__close" (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </div>
    <div class="dialog__content">
        <app-messages [place]="'allmessageDialog'" [searchValue]="searchString"></app-messages>
    </div>
    <div class="dialog__buttons">
        <button mat-stroked-button color="primary" (click)="close()">
            Luk
        </button>
    </div>
</div>
