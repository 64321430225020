<div class="dialog">
    <mat-dialog-content>
        <div class="dialog__header">
            ID{{message.postingNoteId}} - {{message.subject}}
        </div>
        <div class="dialog__close" (click)="close()">
            <fa-icon [icon]="faTimes"></fa-icon>
        </div>
        <div class="dialog__content">
            <div class="dialog__time-author">
                <app-message-time-author [message]="message"></app-message-time-author>
            </div>
            <div class="dialog__text">
                {{message.text}}
            </div>
        </div>
        <div class="dialog__buttons">
            <button mat-stroked-button color="primary" (click)="close()">
                Luk
            </button>
        </div>
    </mat-dialog-content>
</div>