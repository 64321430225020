import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface PostingCheckbox {
  selected: number[];
  deSelected: number[];
  isAllSelected: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CheckBoxService {


  private postingCheckboxs: Subject<PostingCheckbox> = new Subject<PostingCheckbox>();
  public postingCheckboxs$: Observable<PostingCheckbox> = this.postingCheckboxs.asObservable();


  // private checkboxDeSelectedPostingIDList: Subject<number[]> = new Subject<number[]>();
  // public checkboxDeSelectedPostingIDList$: Observable<number[]> = this.checkboxDeSelectedPostingIDList.asObservable();

  // private checkboxSelectedPostingIDList: Subject<number[]> = new Subject<number[]>();
  // public checkboxSelectedPostingIDList$: Observable<number[]> = this.checkboxSelectedPostingIDList.asObservable();

  constructor() { }

  updateSelectionOfCheckbox(postingCheckbox: PostingCheckbox) {
    this.postingCheckboxs
      .next(postingCheckbox);
  }

  // announceChecboxDeSelectedPostingIDs(ids: number[]) {
  //   this.checkboxDeSelectedPostingIDList.next(ids);
  // }
}
