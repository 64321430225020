import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { Moment } from 'moment';
import moment from 'moment';
import { AnalysisFilter, FinancialYear, Period, PostingFilter } from 'src/app/api/vat';
import { Observable, Subscription } from 'rxjs';
import { FilterDataService } from '@modules/filter/services/filter-data.service';
import { CommonHelper } from '@modules/commons/helpers/common-helpers';

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss'],
})
export class PeriodComponent implements OnInit, OnDestroy {
  from: Moment;
  to: Moment;

  @Input() selectedFilters: Observable<AnalysisFilter | PostingFilter>;
  @Input() financialYears: FinancialYear[] = [];
  selectedPeriods: Period[] = [];
  isUpdateButtonDisable: boolean = true;
  private selectedYears: number[] = [];

  subscriptions: Subscription[] = [];

  public isMonthJanuary: boolean = CommonHelper.isMonthJanuary();

  constructor(
    private internalFilterService: InternalFilterService,
    private filterDataService: FilterDataService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.selectedFilters.subscribe((filters) => {
        if (this.hasAnyFinancialYearData()) {
          if (filters.periods !== null) {
            this.from = moment(filters.periods[0].fromDate);
            this.to = moment(filters.periods[0].toDate);
            this.selectedPeriods = filters.periods;
            if (
              this.internalFilterService.isPeriodsRangeSelected(filters.periods)
            ) {
              this.selectedYears = [];
            } else {
              this.selectedYears = this.getYearsFromPeriod(filters.periods);
            }
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public selectionYearChange(
    event: MatCheckbox,
    financialYear: FinancialYear
  ): void {
    this.selectedPeriods = [];
    const index = this.selectedYears.indexOf(financialYear.year);
    if (index > -1) {
      this.selectedYears.splice(index, 1);
    } else {
      this.selectedYears.push(financialYear.year);
    }

    this.selectedPeriods = this.buildPeriods();

    this.isUpdateButtonDisable = false;
  }

  hasAnyFinancialYearData() {
    return (
      this.financialYears
        ?.map((s) => s.yearExists)
        .findIndex((k) => k === true) !== -1
    );
  }

  private buildPeriods(): Period[] {
    let periods: Period[] = [];
    if (this.selectedYears.length === 0) {
      periods = this.internalFilterService.getRangedPeriod(
        this.financialYears.length
      );
    } else {
      this.selectedYears.forEach((year) => {
        periods.push({
          financialYear: year,
          fromDate: this.eraseTimeZoneOffSet(
            new Date(year, this.from.get('month'), this.from.get('date'))
          ),
          toDate: this.eraseTimeZoneOffSet(
            new Date(year, this.to.get('month'), this.to.get('date'))
          ),
        });
      });
    }

    return periods;
  }

  private getYearsFromPeriod(periods: Period[]): number[] {
    const years = periods.map((s) => s.financialYear);
    if (years.includes(NaN)) return [];

    return years;
  }

  public getFromDate(date: Moment): void {
    this.from = date;
    const periods: Period[] = [];
    this.selectedPeriods.forEach((period) => {
      periods.push({
        financialYear: period.financialYear,
        fromDate: this.changeMonthInPeriod(period.fromDate, date),
        toDate: period.toDate,
      });
    });
    this.selectedPeriods = periods;
  }

  public getToDate(date: Moment): void {
    this.to = date;
    const periods: Period[] = [];
    this.selectedPeriods.forEach((period) => {
      periods.push({
        financialYear: period.financialYear,
        fromDate: period.fromDate,
        toDate: this.changeMonthInPeriod(period.toDate, date),
      });
    });
    this.selectedPeriods = periods;
  }

  private changeMonthInPeriod(periodDate: string, date: Moment): string {
    const formatedDate = new Date(
      new Date(periodDate).getFullYear(),
      date.get('month'),
      date.get('date')
    );
    this.isUpdateButtonDisable = false;
    return this.eraseTimeZoneOffSet(formatedDate);
  }

  public isYearSelected(financialYear: FinancialYear): boolean {
    const index = this.selectedYears.indexOf(financialYear.year);
    return index === -1 ? false : true;
  }

  public updateFilter(): void {
    this.filterDataService.updatePrimaryFilterDate(this.selectedPeriods);
    this.internalFilterService.updatePeriodFilter(this.selectedPeriods);
    this.isUpdateButtonDisable = true;
  }

  private eraseTimeZoneOffSet(periodDate: Date): string {
    const UTCDate =
      Date.UTC(
        periodDate.getFullYear(),
        periodDate.getMonth(),
        periodDate.getDate()
      ) - periodDate.getTimezoneOffset();
    return new Date(UTCDate)?.toISOString();
  }
}
