<div class="header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="header__content">
          <div class="header__first">
            <a class="header__logo" routerLink="/">
              <img src="assets/images/bdo-logo.png" />
              <span>Moms Tjek</span>
            </a>
            <div class="header__posting-header" *ngIf="analysisTitle">
              <button mat-icon-button (click)="manualsDialog()">
                i
              </button>
              {{ analysisTitle }}
            </div>
          </div>

          <ul class="header__nav">
            <li class="customer-select">
              <span class="customer-select-fix">
                <app-customer-selector></app-customer-selector>
              </span>
            </li>
            <li>
              <a routerLink="/dashboard" routerLinkActive="active">
                <fa-icon [icon]="faTachometerAlt"></fa-icon>
                <span>Dashboard</span>
              </a>
            </li>
            <li>
              <a *appHasRole="[VAT_Manager, VAT_BDOAdministrator]" routerLink="/leader-dashboard" routerLinkActive="active">
                <fa-icon [icon]="faChartBar"></fa-icon>
                <span>Leder Dashboard</span>
              </a>
            </li>
            <li>
              <a routerLink="/analyser" routerLinkActive="active">
                <fa-icon [icon]="faChartPie"></fa-icon>
                <span>Analyser</span>
              </a>
            </li>
            <li *appHasRole="[VAT_BDOAdministrator]">
              <a routerLink="/admin" routerLinkActive="active">
                <fa-icon [icon]="faUsersCog"></fa-icon>
                <span>Admin</span>
              </a>
            </li>
            <li class="info-center">
              <a (click)="sidebarToggle()">
                <fa-icon [icon]="faComment"></fa-icon>
                <span>
                  Infocenter
                  <div *ngIf="messagesCount>0" class="new-indicator">
                    <span>
                      {{messagesCount}}
                    </span>
                  </div>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
