<div class="togglecontainter">
  <mat-slide-toggle class="expandToggle" [labelPosition]="'before'" [checked]="expandToggle"
    (toggleChange)="expandToggle=!expandToggle">
    Fold ud
  </mat-slide-toggle>
</div>
<mat-tree *ngIf="data" [dataSource]="dataSource" [treeControl]="treeControl" class="tree">

  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <!-- use a disabled button to provide padding for tree leaf -->
      <button class="toggleTree" mat-icon-button disabled></button>
      <mat-checkbox class="the-checkbox" [checked]="isNodeSelected(node)" (change)="toggleNode(node)">
      </mat-checkbox>
      <span matTooltip="{{node.key}} {{node.name}}">
        {{node.key}} {{node.name}}
      </span>
    </li>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node">
        <button class="toggleTree" mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.key">
          <fa-icon [icon]="faMinus" *ngIf="treeControl.isExpanded(node)"></fa-icon>
          <fa-icon [icon]="faPlus" *ngIf="!treeControl.isExpanded(node)"></fa-icon>
        </button>
        <mat-checkbox class="the-checkbox" [indeterminate]="isNodeIndeterminate(node)" [checked]="isNodeSelected(node)"
          (change)="toggleNode(node)">
        </mat-checkbox>
        <span matTooltip="{{node.key}} {{node.name}}">{{node.key}}
          {{node.name}}
        </span>
      </div>
      <ul *ngIf="treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>

</mat-tree>