<div class="dialog">
    <div class="dialog__header">
        Flyt til anden analyse
    </div>
    <div class="dialog__close" (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </div>
    <div class="dialog__top-bar">
        <div>
            <mat-form-field>
                <mat-label>Stikordssøgning i analyser</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
            </mat-form-field>
        </div>
        <div>
            <mat-paginator [pageSizeOptions]="[15]"></mat-paginator>
        </div>
    </div>

    <div class="dialog__content">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Analyse </th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="year">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> År </th>
                <td mat-cell *matCellDef="let row"> {{row.year}} </td>
            </ng-container>

            <ng-container matColumnDef="processed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Behandlet </th>
                <td mat-cell *matCellDef="let row"> {{row.processed | number: '2.':'da-DK'}} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Antal </th>
                <td mat-cell *matCellDef="let row"> {{row.amount | number: '2.':'da-DK'}} </td>
            </ng-container>

            <ng-container matColumnDef="realisedPotential">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Realiseret resultat </th>
                <td mat-cell *matCellDef="let row"> {{row.realisedPotential | vatCurrency}} </td>
            </ng-container>

            <ng-container matColumnDef="remainingPotential">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rest potentiale </th>
                <td mat-cell *matCellDef="let row"> {{row.remainingPotential | vatCurrency}} </td>
            </ng-container>

            <ng-container matColumnDef="totalPotential">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Samlet potentiale </th>
                <td mat-cell *matCellDef="let row"> {{row.totalPotential | vatCurrency}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr [ngClass]="{'selected' : row === selectedAnalysisData, 'ruleMatchedTR' : row.isRuleMatched}" mat-row
                *matRowDef="let row; columns: displayedColumns;" (click)="selectedAnalysisData = row"></tr>
        </table>
    </div>

    <div class="dialog__tooltip">
        *Analyser markeret med farve er forslag af Moms Tjek.
    </div>

    <div class="dialog__buttons">
        <button mat-stroked-button color="primary" (click)="close()">
            Annuller
        </button>
        <button mat-stroked-button color="primary" (click)="setPrimaryAnalysis()">
            Flyt
        </button>
    </div>
</div>