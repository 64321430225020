<header class="top-bar">
  <div class="top-bar__left">
    <button *ngIf="user" class="sidebar-toggle" mat-icon-button
      [attr.aria-label]="sidebarOpen ? 'Luk sidebar' : 'Åbn Sidebar'"
      [matTooltip]="sidebarOpen ? 'Luk sidebar' : 'Åbn Sidebar'" (click)="toggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button>
    {{ title }}
  </div>
  <div *ngIf="user" class="top-bar__right">
    {{ user.username }}
    <app-settings></app-settings>
  </div>
</header>