import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { InternalFilterService } from '@modules/filter/services/internal-filter.service';
import { FilterService } from '@vat/vat-webapi';
import { AnalysisFilter, KeyValuePairOfStringAndString, Period, PostingFilter, TextFilter } from 'src/app/api/vat';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import config from 'src/assets/config.json';
import filterTypes from 'src/assets/filterTypes.json';

export interface ListData {
  key: string;
  dataValue: string;
  checkboxSelected: boolean;
}

enum ListTypes {
  SUPPLIERS = 'suppliers',
  ANNEXNOS = 'annexNos',
  ACCOUNTNOS = 'accountNos',
  PROFITCENTERS = 'profitCenter',
  VATTYPES = 'vatTypes',
}
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnDestroy {
  faTrash = faTrash;
  @Input() type: string;
  filterPeriods: Period[] = [];
  @Input() selectedFilters: Observable<AnalysisFilter | PostingFilter>;

  public selectedNodes: string[] | KeyValuePairOfStringAndString[] = [];
  public searchValues: ListData[] = [];
  searchControl: UntypedFormControl;
  subscriptions: Subscription[] = [];

  private analysisIds: number[] = [];
  private minLength = () => {
    switch (this.type) {
      case ListTypes.VATTYPES:
        return 1;

      case ListTypes.SUPPLIERS:
        return 2;

      default:
        return 3;
    }
  };

  constructor(
    private filterService: FilterService,
    private internalFilterService: InternalFilterService
  ) {}

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl(
      '',
      Validators.minLength(this.minLength())
    );
    this.searchControl.updateValueAndValidity();

    this.subscriptions.push(
      this.selectedFilters.subscribe((filters) => {
        this.initFilterType();
        this.filterPeriods = filters.periods;
        this.analysisIds = filters[filterTypes.analysisIds.key] ?? [];

        if (this.analysisIds.length === 0 && filters['analysisId'])
          this.analysisIds.push(filters['analysisId']);

        this.filterPeriods = filters.periods;
        if (this.isSelectedFilterEmpty(filters[this.type])) {
          filters[this.type] = [];
        }

        this.updateInternalFilterSelection(filters[this.type]);
      })
    );

    this.loadSubscribtion();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  clearCheckBoxSelection() {
    this.searchValues.forEach((value) => {
      value.checkboxSelected = false;
    });
  }

  initFilterType() {
    if (this.isKeyValuePairType()) {
      (this.selectedNodes as KeyValuePairOfStringAndString[]) =
        new Array<KeyValuePairOfStringAndString>();
    } else {
      (this.selectedNodes as string[]) = [];
    }
  }

  private isSelectedFilterEmpty(selectedFilter): boolean {
    return selectedFilter === undefined || selectedFilter === null;
  }

  private updateInternalFilterSelection(
    selectedData: KeyValuePairOfStringAndString[] | string[]
  ) {
    if (this.isKeyValuePairType()) {
      this.selectedNodes = selectedData as KeyValuePairOfStringAndString[];
    } else {
      this.selectedNodes = selectedData as string[];
    }
  }

  public isKeyValuePairType = () =>
    this.type === ListTypes.SUPPLIERS || this.type === ListTypes.PROFITCENTERS;

  private updateFilter(): void {
    this.internalFilterService.updateSideFilter(this.type, this.selectedNodes);
  }

  private loadSubscribtion(): void {
    this.subscriptions.push(
      this.searchControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(config.DebounceTime))
        .subscribe((searchValue) => {
          if (this.searchControl.valid && this.searchControl.value !== '') {
            switch (this.type) {
              case ListTypes.SUPPLIERS:
                this.getSuppliers(searchValue);
                break;
              case ListTypes.ANNEXNOS:
                this.getAnnexNos(searchValue);
                break;
              case ListTypes.ACCOUNTNOS:
                this.getAccountNos(searchValue);
                break;
              case ListTypes.PROFITCENTERS:
                this.getProfitCenters(searchValue);
                break;
              case ListTypes.VATTYPES:
                this.getVatTypes(searchValue);
                break;
              default:
                break;
            }
          } else {
            this.searchValues = [];
          }
        })
    );
  }

  private getSuppliers(searchValue: string): void {
    if (searchValue && searchValue.length > 0) {
      this.subscriptions.push(
        this.filterService
          .filterGetSuppliers(null, null, this.convertToTextFilter(searchValue))
          .subscribe((suppliers: Array<KeyValuePairOfStringAndString>) => {
            this.searchValues = this.convertToListData(suppliers);
          })
      );
    }
  }

  private getAnnexNos(searchValue: string): void {
    if (searchValue && searchValue.length > 0) {
      this.subscriptions.push(
        this.filterService
          .filterGetAnnexNos(null, null, this.convertToTextFilter(searchValue))
          .subscribe((annexNos: string[]) => {
            this.searchValues = this.convertToListData(annexNos);
          })
      );
    }
  }

  private getAccountNos(searchValue: string): void {
    if (searchValue && searchValue.length > 0) {
      this.subscriptions.push(
        this.filterService
          .filterGetAccounts(null, null, this.convertToTextFilter(searchValue))
          .subscribe((accountNos: string[]) => {
            this.searchValues = this.convertToListData(accountNos);
          })
      );
    }
  }

  private getVatTypes(searchValue: string): void {
    if (searchValue && searchValue.length > 0) {
      this.subscriptions.push(
        this.filterService
          .filterGetVatTypes(null, null, this.convertToTextFilter(searchValue))
          .subscribe((vattypes: string[]) => {
            this.searchValues = this.convertToListData(vattypes);
          })
      );
    }
  }

  private getProfitCenters(searchValue: string): void {
    this.subscriptions.push(
      this.filterService
        .filterGetProfitCenters(
          null,
          null,
          this.convertToTextFilter(searchValue)
        )
        .subscribe((result: Array<KeyValuePairOfStringAndString>) => {
          this.searchValues = this.convertToListData(result);
        })
    );
  }

  private convertToTextFilter(search: string): TextFilter {
    return {
      analysisIds: this.analysisIds,
      text: search,
      periods: this.filterPeriods,
    } as TextFilter;
  }

  private convertToListData(
    values: string[] | KeyValuePairOfStringAndString[]
  ): Array<ListData> {
    const newArray = new Array<ListData>();
    let isCheckboxSelected: boolean = false;
    if (this.isKeyValuePairType()) {
      (values as KeyValuePairOfStringAndString[]).forEach((value) => {
        isCheckboxSelected = (
          this.selectedNodes as KeyValuePairOfStringAndString[]
        )
          .map((sn) => sn.value)
          .includes(value.value);
        newArray.push({
          key: value.key,
          dataValue: value.value,
          checkboxSelected: isCheckboxSelected,
        });
      });
    } else {
      let key: number = 0;
      (values as string[]).forEach((value: string) => {
        isCheckboxSelected = (this.selectedNodes as string[]).includes(value);
        newArray.push({
          key: key.toString(),
          dataValue: value,
          checkboxSelected: isCheckboxSelected,
        });
        key++;
      });
    }
    return newArray;
  }

  public deleteSelectedNode(
    node: KeyValuePairOfStringAndString | string
  ): void {
    let index;
    if (this.isKeyValuePairType()) {
      index = (this.selectedNodes as KeyValuePairOfStringAndString[])
        .map((sn) => sn.key)
        .indexOf((node as KeyValuePairOfStringAndString).key);
    } else {
      index = (this.selectedNodes as string[]).indexOf(node as string);
    }
    if (index > -1) {
      this.selectedNodes.splice(index, 1);
    }

    this.updateFilter();
  }

  toggleCheckboxSelection(checkBoxOption: ListData) {
    checkBoxOption.checkboxSelected = !checkBoxOption.checkboxSelected;
  }

  private AddSelectedOptionToSelectedNodes(option: ListData): void {
    let index: number = -1;
    if (this.isKeyValuePairType()) {
      index = this.getIndex(option);
      if (index === -1)
        (this.selectedNodes as KeyValuePairOfStringAndString[]).push({
          key: option.key,
          value: option.dataValue,
        });
    } else {
      index = this.getIndex(option);
      if (index === -1) (this.selectedNodes as string[]).push(option.dataValue);
    }
  }

  private removeSelectedOptionToSelectedNodes(option: ListData): void {
    let index: number = -1;
    if (this.isKeyValuePairType()) {
      index = this.getIndex(option);
      if (index !== -1)
        (this.selectedNodes as KeyValuePairOfStringAndString[]).splice(
          index,
          1
        );
    } else {
      index = this.getIndex(option);
      if (index !== -1) (this.selectedNodes as string[]).splice(index, 1);
    }
  }

  private getIndex(option: ListData): number {
    if (this.isKeyValuePairType()) {
      return (this.selectedNodes as KeyValuePairOfStringAndString[]).findIndex(
        (value) => value.key === option.key
      );
    } else {
      return (this.selectedNodes as string[]).findIndex(
        (value) => value === option.dataValue
      );
    }
  }

  isAnyCheckBoxSelected = () =>
    this.searchValues.map((k) => k.checkboxSelected).includes(true);

  handleCheckboxSelected() {
    this.searchValues.forEach((searchValue) => {
      if (searchValue.checkboxSelected === true) {
        this.AddSelectedOptionToSelectedNodes(searchValue);
      } else {
        this.removeSelectedOptionToSelectedNodes(searchValue);
      }
    });
    this.searchValues = [];
    this.searchControl.reset();
    this.updateFilter();
  }
}
