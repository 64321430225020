import { Component, Inject, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manuals-dialog',
  templateUrl: './manuals-dialog.component.html',
  styleUrls: ['./manuals-dialog.component.scss']
})
export class ManualsDialogComponent implements OnInit {
  // Icons
  faTimes = faTimes;

  constructor(
    public dialogRef: MatDialogRef<ManualsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) { }

  ngOnInit(): void {}

  goToManual() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/manualer`]));

    sessionStorage.setItem('analysisTitle', this.data.title);

    window.open(url, '_blank');

    this.close();
  }

  close() {
    this.dialogRef.close();
  }

}
