import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '@modules/shell/services/menu.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: [ './shell.component.scss' ],
})
export class ShellComponent implements OnInit, AfterViewInit {
  isAuthenticated: boolean = false;
  isBodyFixed: boolean;

  constructor(
    private menuService: MenuService,
    public dialog: MatDialog,
    private oidcSecurityService: OidcSecurityService
  ) { }

  ngOnInit() {
    this.oidcSecurityService.isAuthenticated$.subscribe((auth) => {
      this.isAuthenticated = auth.isAuthenticated;
      if (auth.isAuthenticated) {
        this.menuService.sidebarState.subscribe((value) => {
          this.isBodyFixed = value;
        });
      }
    });
  }

  ngAfterViewInit() { }
}
