import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { YearSelectService } from '@modules/leader-dashboard/services/year-select.service';

@Component({
  selector: 'app-year-selector',
  templateUrl: './year-selector.component.html',
  styleUrls: ['./year-selector.component.scss']
})
export class YearSelectorComponent implements OnInit, AfterViewInit {
  faCalendar = faCalendar;
  yearsList: number[] = [];
  @Output() selectedYearChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(private yearSelectService: YearSelectService) {
    this.yearsList = this.yearSelectService.years;
  }

  ngAfterViewInit(): void {
    this.yearSelectService.globalYear$.subscribe(year => {
      this.selectedYearChange.emit(year);
    });
  }

  ngOnInit(): void {
  }

  public selectedListItemEvent(item: number): void {
    this.yearSelectService.setResetButton(true);
    this.selectedYearChange.emit(item);
  }
}
