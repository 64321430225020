<div class="overlay" [ngClass]="{
    'overlay__hidden': hideWhenNoTabsOpened(),
    'overlay__medium' : overlayState === 1,
    'overlay__full' : overlayState === 2}">

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab.value"
    (selectedIndexChange)="selectedTab.setValue($event)" (focusChange)="changeSelectedTap($event)">

    <mat-tab *ngFor="let postingTab of postingTabs" [label]="postingTab">
      <ng-template mat-tab-label>
        <span class="mat-tab-wrapper" (click)="openTab()">
          <span>{{postingTab?.text}}</span>
          <fa-icon (click)="removeTab(postingTab)" class="removeTab" [icon]="faTimes"></fa-icon>
        </span>
      </ng-template>
      <div class="overlay__content" [ngClass]="notesClosed ? 'notes-closed' : ''">

        <div class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-3">
                <div class="overlay__header">Alle oplysninger</div>
                <div class="overlay__action">
                  <app-posting-action-selector [isOverlay]="true" [postingFilter]="postingFilter"
                    [selectedPostingForStatusChange]="postingTab">
                  </app-posting-action-selector>
                </div>
                <app-information [postingInformation]="postingTab"></app-information>
              </div>
              <div class="col-6">
                <div class="overlay__header">Fakturaoplysninger</div>
                <app-invoice [invoice]="postingTab.invoice"></app-invoice>
              </div>
              <div class="col-3">
                <app-account-no-related-postings [postingId]="postingTab.postingId">
                </app-account-no-related-postings>
                <app-related-postings [postingId]="postingTab.postingId"></app-related-postings>
              </div>
            </div>
          </div>
        </div>
        <div class="notes">
          <div class="notes__close" (click)="closeNotes()">
            <fa-icon [icon]="faChevronRight"></fa-icon>
            <span>Noter</span>
            <fa-icon [icon]="faChevronRight"></fa-icon>
          </div>
          <app-messages *ngIf="noteMode === noteModeTypes.View" (edit)="editNote($event)"
            [postingId]="postingTab.postingId" [place]="'overlay'">
          </app-messages>
          <div class="create-note" *ngIf="noteMode !== noteModeTypes.View">
            <div class="overlay__header">{{createOrEditPostingNoteLabel}}</div>

            <form [formGroup]="postingNoteFormControl">

              <mat-form-field class="create-note__input">
                <mat-chip-list #chipList aria-label="Person selection">
                  <mat-chip *ngFor="let person of Selectedpersons" [selectable]="true" [removable]="true"
                    (removed)="removePerson(person)">
                    {{person.userName}}
                    <fa-icon [icon]="faTimes" matChipRemove *ngIf="true"></fa-icon>
                  </mat-chip>
                </mat-chip-list>
                <input matInput placeholder="Tilføj personer der skal informeres om denne note"
                  formControlName="personName" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="clearTheMatChipInput($event)">
                <mat-autocomplete #auto="matAutocomplete"
                  (optionSelected)="addSelectedPerson($event.option.value, chipList)">
                  <mat-option *ngFor="let person of filteredPersons" [value]="person.userId">
                    {{person.userName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field class="create-note__input">
                <mat-label>Emne (max 100 tegn)</mat-label>
                <input formControlName="postingNoteSubject" matInput placeholder="Skriv emne her" maxlength="100">
              </mat-form-field>

              <mat-form-field class="create-note__textarea">
                <mat-label>Note</mat-label>
                <textarea formControlName="postingNoteText" matInput placeholder="Skriv note her"></textarea>
              </mat-form-field>

              <div class="create-note__buttons">
                <button mat-flat-button (click)="cancelNote()">
                  <fa-icon [icon]="faTimes"></fa-icon> Annuller
                </button>
                <button mat-flat-button color="warn" (click)="clearNote()">
                  <fa-icon [icon]="faTrash"></fa-icon> Ryd
                </button>
                <button mat-raised-button color="primary"
                  [disabled]="(getPostingNoteTextControl().invalid || getPostingNoteSubjectControl().invalid)"
                  (click)="saveNote(postingTab.postingId)">
                  <fa-icon [icon]="faSave"></fa-icon> Gem for
                </button>
              </div>
            </form>
            <div class="create-note__radioes">
              <mat-radio-group [(ngModel)]="savePostingLike" aria-label="Select an option">
                <mat-radio-button name="value1" [value]="1">Denne postering
                </mat-radio-button>
                <mat-radio-button name="value1" [disabled]="selectedCheckboxPostingIdsCounter === 0" [value]="2">Valgte
                  i posteringsoversigt
                  ({{selectedCheckboxPostingIdsCounter }})</mat-radio-button>
                <mat-radio-button *ngIf="noteMode === noteModeTypes.Update" name="value1" [value]="3">Tidl. referencer
                  for note</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="overlay__buttons">
    <button mat-flat-button class="overlay__remove-all-tabs" (click)="removeAllTabs();">
      <span *ngIf="postingTabs.length > 0">
        <fa-icon [icon]="faTimes"></fa-icon> Luk alle faner
      </span>
    </button>
    <button mat-flat-button class="overlay__create" (click)="createNote();">
      <span *ngIf="this.noteMode === 1;else annul_btn">
        <fa-icon [icon]="faPlus"></fa-icon> Note
      </span>
      <ng-template #annul_btn>
        <span>
          <fa-icon [icon]="faTimes"></fa-icon> Annuller
        </span>
      </ng-template>
    </button>
    <button mat-icon-button class="overlay__max" (click)="toggleMaxOverlay()">
      <fa-icon [icon]="faArrowUp"></fa-icon>
    </button>
    <button mat-icon-button class="overlay__min" (click)="toggleMinOverlay()">
      <fa-icon [icon]="faArrowDown"></fa-icon>
    </button>
  </div>
</div>