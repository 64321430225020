import { Component, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { FavoriteService } from '@modules/favorites/services/favorite.service';
import { Analysis } from 'src/app/api/vat';
import { Subscription } from 'rxjs';
import { FavoriteDetail } from 'src/app/global-models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  farStar = farStar;
  fasStar = fasStar;
  favoriteAnalysis: Analysis[];
  favoriteDetail: FavoriteDetail[];
  subscriptions: Subscription[] = [];
  mySubscription: Subscription;

  constructor(
    library: FaIconLibrary,
    private favoriteService: FavoriteService
  ) {
    // Add multiple icons to the library
    library.addIcons(fasStar, farStar);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.favoriteService.favoriteAnalysis.subscribe((res: Analysis[]) => {
        this.favoriteAnalysis = res;
      })
    );
  }
}
