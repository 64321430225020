<div class="content__wrapper">
  <div class="container">
    <div class="row">
      <div class="col-4">
        <div class="sidebar">
          <ul class="contact-us">
            <li class="biggerSidebar" (click)="scroll(target0)">Support / kontakt os</li>
          </ul>
          <ul>
            <mat-expansion-panel class="mat-expansion-panel main-selection" [expanded]="guidancePanelState"
              (opened)="guidancePanelState=true; instructionsPanelState=false"
              (closed)="guidancePanelState = false;regionPanelState=false;kommunePanelState=false;">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Vejledning
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-expansion-panel class="mat-expansion-panel" [expanded]="kommunePanelState"
                (opened)="kommunePanelState=true;regionPanelState=false;" (closed)="kommunePanelState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Kommuner
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ul>
                  <li (click)="scroll(kommunePage1)">Analyser i relation til positivlisten</li>
                  <ul>
                    <li class="smallerSidebar" (click)="scroll(kommunePage2)">Konteret i henhold til positivlisten</li>
                    <li class="smallerSidebar" (click)="scroll(kommunePage3)">Tema positivlisten – HK 0</li>
                    <li class="smallerSidebar" (click)="scroll(kommunePage4)">Tema positivlisten – HK 3</li>
                    <li class="smallerSidebar" (click)="scroll(kommunePage5)">Tema positivlisten – HK 4</li>
                    <li class="smallerSidebar" (click)="scroll(kommunePage6)">Tema positivlisten – HK 5</li>
                    <li class="smallerSidebar" (click)="scroll(kommunePage7)">Tema positivlisten – HK 6</li>
                    <li class="smallerSidebar" (click)="scroll(kommunePage8)">Tema positivlisten – tilskud og støtte
                    </li>

                  </ul>
                  <li (click)="scroll(kommunePage9)">Analyser i relation til en faktura med/uden moms</li>
                  <li (click)="scroll(kommunePage10)">Analyser i relation til art 1 Løn</li>
                  <li (click)="scroll(kommunePage11)">Analyser i relation til art 7.1 Huslejeindtægter</li>
                  <li (click)="scroll(kommunePage12)">Analyser i relation til Momsrefusionsbekendtgørelsen - § 1, stk. 4
                  </li>
                  <li (click)="scroll(kommunePage13)">Øvrige analyser</li>
                  <li (click)="scroll(kommunePage14)">Analyser - Opsamling</li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel class="mat-expansion-panel" [expanded]="regionPanelState"
                (opened)="regionPanelState=true;kommunePanelState = false" (closed)="regionPanelState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Regioner
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ul>
                  <li (click)="scroll(regionPage1)">Analyser i relation til positivlisten</li>
                  <ul>
                    <li class="smallerSidebar" (click)="scroll(regionPage2)">Positivliste analyser</li>
                    <li class="smallerSidebar" (click)="scroll(regionPage3)">Temaliste analyser</li>
                    <li class="smallerSidebar" (click)="scroll(regionPage4)">Fejlliste analyser</li>
                  </ul>
                  <li (click)="scroll(regionPage5)">Analyser i relation til faktura med/uden moms</li>
                  <li (click)="scroll(regionPage6)">Analyser i relation til art 1 Løn</li>
                  <li (click)="scroll(regionPage7)">Analyser i relation til Momsrefusionsbekendtgørelsen - § 1, stk. 4
                  </li>
                  <li (click)="scroll(regionPage8)">Øvrige analyser</li>
                  <li (click)="scroll(regionPage9)">Analyser - Opsamling</li>
                </ul>
              </mat-expansion-panel>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-expansion-panel main-selection" [expanded]="instructionsPanelState"
              (opened)="instructionsPanelState=true; kommunePanelState=false; regionPanelState=false; guidancePanelState=false"
              (closed)="instructionsPanelState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Brugsvejledning
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                <li (click)="scroll(target1)">Indledning</li>
                <li (click)="scroll(target2)">Dashboard</li>
                <li (click)="scroll(target3)">Analyser</li>
                <li (click)="scroll(target4)">Posteringsoverblik ved valgt analyse</li>
                <li (click)="scroll(target9)">Kolonnevalg- og indstillinger</li>
                <li (click)="scroll(target5)">Flyt til anden analyse</li>
                <li (click)="scroll(target6)">Infocenter</li>
                <li (click)="scroll(target8)">Leder Dashboard</li>
              </ul>
            </mat-expansion-panel>

          </ul>
        </div>
      </div>
      <div class="col-8">
        <div #all class="content">

          <div class="section top" #target0>
            <h2>Support / Kontakt os</h2>
            <p>Har du udfordringer med løsningen? Vi står klar til at hjælpe dig.</p>
            <div>
              <p>Kontakt os venligst på:</p>
              <a href="mailto:Momstjek@bdo.dk"> Momstjek@bdo.dk</a>
            </div>
          </div>

          <div [hidden]="!guidancePanelState" class="section top" #page1>
            <div class="intro">
              <div class="intro__text">
                <div class="intro__text--small-header">VEJLEDNING</div>
                <div class="intro__text--header">MOMS TJEK</div>
                <div class="intro__text--p">Følgende vejledning er en oversigt over de forskellige
                  analyser der er adgang til i BDO
                  Moms Tjek.</div>
                <div class="intro__text--p">Ikke alle analyser vil være tilgængelige, da adgang til
                  analyser afhænger af den faktiske
                  bogføring.</div>
                <div class="intro__text--p">I følgende beskrives hvad der kan/skal undersøges i henhold
                  til
                  BDOs anbefalinger.</div>
                <img src="../../../../../assets/images/bdo-logo.png">
              </div>
              <div class="intro__gfx"></div>
            </div>
          </div>

          <div class="kommuneSection" [hidden]="!kommunePanelState">
            <div class="section top" #kommunePage1>
              <h2>Analyser i relation til positivlisten</h2>
              <p>Det bør bemærkes, at momsrefusionsbekendtgørelsen og herunder positivlisten som udgangspunkt ændres 1
                gang årligt. Det
                er derfor vigtigt, at der anvendes den bekendtgørelse med dertilhørende positivliste, som er gældende
                for
                det år der
                arbejdes med.</p>
              <p>Positivlisten indeholder en række funktioner, hvor kommuner og regioner kan få momsrefusion på en række
                udgifter, der
                ikke er tillagt moms.
                Husk herunder betingelser i noterne til positivlisten, som skal være opfyldt for at hjemtage
                momsrefusion,
                herunder
                bestemte grupperinger, driftsoverenskomst, ejerforholdskode 4.
              </p>
              <p>
                For mange områder på positivlisten er ejerforholdskode 4 særlig relevant.
              </p>
              <p>
                Betingelser for ejerforholdskode 4:
              </p>
              <ul>
                <li>
                  Købet skal være fortaget hos en privat leverandør
                </li>
                <li>
                  Købet skal vedrørende ikke momsbelagte ydelser
                </li>
                <li>
                  Ydelsen skal være et alternativ til kommunens tilbud – helt generelt, er der tale om et tilbud, som
                  kommunen selv
                  kunne have udført
                </li>
                <li>Leverandøren må ikke være omfattet af en driftsoverenskomst</li>
              </ul>
              <p>Vurdering af ejerforholdskode 4 er alene relevant i relation til tjenesteydelser, der er bogført på
                funktioner optaget
                på positivlisten.</p>
              <p>På Tema positivlisterne findes ofte de største potentialer. Analyserne indeholder funktioner, som er
                omfattet af
                positivlisten, hvor bogføringen er sket uden moms. For disse bør det undersøges om udgifterne opfylder
                positivlistens
                bestemmelser, og derfor bør bogføres med positivlistemoms.</p>
            </div>
            <div class="section top" #kommunePage2>

              <h3 #analyseTitle>Konteret i henhold til positivlisten</h3>
              <p>Denne analyse viser posteringer, hvor der er afløftet positivlistemoms. Posteringerne er derfor bogført
                efter
                positivlistens bestemmelser.</p>
              <p>Analysen kan bruges til kontrol af, at positivlistens betingelser er opfyldt.</p>
              <p>
                Eksempelvis vil der i denne analyse blive vist et potentiale, såfremt der fremgår moms i den
                elektroniske
                faktura til en
                postering, som er bogført med positivlistemoms, hvor det bør undersøges om udgiften i stedet burde være
                bogført på en
                momsbærende art med fuld momsrefusion.
              </p>

              <p>Omvendt kan der vises et negativt potentiale såfremt eksempelvis udgifter til kommuner/regioner eller
                en
                institution med
                driftsoverenskomst er bogført med ejerforholdskode 4.</p>

              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig
                af, at der
                ofte vil være tale om de samme leverandører og typer af udgifter på tværs af årene.</p>
            </div>
            <div class="section top" #kommunePage3>
              <h3 #analyseTitle>Tema positivlisten – HK 0</h3>
              <p>Denne analyse indeholder posteringer, som er bogført på hovedkonto 0 - funktioner omfattet af
                positivlisten.</p>
              <p>For disse funktioner er art 5.9, tilskud, optaget på positivlisten og det skal derfor vurderes,
                hvorvidt
                der er tale om
                et ”tilskud” eller ”betaling/køb af ydelse”. Hvis der er tale om tilskud, bør det undersøges om posterne
                bør bogføres på
                art 5.9 med positivlistemoms.</p>
              <p>Det kan være en fordel at sortere på funktion, så poster for hver funktion gennemgås samlet.</p>
              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig
                af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.</p>
            </div>
            <div class="section top" #kommunePage4>
              <h3 #analyseTitle>Tema positivlisten – HK 3</h3>
              <p>Denne analyse indeholder posteringer, som er bogført på hovedkonto 3 - funktioner omfattet af
                positivlisten. Det bør
                undersøges, om posterne i denne analyse opfylder positivlistens bestemmelser.</p>
              <p>For de posteringer som er bogført på funktioner, hvor art 5.9 er optaget på positivlisten, skal det
                vurderes, hvorvidt
                der er tale om ”tilskud” eller ”betaling/køb af ydelse”. Hvis der er tale om tilskud, bør det
                undersøges,
                om posterne
                bør bogføres på art 5.9 med positivlistemoms.</p>
              <p>For de posteringer som er bogført på funktioner, hvor art 4.0 er optaget på positivlisten, er særligt
                ejerforholdskode 4
                relevant.</p>
              <p>Gruppering kan ligeledes være afgørende for, hvorvidt der kan opnås positivlistemoms, se henvisning til
                noterne i
                positivlisten.</p>

              <p>Man kan med fordel filtrere på én funktion ad gangen og sortere på leverandør, så poster for hver
                funktion og leverandør
                gennemgås samlet.</p>
              <p>
                Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig
                af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.
              </p>
            </div>
            <div class="section top" #kommunePage5>
              <h3 #analyseTitle>Tema positivlisten – HK 4</h3>
              <p>Denne analyse indeholder posteringer som er bogført på hovedkonto 4 - funktioner omfattet af
                positivlisten. Det bør
                undersøges, om posterne i denne analyse opfylder positivlistens bestemmelser.</p>
              <p>For posteringer bogført på funktioner optaget på positivlisten med art 4.7/4.8, bør det undersøges, om
                der er tale om
                betalinger til andre kommuner/regioner, som burde være bogført på art 4.7/4.8 med mulighed for
                positivlistemoms.</p>

              <p>For de posteringer som er bogført på funktioner, hvor art 5.9 er optaget på positivlisten, skal det
                vurderes, hvorvidt
                der er tale om ”tilskud” eller ”betaling/køb af ydelse”. Hvis der er tale om tilskud, bør det
                undersøges,
                om posterne
                bør bogføres på art 5.9 med positivlistemoms.</p>

              <p>For de posteringer som er bogført på funktioner, hvor art 4.0 er optaget på positivlisten, er særligt
                ejerforholdskode 4
                relevant.</p>
              <p>Gruppering kan ligeledes være afgørende for, hvorvidt der kan opnås positivlistemoms, se henvisning til
                noterne i
                positivlisten.</p>

              <p>Man kan med fordel filtrere på én funktion ad gangen og sortere på leverandør, så poster for hver
                funktion og leverandør
                gennemgås samlet.</p>
              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig
                af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.</p>
            </div>
            <div class="section top" #kommunePage6>
              <h3 #analyseTitle>Tema positivlisten – HK 5</h3>
              <p>Denne analyse indeholder posteringer som er bogført på hovedkonto 5 - funktioner omfattet af
                positivlisten. Det bør
                undersøges, om posterne i denne analyse opfylder positivlistens bestemmelser.</p>
              <p>Der er mange forskellige regler, som er relevante for posteringer i denne analyse, da næsten alle noter
                til
                positivlisten gør sig gældende for udgifter bogført på hovedkonto 5. Det kan derfor være en fordel at
                anvende filter,
                når der arbejdes i analysen. Eksempelvis kan man med fordel gøre brug af filteret ”IM Grp.” og gennemgå
                posteringer, der
                er bogført på én gruppering ad gangen for de funktioner, hvor der alene kan hjemtages positivlistemoms
                på
                udgifter
                bogført på særlige grupperinger jf. noterne til positivlisten.</p>
              <p>For de posteringer som er bogført på funktioner, hvor art 5.9 er optaget på positivlisten, skal det
                vurderes, hvorvidt
                der er tale om ”tilskud” eller ”betaling/køb af ydelse”. Hvis der er tale om tilskud, bør det
                undersøges,
                om posterne
                bør bogføres på art 5.9 med positivlistemoms.</p>
              <p>For de posteringer som er bogført på funktioner, hvor art 4.0 er optaget på positivlisten, er særligt
                ejerforholdskode 4
                relevant.</p>
              <p>Gruppering kan ligeledes være afgørende for, hvorvidt der kan opnås positivlistemoms, se henvisning til
                noterne i
                positivlisten.</p>
              <p>Man kan med fordel filtrere på én funktion ad gangen og sortere på leverandør, så poster for hver
                funktion og leverandør
                gennemgås samlet.</p>
              <p>
                Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig
                af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.
              </p>
            </div>
            <div class="section top" #kommunePage7>
              <h3 #analyseTitle>Tema positivlisten – HK 6</h3>
              <p>Denne analyse indeholder posteringer, som er bogført på hovedkonto 6 - funktioner omfattet af
                positivlisten. Det bør
                undersøges, om posterne i denne analyse opfylder positivlistens bestemmelser.</p>
              <p>Det bør vurderes, hvorvidt der er tale om et ”tilskud” eller ”betaling/køb af ydelse”. Hvis der er tale
                om et tilskud,
                bør det undersøges, om posterne bør bogføres på art 5.9 med positivlistemoms.</p>
              <p>Obs. på at det kun er posteringer bogført på gruppering 1, som er omfattet af positivlisten.</p>
              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig
                af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.</p>
            </div>
            <div class="section top" #kommunePage8>
              <h3 #analyseTitle>Tema positivlisten – tilskud og støtte</h3>
              <p>Denne analyse indeholder posteringer, hvor ordet ”tilskud”, ”støtte” eller ”bidrag” fremgår af
                posteringsteksten, og
                hvor disse er bogført på funktioner optaget på positivlisten.
              </p>
              <p>Ved gennemgang af analysen bør det undersøges, om posterne opfylder positivlistens bestemmelser, og
                herunder om
                posterne er korrekt artskonteret.
              </p>
              <p>Det bør vurderes, om der er tale om ”tilskud/støtte” eller om der er tale om ”betaling/køb af ydelser”.
              </p>
              <p>Eksempelvis kan det undersøges, hvorvidt et tilskud bør bogføres på art 5.9 med positivliste moms.
                Ydermere kan det
                eksempelvis undersøges om udgiften bør bogføres på art 4.0, og om ejerforholdskode 4 gør sig gældende –
                et
                eksempel
                herpå kan være pædagogisk støtte.
              </p>
              <p>Man kan med fordel sortere på funktion, så posterne for hver funktion gennemgås samlet.
              </p>
              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig
                af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.
              </p>
            </div>

            <div class="section top" #kommunePage9>

              <img src="../../../../../assets/images/manual-ui/2.png">

              <h2>Analyser i relation til en faktura med/uden moms</h2>

              <h4 #analyseTitle>Faktura med moms og bogført med moms</h4>
              <p>Denne analyse viser posteringer, der er bogført med moms, og hvor fakturaen indeholder moms.
              </p>
              <p>Analysen viser posteringer, hvor der er en difference i bogført- og faktura moms.
                Det er i denne analyse derfor relevant at kontrollere, om der er fakturaer, hvor fordelingen på
                henholdsvis
                momsbærende og ikke momsbærende arter ikke er foretaget korrekt.
              </p>
              <p>Man kan med fordel sortere faldende på kolonnen ”potentiale”, og som resultat heraf ses alle de
                posteringer, hvor der
                er bogført mindre moms, end hvad fakturaen foreskriver.
              </p>
              <p>Der vises kun et potentiale, hvis der er bogført for lidt moms. Der vises omvendt ikke et potentiale,
                hvis der er
                bogført mere moms end faktura foreskriver. Årsagen hertil er, at det kan være korrekt, at der er bogført
                mere moms end
                fakturaen foreskriver, hvis fakturaen både indeholder en del med- og uden moms, hvor delen uden moms er
                bogført med
                positivliste moms.
              </p>
              <p>Analysen kan endvidere anvendes til kontrol af korrekt konteringspraksis.</p>

              <h4 #analyseTitle>Faktura med moms og bogført uden moms</h4>
              <p>Denne analyse viser posteringer, hvor fakturaen indeholder moms, men hvor posteringer er bogført uden
                moms. Dette
                giver en mulighed for momsrefusion, som bør undersøges nærmere.
              </p>
              <p>Man kan med fordel sortere faldende på kolonnen ”Potentiale”, og som resultat heraf vises de
                posteringer,
                hvor der er
                det største potentiale.
              </p>
              <p>Posterne bør undersøges for, om der er sket korrekt artskontering, eller om de i stedet burde konteres
                på
                en
                momsbærende art.</p>


              <h4 #analyseTitle>Faktura med moms og bogført uden moms – positivliste</h4>
              <p>Denne analyse viser posteringer bogført på funktioner omfattet af positivlisten, hvor fakturaen
                indeholder moms, men
                hvor posteringer er bogført uden moms.
              </p>
              <p>Posteringerne bør derfor gennemgås for at efterse, at der er sket korrekt artskontering.

              </p>
              <p>Er posteringen momspligtig, bør det undersøges om udgiften i stedet bør bogføres på momsbærende art med
                fuld
                momsrefusion. Her kan man med fordel efterse de posteringer, hvor der fremgår et potentiale.

              </p>
              <p>Vedrører posteringen i stedet en momsfri andel af fakturaen, bør det undersøges om udgiften i stedet
                burde være
                bogført med positivlistemoms. Her kan med fordel efterses posteringer uden potentiale, da størstedelen
                af
                disse
                posteringer ikke vil fremgå med potentiale. </p>

              <h4 #analyseTitle>Faktura uden moms og bogført med moms</h4>
              <p>Denne analyse indeholder posteringer, der er bogført med moms, men hvor fakturaen ikke indeholder moms.
              </p>
              <p>Som udgangspunkt er analysen et udtryk for, at der er hjemtaget for meget moms.
              </p>
              <p>Det kan undersøges, om der findes andet bilagsmateriale, som indeholder moms.
              </p>
              <p>Findes der ikke bilagsmateriale, hvor der fremgår moms, bør det i stedet efterses om udgiften er
                bogført
                på en
                funktion omfattet af positivlisten, og om udgiften i så fald i stedet opfylder betingelserne for
                positivlistemoms.
              </p>
              <p>Desuden kan analysen anvendes som orienteringsanalyse, med henblik på at kontrollere hvilke
                leverandører
                der ikke
                fremsender korrekte fakturaer. </p>

              <h4 #analyseTitle>Faktura uden moms og bogført uden moms</h4>
              <p>Denne analyse indeholder posteringer, som er bogført uden moms, og hvor fakturaen ikke indeholder moms.
              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for momsrefusion. Det kan dog med fordel kontrolleres, om
                der er
                leverandører, som umiddelbart leverer momspligtige varer og ydelser. Hvis dette er tilfældet, bør det
                underliggende
                bilagsmateriale undersøges nærmere.
              </p>
              <p>Man kan med fordel anvende filter til venstre i Moms Tjek løsningen og vælge ”Faktura: Vedhæftning”,
                herunder vælge
                ”Billede” samt ”PDF” for at se de posteringer, hvor der er tilknyttet et billede eller en PDF.
              </p>
              <p>Der kan ofte ske konverteringsfejl, når billede/TIF-filer og PDF’er skal konverteres til den
                elektroniske
                faktura,
                hvorfor man særligt bør være opmærksom på disse posteringer i analysen. </p>
              <h4 #analyseTitle>Faktura med moms svarer til bogført moms </h4>
              <p>Analysen viser alle posteringer, hvor fakturamomsbeløbet svarer til det bogførte momsbeløb.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle> Faktura uden moms – udenlandske leverandører </h4>
              <p>Denne analyse indeholder posteringer, hvor fakturaen kan identificeres som udenlandsk på baggrund af
                kreditor, og
                hvor der foreligger en elektronisk faktura, som ikke indeholder moms.
              </p>
              <p>Analysen kan anvendes til efterse, om der er foretaget korrekt momshåndtering, herunder artskontering
                og
                særligt om
                der korrekt er afregnet moms som følge af omvendt betalingspligt.


              </p>
              <h4 #analyseTitle> Moms i fakturavarelinjen</h4>
              <p>I denne analyse vises fakturaer, hvor ordet ”moms” indgår i fakturavarelinjen. Posteringerne i analysen
                er bogført på
                ikke momsbærende arter.
              </p>
              <p>Det skal her undersøges, om fakturaen (andet bilag) indeholder moms. Såfremt det indeholder moms, bør
                det
                kontrolleres, at konteringen sker på momsbærende arter.
              </p>
              <p>Analysen kan endvidere anvendes til kontrol af, hvilke leverandører der muligvis har fejlindlæsning ved
                udstedelse.
              </p>
              <p>Der kan med fordel sorteres på leverandør.
              </p>
              <h4 #analyseTitle> Rykker i fakturavarelinje</h4>
              <p>
                Denne analyse indeholder posteringer, hvor ordet ”rykker” fremgår i fakturavarelinjen.
              </p>
              <p>Er der tale om en ”rykker”, skal det undersøges, om den oprindelige faktura indeholder moms, og
                herefter
                om udgiften
                er artskonteret korrekt.
              </p>
              <p>En ”rykker” vil oftest ikke indeholde oplysninger om moms, da den blot refererer til den originale
                fremsendte
                faktura. Bogføringen bør derfor ske på grundlag af den oprindelige faktura og ikke selve rykkeren.
              </p>
              <p>Hvis der moms i den oprindelige faktura, kan der være mulighed for fuld momsrefusion. Hvis der omvendt
                ikke er moms i
                den oprindelige faktura, så kan man overveje positivlistemoms
              </p>
              <p>Der kan med fordel sorteres på beløb for hurtigt at kunne se, hvorvidt posteringen faktisk er en
                rykker.
              </p>
              <p>Såfremt der eksempelvis er indkøbt ”parykker”, vil sådanne posteringer ligeledes falde under denne
                analyse.
              </p>
              <h4 #analyseTitle> Forskel på bogført beløb og faktura beløb </h4>
              <p>

                Denne analyse indeholder posteringer, hvor det bogførte beløb afviger fra fakturabeløbet.
              </p>
              <p>Ved gennemgang af disse posteringer, bør det undersøges, om hele fakturabeløbet er bogført, og om der
                er
                foretaget
                korrekt artskontering.
              </p>
              <h4 #analyseTitle> Faktura viser kun et totalbeløb </h4>

              <p>
                Denne analyse viser posteringer, hvor fakturaen alene viser et totalbeløb. Posteringer bør undersøges
                for
                andet
                bilagsmateriale for vurdering af korrekt moms- og artskontering.
              </p>

            </div>

            <div class="section top" #kommunePage10>
              <h2>Analyser i relation til art 1 Løn</h2>

              <h4 #analyseTitle> Udgifter til eksterne på art 1 løn </h4>
              <p>
                Denne analyse indeholder posteringer bogført på art 1 - løn til eksterne leverandører.
              </p>
              <p>Her bør det undersøges, om udgifterne indeholder moms. Afhængig af momsgrundlaget bør bogføringen ske
                på henholdsvis
                art 4.0 eller 4.9.
              </p>
              <p>I analysen vises der potentiale for de posteringer, hvor der er moms i fakturaen.
              </p>
              <p>Eksempelvis ses det, at udgifter til bl.a. vikarbureauer bogføres på art 1.
              </p>
              <h4 #analyseTitle>Udgifter til eksterne på art. 9 </h4>
              <p>
                Denne analyse indeholder posteringer bogført på art 9 - interne arter, hvor leverandøren er ekstern.
              </p>
              <p>Her bør det undersøges, om udgifterne er bogført på korrekte arter.
              </p>
              <p>Det kan være en fordel at sortere på kolonnen ”Leverandør”. Betalinger til eksterne leverandører bør
                ikke konteres på
                art 9, men henholdsvis på art 4.0 eller 4.9.
              </p>
              <p>Analysen kan både anvendes som kontrol for korrekt artskontering, men kan også undersøge mulighed for
                momsrefusion, i
                relation til udgifter til eksterne leverandører.
              </p>
              <h4 #analyseTitle>Interne lønudgifter – art. 1</h4>
              <p>
                Denne analyse indeholder posteringer bogført på art 1 - interne lønudgifter.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>

              <img src="../../../../../assets/images/manual-ui/3.png">
            </div>

            <div class="section top" #kommunePage11>
              <h2>Analyser i relation til art 7.1 Huslejeindtægter</h2>

              <p>Analyserne indeholder en liste over alle posteringer, som er bogført på art 7.1.
              </p>
              <p>Det følger af Indenrigs- og Boligministeriets hjemmeside, at:
              </p>
              <p>”Kontraktmæssigt fastsatte huslejebetalinger for ejendomme, kommunen selv ejer, samt varmebidrag i
                forbindelse
                hermed, hvis varmeudgifterne indgår i momsrefusionsordningen”, skal bogføres på art 7.1.
              </p>
              <p>Kommunen får fradrag for udgifter til udleje af fast ejendom, som er momsfri efter Momsloven, som
                private udlejere
                ikke får. For at sikre konkurrenceneutralitet afregnes der 7,5 % af indtægter, der vedrører
                kontraktmæssigt fastsatte
                huslejebetalinger for ejendomme, kommunen selv ejer, som konteres på art 7.1, Egne huslejeindtægter.
              </p>
              <h4 #analyseTitle>Husleje – taksbetalinger</h4>
              <p>
                Analysen indeholder posteringer, som er bogført på art 7.1, og som på baggrund af funktion og gruppering
                vurderes at
                vedrøre beboerbetalinger, opholdsbetalinger, takstbetalinger eller lign.
              </p>
              <p>Indtægter fra eksempelvis beboerbetalinger og opholdsbetalinger, hvor der reelt ikke er tale om en
                huslejekontrakt
                mellem borgeren og kommunen, skal i henhold til Budget- og regnskabssystem konteres på art 7.2.
              </p>
              <p>På art 7.1 afregnes der 7,5%, som tilbagebetales til ordningen.
              </p>
              <p>Analysen kan derfor anvendes til at undersøge, om der foreligger en reel huslejebetaling eller om der
                er tale om en
                takstbetaling. Hvis der er tale om en takstbetaling, eksempelvis opholdsbetalinger så bør dette konteres
                på art. 7.2,
                hvorved der ikke afregnes 7,5% tilbage til refusionsordningen.
              </p>
              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.
              </p>
              <h4 #analyseTitle>Husleje – egne huslejeindtægter</h4>
              <p>
              </p>
              <p>Analysen indeholder posteringer, som på baggrund af særlige logikker, finder egne huslejeindtægter.
              </p>
              <p>Analysen kan anvendes til kontrol af, at der alene afregnes 7,5% af indtægter, der er omfattet af
                reglerne herfor.
              </p>
              <p>Det bør derfor undersøges, om der findes indtægter, der ikke har karakter af at være kontraktmæssig
                husleje,
                eksempelvis fordeling af udgifter i organisationen - udleje fra en afdeling til en anden.
              </p>
              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.
              </p>
              <h4 #analyseTitle>Husleje – opsamling </h4>
              <p>
              </p>
              <p>Analysen viser øvrige posteringer bogført på art 7.1, og som ikke er faldet i andre analyser i relation
                til
                huslejeindtægter (art 7.1).
              </p>
              <p>Posteringer bør vurderes for, om der er indtægter, som ikke er omfattet af bogføring på art 7.1,
                eksempelvis
                indtægter fra videreudlejning af eksterne lejemål, indtægter for leje af jord, engangslejeindtægter uden
                en egentlig
                lejekontrakt m.v.
              </p>
              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene. </p>
            </div>
            <div class="section top" #kommunePage12>
              <h2>Analyser i relation til momsrefusionsbekendtgørelsen - §1, stk. 4</h2>
              <h4 #analyseTitle>Busdrift</h4>
              <p>
                Denne analyse indeholder poster bogført på funktion 2.32.31. Funktionen indgår som udgangspunkt ikke i
                momsrefusionsordningen, dog undtaget gruppering 002 - indtægter og udgifter til drift og vedligeholdelse
                af bus- og
                rutebilstationer mv.
              </p>
              <p>Ved arbejdet i denne analyse, kan det være en fordel at arbejde med flere år på en gang. Dette kommer
                sig
                af, at der
                ofte vil være tale om de samme leverandører og type af udgifter på tværs af årene.
              </p>
              <p>Der bør undersøges, om anvendelse af grupperinger i relation til udgiftens karakter er korrekt.
              </p>
              <h4 #analyseTitle>Ældreboliger</h4>
              <p>
                Denne analyse indeholder poster bogført på funktion 5.32.30 (før 2018) og 0.25.19 (fra 2018), funktionen
                indgår ikke i
                momsrefusionsordningen.
              </p>
              <p>Der kan med fordel arbejdes i flere år på en gang.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Færgedrift</h4>
              <p>
                Denne analyse indeholder poster bogført på funktion 2.32.33, funktionen indgår ikke i
                momsrefusionsordningen.
              </p>
              <p>Der kan med fordel arbejdes i flere år på en gang.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Jernbanedrift</h4>
              <p>
                Denne analyse indeholder poster bogført på funktion 2.32.35, funktionen indgår ikke i
                momsrefusionsordningen.
              </p>
              <p>Der kan med fordel arbejdes i flere år på en gang.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion. </p>




            </div>
            <div class="section top" #kommunePage13>
              <h2>Øvrige analyser</h2>
              <h4 #analyseTitle>Fondsmoms</h4>
              <p>
                Analysen indeholder en liste over alle de posteringer, som tyder på at være tilskud som kommunen har
                modtaget.
                Posteringerne indeholder ord som gave, tilskud, donation og lign.
              </p>
              <p>Det fremgår af momsrefusionsbekendtgørelsen at tilskud som kommunen modtager fra fonde, private
                virksomheder, private
                foreninger, institutioner mv. ikke er omfattet af refusionsordningen, hvorfor der skal tilbagebetales
                17,5% af det
                pågældende tilskud.
              </p>
              <p>Tilskud som findes på funktion 4.62.82 – 4.62.90 er dog undtaget for reduktionen. Ydermere er
                funktionerne 3.22.01,
                3.22.08, 3.22.09 og 3.22.18 også undtaget fra reduktion.
              </p>
              <p>Posteringer i analysen kan både være bogført med og uden moms.
                Analysen kan anvendes til at undersøge om kommunen har modtaget tilskud, som der bør afregnes fondsmoms
                af
                eller om
                der modsat findes indtægter, som der er afregnet fondsmoms af, men som er undtaget reduktionen.
              </p>
              <p>Der kan med fordel sorteres på ”Bruttobeløb”, for at efterse de største beløb først.
              </p>
              <h4 #analyseTitle>Erstatninger</h4>
              <p>
                Denne analyse indeholder posteringer, hvor posteringsteksten antyder, at der kan være tale om en
                erstatning,
                forsikring eller lign, og hvor indtægten er bogført på en momsbærende udgiftsart eksempelvis 4.9.
              </p>
              <p>Når der er tale om erstatninger, forsikringer og lign., bør disse ikke bogføres på momsbærende arter,
                da
                der ellers
                sker en tilbagebetaling til refusionsordningen.
              </p>
              <p>Posteringer bør gennemgås for at vurdere, om der reelt er tale om en erstatning eller
                forsikringsindtægt,
                som i
                stedet bør bogføres på en indtægtsart - art 7.
              </p>
              <h4 #analyseTitle>Øvrige tilskud</h4>
              <p>
                Denne analyse indeholder posteringer, hvor ordet ”tilskud”, ”støtte” eller ”bidrag” fremgår af
                posteringsteksten, og
                hvor konteringen er sket på hovedkonto 6.
              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for momsrefusion, men har til formål at samle tilskud og
                sikre korrekt
                kontering.
              </p>
              <p>I denne analyse kan det med fordel undersøges, om der findes tilskud, som burde være bogført på
                funktioner omfattet
                af positivlisten, eksempelvis kulturelle tilskud, som i stedet burde være bogført på hovedkonto 3.
              </p>
              <h4 #analyseTitle>Intern fakturering – art. 9</h4>
              <p>
                Denne analyse indeholder en liste over alle posteringer bogført på art 9, interne arter, hvor der er
                tale
                om intern
                fakturering, eller hvor der ikke fremgår leverandør på posteringen.
              </p>
              <p>Analysen kan bruges til at efterse, hvordan bogføringen af interne faktureringer sker.
              </p>
              <h4 #analyseTitle>Intern fakturering – øvrige arter </h4>
              <p>
                Denne analyse indeholder posteringer i relation til intern afregning, som ikke er bogført på art 1 eller
                art 9. Man
                kan med fordel sortere på kolonnen ”Moms”. Interne afregninger bør som udgangspunkt ikke ske på
                momsbærende arter.
              </p>
              <p>Man bør derfor undersøg modposter i relation hertil.
              </p>
              <p>Af Budget- og regnskabssystem fremgår følgende:
              </p>
              <p>HOVEDART 9 INTERNE UDGIFTER OG INDTÆGTER
              </p>
              <p style="font-style: italic;">Registrering af udgifter og indtægter i forbindelse med interne afregninger
                mellem
                forskellige funktioner og omkostningssteder i kommunen/regionen kan foretages på 2 måder i budget- og
                regnskabssystemet. Enten ved hjælp af plus/minus-posteringer eller ved anvendelse af de interne arter på
                hovedart 9.
              </p>
              <h4 #analyseTitle>Overførsler til personer på art 5.2. </h4>
              <p>
                Denne analyse indeholder posteringer bogført på art 5.2 - overførsler til personer.
              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for momsrefusion, men kan anvendes til kontrol af korrekt
                bogføring.
              </p>
              <p>Indsigt i underliggende bevillinger/lovgrundlag kan med fordel bruges til vurdering af, om bogføringen
                er
                sket
                korrekt.
              </p>
              <h4 #analyseTitle>Udgifter til apoteker </h4>
              <p>
                Analysen indeholder udgifter til apoteker. Erfaringer viser, at ikke alle fakturaer fra apotekerne
                indeholder moms,
                selvom køb af medicin er momspligtig. Analysen kan derfor anvendes som kontrolværktøj til at påse, om
                der
                bør være
                moms på udgiften.
              </p>
              <p>Indsigt i underliggende bevillinger/lovgrundlag kan med fordel bruges til vurdering af forholdene.
              </p>
              <p>Det kan være en fordel at sortere på funktion, for at efterse at udgifter bogføres ensartet på de
                enkelte
                funktioner.
              </p>
              <h4 #analyseTitle>Udgifter på indtægtsarter </h4>
              <p>
                Denne analyse indeholder posteringer, som typer på at være udgifter, men som er bogført på en
                indtægtsart
                (art 7).
              </p>
              <p>Det bør undersøges, om der er tale om udgifter, som i stedet bør bogføres på en udgiftsart -
                eksempelvis
                art 2.9, 4.0
                eller 4.9.
              </p>
              <p>Der vises alene et potentiale for de posteringer, hvor der er moms i fakturaen.
              </p>
              <h4 #analyseTitle>Gavekort</h4>
              <p>
                Denne analyse indeholder posteringer, hvor ordet ”gavekort” fremgår af posteringsteksten eller
                varelinjen.
              </p>
              <p>Er der tale om et gavekort, bør det kontrolleres, om fakturaen indeholder moms, og herefter om udgiften
                er korrekt
                artskonteret.
              </p>
              <p>Analysen kan også anvendes til at kontrollere brugen af gavekort.
              </p>
              <p>OBS. Det kan medføre skattemæssige konsekvenser at anvende gavekort.
              </p>
              <h4 #analyseTitle>Betalingskort</h4>
              <p>
                Denne analyse indeholder posteringer i relation til brug af et betalingskort, eksempelvis Mastercard,
                SEB
                eller
                Eurocard.
              </p>
              <p>Kontoudtoget kan gennemgås for at efterse om den enkelte udgift er med eller uden moms. Det kan i denne
                forbindelse
                kontrolleres, hvorvidt artkonteringen er korrekt.
              </p>
              <p>OBS - på brug af betalingskort i relation til køb i udlandet og den momsmæssige håndtering.
              </p>
              <h4 #analyseTitle>Beregnede omkostninger – art. 0</h4>
              <p>
                Denne analyse indeholder alle posteringer konteret på art 0, beregnede omkostninger.
              </p>
              <p>Der kan med fordel arbejdes med flere år på en gang.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Trafikselskaber</h4>
              <p>
                Denne analyse indeholder posteringer, som vedrører regionale trafikselskaber.
              </p>
              <p>Analysen kan anvendes til at efterse for korrekt kontering.
              </p>
              <p>Der vil blive vist et negativt potentiale, svarende til den bogførte moms, hvis konteringen er sket med
                moms, og
                der ikke er moms i den elektroniske faktura.
              </p>
              <p>Det kan være en fordel af sortere på kolonnen ”Moms” og kontrollere om der findes en faktura med moms.
              </p>
              <h4 #analyseTitle>Øreafrunding </h4>
              <p>
                Denne analyse indeholder alle posteringer, som vedrører øreafrundinger +/- 1 kr.
              </p>
              <p>Der kan med fordel arbejde med flere år på en gang. Der er dog en begrænsning i Moms Tjek, hvor man kan
                behandle
                optil 10.000 posteringer ad gangen.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Udlægsapp KMD</h4>
              <p>
                Denne analyse indeholder en liste over alle posteringer, som kommer fra KMD udlægsapp. Analysen bør
                anvendes til
                kontrol af korrekt artskontering, hvortil det skal vurderes, hvad det pågældende udlæg vedrører.

              </p>
              <h4 #analyseTitle>Indtægter – poster uden leverandør og bogført uden moms </h4>
              <p>

                Analysen indeholder posteringer, som er bogført på indtægtsarter fx 7.2 og 7.9, hvor der ikke fremgår en
                leverandør,
                og bogføringen er sket uden moms.

              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.


              </p>
              <h4 #analyseTitle>Indtægter – poster uden leverandør og bogført med moms </h4>
              <p>

                Analysen indeholder posteringer, som er bogført på indtægtsarter fx 7.2 og 7.9, hvor der ikke fremgår en
                leverandør,
                og hvor bogføringen er sket med moms.

              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.

              </p>
              <h4 #analyseTitle>Udfakturering – momsbærende arter </h4>
              <p>

                I denne analyse findes posteringer, hvor posteringsteksten indeholder ordene ”Debitor” eller ”Deb”, og
                hvor der ikke
                fremgår en leverandør, eller hvor leverandøren er ukendt. Analysen indeholder posteringer, hvor momsen
                er
                forskelligt fra 0.

              </p>
              <p>Med denne analyse kan det undersøges, hvorvidt faktura sendt af kommunen er håndteres momsmæssigt
                korrekt. Der kan
                eksempelvis ses på, om den pågældende varer eller ydelse er momspligtig eller ej, og om dette stemmer
                overens med
                bogføringen.

              </p>
              <h4 #analyseTitle>Udfakturering – momsfrie arter </h4>
              <p>

                I denne analyse findes posteringer, hvor posteringsteksten indeholder ordene ”Debitor” eller ”Deb”, og
                hvor der ikke
                fremgår en leverandør, eller hvor leverandøren er ukendt. Analysen indeholder posteringer, hvor der ikke
                er bogført
                moms.

              </p>
              <p>Med denne analyse kan det undersøges, hvorvidt faktura sendt af kommunen er håndteres momsmæssigt
                korrekt. Der kan
                eksempelvis ses på, om den pågældende varer eller ydelse er momspligtig eller ej, og om dette stemmer
                overens med
                bogføringen.
              </p>

            </div>
            <div class="section top" #kommunePage14>
              <h2>Analyser – Opsamling</h2>
              <p>Analyserne i nedenstående afsnit er udtryk for opsamlingsanalyser. Posteringer som ikke passer ind i
                andre analyser,
                vil være i en af opsamlingsanalyserne. Som udgangspunkt er der ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Opsamling – poster med leverandørnavn og bogført uden moms</h4>
              <p>
                Analysen viser alle betalinger til leverandører, hvor der ikke er en faktura, og som er bogført uden
                moms.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.

              </p>
              <h4 #analyseTitle>Opsamling – poster med leverandørnavn og bogført med moms </h4>
              <p>
                Analysen viser alle betalinger til leverandører, hvor der ikke er en faktura, og som er bogført med
                moms.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.

              </p>
              <h4 #analyseTitle>Opsamling – poster uden leverandørnavn og bogført med moms </h4>
              <p>
                Analysen viser alle posteringer bogført med moms, hvor der ikke er angivet et leverandørnummer, og hvor
                der ikke er en
                faktura. Eksempelvis vil omposteringer fremgå af denne liste.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.

              </p>
              <h4 #analyseTitle>Opsamling – poster uden leverandørnavn og bogført uden moms</h4>
              <p>
                Analysen viser alle posteringer, hvor der ikke er angivet leverandørnummer, og som er bogført uden moms.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.





              </p>
              <h4 #analyseTitle>Opsamling – omfattet af positivlisten – ukendt</h4>
              <p>
                Analysen viser alle posteringer, som er bogført på funktioner omfattet af positivlisten. Leverandøren er
                ukendt,
                hvorfor det ikke kan afgøres om ejerforholdskode 4 gør sig gældende.
              </p>
              <p>Omposteringer kan også være her.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Opsamling – omfattet af positivlisten – øvrige</h4>
              <p>
                Analysen viser alle posteringer, som er bogført på funktioner omfattet af positivlisten, og som
                umiddelbart ikke er
                omfattet af momsrefusion.
              </p>
              <p>Dette kan eksempelvis vedr. udgifter til kommuner og regioner bogført på hovedkonto 3 og 5, eller
                udgifter til
                personer/CPR-numre.
              </p>
              <h4 #analyseTitle>Opsamling – poster med leverandørnavn – udenlandske leverandører </h4>
              <p>
                Analysen viser alle posteringer, hvor det ud fra leverandørnavnet indikerer, at der er tale om en
                udenlandsk
                leverandør. Analysen indeholder posteringer, hvor der ikke foreligger en faktura.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Alle fakturaer og posteringer </h4>
              <p>
                Analysen indeholder en liste over alle posteringer, som er indlæst i løsningen, og som findes i de
                øvrige
                analyser i
                Moms Tjek.
              </p>
              <p>Analysen kan anvendes til at udsøge i, fx hvis man har brug for at efterse alle posteringer bogført på
                et
                bestemt
                bilagsnummer. Når der ændres noget ved en postering i denne analyse, så vil dette også gøre sig gældende
                i
                den
                konkrete analyse, hvor pågældende postering optræder.</p>

              <img src="../../../../../assets/images/manual-ui/4.png">
            </div>
          </div>
          <div class="regionSection" [hidden]="!regionPanelState">
            <div class="section top" #regionPage1>
              <h2>Analyser i relation til positivlisten</h2>
              <p>Det bør bemærkes, at momsrefusionsbekendtgørelsen og herunder positivlisten som udgangspunkt ændres 1
                gang årligt.
                Det er derfor vigtigt, at der anvendes den bekendtgørelse med dertilhørende positivliste, som er
                gældende
                for det år
                der arbejdes med.
              </p>
              <p>Positivlisten indeholder en række funktioner, hvor kommuner og regioner kan få momsrefusion på en række
                udgifter, der
                ikke er tillagt moms.
              </p>
              <p>Husk herunder betingelser i noterne til positivlisten, som skal være opfyldt for at hjemtage
                momsrefusion, herunder
                bestemte grupperinger, driftsoverenskomst, ejerforholdskode 4.
              </p>
              <p>For mange områder på positivlisten er ejerforholdskode 4 særlig relevant.
              </p>
              <p>Betingelser for ejerforholdskode 4: </p>
              <ul>
                <li>Købet skal være fortaget hos en privat leverandør </li>
                <li>Købet skal vedrørende ikke momsbelagte ydelser</li>

                <li>Ydelsen skal være et alternativ til kommunens tilbud – helt generelt, er der tale om et tilbud, som
                  kommunen selv
                  kunne have udført</li>

                <li>Leverandøren må ikke være omfattet af en driftsoverenskomst</li>
              </ul>
              <p>Vurdering af ejerforholdskode 4 er alene relevant i relation til tjenesteydelser, der er bogført på
                funktioner
                optaget på positivlisten.
              </p>
              <p>På Tema positivlisterne findes ofte de største potentialer. Analyserne indeholder funktioner, som er
                omfattet af
                positivlisten, hvor bogføringen er sket uden moms. For disse bør det undersøges om udgifterne opfylder
                positivlistens
                bestemmelser, og derfor bør bogføres med positivlistemoms. </p>
            </div>
            <div class="section top" #regionPage2>
              <h3> Positivliste analyser </h3>
              <p>Positivliste analyserne er som udgangspunkt udtryk for korrekt kontering i henhold til positivlistens
                bestemmelser,
                og der er allerede afløftet positivlistemoms. Dette betyder, at der som udgangspunkt ikke er mulighed
                for
                at korrigere
                moms i disse.

              </p>
              <p>Analyserne kan anvendes til kontrol af, at positivlistens betingelser er opfyldt.

              <h4 #analyseTitle> Positivliste – 1.10.01 Somatiske sygehuse art 4.0 – Statens Serum</h4>

              <p>Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01, art 4.0, og hvor leverandøren
                er Statens
                Serum. Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.


              </p>
              <h4 #analyseTitle> Positivliste – 1.10.01 Somatiske sygehuse art 4.0 EF4</h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01, art 4.0, og hvor bogføringen
                er
                sket med
                ejerforholdskode 4. Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt. </p>

              <h4 #analyseTitle> Positivliste – 1.10.01 Somatiske sygehuse art 4.7 – kommuner</h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01, art 4.7, hvorved leverandøren
                er
                en kommune.
                Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.

              </p>
              <h4 #analyseTitle> Positivliste – 1.10.01 Somatiske sygehuse art 4.8 – regioner </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01, art 4.8, hvorved leverandøren
                er
                en region.
                Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Som udgangspunkt vil posteringer, som på baggrund af posteringstekst kunne tyde på at vedrøre højt
                specialiseret
                behandling, være sorteret fra. Posteringer vedrørende højt specialiseret behandling vil i stedte kunne
                findes i
                analysen ”Højt specialiseret behandling </p>
              <p>1.10.01 og 1.10.02”. Man kan med fordel efterse, at der ikke findes postering vedr. højt specialiseret
                behandling i
                denne analyse.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da posteringerne som udgangspunkt er
                konteret
                korrekt
                efter positivlistens bestemmelser.

              </p>
              <h4 #analyseTitle> Positivliste – 1.10.02 Psykiatriske sygehuse art 4.0 – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.02, art 4.0, og hvor leverandøren
                er
                Statens
                Serum. Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.


              </p>
              <h4 #analyseTitle> Positivliste – 1.10.02 Psykiatriske sygehuse art 4.0 EF4</h4>

              <p>

              </p>
              <p>Denne analyse indeholder posteringer, som er bogført på funktion 1.10.02, art 4.0, hvor bogføringen er
                sket med
                ejerforholdskode 4. Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.


              </p>
              <h4 #analyseTitle> Positivliste – 1.10.02 Psykiatriske sygehuse art 4.7 – kommuner </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.02, art 4.7, hvorved leverandøren
                er
                en kommune.
                Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.


              </p>
              <h4 #analyseTitle> Positivliste – 1.10.02 Psykiatriske sygehuse art 4.8 – regioner </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.02, art 4.8, hvorved leverandøren
                er
                en region.
                Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Som udgangspunkt vil posteringer, som på baggrund af posteringstekst kunne tyde på at vedrøre højt
                specialiseret
                behandling, være sorteret fra. Posteringer vedrørende højt specialiseret behandling vil i stedte kunne
                findes i
                analysen ”Højt specialiseret behandling 1.10.01 og 1.10.02”. Man kan med fordel efterse, at der ikke
                findes postering
                vedr. højt specialiseret behandling i denne analyse.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

                Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.

              </p>
              <h4 #analyseTitle> Positivlisten – 1.20.10 Almen lægehjælp art 4.0 – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.20.10, art 4.0, og hvor leverandøren
                er
                Statens
                Serum. Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.


              </p>
              <h4 #analyseTitle> Positivlisten – 1.20.11 Speciallægehjælp art 4.0 – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.20.11, art 4.0, og hvor leverandøren
                er
                Statens
                Serum. Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.


              </p>
              <h4 #analyseTitle> Positivliste – 1.20.25 Høreapparater art 5.2 </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.20.25 på art 5.2. Analysen indeholder
                hermed
                posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.


              </p>
              <h4 #analyseTitle> Positivliste – 2.10.01 Sociale tilbud – specialundervisning og rådgivning art 4.0
              </h4>

              <p>
                Denne analyse indeholder posteringer, som er bogført på funktion 2.10.01, art 4.0, hvor bogføringen er
                sket med
                ejerforholdskode 4. Analysen indeholder hermed posteringer, som er bogført i henhold til positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt.


              </p>
              <h4 #analyseTitle> Positivliste – 3.20.10 Tilskud til kulturelle aktiviteter art 5.9 – drift </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 3.20.10, art 5.9, hvor tilskuddet
                vedrører drift,
                herunder er konteret på drast 1. Analysen indeholder hermed posteringer, som er bogført i henhold til
                positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

              </p>
              <p>Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt


              </p>
              <h4 #analyseTitle> Positivliste – 3.20.10 Tilskud til kulturelle aktiviteter art 5.9 – anlæg </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 3.20.10, art 5.9, hvor tilskuddet
                vedrører anlæg,
                herunder er konteret på drast 3. Analysen indeholder hermed posteringer, som er bogført i henhold til
                positivlisten.

              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for refusion, da der allerede er afløftet positivliste
                moms.

                Analysen kan anvendes til at efterse, at positivlistens betingelser er opfyldt

              </p>
              <h4 #analyseTitle> Højt specialiseret behandling – 1.10.01 og 01.10.02 – PLACERING AF ANALYSE? </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktionerne 1.10.01 og 1.10.02, art 4.8, hvor det ud
                fra
                posteringsteksten tyder på at vedrører højt specialiseret behandling. Højt specialiseret behandling er
                ikke opfattet
                af positivlisten, jf. positivlistens noter. Det betyder, at der ikke kan afløftets positivliste moms af
                disse
                posteringer.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, at der faktisk er tale om højt specialiseret
                behandling, som
                er undtaget fra positivlistemoms.

            </div>
            <div class="section top" #regionPage3>

              <h3>Temaliste analyser </h3>
              <p>Temaliste analyserne indeholder posteringer bogført på funktioner optaget på positivlisten, men hvor
                der
                ikke er
                afløftet positivlistemoms. Posteringerne bør gennemgås for at efterse, om betingelserne i positivlisten
                gør sig
                gældende.

              </p>
              <p>Det er ofte i disse analyser, hvor der findes mest potentiale og dermed mulighed for mest momsrefusion.

              </p>
              <h4 #analyseTitle> Temaliste – 1.10.01 Somatiske sygehuse – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.10.01 – funktion optaget på positivlisten.
                Leverandørnavnet
                indikerer, at Statens Serum Institut er leverandøren.

              </p>
              <p>Det kan med fordel efterses, at positivlistens betingelser er opfyldt.

              </p>
              <h4 #analyseTitle> Temaliste – 1.10.01 Somatiske sygehuse art 4.0 </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01, art 4.0 – funktion optaget på
                positivlisten.

              </p>
              <p>I denne analyse bør det undersøges, hvorvidt betingelserne i positivlistens er opfyldt.

              </p>
              <p>Her bør det undersøges, hvorvidt ejerforholdskode 4 gør sig gældende.

              </p>
              <p>Der kan med fordel sorteres på kolonnen ”leverandør”.


              </p>
              <h4 #analyseTitle> Temaliste – 1.10.01 Somatiske sygehuse – kommuner</h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01 - funktion optaget på
                positivlisten.
                Posteringernes leverandørnavne indikerer, at leverandøren er en kommune. Det bør derfor undersøges, om
                bogføringen bør
                ske på art 4.7.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, hvorvidt positivlistens betingelser er opfyldt.

              </p>
              <p>Der kan med fordel sorteres på kolonnen ”leverandør”.


              </p>
              <h4 #analyseTitle> Temaliste – 1.10.01 Somatiske sygehuse – regioner </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01 - funktion optaget på
                positivlisten.
                Posteringernes leverandørnavne indikerer, at leverandøren er en region. Det bør derfor undersøges, om
                bogføringen bør
                ske på art 4.8.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, hvorvidt positivlistens betingelser er opfyldt.

              </p>
              <p>Som udgangspunkt vil posteringer, som på baggrund af posteringstekst kunne tyde på at vedrøre højt
                specialiseret
                behandling, være sorteret fra. Posteringer vedrørende højt specialiseret behandling vil i stedte kunne
                findes i
                analysen ”Højt specialiseret behandling 1.10.01 og 1.10.02”. Man kan med fordel efterse, at der ikke
                findes postering
                vedr. højt specialitet behandling i denne analyse.

              </p>
              <p>Der kan med fordel sorteres på kolonnen ”leverandør”.


              </p>
              <h4 #analyseTitle> Temaliste – 1.10.01 Somatiske sygehuse art 4.0 – ingen leverandør</h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01, art 4.0 – funktion optaget på
                positivlisten.
                I denne analyse er posteringerne, hvor der ikke er identificeret en leverandør.

              </p>
              <p>Der kan først og fremmeste sorteres på posteringstekst for at se, om der er posteringer, som ud fra
                posteringstekst
                kan opfylde betingelserne for ejerforholdskode 4.

              </p>
              <p>Det kan være nødvendigt at efterse yderligere bilag for at vurdere, om posteringer opfylder
                positivlistens
                betingelser.

              </p>
              <h4 #analyseTitle> Temaliste – 1.10.02 Psykiatriske sygehuse art 4.0 – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.02, art 4.0 – funktion optaget på
                positivlisten.
                Leverandørnavnet indikerer, at det er Statens Seruminstitut, som er leverandøren.

              </p>
              <p>Det bør undersøges, hvorvidt betingelserne i positivlisten er opfyldt.


              </p>
              <h4 #analyseTitle> Temaliste – 1.10.02 Psykiatriske sygehuse art 4.0</h4>

              <p>

              </p>
              <p>Denne analyse indeholder posteringer, som er bogført på funktion 1.10.02, art 4.0 – funktion optaget på
                positivlisten.

              </p>
              <p>I denne analyse bør det undersøges, hvorvidt betingelserne i positivlistens er opfyldt.

              </p>
              <p>Her bør det undersøges, hvorvidt ejerforholdskode 4 gør sig gældende.

              </p>
              <p>Der kan med fordel sorteres på kolonnen ”leverandør”.


              </p>
              <h4 #analyseTitle> Temaliste – 1.10.02 Psykiatriske sygehuse – kommuner </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.02 - funktion optaget på
                positivlisten.
                Posteringernes leverandørnavne indikerer, at leverandøren er en kommune. Det bør derfor undersøges, om
                bogføringen bør
                ske på art 4.7.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, hvorvidt positivlistens betingelser er opfyldt.

              </p>
              <p>Der kan med fordel sorteres på kolonnen ”leverandør”.


              </p>
              <h4 #analyseTitle> Temaliste – 1.10.02 Psykiatriske sygehuse – regioner </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.01 - funktion optaget på
                positivlisten.
                Posteringernes leverandørnavne indikerer, at leverandøren er en region.
                Det bør derfor undersøges om bogføringen bør ske på art 4.8.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, hvorvidt positivlistens betingelser er opfyldt.

              </p>
              <p>Som udgangspunkt vil posteringer, som på baggrund af posteringstekst kunne tyde på at vedrøre højt
                specialiseret
                behandling, være sorteret fra. Posteringer vedrørende højt specialiseret behandling vil i stedte kunne
                findes i
                analysen ”Højt specialiseret behandling 1.10.01 og 1.10.02”. Man kan med fordel efterse, at der ikke
                findes postering
                vedr. højt specialiseret behandling i denne analyse.

              </p>
              <p>Der kan med fordel sorteres på kolonnen ”leverandør”.


              </p>
              <h4 #analyseTitle> Temaliste – 1.10.02 Psykiatriske sygehuse art 4.0 – ingen leverandør </h4>

              <p>

                Denne analyse indeholder posteringer, som er bogført på funktion 1.10.02, art 4.0 – funktion optaget på
                positivlisten.
                I denne analyse er posteringerne, hvor der ikke er identificeret en leverandør.

              </p>
              <p>Der kan først og fremmeste sorteres på posteringstekst for at se, om der er posteringer, som ud fra
                posteringstekst
                kan opfylde betingelserne for ejerforholdskode 4.

              </p>
              <p>Det kan være nødvendigt at efterse yderligere bilag for at vurdere, om posteringer opfylder
                positivlistens
                betingelser.

              </p>
              <h4 #analyseTitle> Temaliste – 1.20.10 Almen lægehjælp – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.20.10 – funktion optaget på positivlisten.
                Leverandørnavnet
                indikerer, at Statens Serum Institut er leverandøren.

              </p>
              <p>Det kan med fordel efterses, at positivlistens betingelser er opfyldt.

              </p>
              <h4 #analyseTitle> Temaliste 1.20.11 Speciallægehjælp – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.20.25 – funktion optaget på positivlisten.
              </p>
              <p>Leverandørnavnet indikerer, at det er Statens Seruminstitut, som er er leverandøren.

              </p>
              <p>Det bør efterses, hvorvidt positivlistens betingelser er opfyldt.

              </p>
              <h4 #analyseTitle> Temaliste – 1.20.25 Høreapparater art 5.2 </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.20.25, art 5.2 – funktion optaget på
                positivlisten.

              </p>
              <p>I denne analyse kan posteringerne gennemgås for at efterse, hvorvidt konteringen bør ske med art 5.2.

              </p>
              <h4 #analyseTitle> Temaliste – 2.10.01 Sociale tilbud-specialundervisning og rådgivning art 4.0 </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 2.10.01, art 4.0 – funktion optaget på
                positivlisten.

              </p>
              <p>Posteringerne bør gennemgås for at efterse, om betingelserne for positivlistemoms er opfyldt – herunder
                om
                ejerforholdskode 4 gør sig gældende.

              </p>
              <p>Der kan med fordel sorteres på kolonnen ”Leverandør”.

              </p>
              <h4 #analyseTitle> Temaliste – 2.10.01 Sociale tilbud – specialundervisning og rådgivning – ingen
                leverandør
              </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 2.10.01 – funktion optaget på positivlisten.
                Posteringer i
                denne analyse indeholder ikke oplysninger om leverandøren.

              </p>
              <p>Det bør undersøges, hvorvidt der er tale om en privatleverandør, samt om de øvrige betingelser for
                positivlistemoms
                gør sig gældende.

              </p>
              <p>Der kan med fordel sorteres på bruttobeløb.


              </p>
              <h4 #analyseTitle> Temaliste 3.20.10 Tilskud til kulturelle aktiviteter – drift </h4>

              <p>

                Denne analyse indeholder posteringer på funktion 3.20.10 – funktion optaget på positivlisten. Analysen
                indeholder
                posteringer, som vedrører drift, hvorved bogføringen er sket på dranst 1.

              </p>
              <p>Man kan efterse posteringer for at se, hvorvidt der er tale om tilskud, som bør bogføres med art 5.9,
                eller om der er
                tale om en betaling af en ydelse, som ikke bør konteres med art. 5.9.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at kontrollere, om positivlistens betingelser er opfyldt.

              </p>
              <h4 #analyseTitle> Temaliste 3.20.10 Tilskud til kulturelle aktiviteter – anlæg </h4>

              <p>

                Denne analyse indeholder posteringer på funktion 3.20.10 – funktion optaget på positivlisten. Analysen
                indeholder
                posteringer, som vedrører anlæg, hvorved bogføringen er sket på dranst 3.

              </p>
              <p>Man kan efterse posteringer for at se, hvorvidt der er tale om tilskud, som bør bogføres med art 5.9,
                eller om der er
                tale om en betaling af en ydelse, som ikke bør konteres med art. 5.9.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at kontrollere, om positivlistens betingelser er opfyldt.



              </p>
            </div>
            <div class="section top" #regionPage4>

              <h3>Fejlliste analyser </h3>
              <p>
                Fejlliste analyserne indeholder posteringer, hvor der er afløftet positivlistemoms. Det tyder dog på, at
                betingelser
                for at hjemtage positivliste moms med den anvendte momsprocentandel ikke er opfyldt.
              </p>
              <p>Posteringerne i analyserne bør efterses for at se, om der er sket en fejl, og om betingelser for at
                hjemtage
                positivlistemoms med en anden momsprocentandel gør sig gældende.

              </p>
              <h4 #analyseTitle> Fejlliste – 1.10.01 Somatiske sygehuse art 4.0 – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.10.01, art 4.0 – funktion omfattet af
                positivlisten. Der er
                afløftet positivliste moms efter procenten opgivet for Statens Serum Institut. Ud fra leverandørnavnet
                tyder det på,
                at der er tale om en anden leverandør end Statens Serum Institut.

              </p>
              <p>Posteringerne kan gennemgås for at efterse, hvem leverandøren er.

              </p>
              <p>Hvis leverandøren eksempelvis er en privat leverandør, så kan man med fordel undersøge, om
                betingelserne
                for
                ejerforholdskode 4 gør sig gældende.

              </p>
              <p>I denne analyse viser potentialet forskellen mellem den moms der kan afløftet ved henholdsvis Statens
                Serum og øvrige
                leverandør.
                Hvis der eksempelvis er tale om en udbetaling til en privat person, så vil potentialet være hele
                tilbageregulering.
                Hvis der derimod er tale om en region/kommune, så vil potentiellet være differencen mellem den momsandel
                procent, som
                posteringer er bogført efter og den momsandel procent, som er opgivet i positivlisten ved kommuner og
                regioner.

              </p>
              <p>Der kan med fordel sorteres på kolonnen ”Leverandør”.

              </p>
              <h4 #analyseTitle> Fejlliste – 1.10.01 Somatiske sygehuse art 4.0 EF 4</h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.10.01, art 4.0 – funktion optaget på
                positivlisten.
                Posteringerne i denne analyse er bogført med ejerforholdskode 4, men hvor det ud fra leverandøren
                umildbart ikke tyder
                på, at betingelserne for ejerforholdskode 4 gør sig gældende, eksempelvis fordi leverandøren er en
                kommune, region
                eller der er tale om en udbetaling til en privat person.

              </p>
              <p>Posteringer bør gennemgås for at efterse, om ejerforholdskode 4 gør sig gældende.

              </p>
              <p>I denne analyse viser potentialet forskellen mellem den moms der kan afløftet ved henholdsvis private
                leverandører og
                øvrige leverandør.
                Hvis der eksempelvis er tale om en udbetaling til en privat person, så vil potentialet være hele
                tilbageregulering.
                Hvis der derimod er tale om en region/kommune, så vil potentiellet være differencen mellem den momsandel
                procent, som
                posteringer er bogført efter og den momsandel procent som er opgivet i positivlisten ved kommuner og
                regioner.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, hvem leverandøren er.

              </p>
              <p>Man kan med fordel sortere på kolonnen ”Leverandør”.

              </p>
              <h4 #analyseTitle> Fejlliste – 1.10.01 Somatiske sygehuse art 4.7/4.8 – kommuner og regioner
              </h4>

              <p>
                Denne analyse indeholder posteringer bogført på funktion 1.10.01, art 4.7/4.8 – funktion optaget på
                positivlisten.
                Posteringerne er i denne analyse, da de som udgangspunkt er konteret forkert, idet leverandøren ikke
                tyder
                på at være
                en kommune eller region.

              </p>
              <p>I denne analyse viser potentialet forskellen mellem den moms der kan afløftet ved henholdsvis
                kommunale/regionale
                leverandører og øvrige leverandører.
                Hvis der eksempelvis er tale om en udbetaling til en privat person, så vil potentialet være hele
                tilbageregulering.
                Hvis der derimod er tale om en region/kommune, så vil potentiellet være differencen mellem den momsandel
                procent, som
                posteringer er bogført efter og den momsandel procent som er opgivet i positivlisten ved kommuner og
                regioner.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, hvilke leverandører der er tale om.

              </p>
              <p>Man kan med fordel sortere i kolonnen ”Leverandør”.

              </p>
              <h4 #analyseTitle> Fejlliste – 1.10.02 Psykiatriske sygehuse art 4.0 – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.10.02, art 4.0 – funktion optaget på
                positivlisten. Der er
                afløftet positivliste moms efter procenten opgivet for Statens Serum Institut. Ud fra leverandørnavnet
                tyder det på,
                at der er tale om en anden leverandør end Statens Serum Institut.

              </p>
              <p>Hvis leverandøren eksempelvis er en privat leverandør, så kan man med fordel undersøge, om
                betingelserne
                for
                ejerforholdskode 4 gør sig gældende.

              </p>
              <p>I denne analyse viser potentialet forskellen mellem den moms der kan afløftet ved henholdsvis Statens
                Serum og øvrige
                leverandør.
              </p>
              <p>Hvis der eksempelvis er tale om en udbetaling til en privat person, så vil potentialet være hele
                tilbageregulering.
                Hvis der derimod er tale om en region/kommune, så vil potentiellet være differencen mellem den momsandel
                procent, som
                posteringer er bogført efter og den momsandel procent som er opgivet i positivlisten ved kommuner og
                regioner.

              </p>
              <p>Der kan med fordel sorteres på leverandører.

              </p>
              <h4 #analyseTitle> Fejlliste – 1.10.02 Psykiatriske sygehuse art 4.0 EF 4</h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.10.02, art 4.0 – funktion optaget på
                positivlisten.
                Posteringerne i denne analyse er bogført med ejerforholdskode 4, men hvor det ud fra leverandøren
                umildbart ikke tyder
                på, at betingelserne for ejerforholdskode 4 gør sig gældende, eksempelvis fordi leverandøren er en
                kommune, region
                eller der er tale om en udbetaling til en privat person.

              </p>
              <p>Posteringer bør gennemgås for at efterse, om ejerforholdskode 4 gør sig gældende.

              </p>
              <p>I denne analyse viser potentialet forskellen mellem den moms der kan afløftet ved henholdsvis private
                leverandører og
                øvrige leverandør.
                Hvis der eksempelvis er tale om en udbetaling til en privat person, så vil potentialet være hele
                tilbageregulering.
                Hvis der derimod er tale om en region/kommune, så vil potentiellet være differencen mellem den momsandel
                procent, som
                posteringer er bogført efter og den momsandel procent som er opgivet i positivlisten ved kommuner og
                regioner.

              </p>
              <p>Man kan med fordel sortere på kolonnen ”Leverandør”.


              </p>
              <h4 #analyseTitle> Fejlliste – 1.10.02 Psykiatriske sygehuse art 4.7/4.8 – kommuner og regioner
              </h4>

              <p>
                Denne analyse indeholder posteringer bogført på funktion 1.10.02, art 4.7/4.8 – funktion optaget på
                positivlisten.
                Posteringerne er i denne analyse, da de som udgangspunkt er konteret forkert, idet leverandøren ikke
                tyder
                på at være
                en kommune eller region.

              </p>
              <p>I denne analyse viser potentialet forskellen mellem den moms der kan afløftet ved henholdsvis
                kommunale/regionale
                leverandører og øvrige leverandører.
                Hvis der eksempelvis er tale om en udbetaling til en privat person, så vil potentialet være hele
                tilbageregulering.
                Hvis der derimod er tale om en region/kommune, så vil potentiellet være differencen mellem den momsandel
                procent, som
                posteringer er bogført efter og den momsandel procent som er opgivet i positivlisten ved kommuner og
                regioner.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, hvilke leverandører der er tale om.

              </p>
              <p>Man kan med fordel sortere i kolonnen ”Leverandør”.


              </p>
              <h4 #analyseTitle> Fejlliste – 1.20.10 Almen lægehjælp art 4.0 – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.20.10, art 4.0 – funktion optaget på
                positivlisten.
                Posteringerne er i denne analyse, da de som udgangspunkt er konteret forkert, idet leverandøren ikke
                tyder
                på at være
                Statens Serum.

              </p>
              <p>Det bør efterses, hvem leverandøren er.

              </p>
              <p>Her kan med fordel sorteres på leverandører.

              </p>
              <h4 #analyseTitle> Fejlliste – 1.20.11 Special lægehjælp art 4.0 – Statens Serum </h4>

              <p>

                Denne analyse indeholder posteringer bogført på funktion 1.20.11, art 4.0 – funktion optaget på
                positivlisten.
                Posteringerne er i denne analyse, da de som udgangspunkt er konteret forkert, idet leverandøren ikke
                tyder
                på at være
                Statens Serum.

              </p>
              <p>Det bør efterses, hvem leverandøren er.

              </p>
              <p>Her kan med fordel sorteres på leverandører.


              </p>
              <h4 #analyseTitle> Fejlliste – 2.10.01 Sociale tilbud art 4.0 EF 4 kommuner og regioner </h4>

              <p>

                Denne analyse indeholder posteringer bogført på art 4.0 med ejerforholdskode 4, hvor leverandøren er en
                kommune eller
                region. Posteringerne er i denne analyse, da de som udgangspunkt er konteret forkert.

              </p>
              <p>I denne analyse viser potentialet forskellen mellem den moms der kan afløftet ved henholdsvis
                kommunale/regionale
                leverandører og øvrige leverandører.
                Hvis der eksempelvis er tale om en udbetaling til en privat person, så vil potentialet være hele
                tilbageregulering.
                Hvis der derimod er tale om en region/kommune, så vil potentiellet være differencen mellem den momsandel
                procent, som
                posteringer er bogført efter og den momsandel procent som er opgivet i positivlisten ved kommuner og
                regioner.

              </p>
              <p>Posteringerne kan med fordel gennemgås for at efterse, hvilke leverandører der er tale om.

              </p>
              <p>Man kan med fordel sortere i kolonnen ”Leverandør”.

            </div>
            <div class="section top" #regionPage5>
              <h2>Analyser i relation til faktura med/uden moms</h2>
              <h4 #analyseTitle> Faktura med moms og bogført med moms </h4>

              <p>
                Denne analyse viser posteringer, der er bogført med moms, og hvor fakturaen indeholder moms.
              </p>
              <p>Analysen viser posteringer, hvor der er en difference i bogført- og faktura moms.
                Det er i denne analyse derfor relevant at kontrollere, om der er fakturaer, hvor fordelingen på
                henholdsvis
                momsbærende og ikke momsbærende arter ikke er foretaget korrekt.
              </p>
              <p>Man kan med fordel sortere faldende på kolonnen ”potentiale”, og som resultat heraf ses alle de
                posteringer, hvor der
                er bogført mindre moms, end hvad fakturaen foreskriver.
              </p>
              <p>Der vises kun et potentiale, hvis der er bogført for lidt moms. Der vises omvendt ikke et potentiale,
                hvis der er
                bogført mere moms end faktura foreskriver. Årsagen hertil er, at det kan være korrekt, at der er bogført
                mere moms end
                fakturaen foreskriver, hvis fakturaen både indeholder en del med- og uden moms, hvor delen uden moms er
                bogført med
                positivliste moms.
              </p>
              <p>Analysen kan endvidere anvendes til kontrol af korrekt konteringspraksis.
              </p>
              <h4 #analyseTitle> Faktura med moms og bogført uden moms </h4>

              <p>
                Denne analyse viser posteringer, hvor fakturaen indeholder moms, men hvor posteringer er bogført uden
                moms. Dette
                giver en mulighed for momsrefusion, som bør undersøges nærmere.
              </p>
              <p>Man kan med fordel sortere faldende på kolonnen ”Potentiale”, og som resultat heraf vises de
                posteringer,
                hvor der er
                det største potentiale.
              </p>
              <p>Posterne bør undersøges for, om der er sket korrekt artskontering, eller om de i stedet burde konteres
                på
                en
                momsbærende art.
              </p>
              <h4 #analyseTitle> Faktura med moms svarer til bogført moms </h4>

              <p>
                Analysen viser alle posteringer, hvor fakturamomsbeløbet svarer til det bogførte momsbeløb.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle> Faktura uden moms – udenlandske leverandører </h4>

              <p>
                Denne analyse indeholder posteringer, hvor fakturaen kan identificeres som udenlandsk på baggrund af
                kreditor, og hvor
                der foreligger en elektronisk faktura, som ikke indeholder moms.
              </p>
              <p>Analysen kan anvendes til efterse, om der er foretaget korrekt momshåndtering, herunder artskontering
                og
                særligt om
                der korrekt er afregnet moms som følge af omvendt betalingspligt.

              </p>
              <h4 #analyseTitle> Faktura uden moms og bogført med moms </h4>

              <p>
                Denne analyse indeholder posteringer, der er bogført med moms, men hvor fakturaen ikke indeholder moms.
              </p>
              <p>Som udgangspunkt er analysen et udtryk for, at der er hjemtaget for meget moms.
              </p>
              <p>Det kan undersøges, om der findes andet bilagsmateriale, som indeholder moms.
              </p>
              <p>Findes der ikke bilagsmateriale, hvor der fremgår moms, bør det i stedet efterses om udgiften er
                bogført
                på en
                funktion omfattet af positivlisten, og om udgiften i så fald i stedet opfylder betingelserne for
                positivlistemoms.
              </p>
              <p>Desuden kan analysen anvendes som orienteringsanalyse, med henblik på at kontrollere hvilke
                leverandører
                der ikke
                fremsender korrekte fakturaer.
              </p>
              <h4 #analyseTitle> Faktura uden moms og bogført uden moms </h4>

              <p>
                Denne analyse indeholder posteringer, som er bogført uden moms, og hvor fakturaen ikke indeholder moms.
              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for momsrefusion. Det kan dog med fordel kontrolleres, om
                der er
                leverandører, som umiddelbart leverer momspligtige varer og ydelser. Hvis dette er tilfældet, bør det
                underliggende
                bilagsmateriale undersøges nærmere.
              </p>
              <p>Man kan med fordel anvende filter til venstre i Moms Tjek løsningen og vælge ”Faktura: Vedhæftning”,
                herunder vælge
                ”Billede” samt ”PDF” for at se de posteringer, hvor der er tilknyttet et billede eller en PDF.
              </p>
              <p>Der kan ofte ske konverteringsfejl, når billede/TIF-filer og PDF’er skal konverteres til den
                elektroniske
                faktura,
                hvorfor man særligt bør være opmærksom på disse posteringer i analysen.
              </p>
              <h4 #analyseTitle> Moms i fakturavarelinjen </h4>

              <p>
                I denne analyse vises fakturaer, hvor ordet ”moms” indgår i fakturavarelinjen. Posteringerne i analysen
                er
                bogført på
                ikke momsbærende arter.
              </p>
              <p>Det skal her undersøges, om fakturaen (andet bilag) indeholder moms. Såfremt det indeholder moms, bør
                det
                kontrolleres, at konteringen sker på momsbærende arter.
              </p>
              <p>Analysen kan endvidere anvendes til kontrol af, hvilke leverandører der muligvis har fejlindlæsning ved
                udstedelse.
              </p>
              <p>Der kan med fordel sorteres på leverandør.
              </p>
              <h4 #analyseTitle> Rykker i fakturavarelinje</h4>

              <p>
                Denne analyse indeholder posteringer, hvor ordet ”rykker” fremgår i fakturavarelinjen.
              </p>
              <p>Er der tale om en ”rykker”, skal det undersøges, om den oprindelige faktura indeholder moms, og
                herefter
                om udgiften
                er artskonteret korrekt.
              </p>
              <p>En ”rykker” vil oftest ikke indeholde oplysninger om moms, da den blot refererer til den originale
                fremsendte
                faktura. Bogføringen bør derfor ske på grundlag af den oprindelige faktura og ikke selve rykkeren.
              </p>
              <p>Hvis der moms i den oprindelige faktura, kan der være mulighed for fuld momsrefusion. Hvis der omvendt
                ikke er moms i
                den oprindelige faktura, så kan man overveje positivlistemoms
              </p>
              <p>Der kan med fordel sorteres på beløb for hurtigt at kunne se, hvorvidt posteringen faktisk er en
                rykker.
              </p>
              <p>Såfremt der eksempelvis er indkøbt ”parykker”, vil sådanne posteringer ligeledes falde under denne
                analyse.
              </p>
              <h4 #analyseTitle> Forskel på bogført beløb og faktura beløb </h4>

              <p>
                Denne analyse indeholder posteringer, hvor det bogførte beløb afviger fra fakturabeløbet.
              </p>
              <p>Ved gennemgang af disse posteringer, bør det undersøges, om hele fakturabeløbet er bogført, og om der
                er
                foretaget
                korrekt artskontering.
              </p>
              <h4 #analyseTitle> Faktura viser kun et totalbeløb </h4>

              <p>
                Denne analyse viser posteringer, hvor fakturaen alene viser et totalbeløb. Posteringer bør undersøges
                for
                andet
                bilagsmateriale for vurdering af korrekt moms- og artskontering.</p>

            </div>
            <div class="section top" #regionPage6>
              <h2>Analyser i relation til art 1 Løn</h2>

              <h4 #analyseTitle> Udgifter til eksterne på art 1 løn</h4>

              <p>Denne analyse indeholder posteringer bogført på art 1 - løn til eksterne leverandører.</p>
              <p>Her bør det undersøges, om udgifterne indeholder moms. Afhængig af momsgrundlaget bør bogføringen ske
                på henholdsvis art
                4.0 eller 4.9</p>
              <p>I analysen vises der potentiale for de posteringer, hvor der er moms i fakturaen.</p>

              <p>Eksempelvis ses det, at udgifter til bl.a. vikarbureauer bogføres på art 1.</p>

              <h4 #analyseTitle> Interne lønudgifter – art 1</h4>

              <p>

                Denne analyse indeholder posteringer bogført på art 1 - interne lønudgifter.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.

              </p>
              <h4 #analyseTitle> Udgifter til eksterne på art 9</h4>

              <p>

                Denne analyse indeholder posteringer bogført på art 9 - interne arter, hvor leverandøren er ekstern.
              </p>
              <p>Her bør det undersøges, om udgifterne er bogført på korrekte arter.
              </p>
              <p>Det kan være en fordel at sortere på kolonnen ”Leverandør”. Betalinger til eksterne leverandører bør
                ikke
                konteres på
                art 9, men henholdsvis på art 4.0 eller 4.9.
              </p>
              <p>Analysen kan både anvendes som kontrol for korrekt artskontering, men kan også undersøge mulighed for
                momsrefusion, i
                relation til udgifter til eksterne leverandører.</p>

            </div>

            <div class="section top" #regionPage7>
              <h2>Analyser i relation til momsrefusionsbekendtgørelsen - §1, stk. 4</h2>
              <h4 #analyseTitle> Tilskud til trafikselskaber</h4>

              <p>

                Denne analyse indeholder poster bogført på funktion 3.10.01, funktionen indgår ikke i
                momsrefusionsordningen.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.

              </p>
              <h4 #analyseTitle> Almene ældreboliger</h4>

              <p>

                Denne analyse indeholder poster bogført på funktion 2.10.30, funktionen indgår ikke i
                momsrefusionsordningen.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.

            </div>
            <div class="section top" #regionPage8>
              <h2>Øvrige analyser</h2>
              <h4 #analyseTitle> Egne huslejebetalinger </h4>

              <p>


                Analysen indeholder posteringer, som på baggrund af særlige logikker, finder egne huslejeindtægter.
              </p>
              <p>Analysen kan anvendes til kontrol af, at der alene afregnes 7,5% af indtægter, der er omfattet af
                reglerne herfor.
              </p>
              <p>Det bør derfor undersøges, om der findes indtægter, der ikke har karakter af at være kontraktmæssig
                husleje,
                eksempelvis fordeling af udgifter i organisationen - udleje fra en afdeling til en anden.
              </p>
              <h4 #analyseTitle> Fondsmoms</h4>

              <p>

                Analysen indeholder en liste over alle de posteringer, som tyder på at være tilskud som regionen har
                modtaget.
                Posteringerne indeholder ord som gave, tilskud, donation og lign.,
              </p>
              <p>Det fremgår af momsrefusionsbekendtgørelsen at tilskud som regionen modtager fra fonde, private
                virksomheder, private
                foreninger, institutioner mv. ikke er omfattet af refusionsordningen, hvorfor der skal tilbagebetales
                17,5% af det
                pågældende tilskud.
              </p>
              <p>Tilskud som findes på funktion 1.10.01, Somatiske sygehuse og 1.10.02, Psykiatriske sygehuse, er dog
                undtaget for
                reduktionen.
              </p>
              <p>Posteringer i analysen kan både være bogført med og uden moms.
                Analysen kan anvendes til at undersøge om regionen har modtaget tilskud, som der bør afregnes fondsmoms
                af
                eller om
                der modsat findes indtægter, som der er afregnet fondsmoms af, men som er undtaget reduktionen.
              </p>
              <p>Der kan med fordel sorteres på ”Bruttobeløb”, for at efterse de største beløb først.
              </p>
              <h4 #analyseTitle> Erstatninger</h4>

              <p>

                Denne analyse indeholder posteringer, hvor posteringsteksten antyder, at der kan være tale om en
                erstatning,
                forsikring eller lign, og hvor indtægten er bogført på en momsbærende udgiftsart eksempelvis 4.9.
              </p>
              <p>Når der er tale om erstatninger, forsikringer og lign., bør disse ikke bogføres på momsbærende arter,
                da
                der ellers
                sker en tilbagebetaling til refusionsordningen.
              </p>
              <p>Posteringer bør gennemgås for at vurdere, om der reelt er tale om en erstatning eller
                forsikringsindtægt,
                som i
                stedet bør bogføres på en indtægtsart - art 7.
              </p>
              <h4 #analyseTitle> Øvrige tilskud – Regioner </h4>

              <p>

              </p>
              <p>Denne analyse indeholder posteringer, hvor ordet ”tilskud”, ”støtte” eller ”bidrag” fremgår af
                posteringsteksten.
              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for momsrefusion, men har til formål at samle tilskud og
                sikre korrekt
                kontering.
              </p>
              <p>I denne analyse kan det med fordel undersøges, om der findes tilskud, som burde være bogført på
                funktioner omfattet
                af positivlisten, eksempelvis tilskud til kulturelle aktiviteter, som i stedet burde være bogført på
                hovedkonto 3.
              </p>
              <h4 #analyseTitle> Intern fakturering – art. 9</h4>

              <p>

                Denne analyse indeholder en liste over alle posteringer bogført på art 9, interne arter, hvor der er
                tale
                om intern
                fakturering, eller hvor der ikke fremgår leverandør på posteringen.
              </p>
              <p>Analysen kan bruges til at efterse, hvordan bogføringen af interne faktureringer sker.
              </p>
              <h4 #analyseTitle> Intern fakturering – øvrige arter </h4>

              <p>

                Denne analyse indeholder posteringer i relation til intern afregning, som ikke er bogført på art 1 eller
                art 9. Man
                kan med fordel sortere på kolonnen ”Moms”. Interne afregninger bør som udgangspunkt ikke ske på
                momsbærende arter.
              </p>
              <p>Man bør derfor undersøg modposter i relation hertil.
              </p>
              <p>Af Budget- og regnskabssystem fremgår følgende:
              </p>
              <p>HOVEDART 9 INTERNE UDGIFTER OG INDTÆGTER
              </p>
              <p style="font-style: italic;">Registrering af udgifter og indtægter i forbindelse med interne afregninger
                mellem forskellige funktioner og
                omkostningssteder i kommunen/regionen kan foretages på 2 måder i budget- og regnskabssystemet. Enten ved
                hjælp af
                plus/minus-posteringer eller ved anvendelse af de interne arter på hovedart 9.
              </p>
              <h4 #analyseTitle> Overførsler til personer på art 5.2. </h4>

              <p>

                Denne analyse indeholder posteringer bogført på art 5.2 - overførsler til personer.
              </p>
              <p>Analysen giver som udgangspunkt ikke mulighed for momsrefusion, men kan anvendes til kontrol af korrekt
                bogføring.
              </p>
              <p>Indsigt i underliggende bevillinger/lovgrundlag kan med fordel bruges til vurdering af, om bogføringen
                er
                sket
                korrekt.

              </p>
              <h4 #analyseTitle> Udgifter til apoteker (Region) </h4>

              <p>

                Analysen indeholder udgifter til apoteker. Køb af medicin mv. er momspligtigt, og analysen kan derfor
                anvendes som
                kontrol til at efterse, om udgifter til apoteker er bogført med moms.
              </p>
              <p>Det kan være en fordel at sortere på funktion, for at efterse at udgifter bogføres ensartet på de
                enkelte
                funktioner.

              </p>
              <h4 #analyseTitle> Udgifter på indtægtsarter </h4>

              <p>

                Denne analyse indeholder posteringer, som typer på at være udgifter, men som er bogført på en
                indtægtsart
                (art 7).
              </p>
              <p>Det bør undersøges, om der er tale om udgifter, som i stedet bør bogføres på en udgiftsart -
                eksempelvis
                art 2.9, 4.0
                eller 4.9.
              </p>
              <p>Der vises alene et potentiale for de posteringer, hvor der er moms i fakturaen.

              </p>
              <h4 #analyseTitle> Gavekort </h4>

              <p>

                Denne analyse indeholder posteringer, hvor ordet ”gavekort” fremgår af posteringsteksten eller
                varelinjen.
              </p>
              <p>Er der tale om et gavekort, bør det kontrolleres, om fakturaen indeholder moms, og herefter om udgiften
                er korrekt
                artskonteret.
              </p>
              <p>Analysen kan også anvendes til at kontrollere brugen af gavekort.
              </p>
              <p>OBS. Det kan medføre skattemæssige konsekvenser at anvende gavekort.
              </p>
              <h4 #analyseTitle> Betalingskort</h4>

              <p>

                Denne analyse indeholder posteringer i relation til brug af et betalingskort, eksempelvis Mastercard,
                SEB
                eller
                Eurocard.
              </p>
              <p>Kontoudtoget kan gennemgås for at efterse om den enkelte udgift er med eller uden moms. Det kan i denne
                forbindelse
                kontrolleres, hvorvidt artkonteringen er korrekt.
              </p>
              <p>OBS - på brug af betalingskort i relation til køb i udlandet og den momsmæssige håndtering.
              </p>
              <h4 #analyseTitle> Beregnede omkostninger – art. 0</h4>

              <p>

                Denne analyse indeholder alle posteringer konteret på art 0, beregnede omkostninger.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.

              </p>
              <h4 #analyseTitle> Trafikselskaber (Regioner) </h4>

              <p>


                Denne analyse indeholder posteringer, hvor leverandørnavnet indikerer, at der er tale om et regionalt
                trafikselskab.

              </p>
              <p>Analysen kan anvendes til at efterse, om der er foretaget korrekt artskontering.

              </p>
              <h4 #analyseTitle> Øreafrunding </h4>

              <p>

                Denne analyse indeholder alle posteringer, som vedrører øreafrundinger +/- 1 kr.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle> Udfakturering – momsbærende arter </h4>

              <p>


                I denne analyse findes posteringer, hvor posteringsteksten indeholder ordene ”Debitor” eller ”Deb”, og
                hvor der ikke
                fremgår en leverandør, eller hvor leverandøren er ukendt. Analysen indeholder posteringer, hvor momsen
                er
                forskelligt
                fra 0.

              </p>
              <p>Med denne analyse kan det undersøges, hvorvidt faktura sendt af kommunen er håndteres momsmæssigt
                korrekt. Der kan
                eksempelvis ses på, om den pågældende varer eller ydelse er momspligtig eller ej, og om dette stemmer
                overens med
                bogføringen.

              </p>
              <h4 #analyseTitle> Udfakturering – momsfrie arter </h4>

              <p>


                I denne analyse findes posteringer, hvor posteringsteksten indeholder ordene ”Debitor” eller ”Deb”, og
                hvor der ikke
                fremgår en leverandør, eller hvor leverandøren er ukendt. Analysen indeholder posteringer, hvor der ikke
                er bogført
                moms.

              </p>
              <p>Med denne analyse kan det undersøges, hvorvidt faktura sendt af kommunen er håndteres momsmæssigt
                korrekt. Der kan
                eksempelvis ses på, om den pågældende varer eller ydelse er momspligtig eller ej, og om dette stemmer
                overens med
                bogføringen.

            </div>
            <div class="section top" #regionPage9>
              <h2>Analyser – Opsamling</h2>
              <p>Analyserne i nedenstående afsnit er udtryk for opsamlingsanalyser. Posteringer som ikke passer ind i
                andre analyser,
                vil være i en af opsamlingsanalyserne. Som udgangspunkt er der ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Opsamling – omfattet af positivlisten – ukendt</h4>

              <p>

              </p>
              <p>Analysen viser alle posteringer, som er bogført på funktioner omfattet af positivlisten. Leverandøren
                er
                ukendt,
                hvorfor det ikke kan afgøres om ejerforholdskode 4 gør sig gældende.
              </p>
              <p>Omposteringer kan også være her.
                Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Opsamling – omfattet af positivlisten – øvrige</h4>

              <p>

                Analysen viser alle posteringer, som er bogført på funktioner omfattet af positivlisten, og som
                umiddelbart ikke er
                omfattet af momsrefusion.
              </p>
              <p>Dette kan eksempelvis vedr. udgifter til kommuner og regioner bogført på hovedkonto 3 og 5, eller
                udgifter til
                personer/CPR-numre.
              </p>
              <h4 #analyseTitle>Opsamling – poster med leverandørnavn og bogført uden moms</h4>

              <p>

                Analysen viser alle betalinger til leverandører, hvor der ikke er en faktura, og som er bogført uden
                moms.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Opsamling – poster med leverandørnavn og bogført med moms </h4>

              <p>

                Analysen viser alle betalinger til leverandører, hvor der ikke er en faktura, og som er bogført med
                moms.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Opsamling – poster uden leverandørnavn og bogført med moms </h4>

              <p>

                Analysen viser alle posteringer bogført med moms, hvor der ikke er angivet et leverandørnummer, og hvor
                der ikke er en
                faktura. Eksempelvis vil omposteringer fremgå af denne liste.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Opsamling – poster uden leverandørnavn og bogført uden moms</h4>

              <p>

                Analysen viser alle posteringer, hvor der ikke er angivet leverandørnummer, og som er bogført uden moms.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Opsamling – poster med leverandørnavn – udenlandske leverandører </h4>

              <p>

                Analysen viser alle posteringer, hvor det ud fra leverandørnavnet indikerer, at der er tale om en
                udenlandsk
                leverandør. Analysen indeholder posteringer, hvor der ikke foreligger en faktura.
              </p>
              <p>Analysen er en opsamling og giver som udgangspunkt ikke mulighed for momsrefusion.
              </p>
              <h4 #analyseTitle>Alle fakturaer og posteringer </h4>

              <p>

                Analysen indeholder en liste over alle posteringer, som er indlæst i løsningen, og som findes i de
                øvrige
                analyser i
                Moms Tjek.
              </p>
              <p>Analysen kan anvendes til at udsøge i, fx hvis man har brug for at efterse alle posteringer bogført på
                et
                bestemt
                bilagsnummer. Når der ændres noget ved en postering i denne analyse, så vil dette også gøre sig gældende
                i
                den
                konkrete analyse, hvor pågældende postering optræder.

            </div>
          </div>
          <div class="instructionsSection" [hidden]="!instructionsPanelState">
            <div class="section" #target1>
              <h1>Brugsvejledning</h1>
              <h2>Indledning</h2>
              <p>Denne guide har til formål at give et overblik over funktionalitet i Moms Tjek-løsningen.</p>
              <p>Guiden skal således hjælpe dig hurtigt i gang, og understøtte en effektiv forankring af
                løsningen i organisationen.</p>
              <p>Applikationen er bygget op af tre hovedmenuer. Disse er tilgængelige i højre-top af skærmen:
              </p>
              <ul>
                <li><b>Dashboard</b> – Indsigt i værdi af arbejde, samt hurtige adgange til senest anvendte-
                  og favorit analyser</li>
                <li><b>Analyser</b> – Overblik over alle tilgængelige analyser, samt let tilgængelige
                  filtreringsmuligheder. </li>
                <li><b>Infocenter</b> – Overblik over kommunikation fra både kollegaer, samt vigtige
                  informationer vedr. nyheder om løsningen og eventuelle lovændringer. </li>
                <li>
                  <b>Leder Dasboard</b> - Værdifuld indsigt i fremgangen med arbejdet med moms i din organisation.
                  Indblik i anvendelse, samt opnået værdi af løsningen og arbejdet med moms. (Leder dashboardet er et
                  tilkøbsmodul, hvorfor tilgangen er betinget heraf).
                </li>
              </ul>
              <p>Den overordnede proces, som løsningen er baseret på, følger de ovenstående tre punkter.</p>
              <p>Du vil altid blive præsenteret for dashboard’et, hvorfra du skal tage stilling til om du vil
                bruge en af de af hurtige adgange ’senest anvendt’ og ’favoritter’.</p>
              <p>Vil du ikke anvende en af disse hurtige adgange, klikker du blot på menuen ’Analyser’.
                Foretag her de filtreringer du ønsker. Herefter klikker du blot på én af de præsenterede
                analyser. </p>
              <p>Du vil nu blive præsenteret for et posteringoverblik, indeholdende alle de posteringer som er
                faldet i den valgte analyse. Du kan nu begynde at arbejde med potentialer, rette potentiale
                og behandle posteringer.</p>
            </div>

            <div class="section" #target2>
              <h2>Dashboard</h2>
              <ul>
                <li>Oversigt over KPI’er: Løsningen giver dig øjeblikkeligt indblik i fremgangen i
                  indhentning af potentiale pr. Regnskabsår.
                  <ul>
                    <li>Du kan nemt skifte imellem regnskabsår ved at klikke på årstallet, og vælge det
                      år du gerne vil se</li>
                    <li>Du kan yderligere klikke på ‘Afsluttede analyser’ for at få indblik i, hvilke
                      Moms Tjek analyser du har afsluttet pr. Regnskabsår. </li>
                    <li>‘Data til dato’ forklarer den seneste posteringsdato for det indlæste data</li>
                    <li>‘Data indlæst’ forklarer dato for seneste indlæsning af data i løsningen.</li>
                  </ul>
                </li>
                <img src="../../../../../assets/images/manual/1.jpg">
                <li>Senest anvendte analyser: Løsningen giver dig hurtig adgang til de analyser, du senest
                  har anvendt på din PC.
                  <ul>
                    <li>Løsningen husker yderligere de filtre du anvendte sidst, du brugte en given
                      analyse. </li>
                  </ul>
                </li>
                <img src="../../../../../assets/images/manual/2.jpg">
                <li>Favoritter: Løsningen giver dig muligheden for tilgå dine favoritanalyser fra forsiden
                  ved ét klik.
                  <ul>
                    <li>Analyser kan markeres som favorit, ved at klikke på den gule stjerne i top højre
                      hjørne af en analyse. </li>
                    <li>Løsningen gemmer dine filtreringsvalg for din valgte analyse.</li>
                  </ul>
                </li>
                <img src="../../../../../assets/images/manual/3.JPG">
              </ul>
            </div>

            <div class="section" #target3>
              <h2>Analyser</h2>
              <img class="tall-sidebar" src="../../../../../assets/images/manual/4.JPG">
              <ul>
                <li>
                  Analyser-siden giver dig adgang til alle mulige analyser udfærdiget af Moms Tjek
                  <ul>
                    <li>I venstre side af billedet ses alle mulige filtre. Ved et filter-valg opdaterer
                      analyserne med det samme. Dette betyder at de informationer, som du ser ved hver
                      analyse, opdateres i takt med valg af filtre.</li>
                  </ul>
                </li>
                <li>Analyser-siden giver dig mulighed for at sortere rækkefølgen, hvorpå Moms Tjek
                  analyserne præsenteres - Find blot ‘sorter-knappen’ i højre-top hjørne. Du kan sortere
                  efter følgende: Samlet potentiale, Resterende potentiale, realiseret resultat, antal
                  posteringer og analysetitel. </li>
                <img src="../../../../../assets/images/manual/5.JPG">
                <li>Analyser-siden giver dig mulighed for at få overblik over analyser i to formater: 1. Et
                  kasse-format, hvor hver analyse bliver præsenteret i en kasse, 2. Et liste-format, hvor
                  alle analyser bliver præsenteret i en tabel. Skift imellem de to formater på knapperne
                  under infocenteret. </li>
                <li>Analyser-siden giver dig mulighed for at eksportere listen over analyserne til Excel.
                  Udtrækket har relevant information på analyse-niveau.
                  Udtrækket har informationer, som afspejler de valgte filtreringer på analysesiden</li>
              </ul>
            </div>

            <div class="section" #target4>
              <h2>Posteringsoverblik ved valgt analyse</h2>
              <h4>Filter</h4>
              <p>Ved valg af en analyse, får du præsenteret en række posteringer, der er faldet i en given
                analyse. Vi har taget dine filtreringsvalg med fra analyser-siden. </p>
              <p>Du kan nu vælge at filtrere yderligere i filterbaren i venstre side.</p>

              <h4>Kolonnefiltrer</h4>
              <p>Du kan yderligere filtrere på hver kolonne i tabellen. Tilgå blot input-feltet ved hver
                kolonne for at filtrere. Disse filtre virker i kombination med dine filtreringsvalg, i din
                filtermenu i venstre side af skærmen. </p>
              <img src="../../../../../assets/images/manual/6.JPG">

              <h4>Posteringer i alt - Tæller</h4>
              <p>Posteringsoverblikket præsenterer altid antallet af posteringer gældende, for din analyse, i
                kombination med dine filtreringsvalg. Herudover vil posteringoverblikket angive, hvor mange
                poster du har indlæst i tabellen. En analyse indlæser som standard 200 posteringer ad
                gangen. </p>
              <img src="../../../../../assets/images/manual/7.JPG">

              <h4>Behandle posteringer & flytte posteringer til anden analyse. </h4>
              <p>Løsningen giver mulighed for at udføre 4 forskellige handlinger med én, eller flere
                posteringer ad gangen. En eller flere posteringer skal blot markeres i den lille ‘check box’
                i venstre side, for hver posteringslinje. Vælg herefter ‘handlings-knappen’ i top højre
                hjørne - Vælg din handling, og klik herefter ‘Udfør’: </p>
              <img src="../../../../../assets/images/manual/8.JPG">
              <p>Ubehandlet = Denne handling resulterer i at posteringslinjen får status “Ubehandlet”.
                Herudover låses feltet ‘rettet’ potentiale op, for at man kan indtaste en værdi heri. </p>
              <p>Intet potentiale = Denne handling resulterer i at posteringslinjen får status “Behandlet”.
                Herudover sættes posteringen til værdien 0,00 DKK, i kolonnen ‘Resultat’. Dette betyder at
                man har rettet potentialeresultatet til 0,00 DKK. </p>
              <p>Igangværende = Denne handling resulterer i at posteringslinjen får status “Igangværende”.
                Der sker ikke yderligere med ‘potentiale’, ‘rettet potentiale’ og ‘resultat’.</p>
              <p>Reguler moms = Denne handling resulterer i at posteringslinjen får status “Behandlet”.
                Herudover accepteres værdien i ‘Rettet potentiale’ eller ‘Potentiale’. Denne værdi
                tilskrives i kolonnen ‘Resultat’. Løsningen vil altid acceptere ‘Rettet potentiale’ fremfor
                potentiale. </p>
              <p>Flyt til anden analyse = Denne handling resulterer i at de valgte posteringer nu kan flyttes
                til en anden analyse. Løsningen præsenterer et overblik over alle analyser. Løsningen
                præsenterer, hvilke analyser den foreslår du flytter dem til. Forslag præsenteres ved at
                analyserne er farvelagt, hvori de analyser der ikke foreslås ikke, er farvelagt. </p>
            </div>

            <div class="section" #target9>
              <h2>Kolonnevalg- og indstillinger</h2>
              <p>Løsningen giver mulighed for at lave egne indstillinger for visning af data. En bruger har derfor
                mulighed for at vælge egen rækkefølge på visning af kolonner, samt hvilke kolonner der skal vises.
              </p>
              <p>Dette indstilles ved at klikke på knappen ‘kolonner’.</p>
              <img src="../../../../../assets/images/manual/18.png">
              <p>Ved et klik på ‘kolonner’ komme mulige indstillinger til syne.</p>
              <img src="../../../../../assets/images/manual/17.jpg">
              <p>En bruger kan indstille sine egne visninger.
                Et flueben ud fra et felt betyder at dette felt vises i din posteringstabel.
                Sorteringen af rækkerne kan yderligere ændres på, ved at trække rundt i rækkefølgen af felterne.
                Dette gøres blot ved at trække i felterne med musen/markøren. Sorteringen sker fra top mod bund.</p>

              <p>Når indstillingerne er valgt, har man følgende muligheder: </p>

              <p>Gem = Indstillingerne gemmens nu for den åbne analyse. </p>

              <p>Gem midlertidigt = Sorteringen og valgte rækker vises kun I denne session. Dine valg bliver derfor
                ikke
                gemt til en anden gang. </p>

              <p>Standardindstillinger = Løsningen gendanner dine indstillinger for den åbne analyse til Moms Tjek
                standardindstillinger. </p>

              <p>Annuller = Annullerer dine valg/indstillinger. Vinduet lukker, og ingen ændringer forekommer.</p>

              <p>OBS: Vær opmærksom på at ændringer i indstillinger, kun gælder for den enkelte analyse. Det er derfor
                muligt at have én indstilling pr. analyse I løsningen. </p>

            </div>


            <div class="section" #target5>
              <h2>Flyt til anden analyse</h2>
              <img src="../../../../../assets/images/manual/9.JPG">

              <h4>Fremhæv postering – Yderligere information</h4>
              <p>Du kan få yderligere information om en given postering. Det kræver blot at du klikker på en
                posteringslinje. Heri præsenterer vi alle posteringsdata, tilhørende faktura, relaterede
                poster, og noteinformationer på en given postering. </p>
              <img src="../../../../../assets/images/manual/10.JPG">

              <h4>Andre posteringer på kontonummer og relaterede poster på faktura</h4>
              <p>Har du klikket på en posteringslinje, præsenterer løsningen til højre for fakturaen, to
                moduler: 1. Andre posteringer på kontonummer og 2. Relaterede posteringer til faktura. </p>
              <p>’Andre posteringer på konto nr.’ returnerer en liste over alle poster på samme artskonto.
              </p>
              <p>’Relaterede posteringer til faktura’ returnerer poster med samme faktura oplysninger. </p>
              <p>Øverst ses et søgefelt. Søgefeltet kan anvendes til at søge efter tekst eller beløb.
                Indtaster du i feltet har det effekt i begge moduler. Herudover kan du sortere linjerne
                efter leverandør A-Z, eller efter beløbs værdi, stigende/faldende</p>
              <img src="../../../../../assets/images/manual/11.JPG">

              <h4>Tilføj note</h4>
              <p>Det er muligt at vedhæfte en note på en eller flere posteringer. Posteringen skal blot
                fremhæves ved at klikke på en given posteringslinje. Herefter klikkes der på “NOTER-baren” i
                venstre side af fremhævelsen. Du vil nu blive præsenteret for eventuelle noter allerede
                vedhæftet posteringen. </p>
              <p>Vil du gerne vedhæfte en ny besked, klikkes der på “+Note” </p>
              <p>Her kan du vælge om du vil tilføje en person, som skal blive underrettet af denne besked i
                løsningen.</p>
              <p>Herefter indtastes noten, som ønskes tilføjet i input-feltet. Du kan tilføje en note med op til 2.000
                karakterer.</p>
              <p>Du kan nederst vælge, om du blot vil vedhæfte denne note til den postering som du har
                fremhævet, eller om den skal vedhæftes alle valgte posteringer i posteringsoversigten (ved
                flueben i ‘check-box’en’). Klik herefter ‘Gem for’. Din note er nu gemt. </p>
              <div class="row">
                <div class="col-6">
                  <img src="../../../../../assets/images/manual/12.JPG">
                </div>
                <div class="col-6">
                  <img src="../../../../../assets/images/manual/13.JPG">
                </div>
              </div>

              <h4>Excel eksport</h4>
              <p>Det er muligt at eksportere et posteringsoverblik til en Excel-fil. Filen indeholder alle
                posteringsoplysninger og tilhørende noter. </p>
              <p>Løsningen giver mulighed for at udtrække maksimum 35.000 posteringer pr. udtræk. Vær
                opmærksom på, at jo større forespørgsel på antal af posteringer, jo længere tid vil
                løsningen bruge på at producere dit excel-udtræk. </p>
              <img src="../../../../../assets/images/manual/14.JPG">
            </div>

            <div class="section" #target6>
              <h2>Infocenter</h2>
              <img src="../../../../../assets/images/manual/22.JPG"
                style="float: left; max-width: 380px; padding-right: 30px;">

              <p>Hver bruger har sit eget infocenter. Dette infocenter holder dig opdateret på
                posteringsnoter, hvor dine kollegaer har underrettet dig. Herudover får du også dit
                nyhedsflow herfra. Det kan eksempelvis være nyheder om nye ikrafttrædelser og love vedr.
                Momsrefusion, men også nyheder om ny funktionalitet i Moms Tjek løsningen. Infocenteret
                findes i top højre hjørne: </p>
              <img src="../../../../../assets/images/manual/15.JPG">
              <p>Når du har åbnet infocenteret, bliver du præsenteret for alle egne noter, og underretninger
                fra kollegaer. De bliver præsenteret her indtil, at du markerer dem som læst ved at klikke
                på ‘X’. Noter vedhæftet af dig selv har en bleg <span class="green">grøn</span> farve,
                hvorimod noter hvori kollegaer underretter dig, har en <span class="sand">sandfarve</span>,
                og nyheder har farven <span class="gray">hvid</span>.</p>
              <p>Du kan tilgå posteringen, eller posteringerne noten vedrører, ved at klikke på ‘Gå til
                postering’ på den enkelte note.</p>
              <p>Herudover kan du altid tilgå alle historiske noter og nyheder i toppen, ved at klikke på
                “Alle Nyheder” eller “Alle Noter”.</p>


            </div>

            <div class="section" #target8>
              <h2>Leder Dashboard</h2>
              <p>Leder dashboardet giver værdifuld indsigt i fremgangen i arbejdet med moms i din organisation.
                Indblik
                i
                anvendelse, samt opnået værdi af løsningen og arbejdet med moms.</p>
              <p>Vær opmærksom på at denne side kun er tilgængelig, såfremt modulet er tilkøbt.</p>
              <p>Det er øverst muligt at vælge ‘Global periode’. Dette betyder at alle 6 grafer filtreres på den
                valgte
                periode på én gang. Vil man yderligere blot ændre perioden på den ene graf, kan man i top-venstre
                hjørne
                for den enkelte graf ændre
                perioden. </p>
              <p>Hver enkelt graf kan forstørres på ‘forstør-knappen’ <img
                  src="../../../../../assets/images/manual/19.JPG" style=" position: relative; top: 8px; margin: 0"> i
                top-højre hjørne af graferne. </p>
              <img src="../../../../../assets/images/manual/21.png">
              <p><b>Graf 1</b></p>
              <p>Denne graf viser det samlede potentiale (lyseblå søjle) og det realiserede potentiale (mørkeblå
                søjle).
                Søjlerne er fordelt ud på kontoplanens hovedkonti. Det er muligt at klikke sig ned I næste niveau på
                en
                søjle. Dette kan ske ved at
                dobbeltklikke på en søjle. Vil man gerne tilbage til niveauet over, findes ‘tilbage-knappen’ I
                top-højre
                hjørne af hver graf. Denne er kun synlig, hvis man kan gå et niveau tilbage. </p>
              <p>Det er yderligere muligt at filtrere søjlerne fra ved at klikke på enten ‘Samlet potentiale’ eller
                ‘Realiseret resultat’ over søjlerne. </p>
              <p><b>Graf 2</b></p>
              <p>Denne graf viser det samlede potentiale (lyseblå søjle) og det resterende potentiale (mørkeblå
                søjle).
                Søjlerne er fordelt ud på kontoplanens hovedkonti. Det er muligt at klikke sig ned I næste niveau på
                en
                søjle. Dette kan ske ved at
                dobbeltklikke på en søjle. Vil man gerne tilbage til niveauet over, findes ‘tilbage-knappen’ I
                top-højre
                hjørne af hver graf. Denne er kun synlig, hvis man kan gå et niveau tilbage.</p>
              <p>Det er yderligere muligt at filtrere søjlerne fra ved at klikke på enten ‘Samlet potentiale’ eller
                ‘rest
                potentiale’ over søjlerne.</p>
              <p><b>Graf 3</b></p>
              <p>Denne graf viser et overblik over fremgangen med arbejdet for alle tilgængelige analyser pr.
                Regnskabsår.
                Dette betyder at for hver analyse gives følgende data: Realiseret resultat I DKK, resterende
                potentiale
                i
                DKK, procentuel status
                på antal af behandlede posteringer, seneste dato for anvendelse af analysen. </p>
              <p>Det er muligt at sortere tabellen ved at klikke på kolonnenavnene i grafen. </p>
              <p><b>Graf 4</b></p>
              <p>Denne graf viser det realiserede potentiale (lyseblå søjle) og antal behandlede posteringer (mørkeblå
                søjle). Søjlerne er fordelt ud på måneder. Der kan ved siden af valg af periode, filtreres på enkelte
                brugere på løsningen. Således
                får man de to værdier udregnet for bruger X.</p>
              <p>Det er yderligere muligt at filtrere søjlerne fra ved at klikke på enten ‘Realiseret potentiale’
                eller
                ‘Antal behandlinger’ over søjlerne.</p>
              <p><b>Graf 5</b></p>
              <p>Denne graf viser et overblik og indlæste data fordelt på to søjler: Den lyseblå viser antal
                posteringer
                og den mørkeblå viser antal fakturaer. Disse er fordelt ud på måneder for et år.</p>
              <p>I top-højre hjørne af grafen ses yderligere dato for seneste indlæsning af data I løsningen. </p>
              <p>Det er yderligere muligt at filtrere søjlerne fra, ved at klikke på enten ‘Antal posteringer’ eller
                ‘Antal fakturaer’ over søjlerne.</p>
              <p><b>Graf 6</b></p>
              <p>Denne graf viser et overblik over alle brugere på løsningen. Den giver indsigt i følgende pr. bruger:
                Seneste login på løsningen, antal posteringer behandlet indenfor det valgte regnskabsår, antal
                posteringer
                sat i status igangværende
                indenfor det valgte regnskabsår, samt realiseret resultat i DKK indenfor det valgte regnskabsår.</p>
              <p>Det er muligt at sortere tabellen ved at klikke på kolonnenavnene i grafen.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>