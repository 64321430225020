import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MessagesService } from '@modules/messages/services/messages.service';
import { UntypedFormControl } from '@angular/forms';
import { Message } from 'src/app/api/vat';

@Component({
  selector: 'app-all-messages',
  templateUrl: './all-messages.component.html',
  styleUrls: ['./all-messages.component.scss'],
})
export class AllMessagesComponent implements OnInit, OnDestroy {
  // Icons
  faTimes = faTimes;
  searchString: string = '';
  searchValue = new UntypedFormControl('');
  currentDialog: string;
  constructor(
    public dialogRef: MatDialogRef<AllMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private internalMessagesService: MessagesService
  ) {}

  ngOnInit() {
    this.currentDialog = this.data.dataKey;
    this.internalMessagesService.SelectedMessage$.subscribe((o) =>
      this.close()
    );

    this.searchValue.valueChanges.subscribe((value) => {
      this.searchString = value;
    });
  }

  ngOnDestroy(): void {}

  close() {
    this.dialogRef.close();
  }
}
