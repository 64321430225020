<div #scroller class="table-content" (scroll)="onTableScroll($event)">
  <table mat-table class="table"
    [ngClass]="{'if-2-sticky' : checkStickyEnd() === 2, 'if-3-sticky' : checkStickyEnd() === 3}"
    [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortDirection="desc" matSortDisableClear>
    <ng-container *ngFor="let column of displayedColunms">
      <ng-container matColumnDef="{{column.key}}" [sticky]="column.isStickyStart" [stickyEnd]="column.isStickyEnd">
        <ng-container [ngSwitch]="true">
          <ng-container *ngSwitchCase="column.type === 'checkBox'">
            <th mat-header-cell *matHeaderCellDef (click)="$event.stopPropagation()">
              <div class="selected-postings"
                [ngClass]="getCheckboxSelectedCount > 9 ? 'selected-postings__plus-10' : ''">
                {{getCheckboxSelectedCount | number:'':'da-DK'}}
              </div>
              <div class="table__no-filter">
                <mat-checkbox [indeterminate]="isIndeterminate()" [checked]="isAllChecked"
                  (change)="toggleAll($event.checked)">
                </mat-checkbox>
              </div>
            </th>
          </ng-container>

          <ng-container
            *ngSwitchCase="column.type === 'currency' || column.type === 'invoiceCurrency' || column.type === 'inputCurrency'">
            <th class="align-right" mat-header-cell *matHeaderCellDef mat-sort-header
              [ngClass]="hasColumnFilter(column.key) ? 'has-filter' : ''">
              <div class="table__header-text">
                {{column.name}}
              </div>
              <div class="table__header-filter" (click)="$event.stopPropagation()">
                <button class="table__filter-btn" [matMenuTriggerFor]="menu">Filtre <fa-icon [icon]="faCaretDown">
                  </fa-icon></button>
                <mat-menu class="table__filter-menu" #menu="matMenu">
                  <div class="table__filter-menu__wrapper" (click)="$event.stopPropagation()">
                    <app-column-filter-multi-text-box #columnFilterMultiTextbox
                      (SearchValue)="columnFilterMultiTextBox($event, column.key)" [columnKey]="column.key">
                    </app-column-filter-multi-text-box>
                  </div>
                </mat-menu>
              </div>
              <span class="table__reset-filter" (click)="$event.stopPropagation()">
                <fa-icon *ngIf="hasColumnFilter(column.key)" [icon]="faTimes"
                  (click)="resetColumnFilter(column.key); columnFilterMultiTextbox.clearAllInputs()">
                </fa-icon>
              </span>
            </th>
          </ng-container>

          <ng-container *ngSwitchCase="column.type === 'icon'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [start]="'desc'" class="noteIcon"
              [ngClass]="hasColumnFilter(column.key) ? 'has-filter' : ''">
              <div class="table__no-filter">
                <fa-icon [icon]="faComment"></fa-icon>
              </div>
            </th>
          </ng-container>

          <ng-container *ngSwitchCase="'filterSelect'.indexOf(column.type) > -1 ">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
              [ngClass]="hasColumnFilter(column.key) ? 'has-filter' : ''">
              <div class="table__header-text">
                {{column.name}}
              </div>
              <div class="table__header-filter" (click)="$event.stopPropagation()">
                <button class="table__filter-btn" [matMenuTriggerFor]="menu">Filtre <fa-icon [icon]="faCaretDown">
                  </fa-icon></button>
                <mat-menu class="table__filter-menu" #menu="matMenu">
                  <div class="table__filter-menu__wrapper" (click)="$event.stopPropagation()">
                    <mat-selection-list #checks
                      (selectionChange)="ColumnFilterCheckBox($event?.source?._value, column.key)">
                      <mat-list-option *ngFor="let check of column.filterSelect" value="{{check.value}}"
                        [selected]="isColumnCheckboxSelected(check.value, column.key)">
                        {{check?.viewValue}}
                      </mat-list-option>
                    </mat-selection-list>
                  </div>
                </mat-menu>
              </div>
              <span class="table__reset-filter" (click)="$event.stopPropagation()">
                <fa-icon *ngIf="hasColumnFilter(column.key)" [icon]="faTimes"
                  (click)="resetColumnFilter(column.key); checks.deselectAll();">
                </fa-icon>
              </span>
            </th>
          </ng-container>

          <ng-container *ngSwitchCase="column.type === 'date'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
              [ngClass]="hasColumnFilter(column.key) ? 'has-filter' : ''">
              <div class="table__header-text">
                {{column.name}}
              </div>
              <div class="table__header-filter-date" (click)="$event.stopPropagation()">
                <mat-form-field floatLabel="never">
                  <input #date readonly placeholder="Filtrer" [min]="minDate" [max]="maxDate" matInput
                    [matDatepicker]="picker" (focus)="picker.open()"
                    (dateChange)="ColumnFilterDate($event, column.key)">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker startView="month"></mat-datepicker>
                </mat-form-field>
              </div>
              <span class="table__reset-filter" (click)="$event.stopPropagation()">
                <fa-icon *ngIf="hasColumnFilter(column.key)" [icon]="faTimes"
                  (click)="resetColumnFilter(column.key); date.value='';">
                </fa-icon>
              </span>
            </th>
          </ng-container>
          <ng-container *ngSwitchCase="column.type === 'year'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
              [ngClass]="hasColumnFilter(column.key) ? 'has-filter' : ''">
              <div class="table__header-text">
                {{column.name}}
              </div>
              <div class="table__header-filter-year" (click)="$event.stopPropagation()">
                <mat-form-field dateFormat2 floatLabel="never">
                  <input #year readonly placeholder="Filtrer" value="{{chosenYear}}" [min]="minDate" [max]="maxDate"
                    matInput [matDatepicker]="yearpicker" (focus)="yearpicker.open()"
                    (dateChange)="ColumnFilterDate($event, column.key)">
                  <mat-datepicker-toggle matSuffix [for]="yearpicker"></mat-datepicker-toggle>
                  <mat-datepicker #yearpicker startView="multi-year"
                    (yearSelected)="ColumnFilterYear($event, column.key, yearpicker)">
                  </mat-datepicker>
                </mat-form-field>
              </div>
              <span class="table__reset-filter" (click)="$event.stopPropagation()">
                <fa-icon *ngIf="hasColumnFilter(column.key)" [icon]="faTimes"
                  (click)="resetColumnFilter(column.key); year.value='';">
                </fa-icon>
              </span>
            </th>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <th mat-header-cell *matHeaderCellDef mat-sort-header
              [ngClass]="hasColumnFilter(column.key) ? 'has-filter' : ''">
              <div class="table__header-text">
                {{column.name}}
              </div>
              <div class="table__header-filter" (click)="$event.stopPropagation()"
                (keydown.space)="$event.stopPropagation()">
                <app-column-filter-text-box #defaultColumns (SearchValue)="columnFilterTextBox($event, column.key)"
                  [placeHolder]="'Filtrer'" [columnKey]="column.key" [searchType]="'like'"
                  [columnFilterText]="columnFilter?.columnFilters"></app-column-filter-text-box>
              </div>
              <span class="table__reset-filter" (click)="$event.stopPropagation()">
                <fa-icon *ngIf="hasColumnFilter(column.key)" [icon]="faTimes"
                  (click)="resetColumnFilter(column.key); defaultColumns.clearInput();">
                </fa-icon>
              </span>
            </th>
          </ng-container>

        </ng-container>

        <ng-container [ngSwitch]="column.type">
          <div *ngSwitchCase="'checkBox'">
            <td mat-cell *matCellDef="let row" (mousedown)="$event.stopPropagation()"
              (click)="clickCheckboxArea(row, $event)">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>

              <ng-template #notLoading>
                <mat-checkbox (change)="toggle(row, $event.checked)" [(ngModel)]="row.isSelected">
                </mat-checkbox>
              </ng-template>
            </td>
          </div>

          <div *ngSwitchCase="'icon'">
            <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>
              <ng-template #notLoading>
                <span class="has-note-wrapper" *ngIf="row[column.key] === true || row['hasRelatedNote'] === true">
                  <fa-icon [icon]="faComment" [ngClass]="{'isRelated': row['hasRelatedNote'] === true}"></fa-icon>
                  <div class="has-note-wrapper__popup overlay__content">
                    <app-messages [postingNoteIds]="row.postingNoteIds" [place]="'tableHover'"></app-messages>
                  </div>
                </span>
              </ng-template>
            </td>
          </div>

          <div *ngSwitchCase="'agreement'">
            <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>
              <ng-template #notLoading>
                <a target=”_blank” href="https://offentlig-moms.dk/?autologin_code=dBgx7dVWSjXt1ko9JC5tCu3Hg4uqCdUf">
                  {{row[column.key]}}</a>
              </ng-template>
            </td>
          </div>

          <div *ngSwitchCase="'currency'">
            <td class="right" mat-cell *matCellDef="let row" [ngStyle]="{'max-width':column.key === 'accountNo' ? 'auto' : '180px',
                      'min-width':column.key === 'accountNo' ? '50px' : 'auto' }">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>
              <ng-template #notLoading>
                {{row[column.key] | vatCurrency}}
              </ng-template>
            </td>
          </div>
          <div *ngSwitchCase="'invoiceCurrency'">
            <td class="right" mat-cell *matCellDef="let row" [ngStyle]="{'max-width':column.key === 'accountNo' ? 'auto' : '180px',
                                'min-width':column.key === 'accountNo' ? '50px' : 'auto' }">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>
              <ng-template #notLoading>
                {{row[column.key] | vatCurrency : getCurrency(row)}}
              </ng-template>
            </td>
          </div>

          <div *ngSwitchCase="'inputCurrency'">
            <td mat-cell class="right" *matCellDef="let row" (click)="$event.stopPropagation()"
              (mousedown)="$event.stopPropagation()">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>
              <ng-template #notLoading>
                <app-corrected-potentiale-input-box (inputChanged)="changeOfCorrectedPotential($event)"
                  [columnKey]="column.key" [row]="row">
                </app-corrected-potentiale-input-box>
                <span class="table__currency">DKK</span>
              </ng-template>
            </td>
          </div>

          <ng-container *ngSwitchCase="'date'">
            <td mat-cell *matCellDef="let row">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>
              <ng-template #notLoading>
                <span matTooltip="{{row[column.key] | uppercaseSpace: column.key |  date:'dd-MM-yyyy': 'da-DK'}}">
                  {{row[column.key] | date:'dd-MM-yyyy': 'da-DK'}}
                </span>
              </ng-template>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'year'">
            <td mat-cell *matCellDef="let row">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>
              <ng-template #notLoading>
                <span matTooltip="{{row[column.key]}}">
                  {{row[column.key]}}
                </span>
              </ng-template>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'filterSelect'">
            <span *ngIf="column.key !== 'bdoAudit'; else bdoAudit">
              <td mat-cell *matCellDef="let row">
                <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
                </div>
                <ng-template #notLoading>
                  <span matTooltip="{{getDataValue(row, column.key)| uppercaseSpace: column.key}}">
                    {{getDataValue(row, column.key)| status | uppercaseSpace: column.key}}
                  </span>
                </ng-template>
              </td>
            </span>
            <ng-template #bdoAudit>
              <td mat-cell *matCellDef="let row" (mousedown)="$event.stopPropagation()">
                <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
                </div>
                <ng-template #notLoading>
                  <div>
                    <mat-form-field>
                      <mat-select [(ngModel)]="row[column.key]"
                        (selectionChange)="auditSelectionChanged($event['value'], row)">
                        <mat-option *ngFor="let audit of audits" [value]="audit">
                          {{audit}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </ng-template>
              </td>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <td mat-cell *matCellDef="let row">
              <div *ngIf="isTableLoading; else notLoading" class="content-place-holder">
              </div>
              <ng-template #notLoading>
                <span *ngIf="column.key !== 'art'; else art"
                  matTooltip="{{getDataValue(row, column.key)| uppercaseSpace: column.key}}">
                  {{getDataValue(row, column.key)| status | uppercaseSpace: column.key}}
                </span>
                <ng-template #art>
                  <span matTooltip="{{row[column.key] | uppercaseSpace: column.key}}" aria-label="">
                    {{getDataValue(row, column.key) | uppercaseSpace: column.key}}
                  </span>
                </ng-template>
              </ng-template>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnKeys; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnKeys;" (mousedown)="addRowAsSelected(row, $event)" [ngClass]="{
          'selected-row-color' : row.isSelected,
          'current-chosen-row' : isCurrentChosenRow(row) }"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="table__no-data" class="mat-cell" colspan="50">Intet data der matcher filteret</td>
    </tr>

  </table>
</div>