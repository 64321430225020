import { Component, OnInit } from '@angular/core';
import { LeaderDashBoardHelper, TableInformation } from '@modules/leader-dashboard/Helpers/LeaderDashBoardHelper';
import { YearSelectService } from '@modules/leader-dashboard/services/year-select.service';
import { AnalysisStatus, FunctionsStatistic, LeaderDashboardService, PostingPoll, User, UserInformation, UserManagementPoll } from 'src/app/api/vat';

export interface SelectedFunction {
  functionNo: string;
  functionName: string;
  dataSetOne: number;
  dataSetTwo: number;
  checkboxToggle: boolean;
}

export interface CardDiagramData {
  monthKey: number;
  dataSetOne: number;
  dataSetTwo: number;
  menuInfo: string;
}
@Component({
  selector: 'app-leader-dashboard',
  templateUrl: './leader-dashboard.component.html',
  styleUrls: ['./leader-dashboard.component.scss'],
  providers: [YearSelectService]
})
export class LeaderDashboardComponent implements OnInit {
  // componentIdNames
  readonly realisedPotentialFunctionChart: string =
    'realisedPotentialFunctionChart';
  readonly remainingPotentialFunctionChart: string =
    'remainingPotentialFunctionChart';
  readonly postingAndInvoicePollsChart: string = 'postingAndInvoicePollsChart';
  readonly userManagementsPollsChart: string = 'userManagementsPollsChart';

  private componentloaded: boolean = false;

  postingAndInvoicePollsData: CardDiagramData[] = [];
  userManagementsPollsData: CardDiagramData[] = [];
  realisedPotentialFunctionData: SelectedFunction[] = [];
  remainingPotentialFunctionData: SelectedFunction[] = [];
  userInformation: TableInformation;
  potentialStatusTable: TableInformation;
  users: any[] = [];

  constructor(private leaderDashboardService: LeaderDashboardService) { }

  ngOnInit(): void {
    // this.updateUserInformationTable();
  }

  yearSelectionFunctionDiagramChange(year: number, chartName: string) {
    switch (chartName) {
      case this.realisedPotentialFunctionChart:
        this.getFunctionStatistic(year).then((func: FunctionsStatistic[]) => {
          this.realisedPotentialFunctionData = this.getFunctionChartDataSet(
            func,
            'totalPotential',
            'realisedPotential'
          );

          if (this.componentloaded === false) {
            this.remainingPotentialFunctionData = this.getFunctionChartDataSet(
              func,
              'totalPotential',
              'remainingPotential'
            );
            this.componentloaded = true;
          }
        });
        break;
      case this.remainingPotentialFunctionChart:
        if (this.componentloaded === true)
          this.getFunctionStatistic(year).then((func: FunctionsStatistic[]) => {
            this.remainingPotentialFunctionData = this.getFunctionChartDataSet(
              func,
              'totalPotential',
              'remainingPotential'
            );
          });
        break;
      default:
        break;
    }
  }

  selectionCardDiagramChange(event: { year; item }, chartName: string) {
    switch (chartName) {
      case this.postingAndInvoicePollsChart:
        this.updatePostingAndInvoicePollsCardDiagram(event.year);
        break;
      case this.userManagementsPollsChart:
        this.updateUserManagementPolls(event.year, event.item);
        break;
      default:
        break;
    }
  }

  private getFunctionChartDataSet(
    func: FunctionsStatistic[],
    propertyNameOne: string,
    propertyNameTwo: string
  ): SelectedFunction[] {
    return func.map((f: FunctionsStatistic) => {
      return {
        functionNo: f.functionNo,
        functionName: f.functionName,
        dataSetOne: f[propertyNameOne],
        dataSetTwo: f[propertyNameTwo],
        checkboxToggle: false,
      };
    });
  }

  public updateUserInformationTable(year: number): Promise<void> {
    return this.leaderDashboardService
      .getAllUsersInformation(year)
      .toPromise()
      .then((userInformations: UserInformation[]) => {
        this.userInformation = LeaderDashBoardHelper.mapToTableInformation('userinformation', userInformations);
        this.users = this.userInformation.data.map(
          (u) => ({ userId: u['userId'], name: u['userName'] })
        );
        this.users.unshift({ userId: null, name: 'Alle' });
      });
  }

  private updateUserManagementPolls(year: number, userId): void {
    this.leaderDashboardService
      .getUsageOfApplicationByUser(year, userId)
      .subscribe((userManagementPoll: UserManagementPoll[]) => {
        this.userManagementsPollsData = userManagementPoll.map(
          (p: UserManagementPoll) => {
            return {
              dataSetOne: p.realisedPotentialAmount,
              dataSetTwo: p.postingHandledAmount,
              menuInfo: null,
              monthKey: p.month,
            };
          }
        );
      });
  }


  public doubleClickBarEvent(
    event: { year; functionNo },
    chartName: string
  ): void {
    this.getFunctionStatistic(event.year, event.functionNo).then((func) => {
      switch (chartName) {
        case this.realisedPotentialFunctionChart:
          this.realisedPotentialFunctionData = this.getFunctionChartDataSet(
            func,
            'totalPotential',
            'realisedPotential'
          );
          break;
        case this.remainingPotentialFunctionChart:
          this.remainingPotentialFunctionData = this.getFunctionChartDataSet(
            func,
            'totalPotential',
            'remainingPotential'
          );
          break;
        default:
          break;
      }
    });
  }

  public tableCardAnalysisStatus(year: number): void {
    this.leaderDashboardService
      .getAnalysisStatus(year)
      .subscribe((analysisStatus: AnalysisStatus[]) => {
        this.potentialStatusTable = LeaderDashBoardHelper.mapToTableInformation('analysisstatus', analysisStatus);
      });
  }

  // public tableCardUserInfomation(year: number): void {
  //   this.leaderDashboardService
  //     .leaderDashboardGetAllUsersInformation(year)
  //     .subscribe((userInformation: UserInformation[]) => {
  //       // this.potentialStatusTable = LeaderDashBoardHelper.mapToTableInformation('analysisstatus', analysisStatus);
  //     });
  // }

  public updatePostingAndInvoicePollsCardDiagram(year: number): void {
    this.leaderDashboardService
      .getPollOfPostingAndInvoices(year)
      .subscribe((value: PostingPoll[]) => {
        this.postingAndInvoicePollsData = value.map((p: PostingPoll) => {
          return {
            dataSetOne: p.totalPostings,
            dataSetTwo: p.totalInvoices,
            menuInfo: new Date(p.dataLastLoaded).toLocaleDateString('en-GB'),
            monthKey: p.month,
          };
        });
      });
  }

  private getFunctionStatistic(
    year: number,
    level: string = ' '
  ): Promise<FunctionsStatistic[]> {
    return new Promise<FunctionsStatistic[]>((resolve, rejects) => {
      this.leaderDashboardService
        .getFunctionStatistic(year, level)
        .toPromise()
        .then((func) => {
          resolve(func);
        });
    });
  }
}
