import { Injectable } from '@angular/core';
import { BehaviorSubject, observable } from 'rxjs';
import { User } from 'src/app/api/vat';

@Injectable({
  providedIn: 'root'
})
export class InternalUserService {
  // private user: User = null;
  private user = new BehaviorSubject<User>(null);
  public user$ = this.user.asObservable();

  constructor() {
  }

  public updateUser(user: User): void {
    this.user.next(user);
  }
}
