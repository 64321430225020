<div class="view-switcher" [ngClass]="filterBarState ? '' : 'full-width'">
  <table class="table-view" mat-table [dataSource]="dataSource" matSort [ngClass]="view == 'table' ? '' : 'hidden'">

    <ng-container *ngFor="let column of columns">

      <ng-container [ngSwitch]="column.type">

        <ng-container *ngSwitchCase="'number'">
          <ng-container matColumnDef="{{column.key}}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.name}} </th>
            <td mat-cell *matCellDef="let row"> {{row[column.key] | number:'':'da-DK'}} </td>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'currency'">
          <ng-container matColumnDef="{{column.key}}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.name}} </th>
            <td mat-cell *matCellDef="let row"> {{row[column.key] | vatCurrency }} </td>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ng-container matColumnDef="{{column.key}}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.name}} </th>
            <td mat-cell *matCellDef="let row"> {{row[column.key]}} </td>
          </ng-container>
        </ng-container>

      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnKeys; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnKeys;" (click)="navigateToPosting(row)"
      [ngClass]="row.analysisId === globalAnalysisId ? 'total-row' : ''"></tr>
  </table>

  <div [ngClass]="view == 'card' ? '' : 'hidden'">
    <div class="container-fluid speci-col">
      <div class="row">
        <div class="col-12 col-md-3" *ngFor="let item of dataSource.data">
          <app-analyze-card [analysisFilter]="analysisFilter" [analyze]="item"></app-analyze-card>
        </div>
      </div>
    </div>
  </div>

</div>